import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useCallback, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Divider, EllipsisText, Flex, FlexSpan, H1, H2, Icon, IconButton, Link, YellowButton, } from "../../../../ui";
import { BusinessPlanHeaderContainer, BusinessPlanHeaderLabel } from "./styled";
import { DIALOGS } from "../../../../constants/dialogs";
import { useDialog, usePromBusinessItems } from "../../../../hooks";
import { formatNumber } from "../../../../utils";
import promBusinessPlanBack from "../../../../assets/images/prom-bp-back.jpg";
export const BusinessPlanHeader = ({ data, requestParams }) => {
    const openDialog = useDialog();
    const documents = useMemo(() => data?.blocks.find(item => item.id === 8)?.values, [data]);
    const { businessId, conceptId } = useParams();
    const [searchParams] = useSearchParams();
    const formatId = searchParams.get("formatId");
    const objectId = searchParams.get("objectId");
    const [businessItems] = usePromBusinessItems();
    const businessConcept = useMemo(() => {
        return businessItems?.find(item => item.id === Number(businessId))?.business_concepts?.find(concept => concept.id === Number(conceptId));
    }, [businessId, businessItems, conceptId]);
    const isDownloadDisabled = useMemo(() => !data || data.header.npv <= 0, [data]);
    const onDownload = useCallback(() => {
        window.open(`${process.env.NODE_ENV === "development" ? process.env.API_URL : "/"}api/prom_businessplan/pdf/${formatId}/${objectId}?${new URLSearchParams(requestParams)}`, "new");
    }, [formatId, objectId, requestParams]);
    if (!data) {
        return null;
    }
    return (_jsxs(BusinessPlanHeaderContainer, { backgroundImage: promBusinessPlanBack, flat: true, children: [_jsxs(Flex, { justifyContent: "space-between", children: [_jsxs(FlexSpan, { mr: "2rem", flexGrow: 1, children: [_jsx(H2, { children: _jsx(EllipsisText, { lines: 1, children: data.header.concept }) }), _jsxs(Flex, { mt: "0.25rem", alignItems: "center", children: [_jsx(FlexSpan, { children: _jsxs(BusinessPlanHeaderLabel, { children: ["\u041F\u043B\u043E\u0449\u0430\u0434\u044C ", data.header.format] }) }), _jsx(FlexSpan, { children: _jsx(Divider, { vertical: true, color: "rgba(255, 255, 255, 0.15)", m: "0 0.5rem" }) }), _jsx(FlexSpan, { children: _jsx(Link, { icon: "info_modal2", iconPlacement: "right", onClick: () => openDialog(DIALOGS.BUSINESS_INFO_DIALOG, { businessConcept }), children: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u0431\u0438\u0437\u043D\u0435\u0441\u0430" }) })] })] }), _jsx(FlexSpan, { children: _jsx(YellowButton, { icon: "download", noWrap: true, disabled: isDownloadDisabled, onClick: onDownload, disabledTooltipPlacement: "bottom", disabledTooltip: isDownloadDisabled
                                ? "Срок окупаемости вложений в бизнес по выбранному типовому формату превышает 15 лет" +
                                    " в связи со значительными инвестициями и текущими затратами." +
                                    " Рекомендуем выбрать другой типовой формат, другой вид бизнеса или другой участок трассы."
                                : undefined, children: "\u0421\u043A\u0430\u0447\u0430\u0442\u044C \u0431\u0438\u0437\u043D\u0435\u0441-\u043F\u043B\u0430\u043D" }) })] }), _jsxs(Flex, { mt: "1rem", justifyContent: "space-between", children: [_jsx(FlexSpan, { flexGrow: 1, children: _jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { display: "flex", mr: "0.25rem", children: _jsx(Icon, { icon: "location" }) }), _jsx(FlexSpan, { mr: "0.25rem", children: _jsx(EllipsisText, { lines: 1, children: data.header.address }) })] }) }), documents && (_jsx(FlexSpan, { ml: "3rem", children: _jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { display: "flex", mr: "0.25rem", children: _jsx(Icon, { icon: "document" }) }), _jsx(FlexSpan, { mr: "0.25rem", children: "\u0414\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B" }), _jsx(FlexSpan, { display: "flex", children: _jsx(IconButton, { icon: "info_modal2", onClick: () => {
                                            openDialog(DIALOGS.DOCUMENTS_LIST_DIALOG, { data: documents.map(item => item.value) });
                                        } }) })] }) }))] }), _jsxs(Flex, { mt: "1.5rem", gap: "2rem", children: [_jsxs(FlexSpan, { width: "calc(25% - 1rem)", children: [_jsx(H1, { children: formatNumber(data.blocks.find(item => item.id === 3)?.values.find(item => item.id === 0)?.value, false) }), _jsx(BusinessPlanHeaderLabel, { children: "\u041E\u0431\u044A\u0435\u043C \u0438\u043D\u0432\u0435\u0441\u0442\u0438\u0446\u0438\u0439, \u0442\u044B\u0441. \u0440\u0443\u0431." })] }), _jsxs(FlexSpan, { width: "calc(25% - 1rem)", children: [_jsx(H1, { children: typeof data.blocks.find(item => item.id === 3)?.values.find(item => item.id === 4)?.value === "number"
                                    ? formatNumber(data.blocks.find(item => item.id === 3)?.values.find(item => item.id === 4)?.value)
                                    : data.blocks.find(item => item.id === 3)?.values.find(item => item.id === 4)?.value }), _jsx(BusinessPlanHeaderLabel, { children: "\u0421\u0440\u043E\u043A \u043E\u043A\u0443\u043F\u0430\u0435\u043C\u043E\u0441\u0442\u0438 (DPP), \u043C\u0435\u0441." })] }), _jsxs(FlexSpan, { width: "calc(25% - 1rem)", children: [_jsx(H1, { children: formatNumber(data.blocks.find(item => item.id === 3)?.values.find(item => item.id === 5)?.value, false) }), _jsx(BusinessPlanHeaderLabel, { children: "\u0420\u0435\u043D\u0442\u0430\u0431\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u0438\u043D\u0432\u0435\u0441\u0442. \u043A\u0430\u043F\u0438\u0442\u0430\u043B\u0430 (ROI), %" })] }), _jsxs(FlexSpan, { width: "calc(25% - 1rem)", children: [_jsx(H1, { children: formatNumber(data.blocks.find(item => item.id === 3)?.values.find(item => item.id === 1)?.value, false) }), _jsx(BusinessPlanHeaderLabel, { children: "\u0427\u0438\u0441\u0442\u044B\u0439 \u0434\u0438\u0441\u043A\u043E\u043D\u0442. \u0434\u043E\u0445\u043E\u0434 (NPV), \u0442\u044B\u0441. \u0440\u0443\u0431." })] })] })] }));
};
