import styled from "styled-components";
import { Flex } from "../../../ui";
import { Icon } from "../../../ui/Icon/styled";
export const CompareAreasContainer = styled(Flex) `
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  
  ${Icon} {
    color: #9D87F1;
  }
  
  &:hover {
    color: #7E6CC1;
    
    ${Icon} {
      color: #7E6CC1;
    }
  }
`;
