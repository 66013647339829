import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubjectList } from "../../selectors/dict";
import { setSubjectList } from "../../slices/dict";
export const useSubjectList = () => {
    const dispatch = useDispatch();
    const subjectList = useSelector(getSubjectList);
    const update = useCallback((value) => {
        dispatch(setSubjectList(value));
    }, [dispatch]);
    return [subjectList, update];
};
