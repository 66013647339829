import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { LayoutContainer, LayoutBodyWrapper, LayoutBodyContainer, LeftPanel, LayoutContent, LayoutSidebar, LoadingContainer, } from "./styled";
import { Header } from "./Header";
import { Flex, FlexSpan, Loader } from "../../ui";
import { DEFAULT_SUBJECT_ID, SN_USER_GROUP_NAME } from "../../constants/common";
import { useAppInitialized, useCityList, useCurrentCity, useCurrentSubject, useSubjectList, useUser, } from "../../hooks";
import { useUpdateSection } from "./hooks/useUpdateSection";
export const Layout = ({ loading, section, leftPanel, noPadding, bodyStyle, children, }) => {
    const params = useParams();
    const [user] = useUser();
    const [cityList] = useCityList();
    const [subjectList] = useSubjectList();
    const [currentCity, setCurrentCity] = useCurrentCity();
    const [currentSubject, setCurrentSubject] = useCurrentSubject();
    const [isInitialized] = useAppInitialized();
    useUpdateSection(section);
    useEffect(() => {
        if (currentCity === undefined && cityList !== undefined) {
            const cityFromParams = params.cityId !== undefined ? cityList.features.find(item => item?.id === Number(params.cityId)) : undefined;
            const defaultCity = cityList.features.find(item => item.properties.default);
            if (cityFromParams !== undefined) {
                setCurrentCity(cityFromParams);
            }
            else if (defaultCity !== undefined) {
                setCurrentCity(defaultCity);
            }
        }
    }, [currentCity, cityList, params]); // eslint-disable-line
    useEffect(() => {
        if (currentSubject === undefined && subjectList !== undefined) {
            const subjectFromParams = params.subjectId !== undefined ? subjectList.features.find(item => item?.id === Number(params.subjectId)) : undefined;
            const defaultSubject = subjectList.features.find(item => item.id === DEFAULT_SUBJECT_ID);
            if (subjectFromParams !== undefined) {
                setCurrentSubject(subjectFromParams);
            }
            else if (defaultSubject !== undefined) {
                setCurrentSubject(defaultSubject);
            }
        }
    }, [currentSubject, subjectList, params]); // eslint-disable-line
    if (!isInitialized || (section !== undefined && (user.roles?.length === 0 || user.roles?.includes(SN_USER_GROUP_NAME)))) {
        return null;
    }
    return (_jsxs(LayoutContainer, { children: [_jsx(FlexSpan, { children: _jsx(Header, {}) }), _jsx(LayoutBodyWrapper, { children: _jsxs(LayoutBodyContainer, { noPadding: noPadding, children: [loading && (_jsx(LoadingContainer, { children: _jsx(Loader, {}) })), !loading && leftPanel !== undefined && (_jsxs(Flex, { height: "100%", children: [_jsx(LayoutSidebar, { children: _jsx(LeftPanel, { children: leftPanel }) }), _jsx(LayoutContent, { style: bodyStyle, children: children })] })), !loading && !leftPanel && children] }) })] }));
};
