import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Layout } from "../../components/Layout";
import { Flex, FlexSpan, Tabs } from "../../ui";
import { AboutTab } from "./Tabs/AboutTab";
import { InstructionsTab } from "./Tabs/InstructionsTab";
import { FaqTab } from "./Tabs/FaqTab";
import { HelpPageTabs } from "./types";
export const HelpPage = () => {
    const [tab, setTab] = useState(HelpPageTabs.About);
    const renderTab = useCallback(() => {
        switch (tab) {
            case HelpPageTabs.About:
                return _jsx(AboutTab, {});
            case HelpPageTabs.Instructions:
                return _jsx(InstructionsTab, {});
            case HelpPageTabs.Faq:
                return _jsx(FaqTab, {});
            default:
                return null;
        }
    }, [tab]);
    return (_jsx(Layout, { children: _jsxs(Flex, { column: true, height: "100%", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(Tabs, { onChange: setTab, defaultValue: HelpPageTabs.About, items: [
                            { label: "О системе", value: HelpPageTabs.About },
                            { label: "Инструкции", value: HelpPageTabs.Instructions },
                            { label: "Вопросы и ответы", value: HelpPageTabs.Faq },
                        ] }) }), _jsx(FlexSpan, { width: "100%", mt: "1.5rem", flexGrow: 1, overflowY: "auto", pb: "1.75rem", children: renderTab() })] }) }));
};
