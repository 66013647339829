export class Auth {
    http;
    constructor(http) {
        this.http = http;
    }
    getAuthSso() {
        return this.http.get(`/auth/getauthorisationdp`).json();
    }
    clearSession() {
        return this.http.get(`/auth/getauthorisationdp?clear=1`).json();
    }
    getDevAuth() {
        return this.http.get(`/auth/getauthorisation`, { token: "admin" }).json();
    }
}
