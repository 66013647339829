import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { CheckboxInput, CheckboxCheckMark, CheckboxContainer, CheckboxLabel } from "./styled";
import { Icon } from "../Icon";
export const Checkbox = ({ checked, semi, label, disabled, onChange, name, className, error, ...props }) => {
    const [ownValue, setOwnValue] = useState(false);
    useEffect(() => {
        setOwnValue(checked);
    }, [checked]);
    return (_jsxs(CheckboxContainer, { className: className, disabled: disabled, children: [_jsx(CheckboxInput, { name: name, checked: checked !== undefined ? checked : (ownValue || false), onChange: e => {
                    setOwnValue(e.target.checked);
                    return !(onChange) || onChange(e);
                }, disabled: disabled, ...props }), _jsx(CheckboxCheckMark, { disabled: disabled, error: error, semi: semi, children: (ownValue || checked) && _jsx(Icon, { icon: "check", color: "#ffffff" }) }), label && _jsx(CheckboxLabel, { children: label })] }));
};
