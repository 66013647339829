import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonWrapper, DisabledAnchor, GrayButtonContainer, OutlineButtonContainer, OutlineWhiteButtonContainer, PrimaryButtonContainer, WhiteButtonContainer, YellowButtonContainer, } from "./styled";
import { Icon } from "../Icon";
import { Flex, FlexSpan } from "../Grid";
import { NoWrapText } from "../Typography";
import { Tooltip } from "../Tooltip";
const ButtonBody = ({ icon, iconColor, noWrap, children, }) => {
    return (_jsxs(Flex, { width: "fit-content", alignItems: "center", children: [icon && (_jsx(FlexSpan, { mr: "0.5rem", display: "flex", children: _jsx(Icon, { icon: icon, color: iconColor }) })), _jsx(FlexSpan, { children: noWrap ? _jsx(NoWrapText, { children: children }) : children })] }));
};
export const PrimaryButton = ({ icon, iconColor, iconHoverColor, noWrap, width, height, disabled, disabledTooltip, disabledTooltipPlacement, children, ...rest }) => {
    return (_jsxs(ButtonWrapper, { width: width, height: height, iconHoverColor: iconHoverColor, disabled: disabled, children: [disabled && disabledTooltip && (_jsx(Tooltip, { content: disabledTooltip, placement: disabledTooltipPlacement, children: _jsx(DisabledAnchor, {}) })), _jsx(PrimaryButtonContainer, { disabled: disabled, ...rest, children: _jsx(ButtonBody, { icon: icon, iconColor: iconColor, noWrap: noWrap, children: children }) })] }));
};
export const OutlineButton = ({ icon, iconColor, iconHoverColor, noWrap, width, height, disabled, disabledTooltip, disabledTooltipPlacement, children, ...rest }) => {
    return (_jsxs(ButtonWrapper, { width: width, height: height, iconHoverColor: iconHoverColor, disabled: disabled, children: [disabled && disabledTooltip && (_jsx(Tooltip, { content: disabledTooltip, placement: disabledTooltipPlacement, children: _jsx(DisabledAnchor, {}) })), _jsx(OutlineButtonContainer, { disabled: disabled, ...rest, children: _jsx(ButtonBody, { icon: icon, iconColor: iconColor, noWrap: noWrap, children: children }) })] }));
};
export const OutlineWhiteButton = ({ icon, iconColor, iconHoverColor, noWrap, width, height, disabled, disabledTooltip, disabledTooltipPlacement, children, ...rest }) => {
    return (_jsxs(ButtonWrapper, { width: width, height: height, iconHoverColor: iconHoverColor, disabled: disabled, children: [disabled && disabledTooltip && (_jsx(Tooltip, { content: disabledTooltip, placement: disabledTooltipPlacement, children: _jsx(DisabledAnchor, {}) })), _jsx(OutlineWhiteButtonContainer, { disabled: disabled, ...rest, children: _jsx(ButtonBody, { icon: icon, iconColor: iconColor, noWrap: noWrap, children: children }) })] }));
};
export const WhiteButton = ({ icon, iconColor, iconHoverColor, noWrap, width, height, disabled, disabledTooltip, disabledTooltipPlacement, children, ...rest }) => {
    return (_jsxs(ButtonWrapper, { width: width, height: height, iconHoverColor: iconHoverColor, disabled: disabled, children: [disabled && disabledTooltip && (_jsx(Tooltip, { content: disabledTooltip, placement: disabledTooltipPlacement, children: _jsx(DisabledAnchor, {}) })), _jsx(WhiteButtonContainer, { disabled: disabled, ...rest, children: _jsx(ButtonBody, { icon: icon, iconColor: iconColor, noWrap: noWrap, children: children }) })] }));
};
export const YellowButton = ({ icon, iconColor, iconHoverColor, noWrap, width, height, disabled, disabledTooltip, disabledTooltipPlacement, children, ...rest }) => {
    return (_jsxs(ButtonWrapper, { width: width, height: height, iconHoverColor: iconHoverColor, disabled: disabled, children: [disabled && disabledTooltip && (_jsx(Tooltip, { content: disabledTooltip, placement: disabledTooltipPlacement, children: _jsx(DisabledAnchor, {}) })), _jsx(YellowButtonContainer, { disabled: disabled, ...rest, children: _jsx(ButtonBody, { icon: icon, iconColor: iconColor, noWrap: noWrap, children: children }) })] }));
};
export const GrayButton = ({ icon, iconColor, iconHoverColor, noWrap, width, height, disabled, disabledTooltip, disabledTooltipPlacement, children, ...rest }) => {
    return (_jsxs(ButtonWrapper, { width: width, height: height, iconHoverColor: iconHoverColor, disabled: disabled, children: [disabled && disabledTooltip && (_jsx(Tooltip, { content: disabledTooltip, placement: disabledTooltipPlacement, children: _jsx(DisabledAnchor, {}) })), _jsx(GrayButtonContainer, { disabled: disabled, ...rest, children: _jsx(ButtonBody, { icon: icon, iconColor: iconColor, noWrap: noWrap, children: children }) })] }));
};
