import { createSlice } from "@reduxjs/toolkit";
import { isNil } from "lodash";
import { STORAGE_CITY, STORAGE_SUBJECT } from "../../constants/common";
import { AppSection } from "./types";
const initialState = {
    initialized: false,
    section: AppSection.Retail,
    currentCity: !isNil(window.localStorage.getItem(STORAGE_CITY))
        ? JSON.parse(window.localStorage.getItem(STORAGE_CITY))
        : undefined,
    currentSubject: !isNil(window.localStorage.getItem(STORAGE_SUBJECT))
        ? JSON.parse(window.localStorage.getItem(STORAGE_SUBJECT))
        : undefined,
};
const slice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAppInitialized: (state, action) => {
            state.initialized = action.payload;
        },
        setSection: (state, action) => {
            state.section = action.payload;
        },
        setCurrentCity: (state, action) => {
            state.currentCity = action.payload;
        },
        setCurrentSubject: (state, action) => {
            state.currentSubject = action.payload;
        },
    },
});
export const { setAppInitialized, setSection, setCurrentCity, setCurrentSubject, } = slice.actions;
export default slice.reducer;
