import { jsx as _jsx } from "react/jsx-runtime";
import { Flex, FlexSpan } from "../../../ui";
import { HorizontalBarChart } from "../../HorizontalBarChart";
import { PointDetailsList } from "../../PointDetailsList";
import { PointDetailsBlock } from "../../PointDetailsBlock";
import { useRenderTooltip } from "../../../hooks";
export const BusinessBlock = ({ data }) => {
    const renderTooltip = useRenderTooltip();
    return (_jsx(PointDetailsBlock, { title: "\u041E\u0444\u0438\u0441\u043D\u044B\u0435 \u0441\u043B\u0443\u0436\u0430\u0449\u0438\u0435", icon: "tutoring", titleExtra: data.analytic.bc_people_group.some(item => item > 0) && (_jsx(Flex, { column: true, width: "5rem", children: _jsx(FlexSpan, { children: _jsx(HorizontalBarChart, { withoutXScale: true, withTooltip: true, stackedTooltip: true, renderTooltip: bars => renderTooltip(bars, { unit: "чел." }), data: [
                        [
                            { name: "Высокодоходные", value: +data.analytic.bc_people_group[3].toFixed(2), color: "#4DAEF8" },
                            { name: "Среднеобеспеченные", value: +data.analytic.bc_people_group[2].toFixed(2), color: "#6EE5B9" },
                            { name: "Малообеспеченные", value: +data.analytic.bc_people_group[1].toFixed(2), color: "#F6C544" },
                            { name: "Низкодоходные", value: +data.analytic.bc_people_group[0].toFixed(2), color: "#EA394B" },
                        ],
                    ] }) }) })), children: _jsx(PointDetailsList, { items: [
                { label: "Всего офисных служащих, чел", value: data.analytic.bc_people_count },
                { label: "Доля покупателей, %", value: data.analytic.bc_pen * 100, noRound: true },
                { label: "Средний чек, руб.", value: data.analytic.bc_avg_check },
                { label: "Офисные здания", value: data.analytic.bc_count },
            ] }) }));
};
