import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { useMapContext } from "@evergis/react";
import { toMercator } from "@turf/turf";
import { MapControl } from "../MapControl";
import { SearchInput } from "../SearchInput";
import { useCurrentCity, useUser } from "../../hooks";
import { api } from "../../api";
export const GeocoderControl = props => {
    const { map } = useMapContext();
    const [user] = useUser();
    const [search, setSearch] = useState("");
    const [currentCity] = useCurrentCity();
    const onSearch = useCallback(() => {
        if (!currentCity || !search) {
            return;
        }
        api.geocoder.getAddress(search, currentCity.id, user.guid).then(result => {
            if (!result.error && result.address && result.geometry) {
                setSearch(result.address);
                map.animateTo(toMercator(result.geometry.coordinates), 1);
            }
        });
    }, [currentCity, map, search, user]);
    return (_jsx(MapControl, { ...props, padding: "0", width: "20rem", children: _jsx(SearchInput, { placeholder: "\u041F\u043E\u0438\u0441\u043A \u0430\u0434\u0440\u0435\u0441\u0430", value: search, onChange: setSearch, onEnter: onSearch }) }));
};
