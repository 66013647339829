import styled from "styled-components";
import { space } from "styled-system";
import { StatusType } from "./types";
const getStatusColors = (type, filled) => {
    if (filled) {
        switch (type) {
            case StatusType.InProgress:
                return ["#60519C", "#EBE7FC"];
            case StatusType.Success:
                return ["#0A7739", "#E0F5E9"];
            case StatusType.Draft:
                return ["#666666", "#ECECEC"];
            case StatusType.Completed:
                return ["#000000", "#ECECEC"];
            case StatusType.Error:
                return ["#F1274C", "#FFE8F1"];
            case StatusType.Warning:
                return ["#C55F02", "#FFF2D2"];
            default:
                return ["#000000", "#ECECEC"];
        }
    }
    switch (type) {
        case StatusType.InProgress:
            return ["#7E6CC1", "transparent"];
        case StatusType.Success:
            return ["#219653", "transparent"];
        case StatusType.Draft:
            return ["#9F9F9F", "transparent"];
        case StatusType.Completed:
            return ["#000000", "transparent"];
        case StatusType.Error:
            return ["#F1274C", "transparent"];
        case StatusType.Warning:
            return ["#F09537", "transparent"];
        default:
            return ["#000000", "transparent"];
    }
};
export const Status = styled.span `
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid ${({ type, filled }) => filled ? getStatusColors(type, filled)[1] : getStatusColors(type, filled)[0]};
  background-color: ${({ type, filled }) => filled && getStatusColors(type, filled)[1]};
  color: ${({ type, filled }) => getStatusColors(type, filled)[0]};
  font-weight: 400;
  ${space}
`;
