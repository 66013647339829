import styled from "styled-components";
import { Icon } from "../Icon/styled";
export const CheckboxInput = styled.input.attrs(({ checked }) => ({
    type: "checkbox",
    hidden: true,
    checked: !!checked,
})) `
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  
  &[disabled] {
    pointer-events: none;
  }
`;
export const CheckboxLabel = styled.span `
  display: inline;
  margin-left: 0.5rem;
  user-select: none;
  color: #000000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
export const CheckboxCheckMark = styled.span `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 1.375rem;
  width: 1.375rem;
  border: ${({ semi, error }) => semi ? "0.425rem solid #9D87F1" : `0.0625rem solid ${error ? "#EB5757" : "#9F9F9F"}`};
  border-radius: 0.1875rem;
  box-sizing: border-box;
  background: #ffffff;
  
  ${Icon} {
    position: relative;
    top: 0.0625rem;
  }
  
  ${CheckboxInput}:checked + & {
    border: 1px solid #9D87F1;
    background: #9D87F1;
    
    &[disabled] {
      background: #CEC3F8;
      border-color: #CEC3F8;
    }
  }
  
  &:hover {
    outline: 2px solid #CEC3F8;
  }

  &[disabled] {
    background: #F3F3F3;
    border-color: #E3E3E3;
    outline: none;
  }
`;
export const CheckboxContainer = styled.label `
  display: flex;
  align-items: flex-start;
  text-align: left;
  width: fit-content;

  &:hover {
    cursor: pointer;
    
    &[disabled] {
      cursor: not-allowed;
    }
  }
  
  &:not([disabled]):hover {
    ${CheckboxCheckMark} {
      outline: 2px solid #CEC3F8;
    }
  }

  &[disabled] {    
    ${CheckboxLabel} {
      color: #9F9F9F;
    }
  }
`;
