import { jsx as _jsx } from "react/jsx-runtime";
import { MapControl } from "../../../../components/MapControl";
import { Flex, FlexSpan, Tabs } from "../../../../ui";
import { MAP_CONTROL_LEFT_DESKTOP, MAP_CONTROL_LEFT_TABLET } from "../../../../constants/layout";
import { useIsDesktop } from "../../../../hooks";
import { RetailTab } from "./types";
export const ClusterDistrictControl = ({ tab, setTab, ...rest }) => {
    const isDesktop = useIsDesktop();
    return (_jsx(MapControl, { ...rest, column: true, alignItems: "center", width: isDesktop ? "21rem" : "17rem", padding: isDesktop ? "0.88rem" : "0.5rem", top: isDesktop ? "1.5rem" : "1rem", left: isDesktop ? MAP_CONTROL_LEFT_DESKTOP : MAP_CONTROL_LEFT_TABLET, children: _jsx(FlexSpan, { width: "100%", children: _jsx(Flex, { alignItems: "center", children: _jsx(Tabs, { menu: true, defaultValue: tab, onChange: setTab, items: [
                        { value: RetailTab.Grid, label: "Сетка (соты)" },
                        { value: RetailTab.District, label: "Районы города" },
                    ] }) }) }) }));
};
