import { useEffect, useMemo } from "react";
import debounce from "lodash/debounce";
const DEBOUNCE_DELAY = 144;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useResize = (callback, delay) => {
    const debounceCallback = useMemo(() => {
        return callback ? debounce(callback, delay || DEBOUNCE_DELAY) : undefined;
    }, [callback, delay]);
    useEffect(() => {
        debounceCallback && window.addEventListener("resize", debounceCallback);
        debounceCallback && document.getElementById("root")?.addEventListener("scroll", debounceCallback);
        debounceCallback && document.getElementById("storybook-root")?.addEventListener("scroll", debounceCallback);
        return () => {
            debounceCallback && window.removeEventListener("resize", debounceCallback);
            debounceCallback && document.getElementById("root")?.removeEventListener("scroll", debounceCallback);
            debounceCallback && document.getElementById("storybook-root")?.removeEventListener("scroll", debounceCallback);
        };
    }, [debounceCallback]);
};
