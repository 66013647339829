export const declension = (n, cases) => {
    const num = Math.abs(n);
    if (num.toString().indexOf(".") > -1) {
        return cases.gen;
    }
    return num % 10 === 1 && num % 100 !== 11
        ? cases.nom
        : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
            ? cases.gen
            : cases.plu;
};
