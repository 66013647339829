import styled from "styled-components";
import { Card } from "../../ui";
import { Icon } from "../../ui/Icon/styled";
export const StyledCard = styled(Card) `
  height: 7.25rem;
  background: ${({ active }) => active ? "#9D87F1" : "#F0F1F3"};
  color: ${({ active }) => active && "#FFFFFF"};
  cursor: pointer;
  
  ${Icon} {
    color: ${({ active }) => active ? "#FFFFFF" : "#9D87F1"};
  }

  &:hover {
    box-shadow: 0 0 1rem 0 rgba(63, 67, 106, 0.12);
  }
`;
