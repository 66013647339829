import styled from "styled-components";
import { BarChart, barChartClassNames } from "@evergis/charts";
export const StyledBarChart = styled(BarChart) `
  flex-grow: 1;
  flex-shrink: 0;
  
  .${barChartClassNames.barChartYAxis}, .${barChartClassNames.barChartXAxis} {
    .domain,
    line {
      visibility: hidden;
    }
  }
  
  text {
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%
  }
  
  .${barChartClassNames.barChartMouseTooltip} {
    transition: opacity linear 144ms, top linear 144ms, left linear 144ms;
  }
  
  .${barChartClassNames.barChartTooltip} {
    padding: 0.5rem 0.625rem;
  }
  
  .${barChartClassNames.barChartTooltipItem} {
    margin-bottom: 0.5rem;
  }
  
  .${barChartClassNames.barChartColorBox} {
    width: 1.1875rem;
    height: 0.75rem;
    border-radius: 0;
    margin-right: 0.375rem;
  }
`;
