import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRetailBusinessDisplayMode } from "../../../selectors/filters";
import { setRetailBusinessDisplayMode } from "../../../slices/filters";
export const useRetailBusinessDisplayMode = () => {
    const dispatch = useDispatch();
    const displayMode = useSelector(getRetailBusinessDisplayMode);
    const update = useCallback((value) => {
        dispatch(setRetailBusinessDisplayMode(value));
    }, [dispatch]);
    return [displayMode, update];
};
