import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Divider, Flex, FlexSpan, H2, Link, OutlineButton, PrimaryButton } from "../../ui";
import { useCloseOnEscape } from "../../hooks";
export const ParamsForm = ({ title, subtitle, onClose, onReset, onApply, children }) => {
    useCloseOnEscape(onClose);
    return (_jsx(Card, { height: "calc(100% - 1.5rem)", width: "calc(100% - 1.5rem)", p: 0, m: "0.75rem", children: _jsxs(Flex, { column: true, height: "100%", children: [_jsxs(FlexSpan, { p: "1.5rem", children: [_jsx(H2, { children: title }), subtitle && (_jsx(Flex, { m: "0.5rem 0 0", children: _jsx(FlexSpan, { fontWeight: 400, color: "#666666", children: subtitle }) }))] }), _jsx(FlexSpan, { flexGrow: 1, overflow: "auto", p: "0 1.5rem", children: children }), _jsx(FlexSpan, { mt: "1.5rem", children: _jsx(Divider, {}) }), _jsx(FlexSpan, { p: "1.5rem", width: "100%", children: _jsxs(Flex, { justifyContent: "space-between", gap: "3rem", alignItems: "center", children: [onReset && (_jsx(FlexSpan, { children: _jsx(Link, { onClick: onReset, children: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C \u043A \u0431\u0430\u0437\u043E\u0432\u044B\u043C" }) })), _jsx(FlexSpan, { flexGrow: 1, children: _jsxs(Flex, { gap: "1rem", justifyContent: "right", children: [_jsx(FlexSpan, { children: _jsx(OutlineButton, { buttonSize: "s", onClick: onClose, children: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C" }) }), _jsx(FlexSpan, { children: _jsx(PrimaryButton, { buttonSize: "s", onClick: () => {
                                                    onApply?.();
                                                    onClose?.();
                                                }, children: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C \u0438 \u043F\u0435\u0440\u0435\u0441\u0447\u0438\u0442\u0430\u0442\u044C" }) })] }) })] }) })] }) }));
};
