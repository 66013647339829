import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { getCrs, useMapContext, useMount } from "@evergis/react";
import { Polygon } from "@evergis/sgis/es/features/Polygon";
import { MapContainer, StyledMap } from "./styled";
import { ZoomControl } from "./components/ZoomControl";
import { ToolsControl } from "./components/ToolsControl";
import { YandexMap } from "./components/YandexMap";
import { useCurrentFeature, useIsDesktop } from "../../hooks";
export const Map = ({ width, height, extent, position, resolution, hideZoom, hideTools, measurerDisabled, children, ...rest }) => {
    const { map, painter } = useMapContext();
    const [, setCurrentFeature] = useCurrentFeature();
    const [isMount, onSetMount] = useState(false);
    const prevBbox = useRef();
    const isDesktop = useIsDesktop();
    useMount({ onMount: () => onSetMount(true) });
    useEffect(() => {
        if (!position || !resolution) {
            return;
        }
        map.setPosition(position, resolution);
        prevBbox.current = null;
    }, [map, position, resolution]);
    useEffect(() => {
        if (!extent || !painter) {
            return;
        }
        const { bbox } = new Polygon(extent.coordinates, { crs: extent.sr ? getCrs(extent.sr) : undefined });
        const shouldUpdateBbox = !prevBbox.current || !prevBbox.current.equals(bbox);
        if (isMount && shouldUpdateBbox && painter.width && painter.height) {
            painter.show(bbox, false);
            prevBbox.current = bbox;
        }
    }, [extent, painter.width, painter.height, isMount]); // eslint-disable-line
    useEffect(() => {
        setCurrentFeature(undefined);
        return () => {
            setCurrentFeature(undefined);
        };
    }, []); // eslint-disable-line
    return (_jsx(MapContainer, { width: width, height: height, children: _jsxs(StyledMap, { width: width, height: height, position: position, resolution: resolution, maxScale: 18, ...rest, children: [children, !hideZoom && _jsx(ZoomControl, { bottom: "7rem", right: `${isDesktop ? 1.5 : 1}rem` }), !hideTools && (_jsx(ToolsControl, { bottom: `${isDesktop ? 1.5 : 1}rem`, right: `${isDesktop ? 1.5 : 1}rem`, measurerDisabled: measurerDisabled })), _jsx(YandexMap, {})] }) }));
};
