import styled from "styled-components";
import { Flex } from "../../../ui";
export const AlertMessage = styled(Flex) `
  display: inline-flex;
  padding: 1rem;
  box-sizing: border-box;
  align-items: flex-start;
  border-radius: 0.75rem;
  background: #ffd633;
`;
