import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Pages } from "./pages";
import store from "./store";
import "./assets/main.css";
import "tippy.js/dist/tippy.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(_jsx(Provider, { store: store, children: _jsx(BrowserRouter, { basename: process.env.APP_PATH, children: _jsx(Pages, {}) }) }));
