import styled from "styled-components";
export const CheckboxLabel = styled.span `
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;
export const ListItemAddress = styled.div `
  color: #9F9F9F;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
const Attribute = styled.span `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
export const AttributeLabel = styled(Attribute) `
  color: #9F9F9F;
`;
export const AttributeValue = styled(Attribute) `
  color: initial;
`;
const RangeFilter = styled.span `
  position: relative;
  top: 0.125rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
`;
export const RangeFilterLabel = styled(RangeFilter) `
  color: #9F9F9F;
`;
export const RangeFilterValue = styled(RangeFilter) `
  color: initial;
`;
