import styled from "styled-components";
import { Card } from "../../ui";
export const StyledCard = styled(Card) `
  border: 1px solid #F3F3F3;
  
  .toggle-icon {
    transform: ${({ isOpen }) => `rotate(${isOpen ? 180 : 0}deg)`};
    transition: transform 300ms cubic-bezier(0.15, 1.35, 0.7, 1.5);
  }
`;
export const ToggleCardTitle = styled.span `
  font-size: 1.125rem;
  font-weight: 700;
  user-select: none;
`;
