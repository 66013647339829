import styled from "styled-components";
import { space } from "styled-system";
import { H2 } from "../../../../ui";
export const LargeValue = styled(H2) `
  font-weight: 600;
`;
export const Label = styled.div `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #666666;
`;
export const Subtitle = styled(Label) `
  margin-top: 0.25rem;
`;
export const DetailsInnerSection = styled.div `
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${space}
`;
export const Circle = styled.div `
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 6px;
  flex-shrink: 0;
`;
export const SvgArea = styled.svg `
  position: relative;
  left: 2px;
  width: 10px;
  height: 8px;
  margin-left: -4px;
  margin-right: 6px;
  flex-shrink: 0;
`;
export const SvgAreaPath = styled.polygon.attrs(() => ({
    points: "0,8 10,8 10,0 0,5",
})) `
  fill: #4DAEF8;
  margin-right: 6px;
  flex-shrink: 0;
`;
