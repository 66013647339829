import styled from "styled-components";
import { Card, H3 } from "../../../../ui";
import { BadgeContainer } from "../../../../ui/Badge/styled";
export const StyledCard = styled(Card) `
  width: calc(100% / 4 - 1.5rem);
  margin: 0 1.5rem 1.5rem 0;
  cursor: pointer;
  padding: 1.25rem;
  transition: background 0.5s ease;
  
  ${BadgeContainer} {
    background: #F3F3F3;
    border-color: transparent;
    padding: 0.2rem 0.75rem;
  }

  ${H3} {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 500;
  }

  &:hover {
    background: linear-gradient(90deg, #484C96 0%, #7552AE 100%);
    box-shadow: 0 0 1rem 0 rgba(63, 67, 106, 0.12);
    color: #ffffff;
    
    ${BadgeContainer} {
      background: #FFD633;
    }
  }

  @media only screen and (max-width: 1024px) {
    & {
      width: calc(100% / 3 - 1.5rem);
    }
  }

  @media only screen and (max-width: 812px) {
    & {
      width: calc(100% / 2 - 1.5rem);
    }
  }

  @media only screen and (max-width: 568px) {
    & {
      width: calc(100% - 1.5rem);
    }
  }
`;
