import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useToggle } from "@evergis/react";
import { useMemo } from "react";
import { Badge, Flex, FlexSpan, Link } from "../../../../../ui";
import { FullConceptColumn } from "./styled";
import { formatNumber } from "../../../../../utils";
import { useCurrentSubject } from "../../../../../hooks";
const MAX_VISIBLE_ITEMS = 3;
export const SubjectsProfitList = ({ data }) => {
    const [showAll, toggleAll] = useToggle(false);
    const [currentSubject] = useCurrentSubject();
    const sortedItems = useMemo(() => data.sort(({ rating: rating1 }, { rating: rating2 }) => rating2 - rating1), [data]);
    return (_jsxs(FullConceptColumn, { children: [!showAll &&
                sortedItems.slice(0, MAX_VISIBLE_ITEMS).map(({ subject_id, rating, name }) => (_jsxs(Flex, { mb: "0.75rem", justifyContent: "space-between", alignItems: "baseline", children: [_jsxs(Flex, { alignItems: "baseline", children: [_jsx(FlexSpan, { mr: "0.5rem", children: name }), currentSubject?.id === subject_id && _jsx(Badge, { backgroundColor: "#FFD633", children: "\u0412\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0439 \u0440\u0435\u0433\u0438\u043E\u043D" })] }), _jsx(FlexSpan, { children: _jsx("strong", { children: formatNumber(rating) }) })] }, subject_id))), !showAll && sortedItems.length - MAX_VISIBLE_ITEMS > 1 && (_jsx(Flex, { mb: "0.75rem", justifyContent: "space-between", alignItems: "baseline", children: _jsxs(Link, { onClick: toggleAll, children: ["\u0415\u0449\u0435 ", sortedItems.length - MAX_VISIBLE_ITEMS, " ..."] }) })), showAll &&
                sortedItems.map(({ subject_id, rating, name }) => (_jsxs(Flex, { mb: "0.75rem", justifyContent: "space-between", alignItems: "baseline", children: [_jsxs(Flex, { alignItems: "baseline", children: [_jsx(FlexSpan, { mr: "0.5rem", children: name }), currentSubject?.id === subject_id && _jsx(Badge, { small: true, backgroundColor: "#FFD633", children: "\u0412\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0439 \u0440\u0435\u0433\u0438\u043E\u043D" })] }), _jsx(FlexSpan, { children: _jsx("strong", { children: formatNumber(rating) }) })] }, subject_id)))] }));
};
