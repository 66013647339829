import { useEffect } from "react";
import { useFormValues } from "./useFormValues";
export const useBusinessPlanFormValues = (initialValues, defaultValues, onChange, shouldReset, onResetComplete, onValuesChange) => {
    const [values, onUpdateValues] = useFormValues(initialValues, onChange);
    useEffect(() => {
        onValuesChange && onValuesChange(values);
    }, [values]); // eslint-disable-line
    useEffect(() => {
        if (shouldReset) {
            onUpdateValues(Object.entries(defaultValues).reduce((acc, [key, value]) => value !== undefined ? { ...acc, [key]: value } : acc, {}));
            onResetComplete && onResetComplete();
        }
    }, [shouldReset]); // eslint-disable-line
    return [values, onUpdateValues];
};
