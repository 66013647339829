import styled from "styled-components";
import { FlexSpan } from "../../ui";
export const MainPageColumn = styled(FlexSpan) `
  width: calc(50% - 0.75rem);

  @media only screen and (max-width: 1024px) {
    & {
      width: 100%;
    }
  }
`;
