import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AttributeLabel, AttributeValue, CheckboxLabel, RangeFilterLabel, ListItemAddress, RangeFilterValue, } from "./styled";
import { Badge, Checkbox, Divider, Flex, FlexSpan, Modal, OutlineButton, PrimaryButton, Slider, Tag } from "../../../ui";
import { SearchInput } from "../../SearchInput";
import { useDialogProps, useDialogReset, useUpdatedLocationParams } from "../../../hooks";
import { formatNumber, highlightSearch } from "../../../utils";
export const CompetitorsDialog = () => {
    const closeDialog = useDialogReset();
    const [{ statistic }] = useDialogProps();
    const [searchParams, setSearchParams] = useSearchParams();
    const [, setUpdatedParams] = useUpdatedLocationParams();
    const urlExcludedCompetitors = useMemo(() => searchParams.get("excludedCompetitors"), [searchParams]);
    const excludedCompetitors = useMemo(() => urlExcludedCompetitors ? JSON.parse(urlExcludedCompetitors) : null, [urlExcludedCompetitors]);
    const [itemState, setItemState] = useState();
    const lastCount = useMemo(() => statistic.competitors.length - (itemState?.length ?? 0), [statistic, itemState]);
    const minDistance = useMemo(() => Math.min(...statistic?.competitors.map(item => item.properties.dist)), [statistic]);
    const maxDistance = useMemo(() => Math.max(...statistic?.competitors.map(item => item.properties.dist)), [statistic]);
    const styleTypes = useMemo(() => {
        return statistic?.calc_styles
            .map(item => ({ id: item.styleId, label: item.legendLabel }));
    }, [statistic]);
    const [search, setSearch] = useState("");
    const [distance, setDistance] = useState(maxDistance);
    const [excludedStyles, setExcludedStyles] = useState([]);
    const filteredCompetitors = useMemo(() => {
        if (!statistic) {
            return [];
        }
        return statistic.competitors.filter(item => {
            return item.properties.dist <= distance
                && item.properties.name.toLowerCase().includes(search.toLowerCase())
                && excludedStyles.findIndex(style => style.id === item.properties.styleId) === -1;
        }).sort((a, b) => {
            return a.properties.dist - b.properties.dist;
        });
    }, [statistic, search, distance, excludedStyles]);
    const onApply = useCallback(() => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            excludedCompetitors: !Array.isArray(itemState) || itemState?.length === 0 ? "" : JSON.stringify(itemState),
        });
        setUpdatedParams({ excludedCompetitors: true });
        closeDialog();
    }, [closeDialog, itemState, searchParams, setUpdatedParams, setSearchParams]);
    useEffect(() => {
        if (itemState === undefined) {
            setItemState(excludedCompetitors || []);
        }
    }, [excludedCompetitors]); // eslint-disable-line
    return (_jsx(Modal, { height: "calc(var(--app-height) - 15rem)", title: (_jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { mb: "1.5rem", children: "\u041A\u043E\u043D\u043A\u0443\u0440\u0435\u043D\u0442\u044B" }), _jsx(FlexSpan, { mb: "1rem", children: _jsx(SearchInput, { value: search, onChange: setSearch }) }), _jsx(FlexSpan, { mb: "1.5rem", children: _jsxs(Flex, { children: [_jsx(FlexSpan, { flexGrow: 1, mr: "2rem", children: styleTypes?.map((style, styleIndex) => {
                                    return (_jsx(Tag, { mr: "0.5rem", pointer: true, light: excludedStyles.findIndex(item => item.id === style.id) > -1, onClick: () => {
                                            setExcludedStyles(excludedStyles.findIndex(item => item.id === style.id) > -1
                                                ? excludedStyles.filter(item => item.id !== style.id)
                                                : [...excludedStyles, style]);
                                        }, children: style.label }, `style-type-tag-${styleIndex}`));
                                }) }), _jsx(FlexSpan, { width: "33%", minWidth: "33%", children: _jsxs(Flex, { alignItems: "flex-end", children: [_jsx(FlexSpan, { mr: "0.5rem", children: _jsx(RangeFilterLabel, { children: "\u0420\u0430\u0441\u0441\u0442\u043E\u044F\u043D\u0438\u0435" }) }), _jsx(FlexSpan, { flexGrow: 1, children: _jsx(Slider, { min: minDistance, max: maxDistance, noMarks: true, value: distance, onChange: (value) => setDistance(value) }) }), _jsx(FlexSpan, { ml: "0.5rem", children: _jsxs(RangeFilterValue, { children: [formatNumber(distance), "\u043C"] }) })] }) })] }) }), _jsx(FlexSpan, { pb: "1.5rem", children: _jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { mr: "0.5rem", children: _jsx(Checkbox, { label: _jsx(CheckboxLabel, { children: "\u0412\u0441\u0435 \u043A\u043E\u043D\u043A\u0443\u0440\u0435\u043D\u0442\u044B" }), checked: !itemState?.length, semi: itemState && itemState.length && itemState.length > 0 && itemState.length < statistic.competitors.length, onChange: () => setItemState(itemState?.length === 0 ? statistic.competitors.map(item => item.id) : []) }) }), _jsx(FlexSpan, { children: _jsxs(Badge, { backgroundColor: "#FFD633", children: ["\u0423\u0447\u0430\u0441\u0442\u0432\u0443\u044E\u0442 \u0432 \u0440\u0430\u0441\u0447\u0435\u0442\u0435 ", lastCount, " \u0438\u0437 ", statistic.competitors.length] }) })] }) }), _jsx(FlexSpan, { children: _jsx(Divider, {}) })] })), content: itemState !== undefined && (_jsx(Flex, { column: true, children: filteredCompetitors.map((competitor, competitorIndex) => (_jsxs(Fragment, { children: [_jsx(FlexSpan, { width: "100%", pt: "1.5rem", pb: "1.5rem", children: _jsxs(Flex, { alignItems: "flex-start", children: [_jsx(FlexSpan, { flexGrow: 1, children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { children: _jsx(Checkbox, { label: _jsx(CheckboxLabel, { children: highlightSearch(search, competitor.properties.name) }), checked: !itemState.includes(competitor.id), onChange: () => {
                                                        setItemState(!itemState.includes(competitor.id)
                                                            ? [...itemState, competitor.id]
                                                            : itemState.filter(item => item !== competitor.id));
                                                    } }) }), _jsx(FlexSpan, { pl: "1.875rem", mt: "0.125rem", children: _jsx(ListItemAddress, { children: competitor.properties.address }) }), _jsx(FlexSpan, { pl: "1.875rem", mt: "0.5rem", children: _jsxs(Flex, { children: [_jsx(FlexSpan, { mr: "0.25rem", children: _jsx(AttributeLabel, { children: "\u0422\u0438\u043F" }) }), _jsx(FlexSpan, { children: _jsx(AttributeValue, { children: statistic.calc_styles.find(item => item.styleId === competitor.properties.styleId)?.legendLabel }) })] }) })] }) }), _jsx(FlexSpan, { ml: "3rem", children: _jsxs(Badge, { backgroundColor: "#F3F3F3", children: ["\u0420\u0430\u0441\u0441\u0442\u043E\u044F\u043D\u0438\u0435: ", formatNumber(competitor.properties.dist), "\u043C"] }) })] }) }), _jsx(FlexSpan, { children: _jsx(Divider, {}) })] }, `competitors-list-item-${competitorIndex}`))) })), footer: (_jsxs(Flex, { alignItems: "center", gap: "1rem", justifyContent: "right", children: [_jsx(FlexSpan, { children: _jsx(OutlineButton, { buttonSize: "s", onClick: closeDialog, children: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C" }) }), _jsx(FlexSpan, { children: _jsx(PrimaryButton, { buttonSize: "s", onClick: onApply, children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C" }) })] })), paddingTitle: "1rem 1.5rem 0 2.25rem" }));
};
