import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { Flex, FlexSpan } from "../Grid";
import { TabContainer, TabDivider, TabLabel } from "./styled";
import { Icon } from "../Icon";
export const Tabs = ({ items = [], defaultValue, menu, onChange }) => {
    const [value, setValue] = useState();
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);
    return (_jsxs(Flex, { children: [items.map((item, index) => (_jsxs(Fragment, { children: [_jsx(FlexSpan, { mr: menu && index < items.length - 1 ? "0.62rem" : undefined, children: _jsxs(TabContainer, { menu: menu, active: value === item.value, empty: Boolean(item.empty), disabled: Boolean(item.disabled), onClick: () => {
                                if (!item.disabled) {
                                    setValue(item.value);
                                    onChange && onChange(item.value);
                                }
                            }, children: [item.icon && (_jsx(FlexSpan, { display: "flex", mr: "0.38rem", children: _jsx(Icon, { icon: item.icon }) })), _jsx(TabLabel, { children: item.label })] }) }), !menu && index < items.length - 1 && (_jsx(FlexSpan, { children: _jsx(TabDivider, { forEmpty: Boolean(item.empty) }) }))] }, `tabs-item-${index}`))), !menu && (_jsx(FlexSpan, { flexGrow: 1, children: _jsx(TabDivider, { width: "100%" }) }))] }));
};
