import styled from "styled-components";
import { BarChart, barChartClassNames } from "@evergis/charts";
export const StyledPromAnalyticBarChart = styled(BarChart) `
  overflow: hidden;

  .${barChartClassNames.barChartYAxis} {
    .domain,
    line {
      visibility: hidden;
    }

    .tick {
      text {
        display: none;
      }
    }
  }
  
  .${barChartClassNames.barChartXAxis} {
    .domain,
    line {
      display: none;
    }
    
    .tick {
      :first-of-type {
        text {
          text-anchor: start;
        }
      }
      
      :last-of-type {
        text {
          text-anchor: end;
        }
      }
    }
  }
  
  .${barChartClassNames.barChartTooltip} {
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(0, 0, 0, 1);
  }
  
  text {
    font-size: 0.5625rem;
    font-weight: 500;
  }

  .${barChartClassNames.barChartArea} {
    fill-opacity: 1;
  }
`;
