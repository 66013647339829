import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Flex, FlexSpan, Loader, Modal } from "../../../ui";
import { BusinessInfoExtended } from "./components/BusinessInfoExtended";
import { BusinessInfoShort } from "./components/BusinessInfoShort";
import { PromBusinessInfoExtended } from "./components/PromBusinessInfoExtended";
import { PromBusinessInfoShort } from "./components/PromBusinessInfoShort";
import { BusinessInfoDialogFooter } from "./components/BusinessInfoDialogFooter";
import { useCurrentCity, useDialogProps, useSection, useUser } from "../../../hooks";
import { api } from "../../../api";
import { AppSection } from "../../../slices/app/types";
export const BusinessInfoDialog = () => {
    const [user] = useUser();
    const [{ businessConcept }] = useDialogProps();
    const [currentCity] = useCurrentCity();
    const [cardInfo, setCardInfo] = useState();
    const [searchParams] = useSearchParams();
    const size = searchParams.get("size");
    const formatId = searchParams.get("formatId");
    const [section] = useSection();
    const [loading, setLoading] = useState(true);
    const headerRef = useRef();
    const getCardInfo = useCallback(() => {
        const isRetail = [AppSection.Retail, AppSection.RetailIntegral].includes(section);
        const isSelfEmployed = section === AppSection.SelfEmployed;
        const isEnterprise = [AppSection.Enterprise, AppSection.EnterpriseIntegral].includes(section);
        const endpoint = isRetail ? api.retailCard : isSelfEmployed ? api.selfCard : isEnterprise ? api.promCard : null;
        if (currentCity?.id === void 0 || businessConcept?.id === void 0) {
            return;
        }
        if (!isEnterprise && endpoint) {
            if (!isNaN(parseInt(size, 10))) {
                return endpoint.getExtendedCard(currentCity.id, businessConcept.id, parseInt(size, 10), user.guid);
            }
            return endpoint.getShortCard(currentCity.id, businessConcept.id, user.guid);
        }
        if (endpoint) {
            if (!isNaN(parseInt(formatId, 10))) {
                return api.promCard.getExtendedCard(businessConcept.id, parseInt(formatId, 10), user.guid);
            }
            return api.promCard.getShortCard(businessConcept.id, user.guid);
        }
    }, [businessConcept?.id, currentCity?.id, size, formatId, section, user]);
    useEffect(() => {
        setLoading(true);
        getCardInfo()?.then(setCardInfo).finally(() => setLoading(false));
    }, [getCardInfo]);
    if (!businessConcept) {
        return null;
    }
    return (_jsx(Modal, { title: (_jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { children: businessConcept.name }), _jsx(FlexSpan, { ref: headerRef, mt: "1rem" })] })), content: (loading ? (_jsx(Flex, { alignItems: "center", justifyContent: "center", height: "7rem", children: _jsx(FlexSpan, { children: _jsx(Loader, {}) }) })) : [AppSection.Enterprise, AppSection.EnterpriseIntegral].includes(section) ? (!isNaN(parseInt(formatId, 10))
            ? _jsx(PromBusinessInfoExtended, { headerRef: headerRef, cardInfo: cardInfo })
            : _jsx(PromBusinessInfoShort, { cardInfo: cardInfo })) : !isNaN(parseInt(size, 10))
            ? _jsx(BusinessInfoExtended, { headerRef: headerRef, cardInfo: cardInfo })
            : _jsx(BusinessInfoShort, { cardInfo: cardInfo })), footer: [AppSection.RetailIntegral, AppSection.EnterpriseIntegral].includes(section) && (_jsx(BusinessInfoDialogFooter, {})) }));
};
