import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppInitialized } from "../../selectors/app";
import { setAppInitialized } from "../../slices/app";
export const useAppInitialized = () => {
    const dispatch = useDispatch();
    const appInitialized = useSelector(getAppInitialized);
    const update = useCallback((value) => {
        dispatch(setAppInitialized(value));
    }, [dispatch]);
    return [appInitialized, update];
};
