import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Icon } from "../../ui";
export const InfoIconContainer = styled(props => _jsx(Icon, { icon: "question", ...props })) `
  cursor: pointer;
  color: #7e6cc1;
  transition: color 0.3s;
  
  :hover {
    color: #9d87f1;
  }
`;
