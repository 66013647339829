import styled from "styled-components";
import { Flex } from "../../ui";
import hexagon from "../../assets/images/hexagon.svg";
export const SimpleLegendSymbols = styled(Flex) `
  flex-wrap: nowrap;
  margin: 0 0.75rem;
`;
export const SimpleLegendLabel = styled.div `
  font-size: 0.75rem;
  line-height: 0.975rem;
  color: #666666;
`;
export const SimpleLegendHex = styled.div `
  box-sizing: border-box;
  width: 1.25rem;
  height: 1.25rem;
  mask: url(${hexagon}) no-repeat center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ opacity }) => opacity};
`;
export const SimpleLegendSquare = styled.div `
  box-sizing: border-box;
  width: 1rem;
  height: 1rem;
  margin: 0 0.0625rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ opacity }) => opacity};
`;
