import styled from "styled-components";
import { FlexSpan } from "../../../../ui";
export const ConceptColumn = styled(FlexSpan) `
  width: calc(50% - 0.5rem);
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 0.5rem;

  &:nth-child(odd) {
    margin-right: 1rem;
  }
`;
