import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCityList } from "../../selectors/dict";
import { setCityList } from "../../slices/dict";
export const useCityList = () => {
    const dispatch = useDispatch();
    const cityList = useSelector(getCityList);
    const update = useCallback((value) => {
        dispatch(setCityList(value));
    }, [dispatch]);
    return [cityList, update];
};
