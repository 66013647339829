import { BusinessForm } from "./Forms/BusinessForm";
import { IncomeForm } from "./Forms/IncomeForm";
import { InitialFinancingForm } from "./Forms/InitialFinancingForm";
import { OperatingExpensesForm } from "./Forms/OperatingExpensesForm";
import { CreditForm } from "./Forms/CreditForm";
export const FORM_PARAMS = [
    {
        title: "Параметры бизнеса",
        icon: "settings",
        mode: "Business",
        component: BusinessForm,
        keys: [
            "start_date",
            "square",
            "square_main",
            "rent_cost",
            "rent_month",
            "service_cost",
            "service_month",
        ],
    },
    {
        title: "Доходы",
        icon: "money",
        mode: "Income",
        component: IncomeForm,
        keys: [
            "use_count",
            "avg_check",
            "day_count",
            "power_schedule",
        ],
    },
    {
        title: "Первоначальное финансирование",
        icon: "rouble",
        mode: "InitialFinancing",
        component: InitialFinancingForm,
        keys: [
            "reg_cost",
            "reg_schedule",
            "deposit_cost",
            "deposit_schedule",
            "repair_cost",
            "repair_schedule",
            "product_cost",
            "product_schedule",
            "soft_cost",
            "soft_schedule",
            "adv_cost",
            "adv_schedule",
            "other_cost",
            "other_schedule",
            "equipment_list",
        ],
    },
    {
        title: "Операционные расходы",
        icon: "calculator",
        mode: "OperatingExpenses",
        component: OperatingExpensesForm,
        keys: [
            "material_proc",
            "marketing_proc",
            "production_proc",
            "office_cost",
            "internet_cost",
            "internet_month",
            "security_cost",
            "security_month",
        ],
    },
    {
        title: "Заемные средства",
        icon: "man-with-case",
        mode: "Credit",
        component: CreditForm,
        keys: [
            "credit_flag",
            "credit_time",
            "credit_proc",
            "credit_value",
            "credit_month",
        ],
    },
];
export const CHART_NAMES = {
    0: "Выручка, млн. руб.",
    1: "Прибыль, млн. руб.",
};
