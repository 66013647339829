import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentFeature } from "../../selectors/map";
import { setCurrentFeature } from "../../slices/map";
export const useCurrentFeature = () => {
    const dispatch = useDispatch();
    const currentFeature = useSelector(getCurrentFeature);
    const update = useCallback((value) => {
        dispatch(setCurrentFeature(value));
    }, [dispatch]);
    return [currentFeature, update];
};
