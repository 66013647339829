import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines, max-lines-per-function */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { generatePath, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isNil } from "lodash";
import { addMonths } from "date-fns";
import { Layout } from "../../../components/Layout";
import { Card, Divider, Flex, FlexSpan, H4, H6, OutlineButton, Tag } from "../../../ui";
import { Stepper } from "../../../components/Stepper";
import { ParamsCard } from "../../../components/ParamsCard";
import { BusinessPlanHeader } from "./BusinessPlanHeader";
import { InfoMessage } from "../../../components/InfoMessage";
import { ParamsForm } from "../../../components/ParamsForm";
import { BusinessPlanIndicator } from "../../../components/BusinessPlanIndicator";
import { BarChart } from "../../../components/BarChart";
import { GanttChart } from "../../../components/GanttChart";
import { ChartLegend } from "../../../components/ChartLegend";
import { MobileForm } from "../../../components/MobileForm";
import { PageLoader } from "../../../components/PageLoader";
import { BrokenBusinessPlan } from "../../../components/BrokenBusinessPlan";
import { RetailBusinessUrl, RetailLocationUrl } from "../../../constants/routes";
import { EMPTY_VALUE, MONTH_SHORT_NAMES } from "../../../constants/common";
import { CHART_NAMES, FORM_PARAMS } from "./constants";
import { getDateObject, getGanttChartGroups, getIndicatorsBarChartData, getWktPoint } from "../../../utils";
import { useIsDesktop, useUser } from "../../../hooks";
import { syncRetailGanttEvents, validateRequestValues } from "./utils";
import { api } from "../../../api";
import { AppSection } from "../../../slices/app/types";
import analytics from "../../../assets/images/analytics.svg";
import scoring from "../../../assets/images/scoring.svg";
export const RetailBusinessPlanPage = () => {
    const navigate = useNavigate();
    const isDesktop = useIsDesktop();
    const [user] = useUser();
    const { businessId, conceptId, cityId } = useParams();
    const [searchParams] = useSearchParams();
    const point = searchParams.get("point");
    const formatId = searchParams.get("formatId");
    const size = Number(searchParams.get("size"));
    const zone = searchParams.get("zone");
    const franchise = searchParams.get("franchise");
    const excludedCompetitors = searchParams.get("excludedCompetitors");
    const newBuildings = searchParams.get("newBuildings");
    const [data, setData] = useState();
    const [docs, setDocs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState();
    const currentMode = useRef();
    const backPath = useMemo(() => {
        const path = generatePath(RetailLocationUrl.path, { cityId, businessId, conceptId });
        // eslint-disable-next-line max-len
        return `${path}?point=${encodeURIComponent(point)}&formatId=${formatId}&size=${size}&excludedCompetitors=${encodeURIComponent(excludedCompetitors)}&newBuildings=${newBuildings}&zone=${zone}&franchise=${franchise}`;
    }, [cityId, businessId, conceptId, point, formatId, size, excludedCompetitors, newBuildings, zone, franchise]);
    const [requestValues, setRequestValues] = useState({});
    const [defaultFormValues, setDefaultFormValues] = useState({});
    const [defaultParams, setDefaultParams] = useState();
    const [shouldReset, setShouldReset] = useState(false);
    const initialRender = useRef(true);
    const CurrentForm = useMemo(() => FORM_PARAMS.find(item => item.mode === editMode)?.component, [editMode]);
    const defaultRequestParams = useMemo(() => {
        return {
            point: getWktPoint(JSON.parse(point)),
            size,
            disable_competitors: Array.isArray(JSON.parse(excludedCompetitors)) ? JSON.parse(excludedCompetitors).join(",") : "",
            dev_enable: newBuildings,
            zone,
            guid: user.guid,
        };
    }, [excludedCompetitors, newBuildings, point, size, user, zone]);
    const fetchData = useCallback((params) => {
        setLoading(true);
        Promise.all([
            api.businessPlan.createRetailBusinessPlan(Number(cityId), size, {
                ...defaultRequestParams,
            }, {
                ...params,
                event_list: syncRetailGanttEvents(params, data?.parameters?.event_list, defaultParams?.constants),
            }),
            api.businessPlan.getRetailParams(Number(cityId), size),
            api.businessPlan.getRetailDocuments(Number(cityId), size),
        ])
            .then(([response, defaultRetailParams, retailDocs]) => {
            setData(response);
            const newRequestValues = FORM_PARAMS.reduce((acc, curr) => [...acc, ...curr.keys], []).reduce((acc, curr) => ({
                ...acc,
                [curr]: response?.parameters[curr] ?? "",
            }), {});
            setRequestValues(newRequestValues);
            setDefaultFormValues(newRequestValues);
            setDefaultParams(defaultRetailParams);
            setDocs(retailDocs);
        })
            .finally(() => {
            if (initialRender.current) {
                initialRender.current = false;
            }
            setLoading(false);
        });
    }, [cityId, size, data, defaultParams, defaultRequestParams]);
    useEffect(() => {
        setEditMode(undefined);
    }, [isDesktop]);
    useEffect(() => {
        if (currentMode.current !== undefined) {
            setRequestValues({
                ...requestValues,
                ...Object.entries(defaultFormValues).reduce((acc, [key, value]) => {
                    return FORM_PARAMS.find(item => item.mode === currentMode.current)?.keys.includes(key)
                        ? { ...acc, [key]: value }
                        : acc;
                }, {}),
            });
        }
        currentMode.current = editMode;
    }, [editMode]); // eslint-disable-line
    useEffect(() => {
        if (!user.guid) {
            return;
        }
        fetchData();
    }, [user.guid]); // eslint-disable-line
    if (data?.is_data_error) {
        return (_jsx(Layout, { section: AppSection.Retail, children: _jsx(BrokenBusinessPlan, { section: AppSection.Retail }) }));
    }
    return (_jsxs(Layout, { section: AppSection.Retail, loading: (initialRender.current && loading) || !data, bodyStyle: editMode === undefined ? { height: "100%", maxHeight: "100%" } : undefined, leftPanel: isDesktop ? (_jsxs(Flex, { column: true, height: "100%", children: [_jsx(FlexSpan, { children: _jsx(Stepper, { hideButtons: true, activeStep: 2, steps: [
                            { title: "Выбор бизнеса", onPick: () => navigate(RetailBusinessUrl.path) },
                            { title: "Подбор локации", onPick: () => navigate(backPath) },
                            { title: "Бизнес-план" },
                        ] }) }), _jsx(FlexSpan, { flexGrow: 1, mt: "1rem", overflow: "auto", children: _jsx(Flex, { flexWrap: "wrap", gap: "1rem", children: FORM_PARAMS.map((param, paramIndex) => (_jsx(ParamsCard, { icon: param.icon, label: param.title, width: "calc(50% - 0.5rem)", active: editMode === param.mode, onClick: () => setEditMode(param.mode) }, `business-plan-param-${paramIndex}`))) }) })] })) : undefined, children: [_jsxs(Flex, { column: true, height: "100%", children: [_jsx(FlexSpan, { "data-show-on-mobile": true, children: _jsxs(Flex, { alignItems: "center", justifyContent: "space-between", mb: "1.5rem", children: [_jsx(FlexSpan, { children: _jsx(Stepper, { hideButtons: true, activeStep: 2, steps: [
                                            { title: "Выбор бизнеса", onPick: () => navigate(RetailBusinessUrl.path) },
                                            { title: "Выбор площадки", onPick: () => navigate(backPath) },
                                            { title: "Бизнес-план" },
                                        ] }) }), _jsx(FlexSpan, { children: _jsx(OutlineButton, { icon: "filter", buttonSize: "s", fontColor: "#9D87F1", onClick: () => setEditMode("true"), children: "\u041F\u0410\u0420\u0410\u041C\u0415\u0422\u0420\u042B" }) })] }) }), _jsx(FlexSpan, { flexGrow: 1, width: "100%", height: "100%", overflow: "hidden", children: editMode === undefined ? (_jsxs(Flex, { column: true, height: "100%", p: isDesktop ? "0 1rem 1.75rem" : "0 0 1.75rem", overflow: "auto", children: [_jsxs(Card, { p: 0, children: [_jsx(BusinessPlanHeader, { data: data, docs: docs, defaultParams: defaultParams, defaultRequestParams: defaultRequestParams, requestValues: requestValues }), _jsx(Flex, { p: "1.5rem", children: _jsxs(FlexSpan, { width: "100%", children: [_jsxs(Flex, { alignItems: "center", mb: "1.5rem", children: [_jsx(FlexSpan, { width: "2rem", mr: "0.75rem", children: _jsx("img", { src: scoring, alt: "" }) }), _jsx(FlexSpan, { mr: "1rem", children: _jsx(H4, { children: "\u042D\u0442\u0430\u043F\u044B \u043F\u0440\u043E\u0435\u043A\u0442\u0430" }) }), data?.parameters?.start_date && (_jsx(FlexSpan, { children: _jsxs(Tag, { light: true, children: ["\u0421\u0442\u0430\u0440\u0442 \u043F\u0440\u043E\u0435\u043A\u0442\u0430 ", data.parameters.start_date] }) }))] }), _jsx(Flex, { children: _jsx(GanttChart, { groups: getGanttChartGroups(data?.parameters), monthsRender: month => {
                                                                if (!data.parameters.start_date) {
                                                                    return null;
                                                                }
                                                                const currDate = addMonths(getDateObject(data.parameters.start_date), month - 1);
                                                                return (_jsxs(Flex, { column: true, alignItems: "center", children: [_jsx(FlexSpan, { children: _jsx(H6, { children: MONTH_SHORT_NAMES[currDate.getMonth()] }) }), _jsx(FlexSpan, { fontSize: "0.625rem", color: "#9F9F9F", lineHeight: 1, children: currDate.getFullYear() })] }));
                                                            } }) })] }) })] }), _jsx(Card, { p: "1.5rem", mt: "1rem", children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { children: _jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { width: "2rem", mr: "0.75rem", children: _jsx("img", { src: analytics, alt: "" }) }), _jsx(FlexSpan, { flexGrow: 1, children: _jsx(H4, { children: "\u0424\u0438\u043D\u0430\u043D\u0441\u043E\u0432\u044B\u0435 \u043F\u043E\u043A\u0430\u0437\u0430\u0442\u0435\u043B\u0438" }) }), _jsx(FlexSpan, { ml: "2rem", children: _jsxs(Flex, { children: [_jsx(FlexSpan, { children: _jsx(BusinessPlanIndicator, { label: "\u0420\u0435\u043D\u0442\u0430\u0431\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u043F\u0440\u043E\u0434\u0430\u0436", value: !isNil(data?.bp?.profit) ? (data.bp.profit * 100) : EMPTY_VALUE, unit: "%", align: "right" }) }), _jsx(FlexSpan, { children: _jsx(Divider, { vertical: true, m: "0 1rem", height: "100%" }) }), _jsx(FlexSpan, { children: _jsx(BusinessPlanIndicator, { label: "\u0412\u043D\u0443\u0442\u0440\u0435\u043D\u043D\u044F\u044F \u043D\u043E\u0440\u043C\u0430 \u0434\u043E\u0445\u043E\u0434\u043D\u043E\u0441\u0442\u0438", value: !isNil(data?.bp?.irr) ? (data.bp.irr * 100) : EMPTY_VALUE, unit: "%", align: "right" }) })] }) })] }) }), _jsx(FlexSpan, { children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { mt: "1rem", children: _jsx(BarChart, { height: 160, data: getIndicatorsBarChartData(data?.bp?.revenue_list, data?.bp?.earnings_list), colors: {
                                                                    0: "#CEC3F8",
                                                                    1: "#9D87F1",
                                                                }, drawGridX: true, barWidth: 30, barPadding: 4, dynamicTooltipEnable: true, formatTooltipName: name => CHART_NAMES[name] }) }), _jsx(FlexSpan, { mt: "1rem", children: _jsx(ChartLegend, { items: [
                                                                    { title: CHART_NAMES[0], color: "#CEC3F8" },
                                                                    { title: CHART_NAMES[1], color: "#9D87F1" },
                                                                ] }) })] }) }), !requestValues?.credit_flag && (_jsx(FlexSpan, { mt: "1.5rem", children: _jsx(InfoMessage, { children: "\u0411\u0438\u0437\u043D\u0435\u0441-\u043F\u043B\u0430\u043D \u0440\u0430\u0441\u0441\u0447\u0438\u0442\u0430\u043D \u0431\u0435\u0437 \u043F\u0440\u0438\u0432\u043B\u0435\u0447\u0435\u043D\u0438\u044F \u0437\u0430\u0435\u043C\u043D\u044B\u0445 \u0441\u0440\u0435\u0434\u0441\u0442\u0432. \u0412 \u0441\u043B\u0443\u0447\u0430\u0435 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u043D\u0438\u044F \u043A\u0440\u0435\u0434\u0438\u0442\u0430 \u0434\u043E\u0445\u043E\u0434\u043D\u043E\u0441\u0442\u044C \u0431\u0438\u0437\u043D\u0435\u0441\u0430 \u0443\u043C\u0435\u043D\u044C\u0448\u0438\u0442\u0441\u044F." }) }))] }) })] })) : isDesktop ? (_jsx(ParamsForm, { title: FORM_PARAMS.find(item => item.mode === editMode)?.title, subtitle: FORM_PARAMS.find(item => item.mode === editMode)?.subtitle, onReset: () => setShouldReset(true), onClose: () => setEditMode(undefined), onApply: () => {
                                fetchData({
                                    ...validateRequestValues(requestValues, defaultParams),
                                });
                                setEditMode(undefined);
                            }, children: CurrentForm && (_jsx(CurrentForm, { data: data, defaultParams: defaultParams, keys: FORM_PARAMS.find(item => item.mode === editMode)?.keys, shouldReset: shouldReset, onResetComplete: () => setShouldReset(false), onChange: newValues => {
                                    setRequestValues({
                                        ...requestValues,
                                        ...newValues,
                                    });
                                } })) })) : (_jsx(MobileForm, { data: data, defaultParams: defaultParams, items: FORM_PARAMS, onClose: () => setEditMode(undefined), onApply: values => {
                                fetchData({
                                    ...validateRequestValues(values, defaultParams),
                                });
                                setEditMode(undefined);
                            } })) })] }), loading && _jsx(PageLoader, { message: "\u041F\u0435\u0440\u0435\u0441\u0447\u0435\u0442 \u0431\u0438\u0437\u043D\u0435\u0441-\u043F\u043B\u0430\u043D\u0430..." })] }));
};
