import styled, { css, keyframes } from "styled-components";
const animation = keyframes `
  0% {
    right: -22rem;
  }
  100% {
    right: 0;
  }
`;
export const SideBarWrap = styled.div `
  position: absolute;
  top: 0;
  left: ${({ placement }) => placement === "left" ? 0 : undefined};
  right: ${({ placement }) => placement === "right" ? 0 : undefined};
  background: #ffffff;
  box-shadow: 0 0.25rem 2.75rem 0 rgba(63, 67, 106, 0.3);
  height: 100%;
  box-sizing: border-box;
`;
export const Overflow = styled.div `
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 998;
  background-color: ${({ mask }) => mask ? "rgba(0, 0, 0, 0.5)" : "transparent"};
`;
export const SideBarContainer = styled.div `
  position: fixed;
  top: ${({ fullscreen }) => fullscreen ? 0 : "5rem"};
  left: ${({ fullscreen }) => fullscreen ? 0 : undefined};
  height: ${({ fullscreen }) => fullscreen ? "var(--app-height)" : "calc(var(--app-height) - 5rem)"};
  width: 100%;
  z-index: 999;
  
  ${SideBarWrap} {
    animation: ${({ fullscreen }) => fullscreen ? undefined : css `${animation} 300ms ease-in`};
    width: ${({ fullscreen }) => fullscreen ? "100%" : "22rem"};
  }
`;
