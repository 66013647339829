import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, FlexSpan } from "../../../../ui";
import { CityListItem, CityListItemLabel, CityListItemName } from "./styled";
import { ModalContext } from "../../../../ui/Modal";
import { useDialogReset, useIsDesktop, useOverlayFeature, useResetFilters } from "../../../../hooks";
import { highlightSearch } from "../../../../utils";
export const CityList = ({ entityList, currentEntity, setCurrentEntity, resetPath }) => {
    const navigate = useNavigate();
    const closeDialog = useDialogReset();
    const resetFilters = useResetFilters();
    const [, setOverlayFeature] = useOverlayFeature();
    const { searchValue } = useContext(ModalContext);
    const filteredCities = useMemo(() => {
        return entityList?.features.filter(city => city.properties.name.toLowerCase().includes(searchValue.toLowerCase())) ?? [];
    }, [entityList, searchValue]);
    const isDesktop = useIsDesktop();
    const columnCount = useMemo(() => isDesktop ? 4 : 3, [isDesktop]);
    const columnLength = useMemo(() => Math.ceil(filteredCities.length / columnCount), [columnCount, filteredCities.length]);
    const lastLetter = useRef("");
    useEffect(() => {
        lastLetter.current = "";
    }, [filteredCities]);
    return (_jsx(Flex, { flexWrap: "wrap", children: Array(columnCount).fill(null).map((_, colIndex) => {
            return (_jsx(FlexSpan, { display: "flex", flexDirection: "column", width: `calc(100% / ${columnCount} - 1rem)`, children: filteredCities.slice(colIndex * columnLength, (colIndex + 1) * columnLength).map((city, colCityIndex) => {
                    const getLabel = () => {
                        if (lastLetter.current !== city.properties.name[0]) {
                            lastLetter.current = city.properties.name[0];
                            return city.properties.name[0];
                        }
                        return "";
                    };
                    return (_jsxs(CityListItem, { mt: lastLetter.current !== city.properties.name[0] && colCityIndex !== 0 ? "1rem" : 0, onClick: () => {
                            if (city.id !== currentEntity.id) {
                                resetFilters();
                                setCurrentEntity(city);
                                setOverlayFeature(undefined);
                                if (resetPath) {
                                    navigate(resetPath);
                                }
                            }
                            closeDialog();
                        }, children: [_jsx(CityListItemLabel, { children: getLabel() }), _jsx(CityListItemName, { children: highlightSearch(searchValue, city.properties.name) })] }, `city-list-item-${city.id}`));
                }) }, `city-list-column-${colIndex}`));
        }) }));
};
