export class Service {
    http;
    constructor(http) {
        this.http = http;
    }
    getLegend(formatId, cityId, type, guid) {
        return this.http
            .get(`/api/service/legend`, {
            city: cityId,
            business_format: formatId,
            type,
            guid,
        })
            .json();
    }
    getRetailHexService(cityId, guid) {
        return this.http.get(`/api/service/cellconcepts/83/${cityId}`, { guid }).json();
    }
    getRetailDistrictService(cityId, guid) {
        return this.http.get(`/api/service/districtconcepts/9/${cityId}`, { guid }).json();
    }
    getPromConceptService(conceptId, guid) {
        return this.http.get(`/api/service/prom_profit/107${conceptId ? `/${conceptId}` : ""}`, { guid }).json();
    }
    getPromObjectService(subjectId, formatId, styles, guid) {
        return this.http.get(`/api/service/prom_object/98`, {
            subject: subjectId,
            geom: "",
            business_format: formatId,
            styles: styles?.join(",") ?? "",
            guid,
        }).json();
    }
    getHeatmapService(cityId, signal) {
        return this.http.get(`/api/service/heatmap/${cityId}`, undefined, signal).json();
    }
    getHeatmapArea(cityId, itemId) {
        return this.http.get(`/api/service/heatmap/${cityId}/${itemId}`).json();
    }
}
