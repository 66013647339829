import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STORAGE_CITY } from "../../constants/common";
import { getCurrentCity } from "../../selectors/app";
import { setCurrentCity } from "../../slices/app";
export const useCurrentCity = () => {
    const dispatch = useDispatch();
    const city = useSelector(getCurrentCity);
    const update = useCallback((value) => {
        window.localStorage.setItem(STORAGE_CITY, JSON.stringify(value));
        dispatch(setCurrentCity(value));
    }, [dispatch]);
    return [city, update];
};
