import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { LineChartTooltipStyles, StyledLineChart, StyledSimpleLineChart } from "./styled";
export const LineChart = ({ area, ...props }) => {
    const customYAxisSelection = useCallback(yAxis => {
        yAxis.remove();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(LineChartTooltipStyles, {}), area ? (_jsx(StyledLineChart, { margin: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                }, yAxisPadding: 0, stackedTooltip: true, dynamicTooltipEnable: true, customYAxisSelection: customYAxisSelection, tooltipClassName: "lineChartTooltip", ...props })) : (_jsx(StyledSimpleLineChart, { margin: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                }, yAxisPadding: 0, stackedTooltip: true, dynamicTooltipEnable: true, customYAxisSelection: customYAxisSelection, tooltipClassName: "lineChartTooltip", ...props }))] }));
};
