import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useMapContext } from "@evergis/react";
import { geo } from "@evergis/sgis/es/Crs";
import { YandexMapContainer } from "./styled";
import { BaseMapName } from "../../../../constants/baseMaps";
import { useBaseMapName } from "../../../../hooks";
import { loadScript } from "./utils";
import { YandexLocales, YandexMapType } from "./types";
export const YandexMap = () => {
    const yandexMapContainer = useRef();
    const yandexMapsLoaded = useRef({
        [YandexLocales.RU]: null,
        [YandexLocales.EN]: null,
    });
    const { map, painter } = useMapContext();
    const [baseMapName] = useBaseMapName();
    const isActive = baseMapName !== BaseMapName.DoubleGis;
    const currentLocale = useMemo(() => (baseMapName === BaseMapName.YMapsEng ? YandexLocales.EN : YandexLocales.RU), [baseMapName]);
    const currentType = useMemo(() => (baseMapName === BaseMapName.YMapsHybrid ? YandexMapType.Hybrid : YandexMapType.Default), [baseMapName]);
    const onBBoxChange = useCallback(() => {
        if (!yandexMapsLoaded.current[currentLocale] || !map)
            return;
        const index = map.tileScheme.getLevel(map.resolution);
        if (index === void 0 && index === null)
            return;
        const zoom = map.tileScheme.levels[index].zIndex;
        const center = map.centerPoint.projectTo(geo).position;
        yandexMapsLoaded.current[currentLocale].setCenter(center, zoom);
    }, [map, currentLocale]);
    const onMapResize = useCallback(() => {
        if (yandexMapsLoaded.current[currentLocale]) {
            yandexMapsLoaded.current[currentLocale].container.fitToViewport();
        }
    }, [currentLocale]);
    const initMap = useCallback(() => {
        if (ymaps) {
            ymaps.ready(() => {
                yandexMapsLoaded.current[currentLocale] = new ymaps.Map(yandexMapContainer.current, {
                    center: [0, 0],
                    zoom: 0,
                    behaviors: [],
                    controls: [],
                });
                yandexMapsLoaded.current[currentLocale].setType(currentType);
                onBBoxChange();
            });
        }
    }, [currentType, currentLocale, onBBoxChange]);
    useEffect(() => {
        if (yandexMapsLoaded.current[currentLocale]) {
            yandexMapsLoaded.current[currentLocale].destroy();
        }
        yandexMapContainer.current.innerHTML = "";
        if (!isActive) {
            map.off("bboxChange", onBBoxChange);
            painter.off("mapResize", onMapResize);
            return;
        }
        if (!yandexMapsLoaded.current[currentLocale]) {
            (async () => {
                await loadScript(`//api-maps.yandex.ru/2.1.78/?load=package.map&lang=${currentLocale}`);
                initMap();
            })();
        }
        else {
            initMap();
        }
        map.on("bboxChange", onBBoxChange);
        painter.on("mapResize", onMapResize);
        return () => {
            map.off("bboxChange", onBBoxChange);
            painter.off("mapResize", onMapResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseMapName]);
    return _jsx(YandexMapContainer, { ref: yandexMapContainer });
};
