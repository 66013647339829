import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MapControl } from "../../../components/MapControl";
import { FlexSpan, Icon, Tooltip } from "../../../ui";
import { CompareAreasContainer } from "./styled";
import { MAP_CONTROL_LEFT_DESKTOP, MAP_CONTROL_LEFT_TABLET } from "../../../constants/layout";
import { useIsDesktop } from "../../../hooks";
export const CompareAreasControl = ({ businessAreas, onClick }) => {
    const isDesktop = useIsDesktop();
    if (!businessAreas.length) {
        return null;
    }
    return (_jsx(MapControl, { top: isDesktop ? "1.5rem" : "1rem", left: isDesktop ? MAP_CONTROL_LEFT_DESKTOP : MAP_CONTROL_LEFT_TABLET, children: _jsx(Tooltip, { content: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u0441\u0440\u0430\u0432\u043D\u0435\u043D\u0438\u044E", children: _jsxs(CompareAreasContainer, { onClick: onClick, children: [_jsx(FlexSpan, { display: "flex", children: _jsx(Icon, { icon: "compare" }) }), _jsx(FlexSpan, { children: businessAreas.length })] }) }) }));
};
