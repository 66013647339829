import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines-per-function, max-lines */
import { useCallback, useMemo, useState } from "react";
import { Flex, FlexSpan, H5, Link, NumberInput, OutlineButton, Table, GrayButton, Icon, Popover, Menu, EllipsisText, } from "../../../../ui";
import { InfoMessage } from "../../../../components/InfoMessage";
import { TableHeaderContainer, TableHeaderSubtitle, TableStickyBodyCell } from "../../../../components/ParamsTable/styled";
import { TableRowMenu } from "../../../../components/TableRowMenu";
import { ENTREPRENEUR_FORM_ID } from "../../../../constants/common";
import { useBusinessPlanFormValues } from "../../../../hooks";
import { formatNumber } from "../../../../utils";
export const StaffForm = ({ data, defaultParams, keys, shouldReset, onResetComplete, onChange, onValuesChange }) => {
    const classOptions = useMemo(() => defaultParams.constants.personal_class.map(item => ({ value: item, label: item })), [defaultParams]);
    const [menuOpen, setMenuOpen] = useState();
    const [rowHover, setRowHover] = useState();
    const [values, onUpdateValues] = useBusinessPlanFormValues(keys.reduce((acc, curr) => ({ ...acc, [curr]: data?.parameters[curr] }), {}), {
        personal_list: defaultParams.personal_list.map(item => ({ ...item, count: item.count.value, price: item.price.value })),
    }, onChange, shouldReset, onResetComplete, onValuesChange);
    const onSetMrotToAll = useCallback(() => {
        onUpdateValues({
            personal_list: values.personal_list.map(item => ({
                ...item,
                price: defaultParams.constants.mrot,
            })),
        });
    }, [defaultParams, onUpdateValues, values]);
    const onAddRow = useCallback(() => {
        onUpdateValues({
            personal_list: [
                ...values.personal_list,
                {
                    id: -1 * (values.personal_list.filter(item => item.id < 0).length + 1),
                    class: classOptions[0].value,
                    name: "Доп. сотрудник",
                    count: 1,
                    price: defaultParams.personal_list[0].price?.min,
                },
            ],
        });
    }, [classOptions, defaultParams, onUpdateValues, values]);
    const onPickMenuItem = useCallback((option, rowId) => {
        setRowHover(undefined);
        if (option.value === 1) {
            let userIdCount = 0;
            onUpdateValues({
                personal_list: values.personal_list.reduce((acc, curr, currIndex) => {
                    return currIndex === rowId ? acc : [...acc, curr];
                }, []).map(item => {
                    if (item.id < 0) {
                        userIdCount += 1;
                        return {
                            ...item,
                            id: -1 * userIdCount,
                        };
                    }
                    return item;
                }),
            });
        }
    }, [onUpdateValues, values]);
    return (_jsxs(_Fragment, { children: [_jsxs(Flex, { mb: "1.5rem", column: true, children: [_jsx(FlexSpan, { mb: "1.5rem", children: _jsx(InfoMessage, { children: "\u041E\u0431\u0440\u0430\u0449\u0430\u0435\u043C \u0432\u043D\u0438\u043C\u0430\u043D\u0438\u0435, \u0447\u0442\u043E \u0434\u043E\u0445\u043E\u0434\u044B \u0438\u043D\u0434\u0438\u0432\u0438\u0434\u0443\u0430\u043B\u044C\u043D\u043E\u0433\u043E \u043F\u0440\u0435\u0434\u043F\u0440\u0438\u043D\u0438\u043C\u0430\u0442\u0435\u043B\u044F \u043D\u0435 \u0434\u043E\u043B\u0436\u043D\u044B \u0432\u043A\u043B\u044E\u0447\u0430\u0442\u044C\u0441\u044F \u0432 \u0444\u043E\u043D\u0434 \u043E\u043F\u043B\u0430\u0442\u044B \u0442\u0440\u0443\u0434\u0430 (\u0424\u041E\u0422) \u043D\u0430\u0435\u043C\u043D\u043E\u0433\u043E \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u0430." }) }), _jsx(FlexSpan, { width: "100%", children: _jsx(Table, { cellGap: 0, columns: [
                                {
                                    id: "class",
                                    title: "Категория наемного персонала",
                                    width: "calc(25% - 0.25rem)",
                                    bodyCellStyle: { borderRight: "1px solid #D2D2D2" },
                                    renderHead: column => (_jsxs(TableHeaderContainer, { width: "100%", mb: "0.125rem", children: [_jsx(H5, { children: column.title }), column.subtitle && _jsx(TableHeaderSubtitle, { children: column.subtitle })] })),
                                    renderBody: (row, rowId) => {
                                        return (_jsxs(TableStickyBodyCell, { firstRow: rowId === 0, lastRow: rowId === values.personal_list.length - 1, firstCol: true, colIndex: 0, withBackground: row.data.id >= 0, isEditing: true, onMouseOver: () => rowHover === undefined && setRowHover(rowId), onMouseLeave: () => setRowHover(undefined), children: [rowHover === rowId && (_jsx(TableRowMenu, { items: [
                                                        {
                                                            value: 1,
                                                            label: "Удалить строку",
                                                            prefix: _jsx(Icon, { icon: "trash" }),
                                                            disabled: (data.parameters.form === 1 && values.personal_list.length === 1)
                                                                || (data.parameters.form !== 1 && values.personal_list.filter((_, index) => index !== rowId).every(item => item.count === 0)),
                                                        },
                                                    ], onPick: option => onPickMenuItem(option, rowId) })), row.data.id < 0 ? (_jsx(Popover, { isOpen: menuOpen === rowId, onOpen: () => setMenuOpen(rowId), wrapperStyle: { width: "100%" }, containerStyle: { zIndex: 999 }, offsetY: 5, anchor: (_jsxs(Flex, { width: "calc(100% - 0.5rem)", height: "100%", p: "0.75rem 0.5rem 0.75rem 0", ml: "0.5rem", style: { cursor: "pointer" }, children: [_jsx(FlexSpan, { flexGrow: 1, children: row.data.class }), _jsx(FlexSpan, { display: "flex", children: _jsx(Icon, { icon: "chevron-medium-bottom", color: "#9D87F1" }) })] })), children: _jsx(Menu, { width: "12.375rem", onPick: option => {
                                                            onUpdateValues({
                                                                personal_list: values.personal_list.map((item, itemIndex) => ({
                                                                    ...item,
                                                                    class: itemIndex === rowId ? option.value : item.class,
                                                                })),
                                                            });
                                                            setRowHover(undefined);
                                                            setMenuOpen(undefined);
                                                        }, items: classOptions.map(item => ({
                                                            ...item,
                                                            active: item.value === values.personal_list[rowId].class,
                                                        })) }) })) : (_jsx(Flex, { width: "100%", height: "100%", alignItems: "center", p: "0 0.5rem", children: _jsx(FlexSpan, { width: "100%", children: _jsx(EllipsisText, { children: row.data.class }) }) }))] }));
                                    },
                                },
                                {
                                    id: "name",
                                    title: "Должность",
                                    width: "calc(25% - 0.25rem)",
                                    renderHead: column => (_jsxs(TableHeaderContainer, { width: "100%", mb: "0.125rem", children: [_jsx(H5, { children: column.title }), column.subtitle && _jsx(TableHeaderSubtitle, { children: column.subtitle })] })),
                                    renderBody: (row, rowId) => {
                                        return (_jsx(TableStickyBodyCell, { firstRow: rowId === 0, lastRow: rowId === values.personal_list.length - 1, colIndex: 1, withBackground: true, children: row.data.name }));
                                    },
                                },
                                {
                                    id: "count",
                                    title: "Кол-во ставок",
                                    subtitle: "ед.",
                                    width: "calc(20% - 0.25rem)",
                                    align: "right",
                                    renderHead: column => (_jsxs(TableHeaderContainer, { width: "100%", align: "right", mb: "0.125rem", children: [_jsx(H5, { children: column.title }), column.subtitle && _jsx(TableHeaderSubtitle, { children: column.subtitle })] })),
                                    renderBody: (row, rowId) => {
                                        return (_jsx(TableStickyBodyCell, { firstRow: rowId === 0, lastRow: rowId === values.personal_list.length - 1, colIndex: 2, align: "right", isEditing: true, children: _jsx(NumberInput, { style: { border: "none" }, inputStyle: { textAlign: "right", fontSize: "0.75rem", padding: "0 0.75rem" }, defaultValue: row.data.count?.toString(), hideArrows: true, height: "2.5rem", onChange: value => onUpdateValues({
                                                    personal_list: values.personal_list.map((item, itemIndex) => ({
                                                        ...item,
                                                        count: itemIndex === rowId ? value : item.count,
                                                    })),
                                                }), min: data.parameters.form === ENTREPRENEUR_FORM_ID
                                                    ? defaultParams.personal_list[0].count.min
                                                    : (defaultParams.personal_list[0].count.min || 1), max: defaultParams.personal_list[0].count.max }) }));
                                    },
                                },
                                {
                                    id: "price",
                                    title: "Ставка",
                                    subtitle: "руб. в мес.",
                                    width: "calc(15% - 0.25rem)",
                                    renderHead: column => (_jsxs(TableHeaderContainer, { width: "100%", align: "right", mb: "0.125rem", children: [_jsx(H5, { children: column.title }), column.subtitle && _jsx(TableHeaderSubtitle, { children: column.subtitle })] })),
                                    align: "right",
                                    renderBody: (row, rowId) => {
                                        return (_jsx(TableStickyBodyCell, { firstRow: rowId === 0, lastRow: rowId === values.personal_list.length - 1, lastCol: true, colIndex: 3, align: "right", isEditing: true, children: _jsx(NumberInput, { style: { border: "none" }, inputStyle: { textAlign: "right", fontSize: "0.75rem", padding: "0 0.75rem" }, defaultValue: row.data.price?.toString(), hideArrows: true, height: "2.5rem", onChange: value => onUpdateValues({
                                                    personal_list: values.personal_list.map((item, itemIndex) => ({
                                                        ...item,
                                                        price: itemIndex === rowId ? value : item.price,
                                                    })),
                                                }), min: defaultParams.personal_list[0].price.min, max: defaultParams.personal_list[0].price.max }) }));
                                    },
                                },
                                {
                                    width: "calc(15% - 0.25rem)",
                                    align: "center",
                                    renderHead: () => {
                                        return (_jsx(Flex, { height: "100%", alignItems: "center", justifyContent: "center", textAlign: "center", children: _jsxs(Link, { onClick: onSetMrotToAll, children: ["\u041D\u0430\u0437\u043D\u0430\u0447\u0438\u0442\u044C", _jsx("br", {}), "\u041C\u0420\u041E\u0422 \u0432\u0441\u0435\u043C"] }) }));
                                    },
                                    renderBody: (row, rowId) => {
                                        return (_jsx(Flex, { height: "100%", alignItems: "center", justifyContent: "center", children: _jsx(OutlineButton, { buttonSize: "xs", onClick: () => {
                                                    onUpdateValues({
                                                        personal_list: values.personal_list.map((item, itemIndex) => ({
                                                            ...item,
                                                            price: itemIndex === rowId ? defaultParams.constants.mrot : item.price,
                                                        })),
                                                    });
                                                }, children: "\u041C\u0420\u041E\u0422" }) }));
                                    },
                                },
                            ], rows: values.personal_list.map(item => ({ data: item })) }) }), _jsx(FlexSpan, { width: "100%", mt: "1rem", children: _jsx(GrayButton, { icon: "plus", buttonSize: "s", width: "100%", onClick: onAddRow, children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u0442\u0440\u043E\u043A\u0443" }) })] }), _jsx(InfoMessage, { icon: "wallet", iconSize: 20, children: _jsxs(Flex, { alignItems: "center", justifyContent: "space-between", children: [_jsx(FlexSpan, { children: _jsxs(H5, { children: ["\u0418\u0442\u043E\u0433\u043E \u0424\u041E\u0422 \u0437\u0430 \u0433\u043E\u0434 ", data.parameters.form === ENTREPRENEUR_FORM_ID ? "(без учета ФОТ индивидуального предпринимателя)" : ""] }) }), _jsx(FlexSpan, { children: _jsxs(H5, { children: [formatNumber(values.personal_list.reduce((acc, curr) => (acc + ((curr.count ?? 0) * (curr.price ?? 0))), 0) * 12), " \u20BD"] }) })] }) })] }));
};
