import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../selectors/auth";
import { setUser } from "../../slices/auth";
export const useUser = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const update = useCallback((value) => {
        dispatch(setUser(value));
    }, [dispatch]);
    return [user ?? {}, update];
};
