import { useCallback, useRef } from "react";
import * as d3 from "d3";
import { roundedRect } from "../utils";
const radius = 3;
export const transitionTime = 100;
// eslint-disable-next-line max-lines-per-function
export const useAnimationBars = (excludedNames = []) => {
    const prevData = useRef(null);
    return useCallback(({ groups, marshalledData }) => {
        let diff = 0;
        const bars = groups
            .selectAll("path")
            .data((item) => {
            const filteredPositive = item.filter(({ value, height }) => value > 0 && height >= 1);
            const filteredNegative = item.filter(({ value, height }) => value < 0 && height >= 1);
            filteredPositive
                .filter(({ name }) => !excludedNames.includes(name))
                .forEach(({ name }, index, array) => {
                if (index === array.length - 1) {
                    const rounded = filteredPositive.find(positive => positive.name === name);
                    rounded && (rounded.isTop = true);
                }
            });
            filteredNegative
                .filter(({ name }) => !excludedNames.includes(name))
                .forEach(({ name }, index, array) => {
                if (index === array.length - 1) {
                    const rounded = filteredNegative.find(negative => negative.name === name);
                    rounded && (rounded.isBottom = true);
                }
            });
            return [...filteredPositive, ...filteredNegative];
        })
            .join("path")
            .style("fill", (item) => item.color)
            .style("shape-rendering", "auto");
        if (prevData.current) {
            bars
                .attr("d", (item) => {
                const { height, x, y, isBottom, isTop } = item;
                return roundedRect({
                    width: 6,
                    height,
                    x,
                    y,
                    tlr: isTop ? Math.min(radius, height) : 0,
                    trr: isTop ? Math.min(radius, height) : 0,
                    brr: isBottom ? Math.min(radius, height) : 0,
                    blr: isBottom ? Math.min(radius, height) : 0,
                });
            })
                .attr("transform", "translate(0, 0)")
                .transition()
                .duration(({ name }) => excludedNames.includes(name) ? 0 : transitionTime)
                .ease(d3.easeLinear)
                .attr("transform", (item, index) => {
                const positiveValues = marshalledData[item.groupIndex].filter(({ value }) => value > 0);
                const y = marshalledData[item.groupIndex]
                    .filter(({ value }) => item.value > 0 ? value > 0 : value < 0)
                    .reduce((acc, { height, name }, i) => {
                    if (item.value > 0 &&
                        i < index &&
                        excludedNames.includes(name)) {
                        return acc + height;
                    }
                    if (i < index - positiveValues.length &&
                        item.value < 0 &&
                        excludedNames.includes(name)) {
                        return acc - height;
                    }
                    return acc;
                }, 0);
                return excludedNames.length > 0
                    ? `translate(0, ${y || 0})`
                    : "translate(0, 0)";
            })
                .attr("d", ({ name, x, y, height, isTop, isBottom, }) => {
                return excludedNames.includes(name)
                    ? roundedRect({
                        width: 6,
                        height: 0,
                        x,
                        y,
                        tlr: 0,
                        trr: 0,
                        brr: 0,
                        blr: 0,
                    })
                    : roundedRect({
                        width: 6,
                        height,
                        x,
                        y,
                        tlr: isTop ? Math.min(radius, height) : 0,
                        trr: isTop ? Math.min(radius, height) : 0,
                        brr: isBottom ? Math.min(radius, height) : 0,
                        blr: isBottom ? Math.min(radius, height) : 0,
                    });
            });
        }
        else {
            bars
                .attr("d", (item) => {
                const { height, value, x, y } = item;
                return roundedRect({
                    width: 6,
                    height: 0,
                    x,
                    y: value < 0 ? y : y + height,
                    tlr: 0,
                    trr: 0,
                    brr: 0,
                    blr: 0,
                });
            })
                .transition()
                .duration(() => transitionTime)
                .delay(({ value }, index) => {
                const isPositive = value > 0;
                if (isPositive) {
                    diff += 1;
                }
                return (transitionTime * (isPositive ? index : Math.max(index - diff, 0)));
            })
                .ease(d3.easeLinear)
                .attr("d", ({ isTop, height, x, y, isBottom, }) => roundedRect({
                width: 6,
                height,
                x,
                y,
                tlr: isTop ? Math.min(radius, height) : 0,
                trr: isTop ? Math.min(radius, height) : 0,
                brr: isBottom ? Math.min(radius, height) : 0,
                blr: isBottom ? Math.min(radius, height) : 0,
            }));
        }
        prevData.current = marshalledData;
        return bars;
    }, [excludedNames]);
};
