export class RetailCard {
    http;
    constructor(http) {
        this.http = http;
    }
    getShortCard(cityId, conceptId, guid) {
        return this.http.get(`/api/retail_card/${cityId}/${conceptId}`, { guid }).json();
    }
    getExtendedCard(cityId, conceptId, sizeId, guid) {
        return this.http.get(`/api/retail_card/${cityId}/${conceptId}/${sizeId}`, { guid }).json();
    }
}
