import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRetailInvestValue } from "../../../selectors/filters";
import { setRetailInvestValue } from "../../../slices/filters";
export const useRetailInvestValue = () => {
    const dispatch = useDispatch();
    const investValue = useSelector(getRetailInvestValue);
    const update = useCallback((value) => {
        dispatch(setRetailInvestValue(value));
    }, [dispatch]);
    return [investValue, update];
};
