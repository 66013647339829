import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useRef } from "react";
import { TableHeaderCell, TableHeaderSubtitle } from "./styled";
import { Flex } from "../Grid";
import { H5 } from "../Typography";
export const TableHeader = ({ columns, cellHeight, cellGap, onRender, }) => {
    const headerRef = useRef(null);
    useLayoutEffect(() => {
        onRender && onRender(headerRef.current);
    }, [onRender]);
    return (_jsx(Flex, { gap: cellGap ?? "0.25rem", ref: headerRef, children: columns.map((column, columnIndex) => {
            return (_jsx(TableHeaderCell, { width: column.width, height: cellHeight, isCustomRender: column.renderHead !== undefined, align: column.align, mb: `calc(${cellGap ?? "0.25rem"} + 0.5rem)`, children: column.renderHead !== undefined
                    ? column.renderHead(column)
                    : (_jsxs(_Fragment, { children: [_jsx(H5, { children: column.title }), column.subtitle && (_jsx(TableHeaderSubtitle, { children: column.subtitle }))] })) }, `table-header-cell-${columnIndex}`));
        }) }));
};
