import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { orderBy } from "lodash";
import { Flex, FlexSpan, Modal } from "../../../ui";
import { PromBusinessListItem } from "../../PromBusinessListItem";
import { SearchInput } from "../../SearchInput";
import { MIN_SEARCH_LENGTH } from "../../../constants/common";
import { useDialogReset, useIsDesktop, usePromBusinessItems, usePromConceptItem } from "../../../hooks";
export const PromBusinessPickerDialog = () => {
    const closeDialog = useDialogReset();
    const isDesktop = useIsDesktop();
    const [businessItems] = usePromBusinessItems();
    const [, setConceptItem] = usePromConceptItem();
    const [search, setSearch] = useState("");
    const onClick = useCallback((concept) => {
        setConceptItem(concept);
        closeDialog();
    }, [closeDialog, setConceptItem]);
    return (_jsx(Modal, { height: "calc(var(--app-height) - 15rem)", title: (_jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { children: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0431\u0438\u0437\u043D\u0435\u0441" }), _jsx(FlexSpan, { mt: "1.5rem", children: _jsx(SearchInput, { autofocus: isDesktop, value: search, onChange: setSearch }) })] })), width: "50vw", maxWidth: "50rem", paddingContent: "0 0 0 1rem", content: (_jsx(Flex, { column: true, children: orderBy([...businessItems ?? []].map(item => {
                return {
                    ...item,
                    business_concepts: item.business_concepts.filter(concept => {
                        return (!search
                            || search.length < MIN_SEARCH_LENGTH
                            || concept.name.toLowerCase().includes(search.toLowerCase()));
                    }),
                };
            }).reduce((acc, curr) => [...acc, ...curr.business_concepts.map(item => ({ ...curr, business_concepts: [item] }))], []), "business_concepts[0].name", ["asc"])
                .map(businessItem => {
                return (_jsx(PromBusinessListItem, { listStyle: { padding: 0 }, businessItem: businessItem, onConceptClick: onClick, lightBackground: true, compactMode: true, showCategory: true, search: search }, `business-list-item-${businessItem.id}-${businessItem.business_concepts[0]?.id}`));
            }) })) }));
};
