import styled from "styled-components";
import { Flex, FlexSpan } from "../../ui";
import { SCREEN_WIDTH_DESKTOP } from "../../constants/layout";
export const LayoutContainer = styled(Flex) `
  flex-direction: column;
  height: var(--app-height);
  max-height: var(--app-height);
  background-color: #f9f9f9;

  [data-hide-on-mobile] {
    display: initial;
  }

  [data-show-on-mobile] {
    display: none;
  }

  @media screen and (max-width: ${SCREEN_WIDTH_DESKTOP}px) {
    [data-hide-on-mobile] {
      display: none;
    }

    [data-show-on-mobile] {
      display: initial;
    }
  }
`;
export const LayoutBodyWrapper = styled(FlexSpan) `
  display: flex;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
  max-height: 100%;
  box-sizing: border-box;
`;
export const LayoutBodyContainer = styled.div `
  width: 100%;
  height: calc(var(--app-height) - 5rem);
  max-width: 100rem;
  padding: ${({ noPadding }) => (noPadding ? 0 : "1.75rem 1.75rem 0 1.75rem")};
  box-sizing: border-box;
`;
export const LoadingContainer = styled(Flex) `
  width: 100%;
  height: calc(100% - 1.75rem);
  align-items: center;
  justify-content: center;
`;
export const LeftPanel = styled.div `
  height: 100%;
  box-sizing: border-box;
`;
export const LayoutSidebar = styled(FlexSpan) `
  z-index: 1;
  margin-right: 1.5rem;
  width: 22.5rem;
  height: calc(100% - 1.75rem);
  max-height: calc(100% - 1.75rem);
  box-sizing: border-box;

  @media screen and (max-width: ${SCREEN_WIDTH_DESKTOP}px) {
    height: auto;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1.25rem;
  }
`;
export const LayoutContent = styled(FlexSpan) `
  flex-grow: 1;
  height: calc(100% - 1.5rem);
  max-height: calc(100% - 1.5rem);
  width: calc(100% - 24rem);

  @media screen and (max-width: ${SCREEN_WIDTH_DESKTOP}px) {
    width: 100%;
    position: absolute;
    height: calc(var(--app-height) - 5rem);
  }
`;
