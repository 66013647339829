import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { LocationsCompareWrapper, LocationsCompareContainer, LocationAddress, LocationAdd, DetailsBlock, DetailsLabel, CompareTableCell, AreaHeader, DeleteArea, } from "./styled";
import { BackButton, EllipsisText, Flex, FlexSpan, H4, Icon, IconButton, Link, OutlineButton, PrimaryButton, Tooltip, } from "../../../ui";
import { CategoryIcon } from "../../CategoryIcon";
import { HorizontalBarChart } from "../../HorizontalBarChart";
import { DIALOGS } from "../../../constants/dialogs";
import { useCloseOnEscape, useDialog, useLocationsCompareParams, useRenderTooltip, useRetailBusinessItems, useSection, useSelfBusinessItems, } from "../../../hooks";
import { useCompareTableData } from "./hooks/useCompareTableData";
import { formatNumber } from "../../../utils";
import { getOrderedPinSvg } from "../utils";
import { generateExcel } from "./utils";
import { AppSection } from "../../../slices/app/types";
// eslint-disable-next-line max-lines-per-function
export const LocationsCompare = ({ businessLocations, onClose, onChangeAddingLocation, onGoToLocation, onDeleteLocation, }) => {
    const openDialog = useDialog();
    const [section] = useSection();
    const [retailBusinessItems] = useRetailBusinessItems();
    const [selfBusinessItems] = useSelfBusinessItems();
    const businessItems = useMemo(() => {
        return section === AppSection.Retail ? retailBusinessItems : selfBusinessItems;
    }, [section, retailBusinessItems, selfBusinessItems]);
    const { businessId, conceptId, cityId } = useParams();
    const businessConcept = useMemo(() => {
        return businessItems?.find(item => item.id === Number(businessId))?.business_concepts?.find(concept => concept.id === Number(conceptId));
    }, [businessId, businessItems, conceptId]);
    const businessIcon = useMemo(() => businessItems?.find(item => item.id === Number(businessId))?.ico, [businessId, businessItems]);
    const businessFormats = useMemo(() => businessConcept?.business_formats, [businessConcept]);
    const [locationsCompareParams] = useLocationsCompareParams();
    const [hovered, setHovered] = useState();
    const compareTableData = useCompareTableData(businessLocations);
    const renderTooltip = useRenderTooltip();
    const checkDataHeader = useCallback(rowIndex => {
        return compareTableData[rowIndex]?.filter(Boolean).length === 1;
    }, [compareTableData]);
    useCloseOnEscape(onClose);
    return (_jsx(LocationsCompareWrapper, { children: _jsx(LocationsCompareContainer, { children: _jsxs(Flex, { height: "100%", overflowY: "auto", borderRadius: "0.5rem", children: [_jsx(FlexSpan, { width: "20.5rem", minWidth: "20.5rem", height: "100%", position: "sticky", left: 0, backgroundColor: "#ffffff", zIndex: 2, children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { width: "100%", height: "15.75rem", position: "sticky", top: 0, borderRight: "1px solid #D9D9D9", borderBottom: "1px solid #D9D9D9", backgroundColor: "#ffffff", padding: "1rem", zIndex: 2, children: _jsxs(Flex, { column: true, height: "100%", children: [_jsx(FlexSpan, { flexGrow: 1, width: "100%", children: _jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { flexGrow: 1, children: _jsx(BackButton, { label: "\u041D\u0430\u0437\u0430\u0434", onClick: onClose }) }), _jsx(FlexSpan, { children: _jsx(Tooltip, { content: "\u0421\u043A\u0430\u0447\u0430\u0442\u044C \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442", children: _jsx(IconButton, { icon: "download", size: 20, onClick: () => {
                                                                        generateExcel(`Отчет о сравнении точек_${new Date().toLocaleDateString()} ${new Date()
                                                                            .toLocaleTimeString()
                                                                            .replace(/:/, ".")
                                                                            .replace(/:/, ".")
                                                                            .slice(0, -3)}`, businessConcept, businessLocations, locationsCompareParams);
                                                                    } }) }) })] }) }), _jsx(FlexSpan, { mb: "0.75rem", children: _jsxs(Flex, { alignItems: "flex-start", children: [_jsx(FlexSpan, { mr: "0.75rem", children: _jsx(CategoryIcon, { path: businessIcon, size: "2rem" }) }), _jsx(FlexSpan, { flexGrow: 1, children: _jsx(H4, { lineHeight: "150%", children: _jsx(EllipsisText, { lines: 2, children: businessConcept.name }) }) })] }) }), _jsx(FlexSpan, { children: _jsx(OutlineButton, { buttonSize: "xs", width: "100%", onClick: () => openDialog(DIALOGS.LOCATIONS_COMPARE_PARAMS_DIALOG), children: "\u041F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u044B \u0441\u0440\u0430\u0432\u043D\u0435\u043D\u0438\u044F" }) })] }) }), _jsx(FlexSpan, { width: "100%", borderRight: "1px solid #D9D9D9", children: _jsx(Flex, { column: true, children: compareTableData.map((row, rowIndex) => {
                                            return (_jsx(CompareTableCell, { onMouseOver: () => setHovered(checkDataHeader(rowIndex) ? undefined : rowIndex), onMouseLeave: () => setHovered(undefined), hovered: hovered === rowIndex, isHeader: checkDataHeader(rowIndex), isFirstCol: true, children: checkDataHeader(rowIndex) ? (_jsx(_Fragment, { children: row[0] })) : (_jsx(EllipsisText, { lines: 1, children: row[0] })) }, `compare-table-row-${rowIndex}-col-0`));
                                        }) }) })] }) }), businessLocations.map((location, locationIndex) => {
                        const businessPlanAvailable = location.details && location.details.statistic.businessplans.length > 0;
                        const currentFormat = businessFormats?.find(format => format.id === location.formatId);
                        return (_jsxs(FlexSpan, { width: "calc((100% - 20.5rem) / 5)", minWidth: "12.5rem", height: "fit-content", zIndex: 1, position: "relative", children: [_jsx(Flex, { width: "100%", height: "15.75rem", position: "sticky", top: 0, borderRight: locationIndex < 5 ? "1px solid #D9D9D9" : "none", borderBottom: "1px solid #D9D9D9", backgroundColor: "#ffffff", padding: "1rem", children: _jsxs(Flex, { column: true, children: [_jsx(AreaHeader, { noDelete: businessLocations.length <= 1, children: _jsxs(Flex, { alignItems: "flex-start", children: [_jsx(FlexSpan, { display: "flex", mr: "0.75rem", children: _jsx("img", { src: getOrderedPinSvg(locationIndex + 1, true), width: "36px", alt: "" }) }), _jsx(FlexSpan, { children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { fontWeight: 600, children: _jsx(Tooltip, { content: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u0442\u043E\u0447\u043A\u0435 \u043D\u0430 \u043A\u0430\u0440\u0442\u0435", children: _jsxs(Link, { idleColor: "#000000", onClick: () => {
                                                                                    onGoToLocation && onGoToLocation(locationIndex);
                                                                                    onClose?.();
                                                                                }, children: ["\u0422\u043E\u0447\u043A\u0430 ", locationIndex + 1] }) }) }), _jsx(FlexSpan, { fontSize: "0.75rem", fontWeight: 400, children: _jsx(Tooltip, { content: (_jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { children: "\u0426\u0435\u043D\u043E\u0432\u0430\u044F \u043D\u0438\u0448\u0430" }), _jsxs(FlexSpan, { mt: "0.25rem", color: "#9F9F9F", fontSize: "0.625rem", children: ["\u0423\u0440\u043E\u0432\u0435\u043D\u044C \u0446\u0435\u043D \u043D\u0430 \u0442\u043E\u0432\u0430\u0440\u044B \u0438 \u0443\u0441\u043B\u0443\u0433\u0438, \u043F\u0440\u0435\u0434\u043B\u0430\u0433\u0430\u0435\u043C\u044B\u0435", _jsx("br", {}), "\u043A\u043B\u0438\u0435\u043D\u0442\u0430\u043C \u0432 \u0442\u043E\u0440\u0433\u043E\u0432\u043E\u0439/\u0441\u0435\u0440\u0432\u0438\u0441\u043D\u043E\u0439 \u0442\u043E\u0447\u043A\u0435."] })] })), children: currentFormat.name }) }), _jsx(FlexSpan, { children: _jsx(LocationAddress, { children: _jsx(EllipsisText, { lines: 3, children: location.details.analytic.address }) }) })] }) }), _jsx(DeleteArea, { children: _jsx(Tooltip, { content: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0438\u0437 \u0441\u0440\u0430\u0432\u043D\u0435\u043D\u0438\u044F", children: _jsx(IconButton, { icon: "trash", onClick: () => onDeleteLocation && onDeleteLocation(location.id) }) }) })] }) }), _jsx(FlexSpan, { mb: "0.75rem", children: _jsx(DetailsBlock, { children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { width: "100%", mb: "0.5rem", children: _jsx(HorizontalBarChart, { withoutXScale: true, withTooltip: true, stackedTooltip: true, renderTooltip: bars => {
                                                                        return renderTooltip([
                                                                            {
                                                                                ...bars[0],
                                                                                groupName: (_jsxs(FlexSpan, { opacity: 0.54, children: ["\u0427\u0430\u0441\u0442\u044C \u043F\u043B\u0430\u0442\u0435\u0436\u0435\u0441\u043F\u043E\u0441\u043E\u0431\u043D\u043E\u0433\u043E \u0441\u043F\u0440\u043E\u0441\u0430", _jsx("br", {}), "\u043F\u043E\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043B\u0435\u0439, \u043A\u043E\u0442\u043E\u0440\u0430\u044F \u043C\u043E\u0436\u0435\u0442 \u0431\u044B\u0442\u044C", _jsx("br", {}), "\u0440\u0435\u0430\u043B\u0438\u0437\u043E\u0432\u0430\u043D\u0430 \u043D\u043E\u0432\u043E\u0439 \u0442\u043E\u0440\u0433\u043E\u0432\u043E\u0439/", _jsx("br", {}), "\u0441\u0435\u0440\u0432\u0438\u0441\u043D\u043E\u0439 \u0442\u043E\u0447\u043A\u043E\u0439 \u0441 \u0443\u0447\u0435\u0442\u043E\u043C", _jsx("br", {}), "\u043A\u043E\u043D\u043A\u0443\u0440\u0435\u043D\u0442\u043D\u043E\u0433\u043E \u043E\u043A\u0440\u0443\u0436\u0435\u043D\u0438\u044F."] })),
                                                                            },
                                                                            bars[1],
                                                                        ], {
                                                                            excludedBars: [1],
                                                                            valueStyle: { fontSize: "0.875rem" },
                                                                        });
                                                                    }, data: [
                                                                        [
                                                                            {
                                                                                name: "Нереализованный спрос",
                                                                                value: +location.details?.statistic?.supply.values[1]?.value.toFixed(2),
                                                                                color: "#FFD633",
                                                                            },
                                                                            {
                                                                                name: "Реализованный спрос",
                                                                                value: +location.details?.statistic?.supply.values[0]?.value.toFixed(2),
                                                                                color: "#E6E6E6",
                                                                            },
                                                                        ],
                                                                    ] }) }), _jsx(FlexSpan, { children: _jsx(Tooltip, { content: "\u0420\u0430\u0437\u043C\u0435\u0440 \u0440\u044B\u043D\u043E\u0447\u043D\u043E\u0439 \u043D\u0438\u0448\u0438 (\u043D\u0435\u0440\u0435\u0430\u043B\u0438\u0437\u043E\u0432\u0430\u043D\u043D\u044B\u0439 \u0441\u043F\u0440\u043E\u0441, \u0442\u044B\u0441. \u0440\u0443\u0431./\u0433\u043E\u0434)", children: _jsxs(Flex, { column: true, gap: "0.25rem", children: [_jsx(FlexSpan, { children: _jsxs(Flex, { alignItems: "baseline", children: [_jsx(FlexSpan, { children: _jsx(H4, { children: formatNumber(Math.round(location.details?.statistic?.supply.values[1]?.value)) }) }), _jsxs(FlexSpan, { ml: "0.25rem", children: ["\u0438\u0437 ", formatNumber(Math.round(location.details?.statistic?.demand.values[0]?.value))] })] }) }), _jsx(FlexSpan, { children: _jsx(DetailsLabel, { children: "\u0442\u044B\u0441. \u0440\u0443\u0431./\u0433\u043E\u0434" }) })] }) }) })] }) }) }), _jsx(FlexSpan, { children: _jsx(PrimaryButton, { width: "100%", buttonSize: "xs", style: { paddingLeft: 0, paddingRight: 0 }, onClick: () => {
                                                        openDialog(DIALOGS.BEFORE_BUSINESS_PLAN_DIALOG, {
                                                            section,
                                                            cityId,
                                                            businessId,
                                                            conceptId,
                                                            formatId: location.formatId,
                                                            point: location.point,
                                                            size: location.size,
                                                            excludedCompetitors: location.excludedCompetitors,
                                                            newBuildings: JSON.parse(location.newBuildings),
                                                            zone: location.zone,
                                                            franchise: location.franchise,
                                                        });
                                                    }, disabled: !businessPlanAvailable, disabledTooltip: !businessPlanAvailable ? (section === AppSection.Retail
                                                        ? "Срок окупаемости вложений в бизнес по выбранному типовому формату" +
                                                            " превышает 5 лет в связи с высокой стоимостью аренды помещения," +
                                                            " большим фондом оплаты труда и значительным объёмом инвестиций." +
                                                            " Рекомендуем выбрать другой типовой формат, другой вид бизнеса или другое местоположение."
                                                        : "Размер доступной рыночной ниши в выбранной локации недостаточен для открытия бизнеса" +
                                                            " в соответствии с выбранным типовым форматом. Рекомендуем выбрать другое местоположение," +
                                                            " другой типовой формат (при наличии) или другой вид бизнеса.") : undefined, children: "\u0420\u0430\u0441\u0447\u0435\u0442 \u0431\u0438\u0437\u043D\u0435\u0441-\u043F\u043B\u0430\u043D\u0430" }) })] }) }), _jsx(Flex, { column: true, borderRight: locationIndex < 5 ? "1px solid #D9D9D9" : "none", children: _jsx(Flex, { column: true, children: compareTableData.map((row, rowIndex) => {
                                            return (_jsx(CompareTableCell, { onMouseOver: () => setHovered(checkDataHeader(rowIndex) ? undefined : rowIndex), onMouseLeave: () => setHovered(undefined), hovered: hovered === rowIndex, textAlign: "center", isHeader: checkDataHeader(rowIndex), children: row[locationIndex + 1] }, `compare-table-row-${rowIndex}-col-${locationIndex + 1}`));
                                        }) }) })] }, `location-${locationIndex}`));
                    }), Array(5 - businessLocations.length).fill(null).map((_, placeholderIndex) => {
                        return (_jsxs(FlexSpan, { width: "calc((100% - 20.5rem) / 5)", minWidth: "12.5rem", height: "fit-content", zIndex: 1, position: "relative", children: [_jsx(Flex, { width: "100%", height: "15.75rem", position: "sticky", top: 0, borderRight: placeholderIndex < 5 - businessLocations.length - 1 ? "1px solid #D9D9D9" : "none", borderBottom: "1px solid #D9D9D9", backgroundColor: "#ffffff", padding: "1rem", children: _jsxs(LocationAdd, { onClick: () => {
                                            onChangeAddingLocation && onChangeAddingLocation(true);
                                            onClose?.();
                                        }, children: [_jsx(FlexSpan, { children: _jsx(Icon, { icon: "point-add", size: 30 }) }), _jsx(FlexSpan, { fontWeight: 600, children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0442\u043E\u0447\u043A\u0443" })] }) }), _jsx(Flex, { column: true, borderRight: placeholderIndex < 5 - businessLocations.length - 1 ? "1px solid #D9D9D9" : "none", children: _jsx(Flex, { column: true, children: compareTableData.map((row, rowIndex) => {
                                            return (_jsx(CompareTableCell, { isHeader: checkDataHeader(rowIndex) }, `compare-table-row-${rowIndex}-col-${businessLocations.length + placeholderIndex + 1}`));
                                        }) }) })] }, `location-placeholder-${placeholderIndex}`));
                    })] }) }) }));
};
