import { combineReducers } from "redux";
import app from "./app";
import auth from "./auth";
import dialog from "./dialog";
import dict from "./dict";
import filters from "./filters";
import map from "./map";
export const combinedReducer = combineReducers({
    app,
    auth,
    dialog,
    dict,
    filters,
    map,
});
