import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import { barChartClassNames } from "@evergis/charts";
import { StyledPromAnalyticBarChart } from "./styled";
import { useAnimationBars } from "./hooks/useAnimationBars";
export const PromAnalyticBarChart = props => {
    const drawBars = useAnimationBars();
    const customize = useCallback(({ svg, yScale }) => {
        svg.select(`.${barChartClassNames.barChartXAxis}`).attr("transform", `translate(0, ${yScale(0) - 4})`);
        svg.select(`.${barChartClassNames.barChartAreasGlobal}`).lower();
    }, []);
    const customXScale = useCallback(xScale => {
        xScale.paddingInner(0.45);
    }, []);
    const marshalledMap = useCallback(marshalled => {
        return marshalled.map((group) => {
            return group.map(item => {
                return {
                    ...item,
                    y: item.value < 0 ? item.y + 20 : item.y,
                    tlr: 3,
                    trr: 3,
                    brr: 3,
                    blr: 3,
                };
            });
        });
    }, []);
    return (_jsx(StyledPromAnalyticBarChart, { yAxisPadding: 0, sectionPadding: 0, minValuesLine: 0, drawBars: drawBars, customize: customize, customXScale: customXScale, marshalledMap: marshalledMap, margin: {
            top: 0,
            right: 0,
            bottom: 20,
            left: 0,
        }, ...props }));
};
