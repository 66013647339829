import { jsx as _jsx } from "react/jsx-runtime";
import { webMercator, wgs84 } from "@evergis/sgis/es/Crs";
import { LabelSymbol } from "@evergis/react";
import { LabelFeature } from "@evergis/sgis/es/features/Label";
import { StaticImageSymbol } from "@evergis/sgis/es/symbols/point/StaticImageSymbol";
import { CurrentFeatureCard } from "./CurrentFeatureCard";
import { ORDERED_PINS } from "../../constants/map";
import { getMarkerSymbol } from "../../utils";
export const getCurrentFeatureCard = (feature, onClose, isCompetitor) => {
    return new LabelFeature(feature.geometry.coordinates, {
        crs: wgs84,
        symbol: new LabelSymbol({
            component: () => _jsx(CurrentFeatureCard, { feature: feature, onClose: onClose, isCompetitor: isCompetitor }),
            offset: [-180, -400 - (feature.size?.[1] ?? 0) - 2],
        }),
    }).projectTo(wgs84);
};
export const getOrderedPinSvg = (id, isSelected) => {
    const pin = ORDERED_PINS.find(item => item.id === id);
    if (pin === undefined) {
        return;
    }
    const encodedSvg = btoa(`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 36 36" style="enable-background:new 0 0 36 36;" xml:space="preserve">
    <style type="text/css">
      .st0 { fill-rule: evenodd; clip-rule: evenodd; fill: ${isSelected ? "#7E5DCB" : "#666666"}; }
      .st1 { fill: #FFFFFF; }
    </style>
    ${pin.content}
    </svg>`);
    return `data:image/svg+xml;base64,${encodedSvg}`;
};
export const getOrderedPinSymbol = (id, isSelected) => {
    const source = getOrderedPinSvg(id, isSelected);
    if (source === undefined) {
        return getMarkerSymbol();
    }
    return new StaticImageSymbol({
        width: 36,
        height: 36,
        anchorPoint: [18, 36],
        source,
    });
};
export const getOrderedPinFeature = (position, id, onClick) => {
    const source = getOrderedPinSvg(id, false);
    if (source === undefined) {
        return;
    }
    return new LabelFeature(position, {
        crs: webMercator,
        symbol: new LabelSymbol({
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
            component: () => _jsx("img", { src: source, width: "36px", height: "36px", alt: "", onClick: onClick }),
            offset: [-18, -36],
        }),
    });
};
