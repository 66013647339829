import styled from "styled-components";
import { Icon } from "../../ui/Icon/styled";
export const InputPrefix = styled.span `
  display: flex;
    
  & > button > ${Icon} {
      color: #9F9F9F;
  }
`;
export const InputSuffix = styled.span `
  display: flex;
  
  * {
    color: #7E6CC1; 
  }
`;
