import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useMemo } from "react";
import { isNil } from "lodash";
import { EllipsisText, Flex, FlexSpan, Tooltip } from "../../ui";
import { GanttGroupItemTitle, GanttMonthBar, GanttMonthLabel, GanttMonthsTitle } from "./styled";
export const GanttChart = ({ groups = [], hideGroupNames, monthsRender }) => {
    const monthsArray = useMemo(() => groups.reduce((acc, curr) => {
        return [
            ...acc,
            ...curr.items.reduce((itemAcc, itemCurr) => {
                return [
                    ...itemAcc,
                    ...itemCurr.months.reduce((monthAcc, monthCurr) => !acc.includes(monthCurr) ? [...monthAcc, monthCurr] : monthAcc, []),
                ];
            }, []),
        ];
    }, []).sort(), [groups]);
    const maxMonth = Math.max(...monthsArray);
    const ganttMonths = Array(maxMonth).fill(0).map((_, index) => index + 1);
    return (_jsxs(Flex, { children: [_jsx(FlexSpan, { width: "30%", minWidth: "17rem", pr: "1rem", children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { height: "2rem", children: _jsx(GanttMonthsTitle, { children: "\u041C\u0435\u0441\u044F\u0446\u044B \u043F\u0440\u043E\u0435\u043A\u0442\u0430" }) }), groups.map((group, groupIndex) => {
                            return (_jsxs(Fragment, { children: [!hideGroupNames && group.name !== undefined && (_jsx(FlexSpan, { height: "2rem" })), group.items.map((groupItem, groupItemIndex) => {
                                        return (_jsx(FlexSpan, { height: "2rem", mb: groupItemIndex < group.items.length - 1 && "1rem", display: "flex", alignItems: "center", justifyContent: "right", children: _jsx(Tooltip, { content: groupItem.name, delay: [1000, 0], children: _jsx(GanttGroupItemTitle, { children: _jsx(EllipsisText, { lines: 2, children: groupItem.name }) }) }) }, `gantt-chart-group-${groupIndex}-item-${groupItemIndex}`));
                                    })] }, `gantt-chart-group-${groupIndex}`));
                        })] }) }), _jsx(FlexSpan, { flexGrow: 1, overflowX: "auto", children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { height: "2rem", children: _jsx(Flex, { alignItems: "center", children: ganttMonths.map((month => (_jsx(FlexSpan, { width: `calc(100% / ${ganttMonths.length ?? 1})`, minWidth: "3rem", children: monthsRender !== undefined && !isNil(monthsRender(month))
                                        ? monthsRender(month)
                                        : _jsx(GanttMonthLabel, { children: month }) }, `gantt-chart-header-month-${month}`)))) }) }), groups.map((group, groupIndex) => {
                            return (_jsxs(Fragment, { children: [!hideGroupNames && group.name !== undefined && (_jsx(FlexSpan, { height: "2rem", children: group.name })), group.items.map((groupItem, groupItemIndex) => {
                                        return (_jsx(FlexSpan, { height: "2rem", mb: groupItemIndex < group.items.length - 1 && "1rem", display: "flex", alignItems: "center", children: _jsx(Flex, { children: ganttMonths.map((ganttMonth => (_jsx(FlexSpan, { width: `calc(100% / ${ganttMonths.length ?? 1})`, minWidth: "3rem", children: _jsx(GanttMonthBar, { barColor: groupItem.barColor, active: groupItem.months.includes(ganttMonth), hasLeftNeighbor: groupItem.months.includes(ganttMonth - 1), hasRightNeighbor: groupItem.months.includes(ganttMonth + 1) }) }, `gantt-chart-header-month-${ganttMonth}-bar`)))) }) }, `gantt-chart-group-${groupIndex}-item-${groupItemIndex}-months`));
                                    })] }, `gantt-chart-group-${groupIndex}-months`));
                        })] }) })] }));
};
