import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, FlexSpan, Tooltip } from "../../ui";
import { PointDetailsList } from "../../components/PointDetailsList";
import { PointDetailsBlock } from "../../components/PointDetailsBlock";
import { AREA_COMPARE_PARAM_RENDERS, INIT_AREA_COMPARE_PARAMS } from "../../constants/filters";
const AREA_COMPARE_PARAM_ICONS = {
    "Жители": "people",
    "Офисные служащие": "suitcase",
    "Приезжие": "transport",
    "Туристы": "tap",
};
export const AreaDescription = ({ details }) => {
    return (_jsx(Flex, { column: true, height: "100%", children: _jsx(FlexSpan, { flexGrow: 1, m: "1.25rem 0", overflow: "auto", p: "0 4px", children: Object.entries(INIT_AREA_COMPARE_PARAMS).map(([key, value], groupIndex) => {
                return (_jsx(PointDetailsBlock, { title: key, icon: AREA_COMPARE_PARAM_ICONS[key], children: _jsx(PointDetailsList, { items: value.map(param => {
                            const nameSplit = param.name.split(", ");
                            return {
                                label: nameSplit.length === 1 ? param.name : (_jsx(Tooltip, { content: param.description, children: _jsxs(FlexSpan, { children: [nameSplit.slice(0, -1).join(", "), nameSplit.length > 1 ? (_jsxs(_Fragment, { children: [", ", _jsx(FlexSpan, { style: { whiteSpace: "nowrap" }, children: nameSplit[nameSplit.length - 1] })] })) : null] }) })),
                                value: AREA_COMPARE_PARAM_RENDERS[param.key]?.(details),
                            };
                        }) }) }, `params-group-${groupIndex}`));
            }) }) }));
};
