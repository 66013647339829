import styled, { css } from "styled-components";
import { space, textAlign, fontStyle, fontWeight, fontSize, lineHeight } from "styled-system";
import selectionJson from "../../assets/selection.json";
const getIconSvg = (iconName) => {
    return (`<svg viewBox="0 0 1024 1024" style="display: inline-block; width: 16px; height: 16px;" xmlns="http://www.w3.org/2000/svg">
      ${selectionJson.icons.find(icon => icon.properties.name === iconName)?.icon.paths.map(path => `<path d="${path}" fill="#7E6CC1" />`)}
    </svg>`);
};
const headerMixin = css `
  margin: 0;
  padding: 0;
  ${space}
  ${textAlign}
  ${fontStyle}
  ${fontWeight}
  ${fontSize}
  ${lineHeight}
`;
export const H1 = styled.h1 `
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  ${headerMixin}
`;
export const H2 = styled.h2 `
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  ${headerMixin}
`;
export const H3 = styled.h3 `
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  ${headerMixin}
`;
export const H4 = styled.h4 `
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  ${headerMixin}
`;
export const H5 = styled.h5 `
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  ${headerMixin}
`;
export const H6 = styled.h6 `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  ${headerMixin}
`;
export const List = styled.ul `
  margin: 0 0 0 0.5rem;
  padding-left: 1rem;
  
  & > li {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    list-style-image: ${({ icon }) => icon
    ? `url(data:image/svg+xml,${encodeURIComponent(getIconSvg(icon))})`
    : `url(data:image/svg+xml,${encodeURIComponent("<svg width=\"10\" height=\"6\" viewBox=\"0 0 10 6\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">" +
        "<rect width=\"4\" height=\"4\" fill=\"#9D87F1\"/><rect width=\"4\" height=\"4\" fill=\"none\"/>" +
        "</svg>")})`};
    
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    
    &::marker {
      font-size: 2rem;
      color: #7E6CC1;
      line-height: 0.1;
    }
  }
`;
export const EllipsisText = styled.span `
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines = 1 }) => lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const NoWrapText = styled.span `
  white-space: nowrap;
`;
