import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useEffect, useRef, useState, useCallback, } from "react";
import debounce from "lodash/debounce";
import { AutocompleteContainer, AutocompleteMenu, AutocompleteMenuItem, InputContainer, InputErrorMessage, InputField, InputLabel, InputMarksContainer, InputPrefix, InputSliderContainer, InputSuffix, InputWrapperContainer, StyledCleave, SuffixContainer, } from "./styled";
import { Flex, FlexSpan } from "../Grid";
import { Slider } from "../Slider";
import { IconButton } from "../IconButton";
const InputWrapper = ({ prefix, suffix, inputRenderer, disabled, noDisabledBackground, error, errorMessage, className, hideArrows, style, height, width, isFocused, label, labelExtra, labelPosition = "top", inputSiblings, marks, }) => (_jsxs(InputWrapperContainer, { width: width, children: [_jsxs(Flex, { alignItems: labelPosition !== "top" ? "center" : undefined, flexDirection: labelPosition === "top"
                ? "column"
                : labelPosition === "right"
                    ? "row-reverse"
                    : "row", children: [label && (_jsx(FlexSpan, { onClick: e => e.stopPropagation(), mr: labelPosition === "left" ? "1rem" : undefined, ml: labelPosition === "right" ? "1rem" : undefined, textAlign: labelPosition === "left" ? "right" : undefined, children: _jsxs(InputLabel, { children: [_jsx(FlexSpan, { children: label }), labelExtra && (_jsx(FlexSpan, { display: "flex", ml: "0.5rem", children: labelExtra }))] }) })), _jsxs(FlexSpan, { children: [_jsxs(InputContainer, { disabled: disabled, noDisabledBackground: noDisabledBackground, className: className, hideArrows: hideArrows, style: style, width: width, height: height, error: error, isFocused: isFocused, children: [prefix && _jsx(InputPrefix, { children: prefix }), inputRenderer, suffix && _jsx(InputSuffix, { children: suffix })] }), inputSiblings] })] }), marks?.filter(item => item !== undefined).length > 0 && (_jsx(InputMarksContainer, { children: marks?.filter(item => item !== undefined).map((mark, markIndex) => (_jsx(FlexSpan, { children: mark }, `mark-${markIndex}`))) })), errorMessage && _jsx(InputErrorMessage, { onClick: e => e.stopPropagation(), children: errorMessage })] }));
const InputRenderer = props => {
    const inputRef = useRef(null);
    const numberInputRef = useRef(null);
    const { name, value, defaultValue, placeholder, onEnter, onChange, onKeyDown, onFocusChange, onFocus, onBlur, type, disabled, autofocus, min, max, disableInputOnly, error, inputStyle, round, } = props;
    const [ownValue, setOwnValue] = useState(value || defaultValue || "");
    const [focused, setFocused] = useState(false);
    useEffect(() => {
        defaultValue && setOwnValue(defaultValue);
    }, [defaultValue]);
    useEffect(() => {
        onFocusChange && onFocusChange(focused);
    }, [focused, onFocusChange]);
    useEffect(() => {
        if (inputRef.current && autofocus) {
            inputRef.current.focus();
        }
    }, [inputRef, autofocus]);
    if (type === "number") {
        return (_jsx(StyledCleave, { ref: numberInputRef, options: {
                numeral: true,
                delimiter: " ",
                numeralDecimalMark: ".",
            }, name: name, value: value !== undefined ? value : ownValue, placeholder: placeholder, style: inputStyle, min: min, max: max, error: error, disabled: disabled, onFocus: () => {
                setFocused(true);
                onFocus && onFocus();
            }, onBlur: () => {
                setFocused(false);
                if (min !== undefined && (ownValue === "" || +ownValue < min)) {
                    setOwnValue(min.toString());
                    onChange && onChange(min);
                }
                else if (max !== undefined && ownValue !== undefined && +ownValue > max) {
                    setOwnValue(max.toString());
                    onChange && onChange(max);
                }
                else if (round && ownValue !== undefined && ownValue !== "") {
                    setOwnValue(Math.round(+ownValue).toString());
                    onChange && onChange(Math.round(+ownValue));
                }
                onBlur && onBlur();
            }, onKeyDown: (e) => {
                if (disableInputOnly) {
                    return;
                }
                if (e.key === "Enter" && typeof onEnter === "function") {
                    e.preventDefault();
                    return onEnter();
                }
                onKeyDown && onKeyDown(e);
            }, onChange: (e) => {
                if (disableInputOnly) {
                    return;
                }
                const currentValue = e.target.rawValue;
                setOwnValue(currentValue);
                onChange && onChange(currentValue !== "" ? parseFloat(currentValue) : "");
            } }));
    }
    return (_jsx(InputField, { ref: inputRef, type: type, name: name, value: value !== undefined ? value : ownValue, onFocus: () => {
            setFocused(true);
            onFocus && onFocus();
        }, onBlur: () => {
            setFocused(false);
            onBlur && onBlur();
        }, placeholder: placeholder, min: min, max: max, style: inputStyle, error: error, disabled: disabled, onKeyDown: (e) => {
            if (disableInputOnly) {
                return;
            }
            if (e.key === "Enter" && typeof onEnter === "function") {
                e.preventDefault();
                return onEnter();
            }
            onKeyDown && onKeyDown(e);
        }, onChange: (e) => {
            if (disableInputOnly) {
                return;
            }
            const currentValue = e.target.value;
            setOwnValue(currentValue);
            onChange && onChange(currentValue);
        } }));
};
const TextInput = props => {
    const [focused, setFocused] = useState(false);
    return (_jsx(InputWrapper, { inputRenderer: _jsx(InputRenderer, { type: "text", ...props, onFocusChange: setFocused }), isFocused: focused, ...props }));
};
const NumberInput = props => {
    const [focused, setFocused] = useState(false);
    return (_jsx(InputWrapper, { inputRenderer: _jsx(InputRenderer, { type: "number", ...props, onFocusChange: setFocused }), isFocused: focused, ...props }));
};
const PasswordInput = props => {
    const [focused, setFocused] = useState(false);
    return (_jsx(InputWrapper, { inputRenderer: _jsx(InputRenderer, { type: "password", ...props, onFocusChange: setFocused }), isFocused: focused, ...props }));
};
const AutocompleteInput = ({ options, defaultValue, menuPosition, menuStyle, onClear, onPick, onChange, value, darkTheme, suffix, inputStyle, disabled, ...rest }) => {
    const [isMenuOpened, setMenuOpened] = useState(false);
    const [ownValue, setOwnValue] = useState();
    useEffect(() => {
        setMenuOpened(Array.isArray(options) && options.length > 0);
    }, [options]);
    useEffect(() => {
        defaultValue && setOwnValue(defaultValue);
    }, [defaultValue]);
    useEffect(() => {
        setOwnValue(value);
    }, [value]);
    return (_jsxs(AutocompleteContainer, { darkTheme: darkTheme, children: [_jsx(TextInput, { ...rest, style: inputStyle, defaultValue: defaultValue, disabled: disabled, value: ownValue, onChange: (inputValue) => {
                    setOwnValue(undefined);
                    onChange && onChange(inputValue);
                }, suffix: _jsxs(SuffixContainer, { children: [ownValue && (_jsx(IconButton, { icon: "close", title: "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C", disabled: disabled, onClick: () => {
                                setOwnValue("");
                                setMenuOpened(false);
                                typeof onClear === "function" && onClear();
                            } })), suffix] }) }), isMenuOpened && (_jsx(AutocompleteMenu, { position: menuPosition, style: menuStyle, darkTheme: darkTheme, children: options.map((item, index) => {
                    return (_jsx(AutocompleteMenuItem, { title: item.label, darkTheme: darkTheme, onClick: () => {
                            setMenuOpened(false);
                            setOwnValue(item.text);
                            typeof onPick === "function" && onPick(item);
                        }, children: item.label }, `autocomplete-option-${index}`));
                }) }))] }));
};
const InputSlider = ({ min, max, defaultValue, step, noMarks, disabled, disableInput, markFormatFunc, onChange, delay, value: inputValue, ...props }) => {
    const [value, setValue] = useState(defaultValue);
    const lazySyncValues = useCallback(debounce((outerValue) => {
        onChange && onChange(outerValue);
    }, delay ?? 60), [onChange, delay]);
    useEffect(() => {
        setValue(+inputValue);
    }, [inputValue]);
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]); // eslint-disable-line
    return (_jsx(InputSliderContainer, { children: _jsx(NumberInput, { disabled: disabled || disableInput, defaultValue: defaultValue?.toString(), value: value?.toString(), min: min, max: max, onChange: (e) => {
                if (e === undefined) {
                    setValue(min);
                    onChange && onChange(min);
                }
                else {
                    setValue(e);
                    onChange && onChange(e);
                }
            }, hideArrows: true, inputSiblings: (_jsx(Slider, { min: min, max: max, step: step, disabled: disabled, noMarks: noMarks, value: value, onChange: (e) => {
                    setValue(e);
                    lazySyncValues(e);
                }, style: { marginTop: "-8px" }, noRail: true, markFormatFunc: markFormatFunc })), ...props }) }));
};
export { TextInput, NumberInput, PasswordInput, AutocompleteInput, InputSlider };
