import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPromBusinessSearch } from "../../../selectors/filters";
import { setPromBusinessSearch } from "../../../slices/filters";
export const usePromBusinessSearch = () => {
    const dispatch = useDispatch();
    const search = useSelector(getPromBusinessSearch);
    const update = useCallback((value) => {
        dispatch(setPromBusinessSearch(value));
    }, [dispatch]);
    return [search, update];
};
