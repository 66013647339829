import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSection } from "../../selectors/app";
import { setSection } from "../../slices/app";
export const useSection = () => {
    const dispatch = useDispatch();
    const section = useSelector(getSection);
    const update = useCallback((value) => {
        dispatch(setSection(value));
    }, [dispatch]);
    return [section, update];
};
