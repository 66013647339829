import { getPath } from "../utils";
export const MainPageUrl = getPath("/");
export const RetailBusinessUrl = getPath("/retail");
export const RetailLocationUrl = getPath("/retail/location/:cityId/:businessId/:conceptId");
export const RetailBusinessPlanUrl = getPath("/retail/business-plan/:cityId/:businessId/:conceptId");
export const RetailIntegralLocationUrl = getPath("/retail-integral");
export const SelfEmployedBusinessUrl = getPath("/self_employed");
export const SelfEmployedLocationUrl = getPath("/self_employed/location/:cityId/:businessId/:conceptId");
export const SelfEmployedBusinessPlanUrl = getPath("/self_employed/business-plan/:cityId/:businessId/:conceptId");
export const EnterpriseBusinessUrl = getPath("/enterprise");
export const EnterpriseLocationUrl = getPath("/enterprise/location/:subjectId/:businessId/:conceptId");
export const EnterpriseBusinessPlanUrl = getPath("/enterprise/business-plan/:subjectId/:businessId/:conceptId");
export const EnterpriseIntegralLocationUrl = getPath("/enterprise-integral");
export const AttractivenessUrl = getPath("/attractiveness");
export const HelpPageUrl = getPath("/help");
