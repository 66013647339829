import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EvergisTileLayer } from "@evergis/react";
import { useCallback, useMemo, useState } from "react";
import { Flex, FlexSpan, IconButton, Popover, RadioGroup } from "../../../../../ui";
import { BaseMapName } from "../../../../../constants/baseMaps";
import { BASE_MAPS_LIST } from "./constants";
import { useBaseMapName } from "../../../../../hooks";
export const BaseMapTool = () => {
    const [open, setOpen] = useState(false);
    const [baseMapName, setBaseMapName] = useBaseMapName();
    const onChange = useCallback((event) => {
        setBaseMapName(event.target.value);
        setOpen(false);
    }, [setBaseMapName, setOpen]);
    const currentBaseMap = useMemo(() => baseMapName === BaseMapName.DoubleGis && BASE_MAPS_LIST.find(({ name }) => name === baseMapName), [baseMapName]);
    return (_jsxs(_Fragment, { children: [_jsx(Popover, { placement: "top-right", isOpen: open, onClose: () => setOpen(false), offsetX: 36, offsetY: -24, anchor: _jsx(FlexSpan, { display: "flex", mb: "0.88rem", children: _jsx(IconButton, { icon: "layers", onClick: () => setOpen(!open), active: open }) }), children: _jsx(Flex, { p: "0.5rem", width: "15rem", children: _jsx(RadioGroup, { options: BASE_MAPS_LIST.map(({ title, name }) => ({ value: name, label: title })), column: true, value: baseMapName, onChange: onChange }) }) }), currentBaseMap && (_jsx(EvergisTileLayer, { name: currentBaseMap.name, sourceUrl: currentBaseMap.sourceUrl, zIndex: 0 }))] }));
};
