import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines, max-lines-per-function */
import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DraggableMarker, FeatureLayer, SrId, useMapContext, useMapView } from "@evergis/react";
import { GeometryType } from "@evergis/api";
import { wgs84 } from "@evergis/sgis/es/Crs";
import { isNil } from "lodash";
import { changeProps } from "find-and";
import { Layout } from "../Layout";
import { Flex, FlexSpan, Icon, OutlineButton, Pagination, PrimaryButton } from "../../ui";
import { Map } from "../Map";
import { Stepper } from "../Stepper";
import { MapContainer, PickPointContainer } from "./styled";
import { PointCard } from "../PointCard";
import { PointLocationPlaceholder } from "../PointLocationPlaceholder";
import { PointDescription } from "./PointDescription";
import { FloatLeftPanel } from "../FloatLeftPanel";
import { LocationsCompare } from "./LocationsCompare";
import { MapTooltip } from "../MapTooltip";
import { LegendControl } from "./LegendControl";
import { GeocoderControl } from "./GeocoderControl";
import { RetailBusinessUrl, RetailLocationUrl, SelfEmployedBusinessUrl, SelfEmployedLocationUrl, } from "../../constants/routes";
import { COMPETITORS_LAYER_ID } from "../../constants/common";
import { DIALOGS } from "../../constants/dialogs";
import { useCurrentCity, useCurrentFeature, useDialog, useIsDesktop, useOverlayFeature, useRetailBusinessItems, useSelfBusinessItems, useUpdatedLocationParams, useUser, } from "../../hooks";
import { getCircleBbox, getCircleFeatureWithRadius, getDynamicPointFeature, getMarkerSymbol, getPolygonFeature, getWktBboxPolygon, getWktPoint, } from "../../utils";
import { getCurrentFeatureCard, getOrderedPinSymbol, getOrderedPinFeature } from "./utils";
import { api } from "../../api";
import { AppSection } from "../../slices/app/types";
let controller = null;
let servicesController = null;
export const CommonLocationPage = ({ section }) => {
    const isDesktop = useIsDesktop();
    const navigate = useNavigate();
    const { painter } = useMapContext();
    const mapView = useMapView();
    const [searchParams, setSearchParams] = useSearchParams();
    const openDialog = useDialog();
    const [user] = useUser();
    const [currentFeature, setCurrentFeature] = useCurrentFeature();
    const [overlayFeature, setOverlayFeature] = useOverlayFeature();
    const urlPoint = useMemo(() => searchParams.get("point"), [searchParams]);
    const urlFormatId = useMemo(() => searchParams.get("formatId"), [searchParams]);
    const urlSize = useMemo(() => searchParams.get("size"), [searchParams]);
    const urlExcludedCompetitors = useMemo(() => searchParams.get("excludedCompetitors"), [searchParams]);
    const zone = useMemo(() => searchParams.get("zone"), [searchParams]);
    const franchise = useMemo(() => searchParams.get("franchise"), [searchParams]);
    const [updatedParams, setUpdatedParams] = useUpdatedLocationParams();
    const newBuildings = searchParams.get("newBuildings");
    const { businessId, conceptId, cityId } = useParams();
    const [retailBusinessItems] = useRetailBusinessItems();
    const [selfBusinessItems] = useSelfBusinessItems();
    const businessItems = useMemo(() => section === AppSection.Retail ? retailBusinessItems : selfBusinessItems, [section, retailBusinessItems, selfBusinessItems]);
    const [currentCity] = useCurrentCity();
    const [legend, setLegend] = useState(null);
    const [services, setServices] = useState();
    const pageLoading = !currentCity || !businessItems?.length;
    const [point, setPoint] = useState(!isNil(urlPoint) ? JSON.parse(urlPoint) : null);
    const [formatId, setFormatId] = useState(!isNil(urlFormatId) ? +urlFormatId : null);
    const [size, setSize] = useState(urlSize ? +urlSize : undefined);
    const [details, setDetails] = useState();
    const [excludedCompetitors, setExcludedCompetitors] = useState(urlExcludedCompetitors ? JSON.parse(urlExcludedCompetitors) : null);
    const [initCompetitorsStyles, setInitCompetitorsStyles] = useState();
    const [loading, setLoading] = useState(true);
    const businessConcept = useMemo(() => {
        return businessItems?.find(item => item.id === Number(businessId))?.business_concepts?.find(concept => concept.id === Number(conceptId));
    }, [businessId, businessItems, conceptId]);
    const businessPlanAvailable = useMemo(() => details && details.statistic.businessplans.length > 0, [details]);
    const [bufferFeature, setBufferFeature] = useState();
    const [doubleBufferFeature, setDoubleBufferFeature] = useState();
    const [zoneFeature, setZoneFeature] = useState();
    const [zoneCompFeature, setZoneCompFeature] = useState();
    const [competitorsFeatures, setCompetitorsFeatures] = useState();
    const bufferFeatures = useMemo(() => [bufferFeature, doubleBufferFeature].filter(Boolean), [bufferFeature, doubleBufferFeature]);
    const zoneFeatures = useMemo(() => [zoneFeature, zoneCompFeature].filter(Boolean), [zoneCompFeature, zoneFeature]);
    const legendFeatures = useMemo(() => legend?.find(item => item.id === COMPETITORS_LAYER_ID)?.is_visible ? (competitorsFeatures ?? []) : [], [competitorsFeatures, legend]);
    const [addingLocation, setAddingLocation] = useState(false);
    const [businessLocations, setBusinessLocations] = useState([]);
    const [currentLocationId, setCurrentLocationId] = useState(null);
    const [isCompareOpen, setIsCompareOpen] = useState(false);
    const [tempPoint, setTempPoint] = useState();
    const canCompare = useMemo(() => businessLocations.length > 1, [businessLocations]);
    const servicesFeatures = useMemo(() => {
        return services?.reduce((acc, curr) => {
            return [
                ...acc,
                ...curr.features.features.reduce((accFeatures, currFeature) => {
                    const featureStyle = details && curr.isCompetitors
                        ? details.statistic.calc_styles.find(item => item.styleId === "hide")
                        : curr.styles.find(item => item.styleId === currFeature.properties.styleId);
                    if (details?.statistic.competitors.map(item => item.id).includes(`${currFeature.id}`)) {
                        return accFeatures;
                    }
                    return [
                        ...accFeatures,
                        getDynamicPointFeature(currFeature, featureStyle?.iconSize, featureStyle?.iconAnchor, `${process.env.API_URL || ""}${featureStyle?.iconUrl}`, feature => point && setCurrentFeature({
                            ...feature,
                            size: [
                                (featureStyle?.iconSize?.[0] ?? 0) - (featureStyle?.iconAnchor?.[0] ?? 0) / 2,
                                (featureStyle?.iconSize?.[1] ?? 0) - (featureStyle?.iconAnchor?.[1] ?? 0) / 2,
                            ],
                        })),
                    ];
                }, []),
            ];
        }, []) ?? [];
    }, [details, point, services]); // eslint-disable-line
    const onGoToLocation = useCallback((itemId, locations) => {
        const nextLocation = locations?.[itemId] ?? businessLocations[itemId];
        if (!nextLocation) {
            return;
        }
        setBufferFeature(undefined);
        setDoubleBufferFeature(undefined);
        setZoneFeature(undefined);
        setZoneCompFeature(undefined);
        setCurrentLocationId(nextLocation.id);
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            point: nextLocation.point,
            formatId: nextLocation.formatId?.toString(),
            size: nextLocation.size?.toString(),
            newBuildings: nextLocation.newBuildings,
            excludedCompetitors: nextLocation.excludedCompetitors ? JSON.stringify(nextLocation.excludedCompetitors) : "",
            zone: nextLocation.zone,
            franchise: nextLocation.franchise || "",
        });
        setExcludedCompetitors(nextLocation.excludedCompetitors);
        setFormatId(!isNil(nextLocation.formatId) ? nextLocation.formatId : null);
        setSize(nextLocation.size ? +nextLocation.size : undefined);
        setPoint(JSON.parse(nextLocation.point));
    }, [businessLocations, searchParams, setSearchParams]);
    const onDeleteLocation = useCallback((locationId) => {
        const deleteLocationId = locationId ?? currentLocationId;
        setAddingLocation(false);
        if (businessLocations.length > 1) {
            const newLocations = businessLocations.filter(item => item.id !== deleteLocationId);
            setBusinessLocations(newLocations);
            onGoToLocation(newLocations.length - 1, newLocations);
        }
        else {
            setPoint(null);
            setDetails(null);
            setExcludedCompetitors(null);
            setSize(undefined);
            setSearchParams({});
            setBufferFeature(undefined);
            setDoubleBufferFeature(undefined);
            setZoneFeature(undefined);
            setZoneCompFeature(undefined);
            setCompetitorsFeatures(undefined);
            setCurrentFeature(undefined);
            setBusinessLocations([]);
            setCurrentLocationId(null);
            if (initCompetitorsStyles !== undefined) {
                setLegend(legend?.map(layer => {
                    return layer.id === COMPETITORS_LAYER_ID
                        ? { ...layer, styles: initCompetitorsStyles }
                        : { ...layer };
                }));
                setInitCompetitorsStyles(undefined);
            }
        }
    }, [
        businessLocations,
        currentLocationId,
        initCompetitorsStyles,
        legend,
        onGoToLocation,
        setAddingLocation,
        setBusinessLocations,
        setCurrentFeature,
        setLegend,
        setSearchParams,
    ]);
    const onCreateBusinessPlan = useCallback(() => {
        openDialog(DIALOGS.BEFORE_BUSINESS_PLAN_DIALOG, {
            section,
            cityId,
            businessId,
            conceptId,
            formatId,
            point: JSON.stringify(point),
            size: size ?? details?.statistic.businessplans[0].id,
            excludedCompetitors,
            newBuildings: newBuildings === "true",
            zone,
            franchise,
        });
    }, [
        openDialog,
        section,
        cityId,
        businessId,
        conceptId,
        formatId,
        point,
        size,
        details?.statistic.businessplans,
        excludedCompetitors,
        newBuildings,
        zone,
        franchise,
    ]);
    const fetchData = useCallback(outerCityId => {
        if (!point || !formatId) {
            return;
        }
        const wktPoint = getWktPoint(point);
        controller?.abort();
        controller = new AbortController();
        setLoading(true);
        setAddingLocation(false);
        Promise.all(section === AppSection.Retail ? [
            api.statistic.getStatistic(formatId, outerCityId, wktPoint, excludedCompetitors, zone, newBuildings, user.guid, controller.signal),
            api.analytic.getAnalytic(formatId, outerCityId, wktPoint, zone, user.guid, controller.signal),
        ] : [
            api.statistic.getSelfStatistic(formatId, outerCityId, wktPoint, excludedCompetitors, zone, newBuildings, user.guid, controller.signal),
            api.analytic.getSelfAnalytic(formatId, outerCityId, wktPoint, zone, user.guid, controller.signal),
        ]).then(([statistic, analytic]) => {
            setDetails({ statistic, analytic });
            if (!urlSize) {
                setSize(statistic.businessplans?.[0]?.id);
            }
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                point: JSON.stringify(point),
                formatId: formatId?.toString(),
                size: size ? ((+size).toString() || "") : (statistic.businessplans?.[0]?.id.toString() ?? ""),
                newBuildings: !statistic.dev_enable || isNil(newBuildings) ? "false" : newBuildings,
                excludedCompetitors: excludedCompetitors ? JSON.stringify(excludedCompetitors) : "",
                zone: zone ?? "buffer",
                franchise: franchise?.toString() ?? "",
            });
            if (addingLocation || currentLocationId === null) {
                const newId = Math.max(...businessLocations.map(item => item.id), 0) + 1;
                setBusinessLocations([
                    ...businessLocations,
                    {
                        id: newId,
                        point: JSON.stringify(point),
                        formatId,
                        size: size?.toString() ?? "",
                        newBuildings: !statistic.dev_enable || isNil(newBuildings) ? "false" : newBuildings,
                        excludedCompetitors,
                        zone: zone ?? "buffer",
                        franchise,
                        details: {
                            statistic,
                            analytic,
                        },
                    },
                ]);
                setCurrentLocationId(newId);
            }
            else {
                setBusinessLocations(changeProps(businessLocations, { id: currentLocationId }, {
                    point: JSON.stringify(point),
                    formatId,
                    size: size?.toString() ?? "",
                    newBuildings: !statistic.dev_enable || isNil(newBuildings) ? "false" : newBuildings,
                    excludedCompetitors,
                    zone: zone ?? "buffer",
                    franchise,
                    details: {
                        statistic,
                        analytic,
                    },
                }));
            }
            const pointCoordinates = statistic.point.geometry.coordinates;
            const pointRadius = statistic.radius;
            setBufferFeature(getCircleFeatureWithRadius(pointCoordinates, pointRadius));
            if (statistic.zone.zone?.coordinates) {
                setZoneFeature(getPolygonFeature(statistic.zone.zone?.coordinates));
            }
            if (statistic.zone.zone_comp?.coordinates) {
                setZoneCompFeature(getPolygonFeature(statistic.zone.zone_comp?.coordinates));
            }
            if (statistic.double_radius) {
                setDoubleBufferFeature(getCircleFeatureWithRadius(pointCoordinates, pointRadius * 2));
                if (currentCity) {
                    painter.show(getCircleBbox(pointCoordinates, pointRadius * 2));
                }
            }
            if (statistic.competitors) {
                setCompetitorsFeatures(statistic.competitors.map(item => {
                    const detailsCalcStyle = statistic.calc_styles.find(style => style.styleId === item.properties.styleId);
                    return getDynamicPointFeature(item, detailsCalcStyle?.iconSize, detailsCalcStyle?.iconAnchor, `${process.env.API_URL || ""}${detailsCalcStyle?.iconUrl}`, feature => point && setCurrentFeature({
                        ...feature,
                        size: [
                            (detailsCalcStyle?.iconSize?.[0] ?? 0) - (detailsCalcStyle?.iconAnchor?.[0] ?? 0) / 2,
                            (detailsCalcStyle?.iconSize?.[1] ?? 0) - (detailsCalcStyle?.iconAnchor?.[1] ?? 0) / 2,
                        ],
                    }));
                }));
            }
        })
            .catch(() => null)
            .finally(() => {
            setTempPoint(undefined);
            setLoading(false);
        });
    }, [
        currentLocationId,
        point,
        formatId,
        excludedCompetitors,
        searchParams,
        newBuildings,
        size,
        urlSize,
        zone,
        addingLocation,
        businessLocations,
        franchise,
    ]);
    const fetchLegend = useCallback(outerCityId => {
        if (!formatId) {
            return;
        }
        api.service.getLegend(formatId, outerCityId, section === AppSection.Retail ? "retail" : "self", user.guid)
            .then(setLegend);
    }, [formatId]); // eslint-disable-line
    const fetchServices = useCallback(() => {
        if (!legend || !currentCity || !formatId) {
            return;
        }
        const notNullLayers = legend.filter(layer => layer.is_visible) ?? [];
        if (notNullLayers.length === 0) {
            return setServices([]);
        }
        servicesController?.abort();
        servicesController = new AbortController();
        Promise.all(notNullLayers.map(layer => layer.url).map(url => {
            return api.http.get(url, {
                city: currentCity.id,
                business_format: formatId,
                geom: getWktBboxPolygon(painter.bbox.projectTo(wgs84).coordinates),
                guid: user.guid,
            }, servicesController.signal).json();
        }))
            .then(result => {
            setServices(result.map((service, serviceIndex) => {
                return notNullLayers[serviceIndex].id === COMPETITORS_LAYER_ID
                    ? {
                        ...service,
                        isCompetitors: true,
                    } : service;
            }));
        })
            .catch(() => {
            setServices([]);
        });
    }, [currentCity, formatId, legend, painter.bbox, user]);
    useEffect(() => {
        if (!businessConcept || urlFormatId) {
            return;
        }
        setFormatId(businessConcept.default_format ?? businessConcept.business_formats?.[0]?.id);
    }, [businessConcept]); // eslint-disable-line
    useEffect(() => {
        if (!currentCity || !businessConcept) {
            return;
        }
        if (+cityId !== currentCity.id) {
            setPoint(null);
            setDetails(null);
            setExcludedCompetitors(null);
            setSize(undefined);
            setInitCompetitorsStyles(undefined);
            setSearchParams({});
            setBufferFeature(undefined);
            setDoubleBufferFeature(undefined);
            setZoneFeature(undefined);
            setZoneCompFeature(undefined);
            setCompetitorsFeatures(undefined);
            setInitCompetitorsStyles(undefined);
            navigate(generatePath(section === AppSection.Retail ? RetailLocationUrl.path : SelfEmployedLocationUrl.path, { cityId: currentCity.id, businessId, conceptId }), { replace: true });
        }
    }, [currentCity]); // eslint-disable-line
    useEffect(() => {
        if (!details || !legend || initCompetitorsStyles !== undefined) {
            return;
        }
        setInitCompetitorsStyles(legend.find(layer => layer.id === COMPETITORS_LAYER_ID)?.styles);
        setLegend(legend?.map(layer => {
            return layer.id === COMPETITORS_LAYER_ID
                ? { ...layer, styles: details.statistic.calc_styles }
                : { ...layer };
        }));
    }, [details, initCompetitorsStyles]); // eslint-disable-line
    useEffect(() => {
        if (updatedParams.excludedCompetitors) {
            setExcludedCompetitors(urlExcludedCompetitors ? JSON.parse(urlExcludedCompetitors) : null);
            setUpdatedParams({ excludedCompetitors: false });
        }
        if (updatedParams.formatId) {
            setFormatId(!isNil(urlFormatId) ? +urlFormatId : null);
            setExcludedCompetitors(null);
            setUpdatedParams({ formatId: false });
        }
        if (updatedParams.size) {
            setSize(urlSize ? +urlSize : undefined);
            setUpdatedParams({ size: false });
        }
    }, [updatedParams]); // eslint-disable-line
    useEffect(() => {
        setBusinessLocations(changeProps(businessLocations, { id: currentLocationId }, {
            franchise,
        }));
    }, [franchise]); // eslint-disable-line
    useEffect(() => {
        fetchServices();
        setCurrentFeature(undefined);
    }, [legend]); // eslint-disable-line
    useEffect(() => {
        fetchServices();
    }, [mapView.level, mapView.position?.[0], mapView.position?.[1], mapView.resolution]); // eslint-disable-line
    useEffect(() => {
        if (!point || !businessConcept || !cityId) {
            return;
        }
        fetchData(cityId);
    }, [point, formatId, excludedCompetitors, newBuildings, size, zone]); // eslint-disable-line
    useEffect(() => {
        if (!cityId) {
            return;
        }
        fetchLegend(cityId);
    }, [cityId, formatId]); // eslint-disable-line
    useEffect(() => {
        if (urlPoint && urlFormatId && cityId && businessId && conceptId) {
            fetchData(cityId);
            fetchLegend(cityId);
        }
        return () => {
            setAddingLocation(false);
            setOverlayFeature(undefined);
            controller?.abort();
            servicesController?.abort();
        };
    }, []); // eslint-disable-line
    return (_jsxs(_Fragment, { children: [_jsx(Layout, { loading: pageLoading, section: section, noPadding: !isDesktop, leftPanel: _jsxs(FloatLeftPanel, { isMaxHeight: !!details, children: [_jsx(FlexSpan, { children: _jsx(Stepper, { hideButtons: true, activeStep: 1, steps: [
                                    { title: "Выбор бизнеса", onPick: () => navigate(section === AppSection.Retail ? RetailBusinessUrl.path : SelfEmployedBusinessUrl.path) },
                                    { title: "Подбор локации" },
                                    { title: "Бизнес-план", onPick: businessPlanAvailable ? onCreateBusinessPlan : undefined },
                                ] }) }), _jsx(FlexSpan, { mt: "1rem", children: _jsx(PointCard, { section: section, formatId: formatId, setFormatId: setFormatId, size: size ?? details?.statistic?.businessplans?.[0]?.id, franchiseId: franchise ? +franchise : undefined, businessConcept: businessConcept, details: details, icon: businessItems?.find(item => item.id === Number(businessId))?.ico, onOpenSettings: () => setAddingLocation(false), onDelete: onDeleteLocation, footer: details && businessLocations.length > 0 && (_jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { mb: businessLocations.length > 1 ? "0.75rem" : 0, children: _jsx(Pagination, { itemsCount: businessLocations.length, align: "center", currentItem: businessLocations.findIndex(item => item.id === currentLocationId), onChange: onGoToLocation }) }), _jsx(FlexSpan, { children: _jsxs(Flex, { gap: "1rem", children: [canCompare && (_jsx(FlexSpan, { width: "calc(50% - 0.5rem)", children: _jsx(OutlineButton, { icon: "compare", iconColor: "#9D87F1", iconHoverColor: "#7E6CC1", buttonSize: "xs", width: "100%", style: { paddingLeft: "0.5rem", paddingRight: "0.5rem" }, onClick: () => {
                                                                setAddingLocation(false);
                                                                setIsCompareOpen(true);
                                                            }, children: "\u0421\u0440\u0430\u0432\u043D\u0438\u0442\u044C" }) })), _jsx(FlexSpan, { width: canCompare ? "calc(50% - 0.5rem)" : "100%", children: addingLocation ? (_jsx(OutlineButton, { icon: "close", iconColor: "#9D87F1", iconHoverColor: "#7E6CC1", buttonSize: "xs", width: "100%", onClick: () => setAddingLocation(false), style: { paddingLeft: "0.5rem", paddingRight: "0.5rem" }, children: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C" })) : (_jsx(PrimaryButton, { icon: "location", buttonSize: "xs", width: "100%", onClick: () => setAddingLocation(true), disabled: businessLocations.length >= 5 || loading || !!tempPoint, style: { paddingLeft: "0.5rem", paddingRight: "0.5rem" }, children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0442\u043E\u0447\u043A\u0443" })) })] }) })] })) }) }), _jsxs(FlexSpan, { flexGrow: 1, display: (!point || loading) ? "flex" : undefined, alignItems: (!point || loading) && "center", justifyContent: (!point || loading) && "center", overflow: "hidden", children: [!details && (_jsxs(PointLocationPlaceholder, { hidden: !!point && !loading, loading: !!point && loading, children: ["\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043C\u0435\u0441\u0442\u043E\u043F\u043E\u043B\u043E\u0436\u0435\u043D\u0438\u0435", _jsx("br", {}), "\u0432\u0430\u0448\u0435\u0433\u043E \u0431\u0438\u0437\u043D\u0435\u0441\u0430"] })), details && (_jsx(PointDescription, { data: details }))] }), details && (_jsx(FlexSpan, { display: "flex", justifyContent: "center", mb: "0.25rem", children: _jsx(PrimaryButton, { width: "calc(100% - 0.5rem)", disabled: loading || !businessPlanAvailable, onClick: onCreateBusinessPlan, icon: "metrics", buttonSize: isDesktop ? "xl" : "s", disabledTooltip: !businessPlanAvailable ? (section === AppSection.Retail
                                    ? "Срок окупаемости вложений в бизнес по выбранному типовому формату" +
                                        " превышает 5 лет в связи с высокой стоимостью аренды помещения," +
                                        " большим фондом оплаты труда и значительным объёмом инвестиций." +
                                        " Рекомендуем выбрать другой типовой формат, другой вид бизнеса или другое местоположение."
                                    : "Размер доступной рыночной ниши в выбранной локации недостаточен для открытия бизнеса" +
                                        " в соответствии с выбранным типовым форматом. Рекомендуем выбрать другое местоположение," +
                                        " другой типовой формат (при наличии) или другой вид бизнеса.") : undefined, children: "\u0420\u0430\u0441\u0441\u0447\u0438\u0442\u0430\u0442\u044C \u0431\u0438\u0437\u043D\u0435\u0441-\u043F\u043B\u0430\u043D" }) }))] }), children: _jsxs(MapContainer, { children: [_jsxs(Map, { width: "100%", height: "100%", extent: overlayFeature ? {
                                ...overlayFeature.geometry,
                                coordinates: (overlayFeature.geometry.type === "MultiPolygon"
                                    ? [...(overlayFeature?.geometry).coordinates[0] ?? []]
                                    : [...(overlayFeature?.geometry).coordinates ?? []]),
                                type: GeometryType.Polygon,
                                sr: SrId.Wgs84,
                            } : currentCity ? {
                                ...currentCity.geometry,
                                coordinates: [...currentCity.geometry.coordinates ?? []],
                                type: GeometryType.Polygon,
                                sr: SrId.Wgs84,
                            } : undefined, measurerDisabled: addingLocation || !point, children: [overlayFeature && (_jsx(FeatureLayer, { features: overlayFeature.geometry.coordinates?.map(item => {
                                        return getPolygonFeature(item, {
                                            fillColor: "rgba(126, 108, 193, 0.1)",
                                            strokeColor: "rgba(126, 108, 193, 0.5)",
                                            strokeWidth: 4,
                                            lineDash: [4, 8],
                                        });
                                    }), zIndex: 1 })), _jsx(FeatureLayer, { features: !loading && !tempPoint ? (zone === "buffer" ? bufferFeatures : zoneFeatures) : [], zIndex: 2 }), _jsx(FeatureLayer, { features: servicesFeatures, zIndex: 3 }), _jsx(FeatureLayer, { features: legendFeatures, zIndex: 4 }), _jsx(FeatureLayer, { features: businessLocations?.reduce((acc, curr, currIndex) => {
                                        if (curr.id === currentLocationId) {
                                            return acc;
                                        }
                                        return [
                                            ...acc,
                                            getOrderedPinFeature(JSON.parse(curr.point), currIndex + 1, () => onGoToLocation(currIndex)),
                                        ];
                                    }, []), zIndex: 5 }), _jsx(FeatureLayer, { features: tempPoint ? [getOrderedPinFeature(tempPoint[0], tempPoint[1])] : [], zIndex: 5 }), _jsx(DraggableMarker, { onChange: position => {
                                        if (!addingLocation) {
                                            setPoint(position);
                                            setSize(undefined);
                                            setExcludedCompetitors(null);
                                        }
                                    }, position: point, symbol: getOrderedPinSymbol(tempPoint
                                        ? tempPoint[1] + 1
                                        : (businessLocations.findIndex(item => item.id === currentLocationId) ?? 0) + 1, true) }, `business-location-draggable-marker-${JSON.stringify(businessLocations.find(item => item.id === currentLocationId)?.point)}-${tempPoint
                                    ? tempPoint[1] + 1
                                    : (businessLocations.findIndex(item => item.id === currentLocationId) ?? 0) + 1}`), addingLocation && (_jsx(DraggableMarker, { onChange: position => {
                                        setTempPoint([point, businessLocations.length]);
                                        setPoint(position);
                                        setSize(undefined);
                                        setExcludedCompetitors(null);
                                    }, position: null, symbol: getMarkerSymbol() })), _jsx(FeatureLayer, { features: [
                                        currentFeature
                                            ? getCurrentFeatureCard(currentFeature, () => setCurrentFeature(undefined), services?.find(item => item.isCompetitors)?.features.features.findIndex(item => item.id === +currentFeature.id) > -1)
                                            : undefined,
                                    ].filter(Boolean), zIndex: 99 }), _jsx(GeocoderControl, { top: `${isDesktop ? 1.5 : 1}rem`, left: `${isDesktop ? 1.5 : 24.5}rem` }), _jsx(LegendControl, { legend: legend, setLegend: setLegend, bottom: `${isDesktop ? 1.5 : 1}rem`, left: `${isDesktop ? 1.5 : 24.5}rem` }), addingLocation && tempPoint === undefined && (_jsx(MapTooltip, { content: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u0442\u043E\u0447\u043A\u0443 \u043D\u0430 \u043A\u0430\u0440\u0442\u0435" }))] }), !point && (_jsxs(PickPointContainer, { isLocationPage: true, children: [_jsx(FlexSpan, { mr: "0.75rem", children: _jsx(Icon, { icon: "location" }) }), _jsx(FlexSpan, { children: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u0436\u0435\u043B\u0430\u0435\u043C\u043E\u0435 \u043C\u0435\u0441\u0442\u043E\u043F\u043E\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u0432\u0430\u0448\u0435\u0433\u043E \u0431\u0438\u0437\u043D\u0435\u0441\u0430" })] }))] }) }), isCompareOpen && (_jsx(LocationsCompare, { businessLocations: businessLocations, onClose: () => setIsCompareOpen(false), onChangeAddingLocation: setAddingLocation, onGoToLocation: onGoToLocation, onDeleteLocation: onDeleteLocation }))] }));
};
