import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PointDetailsBlock } from "../../../../components/PointDetailsBlock";
import { Flex, FlexSpan } from "../../../../ui";
import { LineChart } from "../../../../components/LineChart";
import { CHARTS_DYNAMIC_COLORS } from "./constants";
import { getPriceData } from "./utils";
import { useIsDesktop } from "../../../../hooks";
import { useLineChartTooltip } from "../../../../components/LineChart/hooks/useLineChartTooltip";
export const PricesBlock = ({ data }) => {
    const isDesktop = useIsDesktop();
    const chartData = getPriceData(data?.analytic);
    const renderTooltip = useLineChartTooltip();
    if (!data?.analytic?.price || data?.analytic?.price.data.length === 0) {
        return null;
    }
    return (_jsxs(PointDetailsBlock, { title: "\u041E\u043F\u0442\u043E\u0432\u044B\u0435 \u0446\u0435\u043D\u044B", icon: "money", children: [_jsx(Flex, { column: true, mb: "1rem", children: data?.analytic?.price.data[0].data.map((item, index) => (_jsx(FlexSpan, { fontSize: "0.75rem", color: CHARTS_DYNAMIC_COLORS[index], children: item.label }, `price-chart-legend-item-${index}`))) }), data?.analytic?.price.data.map((dataItem, dataIndex) => (_jsxs(Flex, { column: true, mb: dataIndex < data?.analytic?.price.data.length - 1 ? "0.5rem" : 0, children: [_jsx(FlexSpan, { fontWeight: 500, mb: "1rem", children: dataItem.title }), _jsx(FlexSpan, { width: "100%", children: _jsx(LineChart, { data: chartData[dataIndex], width: isDesktop ? 314 : 290, height: 70, stacked: false, drawGridY: true, dynamicCircleRadius: 2, labels: data?.analytic?.price.labels.map((label, index) => index === 0 || index === data?.analytic?.price.labels.length - 1 ? label : ""), renderTooltip: (datas, properties) => {
                                return renderTooltip(datas, properties, Array(chartData[dataIndex][0].values.length).fill("").map((item, index) => {
                                    return data?.analytic?.consumer.labels[index].replace(/[^0-9]/, "");
                                }));
                            }, margin: {
                                top: 0,
                                right: 2,
                                bottom: 0,
                                left: 0,
                            } }) })] }, `price-chart-${dataIndex}`)))] }));
};
