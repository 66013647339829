import { jsx as _jsx } from "react/jsx-runtime";
import { generatePath, useNavigate } from "react-router-dom";
import { Modal } from "../../../ui";
import { PromBusinessListItem } from "../../PromBusinessListItem";
import { useCurrentSubject, useDialogProps, useDialogReset } from "../../../hooks";
export const PromBusinessCardDialog = () => {
    const navigate = useNavigate();
    const [currentSubject] = useCurrentSubject();
    const closeDialog = useDialogReset();
    const [{ businessItem, forwardPath }] = useDialogProps();
    return (_jsx(Modal, { width: "50vw", maxWidth: "50rem", paddingContent: "0 0 0 1rem", title: businessItem?.name, content: (_jsx(PromBusinessListItem, { compactMode: true, listStyle: { padding: 0 }, businessItem: businessItem, lightBackground: true, onConceptClick: concept => {
                closeDialog();
                if (forwardPath && currentSubject && businessItem) {
                    navigate(generatePath(forwardPath, { subjectId: currentSubject.id, businessId: businessItem?.id, conceptId: concept.id }));
                }
            } })) }));
};
