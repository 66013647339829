import styled from "styled-components";
import { space } from "styled-system";
import { FlexSpan } from "../Grid";
import { Icon } from "../Icon/styled";
export const BackButtonContainer = styled.span `
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${({ primary }) => primary && "#7E6CC1"};
  ${space}
  
  ${Icon} {
    color: #7E6CC1;
  }
  
  &:hover {
    color: #6E5CB2;
  }
`;
export const BackButtonLabel = styled(FlexSpan) `
  margin-left: 0.5rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0.03rem;
  text-transform: ${({ disableUppercase }) => !disableUppercase && "uppercase"};
`;
