import styled from "styled-components";
import { Flex, FlexSpan } from "../../../../ui";
export const CityListItem = styled(Flex) `
  width: 100%;
`;
export const CityListItemLabel = styled(FlexSpan) `
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  width: 1.5rem;
  min-width: 1.5rem;
  color: #9D87F1;
`;
export const CityListItemName = styled(FlexSpan) `
  flex-grow: 1;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  padding-right: 2rem;

  &:hover {
    color: #6E5CB2;
  }
`;
