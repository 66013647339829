import { useEffect } from "react";
import { useAppInitialized } from "./app/useAppInitialized";
import { useUser } from "./auth/useUser";
import { useCurrentCity } from "./app/useCurrentCity";
import { useCityList } from "./dict/useCityList";
import { useSubjectList } from "./dict/useSubjectList";
import { useRetailBusinessItems } from "./dict/retail/useRetailBusinessItems";
import { useRetailBusinessPriceRange } from "./dict/retail/useRetailBusinessPriceRange";
import { useSelfBusinessItems } from "./dict/self/useSelfBusinessItems";
import { useSection } from "./app/useSection";
import { usePromBusinessItems } from "./dict/prom/usePromBusinessItems";
import { api } from "../api";
import { AppSection } from "../slices/app/types";
export const usePageLoad = () => {
    const [appInitialized] = useAppInitialized();
    const [user] = useUser();
    const [section] = useSection();
    const [cityList, setCityList] = useCityList();
    const [subjectList, setSubjectList] = useSubjectList();
    const [currentCity] = useCurrentCity();
    const [retailBusinessItems, setRetailBusinessItems] = useRetailBusinessItems();
    const [retailBusinessPriceRange, setRetailBusinessPriceRange] = useRetailBusinessPriceRange();
    const [selfBusinessItems, setSelfBusinessItems] = useSelfBusinessItems();
    const [promBusinessItems, setPromBusinessItems] = usePromBusinessItems();
    useEffect(() => {
        if (!appInitialized) {
            return;
        }
        if (cityList === undefined) {
            api.city.getCityList(user.guid).then(setCityList);
        }
        if (subjectList === undefined) {
            api.city.getSubjectList(user.guid).then(setSubjectList);
        }
    }, [appInitialized]); // eslint-disable-line
    useEffect(() => {
        if (!appInitialized || currentCity === undefined) {
            return;
        }
        if ([AppSection.Retail, AppSection.RetailIntegral].includes(section)) {
            if (retailBusinessPriceRange === undefined ||
                !retailBusinessPriceRange.every(range => range.city === currentCity.id)) {
                api.business.getBusinessPriceRange(currentCity.id, user.guid).then(setRetailBusinessPriceRange);
            }
            if (retailBusinessItems === undefined) {
                api.business.getBusiness(user.guid).then(setRetailBusinessItems);
            }
        }
        if (section === AppSection.SelfEmployed) {
            if (selfBusinessItems === undefined) {
                api.business.getSelfEmployedBusiness(user.guid).then(setSelfBusinessItems);
            }
        }
        if ([AppSection.Enterprise, AppSection.EnterpriseIntegral].includes(section)) {
            if (promBusinessItems === undefined) {
                api.business.getPromEmployedBusiness(user.guid).then(setPromBusinessItems);
            }
        }
    }, [currentCity, section, appInitialized]); // eslint-disable-line
};
