import styled from "styled-components";
import { space } from "styled-system";
import { FlexSpan } from "../Grid";
import { Icon } from "../Icon/styled";
export const CloseButtonContainer = styled(FlexSpan) `
  display: flex;
  margin-left: 0.25rem;
`;
export const TagContainer = styled.span `
  display: inline-flex;
  align-items: center;
  height: 1.5rem;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  background: ${({ light }) => light ? "#EBE7FC" : "#9D87F1"};
  color: ${({ light }) => light ? "#7E6CC1" : "#ffffff"};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.625rem;
  cursor: ${({ pointer }) => pointer ? "pointer" : undefined};
  ${space}
  
  ${CloseButtonContainer} {
    ${Icon} {
      color: ${({ light }) => !light && "#ffffff"};
      
      &:hover {
        color: ${({ light }) => !light && "#E0D3FF"};
      }
    }
  }
`;
