import styled from "styled-components";
import { Flex } from "../../../ui";
import { Icon } from "../../../ui/Icon/styled";
export const TitleCurrentCity = styled.span `
  color: #9D87F1;
`;
export const Description = styled.span `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #9F9F9F;
`;
export const AlertContainer = styled(Flex) `
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  align-items: flex-start;
  border-radius: 0.75rem;
  background-color: rgba(246, 197, 68, 0.40);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  
  ${Icon} {
    position: relative;
    top: 0.0625rem;
  }
`;
