export const getIndicatorsBarChartData = (...args) => {
    if (!args) {
        return [];
    }
    return args.map((item, index) => ({ id: index, value: item })).reduce((acc, { value }, index) => {
        if (Array.isArray(value) && index === 0) {
            value.forEach((v, i) => {
                acc.push({
                    groupName: `${i + 1} год`,
                    [index]: {
                        0: v,
                    },
                });
            });
        }
        else if (Array.isArray(value)) {
            value.forEach((v, i) => {
                acc[i] = {
                    ...acc[i],
                    [index]: {
                        1: v,
                    },
                };
            });
        }
        return acc;
    }, []);
};
export const formatScheduleToMonths = (schedule) => {
    return schedule?.reduce((acc, curr, index) => {
        return curr !== 0 ? [
            ...acc,
            index + 1,
        ] : acc;
    }, []);
};
