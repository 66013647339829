import styled from "styled-components";
import { Flex } from "../Grid";
export const FlexWithMargin = styled(Flex) `
  margin: 0;
  
  > label:not(:last-of-type) {
    margin-bottom: ${({ column }) => (column ? "1rem" : 0)};
    margin-right: ${({ column }) => (column ? 0 : "1rem")};
  }
`;
export const Radio = styled.input.attrs(({ checked }) => ({
    type: "radio",
    hidden: true,
    checked: !!checked,
})) ``;
export const RadioLabel = styled.span `
  display: inline-flex;
  margin-left: 0.5rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  user-select: none;
`;
export const RadioCheckMark = styled.span `
  position: relative;
  display: flex;
  flex-shrink: 0;
  height: 1.125rem;
  width: 1.125rem;
  border: 1px solid ${({ error }) => error ? "#EB5757" : "#9F9F9F"};
  border-radius: 50%;
  box-sizing: border-box;

  ${Radio}:checked + & {
    border: 1px solid #9D87F1;
    
    &:after {
      background-color: #9D87F1;
    }

    &[disabled] {
      border: 1px solid #CEC3F8;

      &:after {
        background-color: #CEC3F8;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0.625rem;
    width: 0.625rem;
    border-radius: 50%;
  }

  &:not([disabled]) {
    &:hover {
      outline: 2px solid #CEC3F8;
    }
  }

  &[disabled] {
    border-color: #E3E3E3;
  }
`;
export const RadioWithLabelContainer = styled.label `
  display: flex;
  align-items: center;
  text-align: left;

  &[disabled] {
    cursor: not-allowed;
  }
  
  &:not([disabled]):hover {
    cursor: pointer;
    
    ${RadioCheckMark} {
      outline: 2px solid #CEC3F8;
    }
  }

  &[disabled] {
    ${RadioLabel} {
      color: #9F9F9F;
    }
  }
`;
