import styled from "styled-components";
import { Flex, FlexSpan } from "../../../ui";
import { Icon } from "../../../ui/Icon/styled";
import { IconButtonContainer } from "../../../ui/IconButton/styled";
import { SCREEN_WIDTH_DESKTOP } from "../../../constants/layout";
export const HeaderWrapper = styled(Flex) `
  align-items: center;
  justify-content: center;
  height: 5rem;
  max-height: 5rem;
  overflow: hidden;
  background: linear-gradient(90deg, #474B95 0%, #7552AE 100%), #8879C2;
  color: #ffffff;
  box-sizing: border-box;
`;
export const HeaderContainer = styled(Flex) `
  align-items: center;
  width: 100%;
  max-width: 100rem;
  padding: 0 1.75rem;
  box-sizing: border-box;
  
  a {
    text-decoration: none;
    color: #ffffff;

    &:hover {
      color: #B19FF4;
    }

    &:active {
      color: #B19FF4;
    }
  }
`;
export const LogoContainer = styled.div `
  cursor: pointer;
`;
export const MenuItem = styled(FlexSpan) `
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  height: 2rem;
  border-radius: 0.25rem;
  transition: 0.15s ease-in-out;
  white-space: nowrap;

  @media only screen and (max-width: ${SCREEN_WIDTH_DESKTOP}px) {
    & {
      display: ${({ hideOnMobile }) => hideOnMobile ? "none" : "flex"};
    }
  }

  ${IconButtonContainer} {
    display: flex;

    ${Icon} {
      position: relative;
      top: 0.0625rem;
      transition: none;
    }
  }
`;
export const MobileMenuContainer = styled(FlexSpan) `
  display: none;
  margin-left: 2rem;
  
  @media only screen and (max-width: ${SCREEN_WIDTH_DESKTOP}px) {
    & {
      display: inline-flex;
    }
  }
`;
