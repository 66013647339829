import { jsx as _jsx } from "react/jsx-runtime";
import { SimpleLegendHex, SimpleLegendSquare } from "./styled";
import { LegendSymbolType } from "./types";
export const SimpleLegendSymbol = ({ type, backgroundColor, opacity }) => {
    switch (type) {
        case LegendSymbolType.Hexagon:
            return _jsx(SimpleLegendHex, { backgroundColor: backgroundColor, opacity: opacity });
        case LegendSymbolType.Square:
            return _jsx(SimpleLegendSquare, { backgroundColor: backgroundColor, opacity: opacity });
        default:
            return null;
    }
};
