import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOverlayFeature } from "../../selectors/map";
import { setOverlayFeature } from "../../slices/map";
export const useOverlayFeature = () => {
    const dispatch = useDispatch();
    const overlayFeature = useSelector(getOverlayFeature);
    const update = useCallback((value) => {
        dispatch(setOverlayFeature(value));
    }, [dispatch]);
    return [overlayFeature, update];
};
