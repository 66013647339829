import styled from "styled-components";
import { Flex } from "../../ui";
export const PageLoaderContainer = styled(Flex) `
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 600;
  top: 5rem;
  left: 0;
  width: 100vw;
  height: calc(var(--app-height) - 5rem);
  background: #F9F9F8;
  opacity: 0.9;
`;
