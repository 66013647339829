import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAreaCompareParams } from "../../selectors/filters";
import { setAreaCompareParams } from "../../slices/filters";
export const useAreaCompareParams = () => {
    const dispatch = useDispatch();
    const areaCompareParams = useSelector(getAreaCompareParams);
    const update = useCallback((value) => {
        dispatch(setAreaCompareParams(value));
    }, [dispatch]);
    return [areaCompareParams, update];
};
