import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { Flex, FlexSpan, H5, Popover, PrimaryButton } from "../../../ui";
import { BusinessSelectPane } from "./styled";
import { RetailBusinessUrl, SelfEmployedBusinessUrl } from "../../../constants/routes";
import { useOverlayFeature } from "../../../hooks";
import roz from "../../../assets/images/roz.png";
import samo from "../../../assets/images/samo.png";
export const AreaOpenBusinessButton = ({ feature, placement = "top", ...buttonProps }) => {
    const navigate = useNavigate();
    const [, setOverlayFeature] = useOverlayFeature();
    return (_jsx(Popover, { anchor: (_jsx(PrimaryButton, { icon: "metrics", ...buttonProps, children: "\u041E\u0442\u043A\u0440\u044B\u0442\u044C \u0431\u0438\u0437\u043D\u0435\u0441" })), wrapperStyle: { width: "100%" }, placement: placement, offsetY: 8, children: _jsxs(Flex, { gap: "1rem", p: "1rem 1.5rem", children: [_jsx(FlexSpan, { width: "7rem", children: _jsxs(BusinessSelectPane, { onClick: () => {
                            setOverlayFeature(feature);
                            navigate(RetailBusinessUrl.path);
                        }, children: [_jsx(FlexSpan, { children: _jsx("img", { src: roz, alt: "", height: "48px" }) }), _jsx(FlexSpan, { textAlign: "center", children: _jsx(H5, { children: "\u0420\u043E\u0437\u043D\u0438\u0447\u043D\u044B\u0439 \u0431\u0438\u0437\u043D\u0435\u0441" }) })] }) }), _jsx(FlexSpan, { width: "7rem", children: _jsxs(BusinessSelectPane, { onClick: () => {
                            setOverlayFeature(feature);
                            navigate(SelfEmployedBusinessUrl.path);
                        }, children: [_jsx(FlexSpan, { children: _jsx("img", { src: samo, alt: "", height: "48px" }) }), _jsx(FlexSpan, { textAlign: "center", children: _jsx(H5, { children: "\u0411\u0438\u0437\u043D\u0435\u0441 \u0434\u043B\u044F \u0441\u0430\u043C\u043E\u0437\u0430\u043D\u044F\u0442\u044B\u0445" }) })] }) })] }) }));
};
