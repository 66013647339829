import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPromBusinessDisplayMode } from "../../../selectors/filters";
import { setPromBusinessDisplayMode } from "../../../slices/filters";
export const usePromBusinessDisplayMode = () => {
    const dispatch = useDispatch();
    const displayMode = useSelector(getPromBusinessDisplayMode);
    const update = useCallback((value) => {
        dispatch(setPromBusinessDisplayMode(value));
    }, [dispatch]);
    return [displayMode, update];
};
