import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const DataFragment = styled.span `
  color: ${({ isHighlighted }) => isHighlighted && "#7E6CC1"};
`;
export const highlightSearch = (searchValue, data, minSearchLength) => {
    if (!data) {
        return "";
    }
    if (!searchValue) {
        return data;
    }
    if (minSearchLength !== undefined && searchValue.length < minSearchLength) {
        return data;
    }
    const processedData = data.toString();
    const parts = (searchValue && processedData.split(new RegExp(`(${searchValue.replace(/[-[\]{}()*+!<=:?.\\^$|#\s,]/g, "\\$&")})`, "gi"))) || [];
    return processedData.toLowerCase().includes(searchValue.toLowerCase())
        ? parts.map((part, i) => (_jsx(DataFragment, { isHighlighted: part.toLowerCase() === searchValue.toLowerCase(), children: part }, `search-text-part-${i}`))) : data;
};
