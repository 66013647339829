import { CONSUMER_COLORS, CHARTS_DYNAMIC_COLORS } from "./constants";
export const getSupplyData = (analytic) => {
    if (!analytic?.supply) {
        return [];
    }
    const excludedKeys = ["consumption", "labels", "multiplier", "unit"];
    const forecastItemIndex = analytic.supply.labels.findIndex(label => label.includes("П"));
    const processedData = analytic.supply.labels.map((label, labelIndex) => {
        return {
            groupName: label.replace(/[^0-9]/, ""),
            bar: Object.entries(analytic.supply).reduce((acc, [key, value]) => {
                if (excludedKeys.includes(key)) {
                    return acc;
                }
                return {
                    ...acc,
                    [key]: value[labelIndex],
                };
            }, {}),
        };
    });
    return [
        processedData.slice(0, forecastItemIndex),
        processedData.slice(forecastItemIndex),
    ];
};
export const getSupplyMinMax = (analytic) => {
    if (!analytic?.supply) {
        return [];
    }
    const excludedKeys = ["labels", "multiplier", "unit"];
    const valuesArray = Object.entries(analytic.supply).reduce((acc, [key, value]) => (excludedKeys.includes(key) ? acc : [...acc, ...value]), []);
    const minValue = Math.min(...valuesArray);
    const maxValue = Math.max(...valuesArray);
    return [
        minValue,
        minValue < 0 ? maxValue + Math.abs(minValue) : maxValue,
    ];
};
export const getProductionData = (analytic) => {
    if (!analytic?.production) {
        return [];
    }
    const forecastItemIndex = analytic.production.labels.findIndex(label => label.includes("П"));
    const processedDataActual = analytic.production.data.map(item => ({
        values: item.data.slice(0, forecastItemIndex),
        name: item.name,
        fill: "#9D87F1",
    }));
    const processedDataForecast = analytic.production.data.map(item => ({
        values: item.data.slice(forecastItemIndex),
        name: item.name,
        fill: "#9D87F1",
    }));
    return [
        processedDataActual,
        processedDataForecast,
    ];
};
export const getConsumerData = (analytic) => {
    if (!analytic?.consumer) {
        return [];
    }
    const forecastItemIndex = analytic.consumer.labels.findIndex(label => label.includes("П"));
    const processedDataActual = analytic.consumer.data.map((item, index) => ({
        values: item.data.slice(0, forecastItemIndex),
        name: item.name,
        fill: CONSUMER_COLORS[index],
    }));
    const processedDataForecast = analytic.consumer.data.map((item, index) => ({
        values: item.data.slice(forecastItemIndex),
        name: item.name,
        fill: CONSUMER_COLORS[index],
    }));
    return [
        processedDataActual,
        processedDataForecast,
    ];
};
export const getPriceData = (analytic) => {
    if (!analytic?.price) {
        return [];
    }
    return analytic.price.data.map(item => item.data.map((dataItem, dataItemIndex) => ({
        values: dataItem.data,
        name: dataItem.label,
        stroke: CHARTS_DYNAMIC_COLORS[dataItemIndex],
    })));
};
