import styled from "styled-components";
import { Flex } from "../../ui";
export const MapContainer = styled.div `
  position: relative;
  width: 100%;
  height: calc(100% - 0.25rem);
`;
export const PickAreaContainer = styled(Flex) `
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem 0;
`;
