import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { changeProps } from "find-and";
import { SearchInput } from "../../SearchInput";
import { Checkbox, Flex, FlexSpan, H4, IconButton, Modal, PrimaryButton } from "../../../ui";
import { ParamsSection, ParamsSectionBody, ParamsSectionHead } from "./styled";
import { INIT_LOCATIONS_COMPARE_PARAMS } from "../../../constants/filters";
import { useDialogReset, useIsDesktop, useLocationsCompareParams } from "../../../hooks";
import { highlightSearch } from "../../../utils";
export const LocationsCompareParamsDialog = () => {
    const isDesktop = useIsDesktop();
    const closeDialog = useDialogReset();
    const [searchValue, setSearchValue] = useState("");
    const [currentParams, setCurrentParams] = useState();
    const [locationsCompareParams, setLocationsCompareParams] = useLocationsCompareParams();
    const [sectionState, setSectionState] = useState({});
    const filteredParams = useMemo(() => {
        return Object.entries(INIT_LOCATIONS_COMPARE_PARAMS).reduce((acc, [key, value]) => ({
            ...acc,
            [key]: value.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase())),
        }), {});
    }, [searchValue]);
    useEffect(() => {
        setCurrentParams(locationsCompareParams);
        setSectionState(Object.keys(locationsCompareParams).reduce((acc, curr) => ({
            ...acc,
            [curr]: true,
        }), {}));
    }, [locationsCompareParams]);
    if (currentParams === undefined) {
        return null;
    }
    return (_jsx(Modal, { width: "41.5rem", height: "40rem", title: (_jsxs(Flex, { column: true, gap: "1rem", children: [_jsx(FlexSpan, { children: "\u041F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u044B \u0441\u0440\u0430\u0432\u043D\u0435\u043D\u0438\u044F" }), _jsx(FlexSpan, { children: _jsx(SearchInput, { autofocus: isDesktop, value: searchValue, onChange: setSearchValue }) })] })), content: (_jsx(Flex, { column: true, gap: "1rem", children: Object.entries(filteredParams).filter(([, value]) => value.length).map(([key, value], sectionIndex) => {
                return (_jsx(FlexSpan, { width: "100%", children: _jsxs(ParamsSection, { children: [_jsxs(ParamsSectionHead, { children: [_jsx(FlexSpan, { children: _jsx(H4, { onClick: () => setSectionState({ ...sectionState, [key]: !sectionState[key] }), children: key }) }), _jsx(FlexSpan, { display: "flex", children: _jsx(IconButton, { icon: sectionState[key] ? "chevron-medium-bottom" : "chevron-medium-right", onClick: () => setSectionState({ ...sectionState, [key]: !sectionState[key] }) }) })] }), _jsx(ParamsSectionBody, { open: sectionState[key], children: _jsx(Flex, { column: true, gap: "0.75rem", children: value.map(option => {
                                        return (_jsx(FlexSpan, { children: _jsx(Checkbox, { label: highlightSearch(searchValue, option.name), checked: currentParams[key].find(item => item.key === option.key)?.visible, onChange: () => {
                                                    setCurrentParams(changeProps(currentParams, { key: option.key }, { visible: !currentParams[key].find(item => item.key === option.key)?.visible }));
                                                } }) }, `locations-compare-param-section-${sectionIndex}-option-${option.key}`));
                                    }) }) })] }) }, `locations-compare-param-section-${sectionIndex}`));
            }) })), footer: (_jsx(PrimaryButton, { buttonSize: "s", onClick: () => {
                setLocationsCompareParams(currentParams);
                closeDialog();
            }, children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C" })) }));
};
