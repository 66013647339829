import { useCallback, useState } from "react";
export const useFormValues = (initialValues, onChange) => {
    const [values, setValues] = useState(initialValues ?? {});
    const update = useCallback((newValues) => {
        const updatedValues = {
            ...values,
            ...newValues,
        };
        setValues(updatedValues);
        onChange && onChange(updatedValues);
    }, [onChange, values]);
    return [values, update];
};
