import styled from "styled-components";
import { Flex, FlexSpan } from "../../ui";
export const PointCardActions = styled.div `
  position: relative;
  top: 0.2rem;
  display: inline-flex;
`;
export const PointCardAddress = styled.div `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #9F9F9F;
`;
export const PointCardFormatsLabel = styled.div `
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #9F9F9F;
`;
export const PointCardFormat = styled(FlexSpan) `
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: ${({ active }) => active ? "#9D87F1" : "#F3F3F3"};
  color: ${({ active }) => active && "#ffffff"};
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s ease;
  
  &:hover {
    background-color: ${({ active }) => !active && "#F7F4FF"};
  }
`;
export const PointCardFormatsContainer = styled(Flex) `
  gap: 0.5rem;
  
  ${PointCardFormat} {
    width: calc(100% / ${({ count }) => count});
  }
`;
