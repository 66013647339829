import styled from "styled-components";
export const SliderContainer = styled.div `
  padding: 0 0.5rem;
  user-select: none;
  
  ${".rc-slider-rail"} {
    background-color: ${({ noRail }) => noRail ? "transparent" : "#E3E3E3"};
    height: 0.1875rem;
  }

  ${".rc-slider-track"} {
    background-color: #9D87F1;
    height: 0.1875rem;
  }
  
  ${".rc-slider-step"} {
    ${".rc-slider-dot"} {
      width: 0.125rem;
      background-color: #ffffff;
      border-radius: 0;
      border: none;
      
      :first-of-type,
      :last-of-type {
        display: none;
      }
    }
  }
  
  ${".rc-slider-mark"} {
    display: ${({ noMarks }) => noMarks && "none"};
    
    ${".rc-slider-mark-text"} {
      position: relative;
      top: -5px;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: #9F9F9F;
      
      :not(:first-of-type, :last-of-type) {
        display: none;
      }
    }
  }
  
  ${".rc-slider-handle"} {
    margin-top: -6px;
    width: 1.0625rem;
    height: 1.0625rem;
    border-radius: 50%;
    border: 2px solid #9D87F1;
    background-color: #ffffff;
    opacity: unset;
    
    &:active {
      box-shadow: none;
      outline: 4px solid #CEC3F8;
      border: 2px solid #9D87F1;
    }
  }
  
  ${".rc-slider-disabled"} {
    background-color: transparent;
    cursor: not-allowed;

    ${".rc-slider-rail"} {
      background-color: ${({ noRail }) => noRail ? "transparent" : "#F9F9F9"};
    }

    ${".rc-slider-track"} {
      background-color: #E3E3E3;
    }

    ${".rc-slider-handle"} {
      pointer-events: none;
      border-color: #E3E3E3;
    }
  }
`;
