import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Flex, FlexSpan } from "../Grid";
import { Icon } from "../Icon";
import { PaginationNav, PaginationItem } from "./styled";
export const Pagination = ({ itemsCount, currentItem, align, disabled, onChange }) => {
    const [ownCurrentItem, setOwnCurrentItem] = useState();
    useEffect(() => {
        setOwnCurrentItem(currentItem !== undefined ? currentItem : 0);
    }, [currentItem]);
    useEffect(() => {
        onChange && onChange(ownCurrentItem);
    }, [ownCurrentItem]); // eslint-disable-line
    if (ownCurrentItem === undefined || itemsCount < 2) {
        return null;
    }
    return (_jsxs(Flex, { gap: "0.75rem", alignItems: "center", flexWrap: "wrap", justifyContent: align, children: [_jsx(PaginationNav, { disabled: disabled, mr: "0.75rem", onClick: () => setOwnCurrentItem(ownCurrentItem > 0 ? ownCurrentItem - 1 : itemsCount - 1), children: _jsx(Icon, { icon: "chevron-large-left", size: 12 }) }), Array(itemsCount).fill(null).map((item, index) => (_jsx(FlexSpan, { children: _jsx(PaginationItem, { disabled: disabled, active: index === ownCurrentItem, onClick: () => {
                        if (!disabled) {
                            setOwnCurrentItem(index);
                        }
                    } }) }, `pagintation-item-${index}`))), _jsx(PaginationNav, { disabled: disabled, ml: "0.75rem", onClick: () => setOwnCurrentItem(ownCurrentItem < itemsCount - 1 ? ownCurrentItem + 1 : 0), children: _jsx(Icon, { icon: "chevron-large-right", size: 12 }) })] }));
};
