import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Measurer } from "@evergis/react";
import { FlexSpan, IconButton } from "../../../../../ui";
import { MeasurerTooltip } from "./styled";
import { areaSymbol, lengthSymbol, snapSymbol } from "./symbols";
import { useCurrentFeature, useMeasurerActive } from "../../../../../hooks";
export const MeasurerTool = ({ disabled }) => {
    const [isMeasurerActive, setIsMeasurerActive] = useMeasurerActive();
    const [, setCurrentFeature] = useCurrentFeature();
    useEffect(() => {
        if (isMeasurerActive) {
            setCurrentFeature(void 0);
        }
    }, [isMeasurerActive, setCurrentFeature]);
    useEffect(() => {
        if (disabled) {
            setIsMeasurerActive(false);
        }
    }, [disabled]); // eslint-disable-line
    useEffect(() => {
        return () => {
            setIsMeasurerActive(false);
        };
    }, []); // eslint-disable-line
    return (_jsxs(FlexSpan, { display: "flex", children: [_jsx(IconButton, { active: isMeasurerActive, icon: "measurer", disabled: disabled, onClick: () => setIsMeasurerActive(!isMeasurerActive) }), _jsx(Measurer, { isActive: isMeasurerActive, lengthSymbol: lengthSymbol, areaSymbol: areaSymbol, snapSymbol: snapSymbol, renderLabelSymbol: ({ content, onClose }) => (_jsxs(MeasurerTooltip, { children: [content, onClose && (_jsx(IconButton, { icon: "close", onClick: event => {
                                event.stopPropagation();
                                onClose();
                            } }))] })) })] }));
};
