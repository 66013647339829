import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BusinessConceptContainer, BusinessConceptDescription, BusinessConceptDescriptionValue } from "./styled";
import { Flex, FlexSpan, H4, H6, Link } from "../../../ui";
import { CategoryIcon } from "../../CategoryIcon";
import { InfoIcon } from "../../InfoIcon";
import { DIALOGS } from "../../../constants/dialogs";
import { MIN_SEARCH_LENGTH } from "../../../constants/common";
import { useDialog } from "../../../hooks";
import { formatNumber, highlightSearch } from "../../../utils";
export const BusinessConcept = ({ businessItem, concept, start, stop, lightBackground, compactMode, search, onClick, }) => {
    const openDialog = useDialog();
    return (_jsxs(BusinessConceptContainer, { onClick: onClick, alignItems: "center", justifyContent: "space-between", lightBackground: lightBackground, children: [!compactMode && (_jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { alignItems: "center", mb: "0.25rem", children: [_jsx(FlexSpan, { mr: "0.5rem", children: _jsx(CategoryIcon, { path: businessItem.ico, size: "1rem" }) }), _jsx(FlexSpan, { children: _jsx(H6, { children: businessItem?.name }) })] }) })), _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { alignItems: "flex-end", justifyContent: "space-between", children: [_jsxs(FlexSpan, { pr: "3rem", width: compactMode ? "100%" : undefined, flexGrow: !compactMode ? 1 : undefined, minWidth: "20rem", children: [_jsxs(Flex, { alignItems: "center", children: [_jsx(H4, { children: highlightSearch(search, concept.name, MIN_SEARCH_LENGTH) }), _jsx(InfoIcon, { content: concept.description })] }), _jsxs(BusinessConceptDescription, { children: [_jsx(FlexSpan, { mr: "0.5rem", children: "\u0421\u0440\u0435\u0434\u043D\u0438\u0439 \u043E\u0431\u044A\u0435\u043C \u0438\u043D\u0432\u0435\u0441\u0442\u0438\u0446\u0438\u0439" }), _jsxs(BusinessConceptDescriptionValue, { children: [formatNumber(start), " - ", formatNumber(stop), " \u20BD"] })] })] }), !compactMode && (_jsx(FlexSpan, { alignSelf: "baseline", pl: "3rem", minWidth: "15rem", children: _jsx(Link, { icon: "info_modal2", iconPlacement: "right", onClick: e => {
                                    e.stopPropagation();
                                    openDialog(DIALOGS.BUSINESS_INFO_DIALOG, { businessConcept: concept });
                                }, children: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435 \u043E \u0431\u0438\u0437\u043D\u0435\u0441\u0435" }) }))] }) })] }));
};
