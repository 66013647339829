import { barChartClassNames } from "@evergis/charts";
import styled from "styled-components";
import { BarChart } from "../../../../BarChart";
export const SimpleBarChart = styled(BarChart) `
  .${barChartClassNames.barChartGridGlobal}, .${barChartClassNames.barChartYAxis} {
    visibility: hidden;
  }
  
  .${barChartClassNames.barChartXAxis} {
    text {
      text-transform: capitalize;
    }
  }
`;
export const TooltipWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: 0 0.625rem;
  padding: 1rem 1.25rem;
  background-color: white;
  box-shadow: 0 0 1rem 0 rgba(63, 67, 106, 0.12);
  color: black;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
`;
export const TooltipValue = styled.div `
  font-weight: 500;
  font-size: 0.875rem;
`;
