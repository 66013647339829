import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelfBusinessItems } from "../../../selectors/dict";
import { setSelfBusinessItems } from "../../../slices/dict";
export const useSelfBusinessItems = () => {
    const dispatch = useDispatch();
    const businessItems = useSelector(getSelfBusinessItems);
    const update = useCallback((value) => {
        dispatch(setSelfBusinessItems(value));
    }, [dispatch]);
    return [businessItems, update];
};
