import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPromConceptItem } from "../../../selectors/dict";
import { setPromConceptItem } from "../../../slices/dict";
export const usePromConceptItem = () => {
    const dispatch = useDispatch();
    const conceptItem = useSelector(getPromConceptItem);
    const update = useCallback((value) => {
        dispatch(setPromConceptItem(value));
    }, [dispatch]);
    return [conceptItem, update];
};
