import styled from "styled-components";
import { Card } from "../../../ui";
export const BlockDescription = styled.div `
  color: #9F9F9F;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;
export const CardWrapper = styled.div `
  height: 100%;
  
  ${Card} {
    &:hover {
      background: linear-gradient(90deg, #474B95 0%, #7552AE 100%);
      color: #ffffff;

      ${BlockDescription} {
        color: #ffffff;
      }
    }
  }
`;
