import styled from "styled-components";
import { Card } from "../../../../ui";
import { Icon } from "../../../../ui/Icon/styled";
import { LinkWrapper } from "../../../../ui/Link/styled";
import { IconButtonContainer } from "../../../../ui/IconButton/styled";
export const BusinessPlanHeaderContainer = styled(Card) `
  background: url(${({ backgroundImage }) => backgroundImage}) center center;
  background-size: cover;
  color: #ffffff;
  padding: 1.5rem;

  ${IconButtonContainer} {
    ${Icon} {
      color: rgba(255, 255, 255, 0.5);

      &:hover {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  
  ${LinkWrapper} {
    color: #ffffff;
    opacity: 0.6;
    
    &:hover {
      opacity: 0.8;
    }
  }
`;
export const BusinessPlanHeaderLabel = styled.div `
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
