import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { CompareTableParamLabel, CompareTableParamValue, CompareTableParamValueIconContainer, CompareTableParamValueSubKeyBar, } from "../styled";
import { EllipsisText, Flex, FlexSpan, Icon, Tooltip } from "../../../../ui";
import { AREA_COMPARE_PARAM_RENDERS } from "../../../../constants/filters";
import { useAreaCompareParams } from "../../../../hooks";
import { formatNumber } from "../../../../utils";
export const useCompareTableData = (businessAreas) => {
    const [areaCompareParams] = useAreaCompareParams();
    return useMemo(() => {
        return Object.entries(areaCompareParams).reduce((acc, [key, value]) => {
            if (value.filter(item => item.visible).length === 0) {
                return acc;
            }
            return [
                ...acc,
                [
                    _jsx(FlexSpan, { fontWeight: 600, style: { position: "relative", top: "0.75rem" }, children: key }),
                    ...Array(5).fill(""),
                ],
                ...value.filter(item => item.visible).map((item, itemIndex) => ([
                    _jsx(CompareTableParamLabel, { children: _jsx(Tooltip, { content: item.description, children: _jsx(EllipsisText, { lines: 1, children: item.name }) }) }),
                    ...businessAreas.map(location => {
                        const paramRender = AREA_COMPARE_PARAM_RENDERS[item.key]?.(location.details);
                        const allRowRenders = businessAreas.reduce((maxAcc, maxCurr) => ([
                            ...maxAcc,
                            AREA_COMPARE_PARAM_RENDERS[item.key]?.(maxCurr.details),
                        ]), []);
                        const isNumberParam = typeof paramRender === "number";
                        return (_jsx(CompareTableParamValue, { isMax: (isNumberParam
                                && businessAreas.length > 1
                                && allRowRenders.filter(num => num === Math.max(...allRowRenders)).length === 1
                                && paramRender === Math.max(...allRowRenders)), children: _jsxs(Flex, { justifyContent: "center", gap: "0.25rem", children: [_jsx(FlexSpan, { children: isNumberParam
                                            ? formatNumber(paramRender, false) === ""
                                                ? "Нет данных"
                                                : formatNumber(paramRender, false)
                                            : paramRender ?? "Нет данных" }), isNumberParam && Array.isArray(item.subKeys) && paramRender !== 0 && (_jsx(FlexSpan, { children: _jsx(CompareTableParamValueIconContainer, { children: _jsx(Tooltip, { content: (_jsxs(Flex, { gap: "0.5rem", children: [_jsx(FlexSpan, { mr: "0.5rem", children: _jsx(Flex, { column: true, gap: "0.5rem", children: item.subKeys.map((subKey, subKeyIndex) => (_jsx(FlexSpan, { color: subKey.color, height: "1.125rem", fontSize: "0.75rem", lineHeight: "150%", children: _jsx(EllipsisText, { lines: 1, children: subKey.name }) }, `compare-table-data-row-${item.key}-col-${itemIndex}-subkey-${subKeyIndex}-1`))) }) }), _jsx(FlexSpan, { children: _jsx(Flex, { column: true, gap: "0.5rem", children: item.subKeys.map((subKey, subKeyIndex) => (_jsx(FlexSpan, { height: "1.125rem", display: "flex", justifyContent: "right", alignItems: "center", children: _jsx(CompareTableParamValueSubKeyBar, { color: subKey.color, value: ((AREA_COMPARE_PARAM_RENDERS[subKey.key]?.(location.details) ?? 0) * 100) / paramRender }) }, `compare-table-data-row-${item.key}-col-${itemIndex}-subkey-${subKeyIndex}-2`))) }) }), _jsx(FlexSpan, { children: _jsx(Flex, { column: true, gap: "0.5rem", children: item.subKeys.map((subKey, subKeyIndex) => (_jsx(FlexSpan, { height: "1.125rem", display: "flex", justifyContent: "right", lineHeight: "1.25rem", children: formatNumber(AREA_COMPARE_PARAM_RENDERS[subKey.key]?.(location.details)) }, `compare-table-data-row-${item.key}-col-${itemIndex}-subkey-${subKeyIndex}-3`))) }) })] })), children: _jsx(Icon, { icon: "users" }) }) }) }))] }) }, `compare-table-data-row-${item.key}-col-${itemIndex}`));
                    }),
                    ...Array(5 - businessAreas.length).fill(""),
                ])),
            ];
        }, []);
    }, [businessAreas, areaCompareParams]);
};
