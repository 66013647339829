import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PointDetailsBadgesContainer } from "./styled";
import { PointDetailsBlock } from "../../PointDetailsBlock";
import { PointDetailsBadge } from "../../PointDetailsBadge";
import { getTransportIcon } from "./utils";
export const TransportBlock = ({ data }) => {
    return (_jsx(_Fragment, { children: (data.analytic.trans_distribution.length > 0 || (data.analytic.trans_parking_count !== undefined || data.analytic.trans_parking_capacity !== undefined)) && (_jsx(PointDetailsBlock, { title: "\u0422\u0430\u043D\u0441\u043F\u043E\u0440\u0442 \u0438 \u043F\u0430\u0440\u043A\u043E\u0432\u043A\u0438", icon: "transport", children: _jsxs(PointDetailsBadgesContainer, { children: [data.analytic.trans_distribution.map((item, itemIndex) => {
                        const transportIcon = getTransportIcon(item.type);
                        return (_jsx(PointDetailsBadge, { width: "calc(50% - 0.5rem)", title: item.type, icon: transportIcon.icon, iconColor: transportIcon.color, listItems: item.route_count !== undefined ? [
                                { label: "Маршрутов", value: item.route_count },
                                { label: "Остановок", value: item.count },
                            ] : undefined }, `transport-badge-${itemIndex}`));
                    }), (data.analytic.trans_parking_count !== undefined || data.analytic.trans_parking_capacity !== undefined) && (_jsx(PointDetailsBadge, { title: "\u041F\u0430\u0440\u043A\u043E\u0432\u043A\u0438", icon: getTransportIcon("Парковки").icon, iconColor: getTransportIcon("Парковки").color, width: "calc(50% - 0.5rem)", listItems: [
                            { label: "Количество", value: data.analytic.trans_parking_count ?? 0 },
                            { label: "Вместимость", value: data.analytic.trans_parking_capacity ?? 0 },
                        ] }))] }) })) }));
};
