import styled from "styled-components";
import { Flex, FlexSpan, H4 } from "../../../ui";
import { Icon } from "../../../ui/Icon/styled";
export const ParamsSection = styled(Flex) `
  gap: 1rem;
  flex-direction: column;
  border-radius: 0.5rem;
  background: #F2F3F4;
  padding: 1rem 1.25rem;
`;
export const ParamsSectionHead = styled(FlexSpan) `
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  ${H4} {
    cursor: pointer;
    user-select: none;
  }

  ${Icon} {
    color: #000000;
  }
`;
export const ParamsSectionBody = styled(FlexSpan) `
  display: ${({ open }) => open ? "contents" : "none"};
  padding: 0 0.75rem;
`;
