import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMeasurerActive } from "../../selectors/map";
import { setMeasurerActive } from "../../slices/map";
export const useMeasurerActive = () => {
    const dispatch = useDispatch();
    const measurerActive = useSelector(getMeasurerActive);
    const update = useCallback((value) => {
        dispatch(setMeasurerActive(value));
    }, [dispatch]);
    return [measurerActive, update];
};
