import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { TableRowMenuContainer } from "./styled";
import { FlexSpan, Icon, Menu, Popover } from "../../ui";
export const TableRowMenu = ({ items = [], onPick, top, left, menuOffsetX, menuOffsetY, menuWidth, }) => {
    const [isActive, setIsActive] = useState(false);
    return (_jsx(Popover, { isOpen: isActive, containerStyle: { zIndex: 999 }, onOpen: () => setIsActive(true), onClose: () => setIsActive(false), offsetX: menuOffsetX ?? 4, offsetY: menuOffsetY ?? 4, placement: "bottom-left", anchor: (_jsx(TableRowMenuContainer, { active: isActive, top: top, left: left, children: _jsx(FlexSpan, { children: _jsx(Icon, { icon: "more_ver" }) }) })), children: _jsx(Menu, { items: items, onPick: option => {
                onPick && onPick(option);
                setIsActive(false);
            }, width: menuWidth ?? "13rem" }) }));
};
