import styled from "styled-components";
import { Flex } from "../../../ui";
export const BusinessSelectPane = styled(Flex) `
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  
  &:hover {
    color: #9D87F1;
  }
`;
