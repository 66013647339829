import styled from "styled-components";
import Tippy from "@tippyjs/react";
import { SCREEN_WIDTH_DESKTOP } from "../../constants/layout";
export const StyledTippy = styled(Tippy) `
  background: #ffffff;
  border-radius: 0.25rem;
  box-shadow: 0 0 1rem 0 rgba(63, 67, 106, 0.12);
  color: #000000;
  padding: 1.25rem;
  font-size: 0.875rem;

  @media screen and (max-width: ${SCREEN_WIDTH_DESKTOP}px) {
    padding: 1rem;
  }

  &[data-placement^="top"] > .tippy-arrow::before {
    display: none;
  }

  &.tippy-box[data-placement^="top"] > .tippy-arrow {
    left: calc(50% - 0.3125rem) !important;
    bottom: 0;
    transform: translateY(100%) !important;
    border-left: 0.375rem solid transparent;
    border-right: 0.375rem solid transparent;
    border-top: 0.375rem solid #ffffff;
    ${({ arrowMixin }) => arrowMixin}
  }

  &[data-placement^="bottom"] > .tippy-arrow::before {
    display: none;
  }

  &.tippy-box[data-placement^="bottom"] > .tippy-arrow {
    top: -0.375rem;
    border-left: 0.375rem solid transparent;
    border-right: 0.375rem solid transparent;
    border-bottom: 0.375rem solid #ffffff;
    ${({ arrowMixin }) => arrowMixin}
  }

  &[data-placement^="left"] > .tippy-arrow::before {
    display: none;
  }

  &.tippy-box[data-placement^="left"] > .tippy-arrow {
    border-top: 0.375rem solid transparent;
    border-bottom: 0.375rem solid transparent;
    border-left: 0.375rem solid #ffffff;
    right: -0.375rem;
    ${({ arrowMixin }) => arrowMixin}
  }

  &[data-placement^="right"] > .tippy-arrow::before {
    display: none;
  }

  &.tippy-box[data-placement^="right"] > .tippy-arrow {
    border-top: 0.375rem solid transparent;
    border-bottom: 0.375rem solid transparent;
    border-right: 0.375rem solid #ffffff;
    left: -0.375rem;
    ${({ arrowMixin }) => arrowMixin}
  }

  .tippy-arrow {
    width: 0;
    height: 0;
  }

  .tippy-content {
    padding: 0;
  }
`;
