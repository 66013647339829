import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { addMonths } from "date-fns";
import { EllipsisText, Flex, FlexSpan, H5, Table, Tooltip } from "../../ui";
import { TableHeaderContainer, TableHeaderSubtitle, TableStickyBodyCell } from "./styled";
import { TableMonthCell } from "./TableMonthCell";
import { TableRowMenu } from "../TableRowMenu";
import { MONTH_SHORT_NAMES } from "../../constants/common";
import { getDateObject } from "../../utils";
export const ParamsTable = ({ width, startDate, monthsCount, columns = [], rows = [], headCellHeight, bodyCellHeight, rowMenuItems, onPickMenuItem, }) => {
    const [rowHover, setRowHover] = useState();
    const commonColumnProps = useCallback((col, colIndex) => {
        return ({
            bodyCellStyle: colIndex === 0 ? { borderRight: "1px solid #D2D2D2" } : undefined,
            renderHead: column => (_jsxs(TableHeaderContainer, { width: column.width, align: col.align, mb: "0.125rem", children: [_jsx(H5, { children: column.title }), column.subtitle && _jsx(TableHeaderSubtitle, { children: column.subtitle })] })),
            renderBody: (row, rowId) => (_jsx(_Fragment, { children: _jsxs(TableStickyBodyCell, { align: col.align, firstRow: rowId === 0, lastRow: rowId === rows.length - 1, firstCol: colIndex === 0, lastCol: colIndex === columns.length - 1, onClick: e => row.onColumnClick?.(colIndex, e), style: (colIndex === 0
                        ? {
                            ...col.bodyCellStyle,
                            borderRight: "1px solid #D2D2D2",
                            padding: rowMenuItems !== undefined ? "0.25rem" : undefined,
                            marginLeft: rowMenuItems !== undefined ? "0.125rem" : undefined,
                            width: rowMenuItems !== undefined ? "calc(100% - 0.125rem)" : undefined,
                            position: rowMenuItems !== undefined ? "relative" : undefined,
                            left: rowMenuItems !== undefined ? "0.125rem" : undefined,
                        }
                        : undefined), isEditing: col.onEditCheck && col.onEditCheck(rowId), colIndex: colIndex, withBackground: colIndex === 0, onMouseOver: () => colIndex === 0 && rowHover === undefined && setRowHover(rowId), onMouseLeave: () => setRowHover(undefined), children: [rowMenuItems !== undefined && colIndex === 0 && rowHover === rowId && (_jsx(TableRowMenu, { top: "0.25rem", left: "-0.575rem", items: rowMenuItems, onPick: value => onPickMenuItem(value, rowId) })), col.formatter
                            ? col.formatter(row, rowId)
                            : (_jsx(Tooltip, { content: row.data[col.id], delay: [1000, 0], wrapperHeight: "100%", children: _jsx(Flex, { width: "100%", height: "100%", alignItems: "center", p: rowMenuItems !== undefined ? "0 0.5rem" : undefined, children: _jsx(FlexSpan, { width: "100%", children: _jsx(EllipsisText, { children: row.data[col.id] }) }) }) }))] }) })),
        });
    }, [columns, rows, rowMenuItems, rowHover]);
    return (_jsx(Table, { width: width, headCellHeight: headCellHeight ?? "5rem", bodyCellHeight: bodyCellHeight ?? "3rem", stickyFirstColumn: columns.length > 0, cellGap: 0, columns: [
            ...columns.map((column, columnIndex) => ({
                ...column,
                ...commonColumnProps(column, columnIndex),
            })),
            ...Array(monthsCount >= 0 ? monthsCount : 0).fill(null).map((_, monthIndex) => {
                const currDate = addMonths(getDateObject(startDate), monthIndex);
                return {
                    title: MONTH_SHORT_NAMES[currDate.getMonth()],
                    subtitle: currDate.getFullYear(),
                    align: "center",
                    width: "4.25rem",
                    renderHead: column => (_jsxs(TableHeaderContainer, { align: "center", width: "4.25rem", children: [_jsx(H5, { children: column.title }), column.subtitle && _jsx(TableHeaderSubtitle, { children: column.subtitle })] })),
                    renderBody: row => (_jsx(TableMonthCell, { width: "4.25rem", active: row.onActiveCheck?.(monthIndex), disabled: row.onDisabledCheck?.(monthIndex), onClick: e => row.onMonthClick?.(monthIndex, e) })),
                };
            }),
        ], rows: rows }));
};
