import styled from "styled-components";
import { flexbox, layout, space, textAlign } from "styled-system";
const setWidth = (width) => typeof width === "number" ? `${width}px` : typeof width === "undefined" ? "100%" : width;
export const Card = styled.span `
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.5rem;
  background: ${({ background }) => background ?? "#ffffff"};
  box-shadow: ${({ flat }) => !flat && "0 0 1rem 0 rgba(63, 67, 106, 0.12)"};
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  width: ${({ width }) => setWidth(width)};
  ${flexbox}
  ${space}
  ${layout}
  ${textAlign}
`;
