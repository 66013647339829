import styled from "styled-components";
import { space } from "styled-system";
import { Flex, H2 } from "../../../ui";
export const LargeValue = styled(H2) `
  font-weight: 600;
`;
export const Label = styled.div `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #666666;
`;
export const Subtitle = styled(Label) `
  margin-top: 0.25rem;
`;
export const PointDetailsBadgesContainer = styled(Flex) `
  flex-wrap: wrap;
  gap: 1rem;
`;
export const DetailsInnerSection = styled.div `
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${space}
`;
export const Address = styled.div `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #9F9F9F;
`;
