import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Flex, FlexSpan } from "../../../../ui";
import { Circle, SvgArea, SvgAreaPath } from "./styled";
import { PointDetailsBlock } from "../../../../components/PointDetailsBlock";
import { PromAnalyticBarChart } from "./PromAnalyticBarChart";
import { getSupplyData, getSupplyMinMax } from "./utils";
const CHART_ITEMS = {
    consumption: {
        title: "Потребление",
        color: "",
    },
    in: {
        title: "Ввоз",
        color: "#F6C544",
    },
    export: {
        title: "Экспорт",
        color: "#666666",
    },
    import: {
        title: "Импорт",
        color: "#23CAE0",
    },
    production: {
        title: "Производство",
        color: "#9D87F1",
    },
    out: {
        title: "Вывоз",
        color: "#9F9F9F",
    },
};
export const SupplyBlock = ({ data }) => {
    const [actual, forecast] = getSupplyData(data?.analytic);
    const [minValue, maxValue] = getSupplyMinMax(data?.analytic);
    const customXAxisBottomActual = useCallback(axisBottom => {
        axisBottom.tickFormat((index, _, texts) => index === 0 || texts.length - 1 === index
            ? data?.analytic?.supply.labels[index].replace(/[^0-9]/, "")
            : "");
    }, [data]);
    const customXAxisBottomForecast = useCallback(axisBottom => {
        axisBottom.tickFormat((index, _, texts) => index === 0 || texts.length - 1 === index
            ? index === 0 ? "прогноз" : data?.analytic?.supply.labels[actual.length + index].replace(/[^0-9]/, "")
            : "");
    }, [data, actual]);
    if (!data?.analytic?.supply) {
        return null;
    }
    return (_jsxs(PointDetailsBlock, { title: "\u0421\u043F\u0440\u043E\u0441 \u0438 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0435", icon: "feedback", children: [_jsx(Flex, { column: true, children: _jsx(FlexSpan, { width: "100%", mb: "1rem", children: _jsx(Flex, { flexWrap: "wrap", children: Object.keys(CHART_ITEMS).map((name, index) => (_jsx(FlexSpan, { width: index === 2 || index === 5 ? "26%" : "37%", children: _jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { mr: "0.125rem", children: name === "consumption" ? (_jsx(SvgArea, { children: _jsx(SvgAreaPath, {}) })) : (_jsx(Circle, { style: { backgroundColor: CHART_ITEMS[name].color } })) }), _jsx(FlexSpan, { color: "#666666", fontSize: "0.75rem", flexGrow: 1, pr: "1rem", children: CHART_ITEMS[name].title })] }) }, `supply-legend-item-${name}`))) }) }) }), _jsxs(Flex, { width: "100%", justifyContent: "space-between", gap: "1rem", children: [_jsx(FlexSpan, { width: "calc(35% - 0.5rem)", children: _jsx(PromAnalyticBarChart, { height: 160, barWidth: 6, dynamicTooltipEnable: true, data: actual, lineData: [
                                {
                                    lineType: "area",
                                    name: "consumption",
                                    fill: "rgba(77, 174, 248, 0.26)",
                                    values: data?.analytic?.supply.consumption.slice(0, actual.length),
                                },
                            ], colors: Object.entries(CHART_ITEMS).reduce((acc, [key, value]) => ({ ...acc, [key]: value.color }), {}), customXAxisBottom: customXAxisBottomActual, minValue: minValue, maxValue: maxValue, formatTooltipName: name => CHART_ITEMS[name].title }) }), _jsx(FlexSpan, { width: "calc(65% - 0.5rem)", children: _jsx(PromAnalyticBarChart, { height: 160, barWidth: 6, dynamicTooltipEnable: true, data: forecast, lineData: [
                                {
                                    lineType: "area",
                                    name: "consumption",
                                    fill: "rgba(77, 174, 248, 0.26)",
                                    values: data?.analytic?.supply.consumption.slice(actual.length),
                                },
                            ], colors: Object.entries(CHART_ITEMS).reduce((acc, [key, value]) => ({ ...acc, [key]: value.color }), {}), customXAxisBottom: customXAxisBottomForecast, minValue: minValue, maxValue: maxValue, formatTooltipName: name => CHART_ITEMS[name].title }) })] })] }));
};
