import styled from "styled-components";
import { Flex } from "../../ui";
export const TableHeaderContainer = styled(Flex) `
  flex-direction: column;
  width: calc(${({ width }) => width ?? "4rem"} - 0.25rem);
  min-width: calc(${({ width }) => width ?? "4rem"} - 0.25rem);
  margin-left: 0.125rem;
  height: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: rgba(84, 173, 255, 0.20);
  align-items: ${({ align }) => align === undefined || align === "left"
    ? "start"
    : align === "center"
        ? "center"
        : "end"};
  text-align: ${({ align }) => align};
`;
export const TableHeaderSubtitle = styled.div `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-top: 0.5rem;
`;
export const TableStickyBodyCell = styled(Flex) `
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-top: 1px solid #D2D2D2;
  border-right: ${({ lastCol }) => lastCol && "1px solid #D2D2D2"};
  border-bottom: ${({ lastRow }) => lastRow && "1px solid #D2D2D2"};
  border-left: ${({ colIndex }) => colIndex !== 1 && "1px solid #D2D2D2"};
  box-sizing: border-box;
  background-color: ${({ withBackground }) => withBackground && "#F9F9F9"};
  padding: ${({ isEditing, firstCol }) => isEditing ? "0.25rem" : firstCol ? "1rem 0.75rem" : "1rem"};
  margin-left: ${({ firstCol }) => firstCol && "0.25rem"};
  border-top-left-radius: ${({ firstRow, firstCol }) => firstRow && firstCol && "0.5rem"};
  border-bottom-left-radius: ${({ lastRow, firstCol }) => lastRow && firstCol && "0.5rem"};
  border-top-right-radius: ${({ firstRow, lastCol }) => firstRow && lastCol && "0.5rem"};
  border-bottom-right-radius: ${({ lastRow, lastCol }) => lastRow && lastCol && "0.5rem"};
  align-items: ${({ align }) => align === undefined || align === "left"
    ? "start"
    : align === "center"
        ? "center"
        : "end"};
  text-align: ${({ align }) => align};
`;
export const TableMonthIconContainer = styled(Flex) `
  position: relative;
  align-items: center;
  justify-content: center;
  opacity: 0;
  width: 1rem;
  height: 1rem;
  background-color: #FFFFFF;
  color: #9D87F1;
  border-radius: 1rem;
`;
export const TableMonthCellContainer = styled(Flex) `
  align-items: center;
  justify-content: center;
  background-color: ${({ active }) => active ? "#9D87F1" : "#F0F1F3"};
  height: 100%;
  margin: 0.25rem;
  border-radius: 0.5rem;
  width: calc(${({ width }) => width ?? "4rem"} - 0.25rem);
  min-width: calc(${({ width }) => width ?? "4rem"} - 0.25rem);
  cursor: pointer;
  pointer-events: ${({ disabled }) => disabled && "none"};

  ${TableMonthIconContainer} {
    opacity: ${({ active }) => active && 1};
  }
  
  &:hover {
    background-color: ${({ active }) => active && "#7E6CC1"};
    
    ${TableMonthIconContainer} {
      opacity: 1;
      color: ${({ active }) => active ? "#7E6CC1" : "#FFFFFF"};
      background-color: ${({ active }) => !active && "#9F9F9F"};
    }
  }
`;
