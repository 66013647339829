import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Flex, FlexSpan } from "../ui/Grid";
import { formatNumber } from "../utils";
export const useRenderTooltip = () => {
    return useCallback((bars = [], options) => {
        return (_jsx(Flex, { column: true, gap: "0.5rem", children: bars.filter((_, barIndex) => !(options?.excludedBars ?? []).includes(barIndex)).map(bar => {
                return (_jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { alignItems: "baseline", gap: "0.5rem", fontSize: "0.75rem", fontWeight: 400, children: [_jsx(FlexSpan, { display: "flex", children: _jsx(Flex, { width: "0.75rem", height: "0.75rem", borderRadius: "0.125rem", backgroundColor: bar.color, style: { position: "relative", top: "0.0625rem" } }) }), _jsx(FlexSpan, { flexGrow: 1, mr: "0.75rem", children: _jsxs(Flex, { column: true, gap: "0.25rem", children: [_jsx(FlexSpan, { children: bar.name }), bar.groupName ? _jsx(FlexSpan, { children: bar.groupName }) : null] }) }), _jsxs(FlexSpan, { fontWeight: 500, style: { ...options?.valueStyle, whiteSpace: "nowrap" }, children: [options?.formatValue ? options.formatValue(bar.value) : formatNumber(bar.value), options?.unit !== undefined
                                        ? _jsx(FlexSpan, { fontWeight: 400, ml: "0.25rem", children: options.unit })
                                        : null] })] }) }));
            }) }));
    }, []);
};
