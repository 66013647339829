import styled, { css } from "styled-components";
import Cleave from "cleave.js/react";
import { Flex } from "../Grid";
const hideArrowsMixin = css `
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const InputWrapperContainer = styled.span `
  position: relative;
  display: inline-block;
  width: ${({ width }) => width || "100%"};
`;
export const InputContainer = styled.div `
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: ${({ disabled, noDisabledBackground }) => disabled && !noDisabledBackground ? "#F3F3F3" : "transparent"};
  border: 1px solid ${({ isFocused, error }) => isFocused ? "#9D87F1" : error ? "#F1274C" : "#E3E3E3"};
  border-radius: 0.5rem;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "2.75rem"};
  outline: ${({ isFocused }) => isFocused ? "4px solid #CEC3F8" : undefined};

  &:hover {
    border-color: ${({ disabled, error }) => !(disabled || error) && "#9F9F9F"};
  }

  ${({ hideArrows }) => hideArrows && hideArrowsMixin}
`;
export const InputPrefix = styled.div `
  display: inline-flex;
  padding: 0 0 0 1rem;
`;
export const InputSuffix = styled.div `
  display: inline-flex;
  padding: 0 0.5rem 0 0;
`;
export const InputField = styled.input `
  width: 100%;
  border: none;
  outline: none;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${({ error }) => error ? "#F1274C" : "#000000"};
  padding: 0 1rem;
  background-color: transparent;
  
  &[disabled] {
    color: #9F9F9F;
  }

  &::placeholder {
    color: ${({ error }) => error ? "#F1274C" : "#9F9F9F"};
  }
`;
export const StyledCleave = styled(Cleave) `
  width: 100%;
  border: none;
  outline: none;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${({ error }) => error ? "#F1274C" : "#000000"};
  padding: 0 1rem;
  background-color: transparent;

  &[disabled] {
    color: #9F9F9F;
  }

  &::placeholder {
    color: ${({ error }) => error ? "#F1274C" : "#9F9F9F"};
  }
`;
export const InputLabel = styled(Flex) `
  align-items: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #9F9F9F;
  margin-bottom: 0.5rem;
`;
export const InputErrorMessage = styled.div `
  position: absolute;
  color: #F1274C;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-top: 0.25rem;
`;
export const SuffixContainer = styled.div `
  display: flex;
`;
export const AutocompleteContainer = styled.div `
  position: relative;
  width: 100%;

  ${InputContainer} {
    color: ${({ theme, darkTheme }) => darkTheme && theme.colors.white};
    border-color: ${({ theme, darkTheme }) => darkTheme && theme.colors.green};

    ${InputField} {
      color: ${({ theme, darkTheme }) => darkTheme && theme.colors.white};

      &::placeholder {
        color: ${({ darkTheme }) => darkTheme && "rgba(255,255,255, 0.54)"}};
      }
    }
  }
`;
export const AutocompleteMenu = styled.div `
  position: absolute;
  left: 0;
  width: 100%;
  background-color: ${({ theme, darkTheme }) => (darkTheme ? theme.colors.darkGray : theme.colors.white)};
  box-shadow: 0 0.1875rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
  z-index: 99;
  padding: 0.5rem 0;
  box-sizing: border-box;
  user-select: none;

  ${({ position }) => !position || position === "bottom"
    ? css `
          top: 2.25rem;
        `
    : css `
          bottom: 2.25rem;
        `}
`;
export const AutocompleteMenuItem = styled.div `
  padding: 0 1rem;
  margin: 0.5rem 0;
  max-width: 100%;
  white-space: normal;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  color: ${({ theme, darkTheme }) => darkTheme && theme.colors.white};

  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;
export const InputSliderContainer = styled.div `
  display: inline-block;
  width: 100%;
  
  ${".rc-slider-mark"} {
    & > ${".rc-slider-mark-text"} {
      &:first-child {
        transform: translateX(0%) !important;
      }

      &:last-child {
        position: absolute;
        transform: translateX(-100%) !important;
        white-space: nowrap;
      }
    }
  }
`;
export const InputMarksContainer = styled(Flex) `
  position: absolute;
  bottom: -1.625rem;
  justify-content: space-between;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #9F9F9F;
  padding: 0 0.5rem;
`;
