import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines, max-lines-per-function */
import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FeatureLayer, SrId, useMapView } from "@evergis/react";
import { isNil } from "lodash";
import { GeometryType } from "@evergis/api";
import { Layout } from "../../../components/Layout";
import { FlexSpan, Icon, PrimaryButton } from "../../../ui";
import { Stepper } from "../../../components/Stepper";
import { PointLocationPlaceholder } from "../../../components/PointLocationPlaceholder";
import { PointDetails } from "./PointDetails";
import { MapContainer, PickPointContainer } from "./styled";
import { Map } from "../../../components/Map";
import { PointCard } from "../../../components/PointCard";
import { FloatLeftPanel } from "../../../components/FloatLeftPanel";
import { EnterpriseBusinessUrl, EnterpriseLocationUrl } from "../../../constants/routes";
import { DIALOGS } from "../../../constants/dialogs";
import { useCurrentSubject, useDialog, useIsDesktop, useOverlayFeature, usePromBusinessItems, useUpdatedLocationParams, useUser, } from "../../../hooks";
import { getPointFeature, getDynamicPointFeature, getPolygonFeature } from "../../../utils";
import { api } from "../../../api";
import { AppSection } from "../../../slices/app/types";
export const EnterpriseLocationPage = () => {
    const isDesktop = useIsDesktop();
    const navigate = useNavigate();
    const mapView = useMapView();
    const [user] = useUser();
    const [searchParams, setSearchParams] = useSearchParams();
    const openDialog = useDialog();
    const [overlayFeature, setOverlayFeature] = useOverlayFeature();
    const [businessItems] = usePromBusinessItems();
    const { businessId, conceptId, subjectId } = useParams();
    const businessConcept = useMemo(() => {
        return businessItems?.find(item => item.id === Number(businessId))?.business_concepts?.find(concept => concept.id === Number(conceptId));
    }, [businessId, businessItems, conceptId]);
    const [currentSubject] = useCurrentSubject();
    const [updatedParams, setUpdatedParams] = useUpdatedLocationParams();
    const urlObjectId = useMemo(() => searchParams.get("objectId"), [searchParams]);
    const urlFormatId = useMemo(() => searchParams.get("formatId"), [searchParams]);
    const [objectId, setObjectId] = useState(!isNil(urlObjectId) ? JSON.parse(urlObjectId) : null);
    const [formatId, setFormatId] = useState(!isNil(urlFormatId) ? +urlFormatId : null);
    const [details, setDetails] = useState();
    const [loading, setLoading] = useState(true);
    const [service, setService] = useState();
    const businessPlanAvailable = useMemo(() => details && details.statistic.businessplans.length > 0, [details]);
    const currentObjectFeature = useMemo(() => {
        const feature = service?.features.features.find(item => item.id === objectId);
        const featureStyle = service?.styles.find(style => style.styleId === feature?.properties.styleId);
        return objectId && details
            ? getPointFeature(details.statistic.target.feature.geometry.coordinates, featureStyle?.iconSize?.[0])
            : undefined;
    }, [details, objectId, service, mapView.level, mapView.position?.[0], mapView.position?.[1], mapView.resolution]); // eslint-disable-line
    const servicesFeatures = useMemo(() => {
        return service?.features.features.reduce((accFeatures, currFeature) => {
            const featureStyle = service.styles.find(style => style.styleId === currFeature.properties.styleId);
            return [
                ...accFeatures,
                getDynamicPointFeature(currFeature, featureStyle?.iconSize, featureStyle?.iconAnchor, `${process.env.API_URL || ""}${featureStyle?.iconUrl}`, feature => {
                    setObjectId(feature.id);
                }),
            ];
        }, []) ?? [];
    }, [service]);
    const onCreateBusinessPlan = useCallback(() => {
        openDialog(DIALOGS.BEFORE_PROM_BUSINESS_PLAN_DIALOG, {
            subjectId,
            businessId,
            conceptId,
            formatId,
            objectId,
        });
    }, [openDialog, subjectId, businessId, conceptId, formatId, objectId]);
    const onDeleteCard = useCallback(() => {
        setObjectId(null);
        setDetails(null);
        setSearchParams({});
    }, []); // eslint-disable-line
    const fetchData = useCallback(async () => {
        if (!objectId || !formatId) {
            return;
        }
        setLoading(true);
        let newDetails = {};
        try {
            const statistic = await api.statistic.getPromStatistic(formatId, +objectId, user.guid);
            newDetails = {
                ...newDetails,
                statistic,
            };
        }
        catch {
            // ...
        }
        try {
            const analytic = await api.analytic.getPromAnalytic(formatId, +objectId, user.guid);
            newDetails = {
                ...newDetails,
                analytic,
            };
        }
        catch {
            // ...
        }
        setDetails(newDetails);
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            objectId,
            formatId: formatId?.toString(),
        });
        setLoading(false);
    }, [objectId, formatId, searchParams, user]); // eslint-disable-line
    const fetchLegend = useCallback(outerCityId => {
        if (!formatId) {
            return;
        }
        api.service.getPromObjectService(outerCityId, formatId, undefined, user.guid).then(setService);
    }, [formatId, user]); // eslint-disable-line
    useEffect(() => {
        if (!businessConcept || urlFormatId) {
            return;
        }
        setFormatId(businessConcept.default_format ?? businessConcept.business_formats?.[0]?.id);
    }, [businessConcept]); // eslint-disable-line
    useEffect(() => {
        if (!currentSubject || !businessConcept) {
            return;
        }
        if (+subjectId !== currentSubject.id) {
            setObjectId(null);
            setDetails(null);
            setSearchParams({});
            navigate(generatePath(EnterpriseLocationUrl.path, { cityId: currentSubject.id, businessId, conceptId }), { replace: true });
        }
    }, [currentSubject]); // eslint-disable-line
    useEffect(() => {
        if (updatedParams.formatId) {
            setFormatId(!isNil(urlFormatId) ? +urlFormatId : null);
            setObjectId(null);
            setDetails(null);
            setUpdatedParams({ formatId: false });
        }
    }, [updatedParams]); // eslint-disable-line
    useEffect(() => {
        if (!subjectId) {
            return;
        }
        fetchLegend(subjectId);
    }, [subjectId, formatId]); // eslint-disable-line
    useEffect(() => {
        if (!objectId || !formatId || !businessConcept || !subjectId) {
            return;
        }
        fetchData();
    }, [objectId, formatId]); // eslint-disable-line
    useEffect(() => {
        if (urlFormatId && subjectId && businessId && conceptId) {
            fetchData();
            fetchLegend(subjectId);
        }
        return () => {
            setOverlayFeature(undefined);
        };
    }, []); // eslint-disable-line
    return (_jsx(Layout, { section: AppSection.Enterprise, noPadding: !isDesktop, leftPanel: (_jsxs(FloatLeftPanel, { isMaxHeight: !!details, children: [_jsx(FlexSpan, { children: _jsx(Stepper, { hideButtons: true, activeStep: 1, steps: [
                            { title: "Выбор бизнеса", onPick: () => navigate(EnterpriseBusinessUrl.path) },
                            { title: "Выбор площадки" },
                            { title: "Бизнес-план", onPick: businessPlanAvailable ? onCreateBusinessPlan : undefined },
                        ] }) }), _jsx(FlexSpan, { mt: "1rem", children: _jsx(PointCard, { section: AppSection.Enterprise, formatId: formatId, setFormatId: setFormatId, size: formatId, businessConcept: businessConcept, details: details, icon: businessItems?.find(item => item.id === Number(businessId))?.ico, onDelete: onDeleteCard }) }), _jsxs(FlexSpan, { flexGrow: 1, display: (!objectId || loading) ? "flex" : undefined, alignItems: (!objectId || loading) && "center", justifyContent: (!objectId || loading) && "center", overflow: "hidden", children: [!details && (_jsxs(PointLocationPlaceholder, { hidden: !!objectId && !loading, loading: !!objectId && loading, children: ["\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043F\u0440\u043E\u0438\u0437\u0432\u043E\u0434\u0441\u0442\u0432\u0435\u043D\u043D\u0443\u044E", _jsx("br", {}), "\u043F\u043B\u043E\u0449\u0430\u0434\u043A\u0443 \u0434\u043B\u044F \u0432\u0430\u0448\u0435\u0433\u043E \u0431\u0438\u0437\u043D\u0435\u0441\u0430"] })), details && _jsx(PointDetails, { data: details })] }), details && (_jsx(FlexSpan, { display: "flex", justifyContent: "center", mb: "0.25rem", children: _jsx(PrimaryButton, { width: "calc(100% - 0.5rem)", disabled: loading || !businessPlanAvailable, onClick: onCreateBusinessPlan, icon: "metrics", buttonSize: isDesktop ? "xl" : "s", disabledTooltip: !businessPlanAvailable
                            ? "Срок окупаемости вложений в бизнес по выбранному типовому формату" +
                                " превышает 15 лет в связи со значительными инвестициями и текущими затратами." +
                                " Рекомендуем выбрать другой типовой формат, другой вид бизнеса или другую площадку."
                            : undefined, children: "\u0420\u0430\u0441\u0441\u0447\u0438\u0442\u0430\u0442\u044C \u0431\u0438\u0437\u043D\u0435\u0441-\u043F\u043B\u0430\u043D" }) }))] })), children: _jsxs(MapContainer, { children: [_jsxs(Map, { width: "100%", height: "100%", 
                    // @ts-ignore
                    extent: overlayFeature ? {
                        ...overlayFeature.geometry,
                        coordinates: (overlayFeature.geometry.type === "MultiPolygon"
                            ? [...(overlayFeature?.geometry).coordinates[0] ?? []]
                            : [...(overlayFeature?.geometry).coordinates ?? []]),
                        type: GeometryType.Polygon,
                        sr: SrId.Wgs84,
                    } : currentSubject ? {
                        ...currentSubject.geometry,
                        coordinates: [[currentSubject.bbox[1], currentSubject.bbox[0]], [currentSubject.bbox[3], currentSubject.bbox[2]]],
                        type: GeometryType.Polygon,
                    } : undefined, children: [overlayFeature && (_jsx(FeatureLayer, { features: overlayFeature.geometry.coordinates?.map(item => {
                                return getPolygonFeature(item, {
                                    fillColor: "rgba(126, 108, 193, 0.1)",
                                    strokeColor: "rgba(126, 108, 193, 0.5)",
                                    strokeWidth: 4,
                                    lineDash: [4, 8],
                                });
                            }), zIndex: 1 })), _jsx(FeatureLayer, { features: currentSubject?.geometry.coordinates?.map(item => getPolygonFeature(item)), zIndex: 2 }), _jsx(FeatureLayer, { features: servicesFeatures, zIndex: 3 }), _jsx(FeatureLayer, { features: [currentObjectFeature].filter(Boolean), zIndex: 4 })] }), !objectId && (_jsxs(PickPointContainer, { children: [_jsx(FlexSpan, { mr: "0.75rem", children: _jsx(Icon, { icon: "location" }) }), _jsx(FlexSpan, { children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043F\u0440\u043E\u0438\u0437\u0432\u043E\u0434\u0441\u0442\u0432\u0435\u043D\u043D\u0443\u044E \u043F\u043B\u043E\u0449\u0430\u0434\u043A\u0443 \u0434\u043B\u044F \u0432\u0430\u0448\u0435\u0433\u043E \u0431\u0438\u0437\u043D\u0435\u0441\u0430" })] }))] }) }));
};
