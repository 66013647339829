import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { BusinessRetailDescription, BusinessRetailItem, BusinessRetailListContainer } from "./styled";
import { FlexSpan, H4 } from "../../../ui";
import { CategoryIcon } from "../../../components/CategoryIcon";
import { DIALOGS } from "../../../constants/dialogs";
import { useDialog, useRetailBusinessItems } from "../../../hooks";
export const BusinessRetailList = ({ currentFeature }) => {
    const [businessItems] = useRetailBusinessItems();
    const openDialog = useDialog();
    const onClick = useCallback((businessItem, conceptId) => {
        const businessConcept = businessItem.business_concepts?.find(concept => concept.id === Number(conceptId));
        openDialog(DIALOGS.BUSINESS_INFO_DIALOG, {
            businessConcept,
            businessId: businessItem.id,
        });
    }, [openDialog]);
    return (_jsx(BusinessRetailListContainer, { children: currentFeature.properties.concepts_rating.map(({ id, name }, index) => {
            const parsedName = name.match(/(.*),(.*)/) || name;
            const businessItem = businessItems.find(({ business_concepts }) => business_concepts.some(concept => concept.id === id));
            const ico = businessItem?.ico;
            return (_jsxs(BusinessRetailItem, { onClick: () => onClick(businessItem, id), children: [!!ico && (_jsx(FlexSpan, { children: _jsx(CategoryIcon, { path: ico, size: "1.5rem" }) })), _jsxs(FlexSpan, { children: [_jsx(H4, { fontWeight: 500, children: parsedName[1] }), !!parsedName[2] && _jsx(BusinessRetailDescription, { children: parsedName[2] })] })] }, index));
        }) }));
};
