import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { CityList } from "./CityList";
import { Flex, FlexSpan, Icon, Modal } from "../../../ui";
import { AlertContainer, Description, TitleCurrentCity } from "./styled";
import { SearchInput } from "../../SearchInput";
import { EnterpriseBusinessUrl, RetailBusinessUrl, SelfEmployedBusinessUrl } from "../../../constants/routes";
import { useCityList, useCurrentCity, useCurrentSubject, useIsDesktop, useSubjectList, useSection, } from "../../../hooks";
import { declension } from "../../../utils";
import { AppSection } from "../../../slices/app/types";
export const CityPickerDialog = () => {
    const isDesktop = useIsDesktop();
    const [section] = useSection();
    const [currentCity, setCurrentCity] = useCurrentCity();
    const [currentSubject, setCurrentSubject] = useCurrentSubject();
    const [cityList] = useCityList();
    const [subjectList] = useSubjectList();
    const isRetail = useMemo(() => section === AppSection.Retail, [section]);
    const isSelfEmployed = useMemo(() => section === AppSection.SelfEmployed, [section]);
    const isEnterprise = useMemo(() => section === AppSection.Enterprise, [section]);
    const currentEntity = useMemo(() => isEnterprise ? currentSubject : currentCity, [isEnterprise, currentCity, currentSubject]);
    const currentList = useMemo(() => isEnterprise ? subjectList : cityList, [isEnterprise, cityList, subjectList]);
    const [searchValue, setSearchValue] = useState("");
    if (!currentList) {
        return null;
    }
    return (_jsx(Modal, { width: "80vw", maxWidth: "75rem", title: _jsxs(_Fragment, { children: [_jsxs(Flex, { flexWrap: "wrap", children: [_jsxs(FlexSpan, { mr: "0.3rem", children: ["\u0412\u0430\u0448 ", isEnterprise ? "субъект" : "город", ":"] }), _jsx(FlexSpan, { children: _jsx(TitleCurrentCity, { children: currentEntity?.properties.name }) })] }), _jsx(Flex, { mb: "1.5rem", mt: "-0.5rem", children: _jsx(FlexSpan, { children: _jsxs(Description, { children: ["\u0414\u043E\u0441\u0442\u0443\u043F\u043D\u043E ", currentList.features.length, " ", isEnterprise
                                    ? declension(currentList.features.length, { nom: "субъект", gen: "субъекта", plu: "субъектов" })
                                    : declension(currentList.features.length, { nom: "город", gen: "города", plu: "городов" }), " ", "\u0420\u043E\u0441\u0441\u0438\u0439\u0441\u043A\u043E\u0439 \u0424\u0435\u0434\u0435\u0440\u0430\u0446\u0438\u0438"] }) }) }), _jsx(Flex, { mb: "1.5rem", width: "100%", children: _jsx(FlexSpan, { width: "100%", children: _jsxs(AlertContainer, { children: [_jsx(FlexSpan, { mr: "0.75rem", children: _jsx(Icon, { icon: "alert", color: "#F09537" }) }), _jsxs(FlexSpan, { children: ["\u041F\u0440\u0438 \u0441\u043C\u0435\u043D\u0435 ", isEnterprise ? "субъекта" : "города", " \u043F\u0440\u043E\u0438\u0437\u043E\u0439\u0434\u0435\u0442 \u0441\u0431\u0440\u043E\u0441 \u0440\u0430\u0441\u0447\u0435\u0442\u043E\u0432 \u0438/\u0438\u043B\u0438 \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u043E\u0432 \u0444\u0438\u043B\u044C\u0442\u0440\u0430\u0446\u0438\u0438!"] })] }) }) }), _jsx(Flex, { children: _jsx(SearchInput, { autofocus: isDesktop, value: searchValue, onChange: setSearchValue }) })] }), content: (_jsx(CityList, { entityList: currentList, currentEntity: currentEntity, setCurrentEntity: isEnterprise ? setCurrentSubject : setCurrentCity, resetPath: isEnterprise
                ? EnterpriseBusinessUrl.path
                : isRetail
                    ? RetailBusinessUrl.path
                    : isSelfEmployed
                        ? SelfEmployedBusinessUrl.path
                        : undefined })), contextValue: { searchValue } }));
};
