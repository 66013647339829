import { HttpClient } from "./HttpClient";
export class ExtendedHttpClient extends HttpClient {
    constructor(options) {
        const { prefixUrl, hooks, ...otherOptions } = options || {};
        super(prefixUrl && prefixUrl.toString());
        this.extend({
            timeout: false,
            ...otherOptions,
            prefixUrl,
        });
    }
}
