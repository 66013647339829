import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isNil } from "lodash";
import { Flex, FlexSpan, H3, Loader, PrimaryButton } from "../../ui";
import { PointCard } from "../PointCard";
import { RetailLocationUrl, SelfEmployedLocationUrl } from "../../constants/routes";
import { useRetailBusinessItems, useSelfBusinessItems, useUser } from "../../hooks";
import { getWktPoint } from "../../utils";
import { api } from "../../api";
import { AppSection } from "../../slices/app/types";
import link from "../../assets/images/link.svg";
export const BrokenBusinessPlan = ({ section }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [user] = useUser();
    const { businessId, conceptId, cityId } = useParams();
    const urlPoint = useMemo(() => searchParams.get("point"), [searchParams]);
    const point = useMemo(() => !isNil(urlPoint) ? JSON.parse(urlPoint) : null, [urlPoint]);
    const [retailBusinessItems] = useRetailBusinessItems();
    const [selfBusinessItems] = useSelfBusinessItems();
    const formatId = Number(searchParams.get("formatId"));
    const size = Number(searchParams.get("size"));
    const zone = searchParams.get("zone");
    const excludedCompetitors = searchParams.get("excludedCompetitors");
    const newBuildings = searchParams.get("newBuildings");
    const franchise = searchParams.get("franchise");
    const franchiseId = useMemo(() => franchise ? +franchise : null, [franchise]);
    const businessItems = useMemo(() => {
        return section === AppSection.Retail ? retailBusinessItems : selfBusinessItems;
    }, [section, retailBusinessItems, selfBusinessItems]);
    const [details, setDetails] = useState();
    const [loading, setLoading] = useState(true);
    const businessConcept = useMemo(() => {
        return businessItems?.find(item => item.id === Number(businessId))?.business_concepts?.find(concept => concept.id === Number(conceptId));
    }, [businessId, businessItems, conceptId]);
    const backPath = useMemo(() => {
        const path = section === AppSection.Retail
            ? generatePath(RetailLocationUrl.path, { cityId, businessId, conceptId })
            : generatePath(SelfEmployedLocationUrl.path, { cityId, businessId, conceptId });
        // eslint-disable-next-line max-len
        return `${path}?point=${encodeURIComponent(urlPoint)}&formatId=${formatId}&size=${size}&excludedCompetitors=${encodeURIComponent(excludedCompetitors)}&newBuildings=${newBuildings}&zone=${zone}&franchise=${franchiseId}`;
    }, [section, cityId, businessId, conceptId, urlPoint, formatId, size, excludedCompetitors, newBuildings, zone, franchiseId]);
    useEffect(() => {
        if (details) {
            return;
        }
        const wktPoint = getWktPoint(point);
        setLoading(true);
        Promise.all(section === AppSection.Retail ? [
            api.statistic.getStatistic(formatId, +cityId, wktPoint, undefined, undefined, undefined, user.guid),
            api.analytic.getAnalytic(formatId, +cityId, wktPoint, undefined, user.guid),
        ] : [
            api.statistic.getSelfStatistic(formatId, +cityId, wktPoint, undefined, undefined, undefined, user.guid),
            api.analytic.getSelfAnalytic(formatId, +cityId, wktPoint, undefined, user.guid),
        ]).then(([statistic, analytic]) => {
            setDetails({ statistic, analytic });
        }).finally(() => setLoading(false));
    }, [section, cityId, details, formatId, point, user]);
    return (_jsx(Flex, { column: true, height: "calc(100% - 1.75rem)", width: "100%", alignItems: "center", justifyContent: "center", children: _jsx(FlexSpan, { children: loading ? (_jsx(Loader, {})) : (_jsxs(Flex, { column: true, alignItems: "center", children: [_jsx(FlexSpan, { mb: "1.5rem", children: _jsx("img", { src: link, alt: "", width: "48px", height: "48px" }) }), _jsx(FlexSpan, { mb: "1rem", children: _jsx(H3, { fontWeight: 700, children: "\u0421\u0441\u044B\u043B\u043A\u0430 \u043D\u0435 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u044C\u043D\u0430" }) }), _jsx(FlexSpan, { mb: "2rem", children: "\u0412\u0435\u0440\u043D\u0438\u0442\u0435\u0441\u044C \u043D\u0430 \u0448\u0430\u0433 \u043F\u043E\u0434\u0431\u043E\u0440\u0430 \u043B\u043E\u043A\u0430\u0446\u0438\u0438 \u0438 \u043F\u0435\u0440\u0435\u0441\u0447\u0438\u0442\u0430\u0439\u0442\u0435 \u0411\u0438\u0437\u043D\u0435\u0441-\u043F\u043B\u0430\u043D" }), !loading && (_jsx(FlexSpan, { width: "22.5rem", children: _jsx(PointCard, { section: section, readOnly: true, disableMobile: true, formatId: formatId, size: size, franchiseId: franchiseId, businessConcept: businessConcept, details: details, icon: businessItems?.find(item => item.id === Number(businessId))?.ico, footer: (_jsx(PrimaryButton, { width: "100%", buttonSize: "m", icon: "location", onClick: () => navigate(backPath), children: "\u041A \u043F\u043E\u0434\u0431\u043E\u0440\u0443 \u043B\u043E\u043A\u0430\u0446\u0438\u0438" })) }) }))] })) }) }));
};
