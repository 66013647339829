import styled from "styled-components";
import { textAlign } from "styled-system";
export const IndicatorUnit = styled.div `
  color: #9F9F9F;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
export const IndicatorLabel = styled.div `
  color: #666666;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  ${textAlign}
`;
