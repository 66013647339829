import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRetailBusinessSearch } from "../../../selectors/filters";
import { setRetailBusinessSearch } from "../../../slices/filters";
export const useRetailBusinessSearch = () => {
    const dispatch = useDispatch();
    const search = useSelector(getRetailBusinessSearch);
    const update = useCallback((value) => {
        dispatch(setRetailBusinessSearch(value));
    }, [dispatch]);
    return [search, update];
};
