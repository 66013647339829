export const onParamsTableMonthClick = (colIndex, schedule, key, onUpdateValues) => {
    const scheduleClone = [...schedule];
    const checkedColsCount = scheduleClone.filter((col, index) => {
        return index === colIndex
            ? col === 0
            : col !== 0;
    }).length;
    onUpdateValues({
        [key]: scheduleClone.map((col, index) => {
            return index === colIndex
                ? col === 0 && checkedColsCount !== 0
                    ? (1 / checkedColsCount)
                    : 0
                : col !== 0
                    ? (1 / checkedColsCount)
                    : 0;
        }),
    });
};
