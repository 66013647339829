import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelfBusinessSearch } from "../../../selectors/filters";
import { setSelfBusinessSearch } from "../../../slices/filters";
export const useSelfBusinessSearch = () => {
    const dispatch = useDispatch();
    const search = useSelector(getSelfBusinessSearch);
    const update = useCallback((value) => {
        dispatch(setSelfBusinessSearch(value));
    }, [dispatch]);
    return [search, update];
};
