export const MENU_ITEMS = {
    support: {
        label: "Меры поддержки",
        prefixIcon: "aid",
        link: "https://мсп.рф/services/support/filter/",
        hideOnMobile: true,
        isMoreMenuItem: false,
    },
    services: {
        label: "Сервисы",
        prefixIcon: "grid-alt",
        link: "https://мсп.рф/services/",
        hideOnMobile: true,
        isMoreMenuItem: false,
    },
    education: {
        label: "Бизнес-обучение",
        prefixIcon: "education",
        link: "https://мсп.рф/education/",
        hideOnMobile: true,
        isMoreMenuItem: false,
    },
    knowledge: {
        label: "База знаний",
        prefixIcon: "competencies",
        link: "https://мсп.рф/services/knowledge-base/",
        hideOnMobile: true,
        isMoreMenuItem: true,
    },
    statistics: {
        label: "Статистика",
        prefixIcon: "three-arrows-up",
        link: "https://мсп.рф/analytics/",
        hideOnMobile: true,
        isMoreMenuItem: true,
    },
    law: {
        label: "Законодательный дайджест",
        prefixIcon: "suitcase",
        link: "https://мсп.рф/services/news/digest/",
        hideOnMobile: true,
        isMoreMenuItem: true,
    },
    news: {
        label: "Новости",
        prefixIcon: "news",
        link: "https://мсп.рф/services/news/",
        hideOnMobile: true,
        isMoreMenuItem: true,
    },
};
