import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { PointCardActions, PointCardAddress, PointCardFormat, PointCardFormatsContainer, PointCardFormatsLabel, } from "./styled";
import { InfoIcon } from "../InfoIcon";
import { Card, Divider, EllipsisText, Flex, FlexSpan, H4, Icon, IconButton, Tooltip } from "../../ui";
import { CategoryIcon } from "../CategoryIcon";
import { DIALOGS } from "../../constants/dialogs";
import { useDialog, useIsDesktop } from "../../hooks";
import { AppSection } from "../../slices/app/types";
const businessFormatsTooltips = {
    "Эконом": "Предполагает низкий уровень цен",
    "Массовая": "Предполагает средний уровень цен",
    "Премиум": "Предполагает высокий уровень цен",
};
export const PointCard = ({ section, businessConcept, icon, details, formatId, setFormatId, franchiseId, size, onOpenSettings, onDelete, readOnly, footer, disableMobile, }) => {
    const openDialog = useDialog();
    const isDesktop = useIsDesktop();
    const businessFormats = useMemo(() => businessConcept?.business_formats, [businessConcept]);
    const currentFormat = useMemo(() => businessFormats?.find(format => format.id === formatId), [formatId, businessFormats]);
    const currentBusinessPlan = useMemo(() => details?.statistic.businessplans.find(item => item.id === size), [details, size]);
    const currentFranchise = useMemo(() => currentFormat?.franchises?.find(item => item.id === franchiseId), [currentFormat, franchiseId]);
    const isEnterprise = useMemo(() => section === AppSection.Enterprise, [section]);
    return (_jsxs(Card, { flat: !disableMobile && !isDesktop, p: !disableMobile && !isDesktop ? "0.75rem 0 0 0" : undefined, children: [_jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { children: [_jsx(FlexSpan, { mr: "0.75rem", children: _jsx(CategoryIcon, { path: icon, size: "2rem" }) }), _jsx(FlexSpan, { pt: "0.3rem", flexGrow: 1, children: _jsxs(Flex, { alignItems: "baseline", children: [_jsx(FlexSpan, { flexGrow: 1, pr: "1rem", children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { children: _jsx(H4, { mb: !details && "0.5rem", children: businessConcept?.name }) }), details && (_jsx(FlexSpan, { mt: "0.25rem", mb: !businessFormats && "0.5rem", children: _jsx(PointCardAddress, { children: _jsx(EllipsisText, { lines: isDesktop ? 5 : 3, children: section === AppSection.Enterprise
                                                                ? details.statistic?.target.feature.properties.description
                                                                : details.analytic?.address }) }) }))] }) }), !readOnly && (_jsx(FlexSpan, { children: _jsx(PointCardActions, { children: _jsxs(Flex, { alignItems: "center", gap: "1rem", ml: "1rem", children: [_jsx(FlexSpan, { children: _jsx(IconButton, { icon: "info_modal2", onClick: () => openDialog(DIALOGS.BUSINESS_INFO_DIALOG, { businessConcept }) }) }), details && (_jsx(FlexSpan, { children: _jsx(IconButton, { icon: "trash", onClick: () => onDelete && onDelete() }) }))] }) }) }))] }) })] }) }), details && (_jsxs(_Fragment, { children: [_jsx(FlexSpan, { width: "100%", children: _jsx(Divider, { m: "1rem 0 1rem" }) }), _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { flexGrow: 1, children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { alignItems: "flex-start", children: [businessFormats && (_jsxs(FlexSpan, { display: "inline-flex", alignItems: "center", children: [_jsx(FlexSpan, { children: currentFormat?.name }), " ", section !== AppSection.Enterprise && (_jsx(InfoIcon, { content: businessFormatsTooltips[currentFormat?.name] }))] })), !isEnterprise && businessFormats && currentBusinessPlan && (_jsx(FlexSpan, { children: _jsx(Divider, { vertical: true, m: "0 0.5rem" }) })), !isEnterprise && currentBusinessPlan && (_jsx(FlexSpan, { children: _jsx(EllipsisText, { children: currentBusinessPlan.name }) }))] }) }), currentFranchise && (_jsx(FlexSpan, { mt: "0.5rem", children: _jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { mr: "0.25rem", children: _jsx(CategoryIcon, { path: currentFranchise.ico }) }), _jsx(FlexSpan, { flexGrow: 1, children: currentFranchise.name })] }) }))] }) }), !readOnly && (_jsx(FlexSpan, { display: "flex", ml: "1.5rem", children: _jsx(IconButton, { icon: "edit", onClick: () => {
                                            onOpenSettings && onOpenSettings();
                                            openDialog(DIALOGS.LOCATION_SETTINGS_DIALOG, {
                                                section,
                                                formats: businessFormats,
                                                currentFormatId: formatId,
                                                currentSize: size,
                                            });
                                        } }) }))] }) })] })), !details && businessFormats?.length > 1 && (_jsxs(_Fragment, { children: [_jsx(FlexSpan, { mt: "0.75rem", children: _jsxs(PointCardFormatsLabel, { children: ["\u0414\u043E\u0441\u0442\u0443\u043F\u043D\u044B\u0435 ", section === AppSection.Enterprise ? "форматы" : "ценовые ниши"] }) }), _jsx(FlexSpan, { mt: "0.5rem", width: "100%", children: _jsx(PointCardFormatsContainer, { count: businessFormats.length, children: businessFormats.map(businessFormat => (_jsx(PointCardFormat, { active: businessFormat.id === formatId, onClick: () => setFormatId && setFormatId(businessFormat.id), children: section === AppSection.Enterprise ? (_jsx(_Fragment, { children: businessFormat.name })) : (_jsx(Tooltip, { content: _jsxs(Flex, { column: true, children: [_jsxs(Flex, { mb: "0.5rem", alignItems: "center", children: [_jsx(Icon, { color: "#7e6cc1", icon: "info" }), _jsx(FlexSpan, { ml: "0.5rem", fontWeight: 500, children: businessFormat.name })] }), _jsx(FlexSpan, { ml: "1.5rem", children: businessFormatsTooltips[businessFormat.name] })] }), placement: "bottom", children: businessFormat.name })) }, `available-business-format-${businessFormat.id}`))) }) })] })), footer && (_jsx(FlexSpan, { mt: "0.75rem", width: "100%", children: footer }))] }));
};
