import styled from "styled-components";
import { Flex } from "../Grid";
export const LinkWrapper = styled(Flex) `
  display: inline-flex;
  width: auto;
  align-items: center;
  color: ${({ idleColor }) => idleColor ?? "#7E6CC1"};
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  cursor: pointer;

  & * {
    cursor: pointer;
  }
  
  &:hover {
    color: #6E5CB2;
  }
`;
