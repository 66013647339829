import { jsx as _jsx } from "react/jsx-runtime";
import { ListItemConceptList } from "./styled";
import { BusinessConcept } from "./BusinessConcept";
export const PromBusinessListItem = ({ businessItem, lightBackground, listStyle, compactMode, showCategory, search, onConceptClick, }) => {
    return (_jsx(ListItemConceptList, { style: listStyle, children: [...businessItem?.business_concepts ?? []]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(item => {
            return (_jsx(BusinessConcept, { businessItem: businessItem, search: search, concept: item, lightBackground: lightBackground, onClick: () => onConceptClick(item), compactMode: compactMode, showCategory: showCategory }, `business-list-concept-${item.id}`));
        }) }));
};
