import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRetailBusinessPriceRange } from "../../../selectors/dict";
import { setRetailBusinessPriceRange } from "../../../slices/dict";
export const useRetailBusinessPriceRange = () => {
    const dispatch = useDispatch();
    const businessPriceRange = useSelector(getRetailBusinessPriceRange);
    const update = useCallback((value) => {
        dispatch(setRetailBusinessPriceRange(value));
    }, [dispatch]);
    return [businessPriceRange, update];
};
