import styled from "styled-components";
import { Map as EvergisMap } from "@evergis/react";
export const MapContainer = styled.div `
  border-radius: 0.5rem;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  overflow: hidden;
  box-shadow: 0 0 1rem 0 rgba(63,67,106,0.12);
  background-color: #ffffff;
`;
export const StyledMap = styled(EvergisMap) `
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
