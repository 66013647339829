export class BusinessPlan {
    http;
    constructor(http) {
        this.http = http;
    }
    getRetailParams(cityId, sizeId) {
        return this.http.get(`/api/retail_pars/${cityId}/${sizeId}`).json();
    }
    getRetailDocuments(cityId, sizeId) {
        return this.http.get(`/api/retail_doc/${cityId}/${sizeId}`).json();
    }
    createRetailBusinessPlan(cityId, sizeId, query, body) {
        return this.http.post(`/api/retail_bp/${cityId}/${sizeId}`, body ?? {}, query).json();
    }
    getSelfParams(cityId, sizeId) {
        return this.http.get(`/api/self_pars/${cityId}/${sizeId}`).json();
    }
    createSelfBusinessPlan(cityId, sizeId, query, body) {
        return this.http.post(`/api/self_bp/${cityId}/${sizeId}`, body ?? {}, query).json();
    }
    getPromBusinessPlan(formatId, objectId, query) {
        return this.http.get(`/api/prom_businessplan/${formatId}/${objectId}`, query).json();
    }
}
