import { jsx as _jsx } from "react/jsx-runtime";
import { LoaderContainer } from "./styled";
const LOADER_SVG = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24" cy="24" r="22" stroke="black" stroke-opacity="0.1" stroke-width="4"/>
<path d="M24 2C28.843 2 33.5506 3.59802 37.3928 6.54623C41.2349 9.49443 43.9969 13.6281 45.2504 18.306" stroke="#8879C2" stroke-width="4" stroke-linecap="round"/>
</svg>
`;
export const Loader = ({ size, speed }) => {
    return (_jsx(LoaderContainer, { speed: speed, children: _jsx("img", { src: `data:image/svg+xml,${encodeURIComponent(LOADER_SVG)}`, width: size ?? 48, height: size ?? 48, alt: "" }) }));
};
