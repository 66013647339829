import styled from "styled-components";
import { space } from "styled-system";
const transition = {
    hover: "150ms cubic-bezier(0.2, 1, 0.6, 1)",
    release: "200ms cubic-bezier(0.2, 1, 0.6, 1)",
};
export const SwitchWrapper = styled.label `
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }
`;
export const SwitchArea = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  width: 1.75rem;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0.4475rem;
    transition: background ${transition.hover};
  }

  ${SwitchWrapper}:active &:after {
    transition: background ${transition.release};
  }

  ${SwitchWrapper} [disabled] &:after {
    box-shadow: none;
  }
`;
export const Switcher = styled.div `
  width: 1rem;
  height: 1rem;
  box-sizing: border-box;
  box-shadow: 0 0 1rem rgba(23, 28, 143, 0.1);
  border-radius: 50%;
  border: 0.0625rem solid #ffffff;
  transition: margin-left ${transition.release};
  z-index: 10;

  ${SwitchWrapper} [disabled] & {
    box-shadow: none;
  }
`;
export const SwitchContainer = styled.span `
  width: 2.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.81rem;
  background: ${({ isOn, alwaysOn }) => (isOn || alwaysOn ? "#9D87F1" : "#E3E3E3")};
  
  &:hover {
    background: ${({ isOn, alwaysOn }) => (isOn || alwaysOn) && "#B19FF4"};

    ${SwitchArea} {
      &:after {
        background: ${({ isOn, alwaysOn }) => (isOn || alwaysOn) && "#B19FF4"};
      }
    }
  }
  
  ${SwitchArea} {
    &:after {
      background: ${({ isOn, alwaysOn }) => (isOn || alwaysOn ? "#9D87F1" : "#E3E3E3")};
    }
  }
  
  ${Switcher} {
    background: #ffffff;
    margin-left: ${({ isOn }) => (isOn ? "0.725rem" : 0)};
  }
  
  &[disabled] {
    & {
      background-color: ${({ isOn, alwaysOn }) => isOn || alwaysOn ? "#CEC3F8" : "#F3F3F3"};

      ${SwitchArea} {
        &:after {
          background: ${({ isOn, alwaysOn }) => isOn || alwaysOn ? "#CEC3F8" : "#F3F3F3"};
        }
      }
    }
  }
`;
export const SwitchLabel = styled.span `
  user-select: none;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  ${space}

  &[disabled] {
    cursor: not-allowed;
  }
`;
