import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUpdatedLocationParams } from "../../selectors/filters";
import { setUpdatedLocationParams } from "../../slices/filters";
export const useUpdatedLocationParams = () => {
    const dispatch = useDispatch();
    const updatedParams = useSelector(getUpdatedLocationParams);
    const update = useCallback((value) => {
        dispatch(setUpdatedLocationParams({
            ...updatedParams,
            ...value,
        }));
    }, [dispatch, updatedParams]);
    return [updatedParams, update];
};
