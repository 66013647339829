import { createSlice } from "@reduxjs/toolkit";
import { INIT_LOCATIONS_COMPARE_PARAMS, INIT_AREA_COMPARE_PARAMS } from "../../constants/filters";
import { BusinessDisplayMode } from "./types";
const initialState = {
    retail: {
        businessSearch: "",
        businessDisplayMode: BusinessDisplayMode.Categories,
    },
    self: {
        businessSearch: "",
    },
    prom: {
        businessSearch: "",
        businessDisplayMode: BusinessDisplayMode.Categories,
    },
    updatedLocationParams: {
        excludedCompetitors: false,
        formatId: false,
        size: false,
    },
    locationsCompareParams: INIT_LOCATIONS_COMPARE_PARAMS,
    areaCompareParams: INIT_AREA_COMPARE_PARAMS,
};
const slice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setRetailInvestValue: (state, action) => {
            state.retail.investValue = action.payload;
        },
        setRetailBusinessSearch: (state, action) => {
            state.retail.businessSearch = action.payload;
        },
        setRetailBusinessDisplayMode: (state, action) => {
            state.retail.businessDisplayMode = action.payload;
        },
        setSelfBusinessSearch: (state, action) => {
            state.self.businessSearch = action.payload;
        },
        setPromBusinessSearch: (state, action) => {
            state.prom.businessSearch = action.payload;
        },
        setPromBusinessDisplayMode: (state, action) => {
            state.prom.businessDisplayMode = action.payload;
        },
        setUpdatedLocationParams: (state, action) => {
            state.updatedLocationParams = action.payload;
        },
        setLocationsCompareParams: (state, action) => {
            state.locationsCompareParams = action.payload;
        },
        setAreaCompareParams: (state, action) => {
            state.areaCompareParams = action.payload;
        },
        resetFilters: (state) => {
            return {
                ...initialState,
                locationsCompareParams: state.locationsCompareParams,
                areaCompareParams: state.areaCompareParams,
            };
        },
    },
});
export const { setRetailInvestValue, setRetailBusinessSearch, setRetailBusinessDisplayMode, setSelfBusinessSearch, setPromBusinessSearch, setPromBusinessDisplayMode, setUpdatedLocationParams, setLocationsCompareParams, setAreaCompareParams, resetFilters, } = slice.actions;
export default slice.reducer;
