import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Flex, FlexSpan, InputSlider, Select } from "../../../../ui";
import { useBusinessPlanFormValues } from "../../../../hooks";
import { formatNumber } from "../../../../utils";
export const LeasingForm = ({ data, keys, shouldReset, onResetComplete, onChange, onValuesChange }) => {
    const equipmentFormOptions = useMemo(() => data?.left_menu.equipment_values.map(item => ({ value: item.id, label: item.name })) ?? [], [data]);
    const [values, onUpdateValues] = useBusinessPlanFormValues(keys.reduce((acc, curr) => ({ ...acc, [curr]: data?.left_menu[curr] }), {}), undefined, onChange, shouldReset, onResetComplete, onValuesChange);
    return (_jsxs(Flex, { flexWrap: "wrap", gap: "1.5rem", children: [_jsx(FlexSpan, { width: "100%", mb: "1.5rem", children: _jsx(Select, { isMulti: true, label: "\u041E\u0431\u043E\u0440\u0443\u0434\u043E\u0432\u0430\u043D\u0438\u0435", options: equipmentFormOptions, value: equipmentFormOptions.filter(item => values.equipment?.includes(item.value)), onChange: options => onUpdateValues({ equipment: options?.map(item => item.value) }) }) }), _jsx(FlexSpan, { width: "calc(50% - 0.75rem)", mb: "1.5rem", children: _jsx(InputSlider, { label: "\u0421\u0440\u043E\u043A \u043B\u0438\u0437\u0438\u043D\u0433\u0430, \u043C\u0435\u0441", min: 12, max: 60, step: 1, defaultValue: data?.left_menu.leasing_time, markFormatFunc: formatNumber, value: values.leasing_time?.toString(), onChange: value => onUpdateValues({ leasing_time: value }), delay: 10 }) }), _jsx(FlexSpan, { width: "calc(50% - 0.75rem)", mb: "1.5rem", children: _jsx(InputSlider, { label: "\u0421\u0442\u0430\u0432\u043A\u0430 \u043B\u0438\u0437\u0438\u043D\u0433\u0430, % \u0433\u043E\u0434\u043E\u0432\u044B\u0445", min: 0, max: 50, step: 1, defaultValue: data?.left_menu.leasing_procent, markFormatFunc: formatNumber, value: values.leasing_procent?.toString(), onChange: value => onUpdateValues({ leasing_procent: value }), delay: 10 }) }), _jsx(FlexSpan, { width: "calc(50% - 0.75rem)", mb: "1.5rem", children: _jsx(InputSlider, { label: "\u0410\u0432\u0430\u043D\u0441\u043E\u0432\u044B\u0439 \u043F\u043B\u0430\u0442\u0435\u0436, %", min: 0, max: 100, step: 1, defaultValue: data?.left_menu.leasing_payment, markFormatFunc: formatNumber, value: values.leasing_payment?.toString(), onChange: value => onUpdateValues({ leasing_payment: value }), delay: 10 }) })] }));
};
