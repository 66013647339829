import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines-per-function, max-lines */
import { useCallback } from "react";
import { EllipsisText, Flex, FlexSpan, GrayButton, H4, Icon, NumberInput, TextInput, Tooltip } from "../../../../ui";
import { ParamsTable } from "../../../../components/ParamsTable";
import { InfoIcon } from "../../../../components/InfoIcon";
import { useBusinessPlanFormValues } from "../../../../hooks";
import { onParamsTableMonthClick } from "../../../../utils";
const INITIAL_FINANCING_PARAMS = [
    {
        id: "reg_cost",
        schedule: "reg_schedule",
        title: "Регистрация в качестве самозанятого («Мой налог»)",
        description: "Вы можете бесплатно зарегистрироваться в качестве самозанятого на сайте " +
            "Федеральной налоговой службы или скачать приложение \"Мой налог\", " +
            "но предусмотреть затраты до 20 тысяч рублей при необходимости.",
    },
    {
        id: "deposit_cost",
        schedule: "deposit_schedule",
        title: "Подбор помещения и заключение договора аренды помещения",
        description: "Включает затраты в сумме до 500 тысяч рублей на поиск помещения для бизнеса " +
            "и выплату обеспечительного платежа.",
    },
    {
        id: "repair_cost",
        schedule: "repair_schedule",
        title: "Подготовка и ремонт помещения",
        description: "Включает все затраты на подготовку помещения к эксплуатации " +
            "в сумме до 800 тысяч рублей.",
    },
    {
        id: "product_cost",
        schedule: "product_schedule",
        title: "Закупка первой партии расходных материалов",
        description: "Включает затраты на закупку всех материалов, требуемых " +
            "для начала работы в сумме до 100 тысяч рублей.",
    },
    {
        id: "soft_cost",
        schedule: "soft_schedule",
        title: "Приобретение лицензий на необходимое программное обеспечение",
        description: "Включает затраты на закупку программного обеспечения (лицензий) " +
            "в сумме до 50 тысяч рублей.",
    },
    {
        id: "adv_cost",
        schedule: "adv_schedule",
        title: "Проведение рекламной кампании",
        description: "Включает затраты на подготовку и проведение мероприятий " +
            "по открытию бизнеса в сумме до 100 тысяч рублей.",
    },
    {
        id: "other_cost",
        schedule: "other_schedule",
        title: "Прочие статьи затрат",
        description: "Вы можете предусмотреть дополнительные затраты в сумме " +
            "до 990 тысяч рублей при необходимости.",
    },
];
export const InitialFinancingForm = ({ data, defaultParams, keys, shouldReset, onResetComplete, onChange, onValuesChange, }) => {
    const [values, onUpdateValues] = useBusinessPlanFormValues(keys.reduce((acc, curr) => ({ ...acc, [curr]: data?.parameters[curr] }), {}), {
        reg_cost: defaultParams.reg_cost?.value,
        reg_schedule: defaultParams.reg_schedule,
        deposit_cost: defaultParams.deposit_cost?.value,
        deposit_schedule: defaultParams.deposit_schedule,
        repair_cost: defaultParams.repair_cost?.value,
        repair_schedule: defaultParams.repair_schedule,
        product_cost: defaultParams.product_cost?.value,
        product_schedule: defaultParams.product_schedule,
        soft_cost: defaultParams.soft_cost?.value,
        soft_schedule: defaultParams.soft_schedule,
        adv_cost: defaultParams.adv_cost?.value,
        adv_schedule: defaultParams.adv_schedule,
        other_cost: defaultParams.other_cost?.value,
        other_schedule: defaultParams.other_schedule,
        equipment_list: defaultParams.equipment_list.map(item => ({
            ...item,
            count: item.count.value,
            price: item.price.value,
            time: item.time.value,
        })),
    }, onChange, shouldReset, onResetComplete, onValuesChange);
    const onChangeEquipmentCellValue = useCallback((rowId, key, value) => {
        onUpdateValues({
            equipment_list: values.equipment_list.map((currRow, currIndex) => {
                if (currIndex === rowId) {
                    return {
                        ...currRow,
                        [key]: value,
                    };
                }
                return currRow;
            }),
        });
    }, [onUpdateValues, values.equipment_list]);
    const onAddEquipment = useCallback(() => {
        onUpdateValues({
            equipment_list: [
                ...values.equipment_list,
                {
                    id: -1 * (values.equipment_list.filter(item => item.id < 0).length + 1),
                    name: "Доп. оборудование",
                    count: defaultParams.equipment_list[0].count?.min,
                    price: defaultParams.equipment_list[0].price?.min,
                    schedule: Array(12).fill(0),
                    time: defaultParams.equipment_list[0].time?.min,
                },
            ],
        });
    }, [defaultParams, onUpdateValues, values]);
    const onPickMenuItem = useCallback((option, rowId) => {
        if (option.value === 1) {
            let equipmentIdCount = 0;
            onUpdateValues({
                equipment_list: values.equipment_list.reduce((acc, curr, currIndex) => {
                    return currIndex === rowId ? acc : [...acc, curr];
                }, []).map(item => {
                    if (item.id < 0) {
                        equipmentIdCount += 1;
                        return {
                            ...item,
                            id: -1 * equipmentIdCount,
                        };
                    }
                    return item;
                }),
            });
        }
    }, [onUpdateValues, values]);
    return (_jsxs(Flex, { column: true, children: [_jsxs(FlexSpan, { width: "100%", mb: "2rem", children: [_jsx(H4, { fontWeight: 500, mb: "1.5rem", children: "\u041F\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0435 \u0437\u0430\u0442\u0440\u0430\u0442\u044B" }), _jsx(ParamsTable, { startDate: data.parameters.start_date, monthsCount: 12, columns: [
                            {
                                id: "name",
                                title: "Статья инвестиций",
                                width: "23rem",
                                formatter: row => {
                                    const isRowDisabled = values[INITIAL_FINANCING_PARAMS?.find(item => {
                                        return item.id === row.id;
                                    })?.schedule]?.every(item => item === 0);
                                    return (_jsxs(Flex, { width: "100%", height: "100%", alignItems: "center", gap: "0.5rem", children: [_jsx(FlexSpan, { flexGrow: 1, color: isRowDisabled ? "#9F9F9F" : undefined, children: _jsx(Tooltip, { content: (_jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { children: row.data.name }), _jsx(FlexSpan, { mt: "0.25rem", color: "#9F9F9F", fontSize: "0.625rem", children: row.data.description })] })), delay: [1000, 0], wrapperHeight: "100%", children: _jsx(EllipsisText, { children: row.data.name }) }) }), isRowDisabled && (_jsx(FlexSpan, { display: "flex", children: _jsx(Tooltip, { content: "\u041D\u0435 \u0443\u0447\u0430\u0441\u0442\u0432\u0443\u0435\u0442 \u0432 \u0440\u0430\u0441\u0447\u0435\u0442\u0430\u0445", delay: [1000, 0], children: _jsx(Icon, { icon: "problem", color: "#F09537" }) }) }))] }));
                                },
                            },
                            {
                                id: "cost",
                                title: "Стоимость",
                                subtitle: "руб.",
                                width: "7.625rem",
                                align: "right",
                                onEditCheck: () => true,
                                formatter: row => (_jsx(NumberInput, { style: { border: "none" }, inputStyle: { textAlign: "right", fontSize: "0.75rem", padding: "0 0.75rem" }, defaultValue: row.data.cost?.toString(), hideArrows: true, height: "2.5rem", onChange: value => onUpdateValues({ [row.id]: value }), min: defaultParams[row.id].min, max: defaultParams[row.id].max, disabled: values[INITIAL_FINANCING_PARAMS?.find(item => item.id === row.id)?.schedule]?.every(item => item === 0), noDisabledBackground: true })),
                            },
                        ], rows: INITIAL_FINANCING_PARAMS.map(param => {
                            return {
                                id: param.id,
                                onMonthClick: colIndex => onParamsTableMonthClick(colIndex, values[param.schedule], param.schedule, onUpdateValues),
                                onActiveCheck: colIndex => values[param.schedule]?.[colIndex] !== 0 && values[param.schedule]?.[colIndex] !== undefined,
                                onDisabledCheck: colIndex => data.parameters.event_list.find(item => item.type === "power_start").month?.[0] < colIndex + 1,
                                data: {
                                    name: param.title,
                                    description: param.description,
                                    cost: values[param.id],
                                    months: values[param.schedule],
                                },
                            };
                        }) })] }), _jsxs(FlexSpan, { width: "100%", children: [_jsx(H4, { fontWeight: 500, mb: "1.5rem", children: "\u041F\u043B\u0430\u043D-\u0433\u0440\u0430\u0444\u0438\u043A \u043F\u0440\u0438\u043E\u0431\u0440\u0435\u0442\u0435\u043D\u0438\u044F \u043E\u0441\u043D\u043E\u0432\u043D\u044B\u0445 \u0441\u0440\u0435\u0434\u0441\u0442\u0432" }), _jsx(ParamsTable, { rowMenuItems: [
                            { value: 1, label: "Удалить строку", prefix: _jsx(Icon, { icon: "trash" }) },
                        ], onPickMenuItem: onPickMenuItem, startDate: data.parameters.start_date, monthsCount: 12, headCellHeight: "6.5rem", columns: [
                            {
                                id: "name",
                                title: "Наименование оборудования",
                                width: "14.25rem",
                                bodyCellStyle: { backgroundColor: "#FFFFFF" },
                                onEditCheck: () => true,
                                formatter: (row, rowId) => {
                                    const isRowDisabled = values.equipment_list[rowId]?.schedule?.every(item => item === 0);
                                    return isRowDisabled ? (_jsxs(Flex, { width: "100%", height: "100%", alignItems: "center", gap: "0.5rem", children: [_jsx(FlexSpan, { flexGrow: 1, color: "#9F9F9F", pl: "0.5rem", children: _jsx(Tooltip, { content: row.data.name, delay: [1000, 0], wrapperHeight: "100%", children: _jsx(EllipsisText, { children: row.data.name }) }) }), _jsx(FlexSpan, { display: "flex", pr: "0.5rem", children: _jsx(Tooltip, { content: "\u041D\u0435 \u0443\u0447\u0430\u0441\u0442\u0432\u0443\u0435\u0442 \u0432 \u0440\u0430\u0441\u0447\u0435\u0442\u0430\u0445", delay: [1000, 0], children: _jsx(Icon, { icon: "problem", color: "#F09537" }) }) })] })) : (_jsx(TextInput, { style: { marginLeft: "0.5rem", border: "none" }, inputStyle: { fontSize: "0.75rem", padding: 0 }, defaultValue: row.data.name, height: "2.5rem", width: "calc(100% - 0.5rem)", onBlur: () => {
                                            if (!values.equipment_list[rowId].name.trim()) {
                                                onChangeEquipmentCellValue(rowId, "name", "Доп. оборудование");
                                            }
                                        }, onChange: value => onChangeEquipmentCellValue(rowId, "name", value) }));
                                },
                            },
                            {
                                id: "count",
                                title: (_jsx(FlexSpan, { children: _jsxs(Flex, { alignItems: "baseline", children: [_jsx(FlexSpan, { flexGrow: 1, children: "\u041A\u043E\u043B-\u0432\u043E" }), _jsx(FlexSpan, { width: "1.5rem", children: _jsx(InfoIcon, { iconProps: { style: { position: "relative", top: "0.125rem" } }, content: "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u043E\u0431\u043E\u0440\u0443\u0434\u043E\u0432\u0430\u043D\u0438\u044F \u043E\u0434\u043D\u043E\u0433\u043E \u043D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u044F \u043D\u0435 \u043C\u043E\u0436\u0435\u0442 \u043F\u0440\u0435\u0432\u044B\u0448\u0430\u0442\u044C 10 \u0435\u0434\u0438\u043D\u0438\u0446." }) })] }) })),
                                width: "7.625rem",
                                align: "right",
                                onEditCheck: () => true,
                                formatter: (row, rowId) => (_jsx(NumberInput, { style: { border: "none" }, inputStyle: { textAlign: "right", fontSize: "0.75rem", padding: "0 0.75rem" }, defaultValue: row.data.count?.toString(), hideArrows: true, height: "2.5rem", onChange: value => onChangeEquipmentCellValue(rowId, "count", value), min: defaultParams.equipment_list?.[0].count?.min, max: defaultParams.equipment_list?.[0].count?.max, disabled: values.equipment_list[rowId]?.schedule?.every(item => item === 0), noDisabledBackground: true })),
                            },
                            {
                                id: "price",
                                title: (_jsx(FlexSpan, { children: _jsxs(Flex, { alignItems: "baseline", children: [_jsx(FlexSpan, { flexGrow: 1, children: "\u0426\u0435\u043D\u0430 \u0437\u0430 \u0435\u0434." }), _jsx(FlexSpan, { width: "1.5rem", children: _jsx(InfoIcon, { iconProps: { style: { position: "relative", top: "0.125rem" } }, content: "\u0423\u0434\u0435\u043B\u044C\u043D\u0430\u044F \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u043E\u0431\u043E\u0440\u0443\u0434\u043E\u0432\u0430\u043D\u0438\u044F \u043D\u0435 \u043C\u043E\u0436\u0435\u0442 \u043F\u0440\u0435\u0432\u044B\u0448\u0430\u0442\u044C 300 \u0442\u044B\u0441\u044F\u0447 \u0440\u0443\u0431\u043B\u0435\u0439." }) })] }) })),
                                subtitle: (_jsx(FlexSpan, { pr: "1.5rem", children: "\u0440\u0443\u0431." })),
                                width: "8.625rem",
                                align: "right",
                                onEditCheck: () => true,
                                formatter: (row, rowId) => (_jsx(NumberInput, { style: { border: "none" }, inputStyle: { textAlign: "right", fontSize: "0.75rem", padding: "0 0.75rem" }, defaultValue: row.data.price?.toString(), hideArrows: true, height: "2.5rem", onChange: value => onChangeEquipmentCellValue(rowId, "price", value), min: defaultParams.equipment_list?.[0].price?.min, max: defaultParams.equipment_list?.[0].price?.max, disabled: values.equipment_list[rowId]?.schedule?.every(item => item === 0), noDisabledBackground: true })),
                            },
                            {
                                id: "time",
                                title: (_jsx(FlexSpan, { children: _jsxs(Flex, { alignItems: "baseline", children: [_jsxs(FlexSpan, { flexGrow: 1, children: [_jsx(FlexSpan, { color: "#7e6cc1", mr: "0.25rem", children: "*" }), "\u0421\u0440\u043E\u043A \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u043D\u0438\u044F"] }), _jsx(FlexSpan, { width: "1.5rem", children: _jsx(InfoIcon, { iconProps: { style: { position: "relative", top: "0.125rem" } }, content: ("Срок полезного использования основных средств является обязательным " +
                                                        "для ведения бухгалтерского и налогового учета, устанавливается исходя из того, " +
                                                        "в какую амортизационную группу входит оборудование (всего 10 групп). " +
                                                        "Определяется в соответствии с Общероссийским классификатором основных средств (ОКОФ). " +
                                                        "Вы можете ввести значение показателя от 1 года до 10 лет.") }) })] }) })),
                                subtitle: (_jsx(FlexSpan, { pr: "1.5rem", children: "\u043B\u0435\u0442" })),
                                width: "11.5rem",
                                align: "right",
                                onEditCheck: () => true,
                                formatter: (row, rowId) => (_jsx(NumberInput, { style: { border: "none" }, inputStyle: { textAlign: "right", fontSize: "0.75rem", padding: "0 0.75rem" }, defaultValue: row.data.time?.toString(), hideArrows: true, height: "2.5rem", onChange: value => onChangeEquipmentCellValue(rowId, "time", value), min: defaultParams.equipment_list?.[0].time?.min, max: defaultParams.equipment_list?.[0].time?.max, disabled: values.equipment_list[rowId]?.schedule?.every(item => item === 0), noDisabledBackground: true })),
                            },
                        ], rows: values.equipment_list.map(({ name, count, price, time, schedule }, rowId) => ({
                            onMonthClick: colIndex => {
                                const scheduleClone = [...values.equipment_list?.[rowId].schedule];
                                const checkedColsCount = scheduleClone.filter((col, index) => index === colIndex ? col === 0 : col !== 0).length;
                                onUpdateValues({
                                    equipment_list: values.equipment_list.map((row, rIndex) => {
                                        if (rIndex === rowId) {
                                            return {
                                                ...row,
                                                schedule: scheduleClone.map((col, cIndex) => {
                                                    return cIndex === colIndex
                                                        ? col === 0 && checkedColsCount !== 0
                                                            ? (1 / checkedColsCount)
                                                            : 0
                                                        : col !== 0
                                                            ? (1 / checkedColsCount)
                                                            : 0;
                                                }),
                                            };
                                        }
                                        return row;
                                    }),
                                });
                            },
                            onActiveCheck: colIndex => values.equipment_list?.[rowId].schedule?.[colIndex] !== 0 && values.equipment_list?.[rowId].schedule?.[colIndex] !== undefined,
                            onDisabledCheck: colIndex => data.parameters.event_list.find(item => item.type === "power_start").month?.[0] < colIndex + 1,
                            data: {
                                name,
                                count,
                                price,
                                time,
                                months: schedule,
                            },
                        })) })] }), _jsx(FlexSpan, { width: "100%", mt: "1rem", pb: "0.5rem", children: _jsx(GrayButton, { icon: "plus", buttonSize: "s", width: "100%", onClick: onAddEquipment, disabled: values.equipment_list.filter(item => item.id < 0).length > 4, children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u0442\u0440\u043E\u043A\u0443" }) })] }));
};
