import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BusinessConceptContainer } from "./styled";
import { Flex, FlexSpan, H4, H6, Link } from "../../../ui";
import { CategoryIcon } from "../../CategoryIcon";
import { DIALOGS } from "../../../constants/dialogs";
import { MIN_SEARCH_LENGTH } from "../../../constants/common";
import { useDialog } from "../../../hooks";
import { highlightSearch } from "../../../utils";
export const BusinessConcept = ({ businessItem, concept, lightBackground, compactMode, showCategory, search, onClick, }) => {
    const openDialog = useDialog();
    return (_jsxs(BusinessConceptContainer, { onClick: onClick, alignItems: "center", justifyContent: "space-between", lightBackground: lightBackground, children: [(!compactMode || showCategory) && (_jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { alignItems: "center", mb: "0.25rem", children: [_jsx(FlexSpan, { mr: "0.5rem", children: _jsx(CategoryIcon, { path: businessItem.ico, size: "1rem" }) }), _jsx(FlexSpan, { children: _jsx(H6, { children: businessItem?.name }) })] }) })), _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { alignItems: "flex-end", justifyContent: "space-between", children: [_jsx(FlexSpan, { pr: "3rem", width: compactMode ? "100%" : undefined, flexGrow: !compactMode ? 1 : undefined, minWidth: "20rem", children: _jsx(H4, { children: highlightSearch(search, concept.name, MIN_SEARCH_LENGTH) }) }), !compactMode && (_jsx(FlexSpan, { alignSelf: "baseline", pl: "3rem", minWidth: "15rem", children: _jsx(Link, { icon: "info_modal2", iconPlacement: "right", onClick: e => {
                                    e.stopPropagation();
                                    openDialog(DIALOGS.BUSINESS_INFO_DIALOG, { businessConcept: concept });
                                }, children: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435 \u043E \u0431\u0438\u0437\u043D\u0435\u0441\u0435" }) }))] }) })] }));
};
