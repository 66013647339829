import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { generatePath, useNavigate } from "react-router-dom";
import { Layout } from "../../../components/Layout";
import { EllipsisText, Flex, FlexSpan, H4, Link } from "../../../ui";
import { Stepper } from "../../../components/Stepper";
import { CategoryIcon } from "../../../components/CategoryIcon";
import { SearchInput } from "../../../components/SearchInput";
import { BusinessConceptContainer, ListItemConceptList } from "./styled";
import { MIN_SEARCH_LENGTH } from "../../../constants/common";
import { DIALOGS } from "../../../constants/dialogs";
import { SelfEmployedLocationUrl } from "../../../constants/routes";
import { useCurrentCity, useDialog, useSelfBusinessItems, useSelfBusinessSearch } from "../../../hooks";
import { highlightSearch } from "../../../utils";
import { AppSection } from "../../../slices/app/types";
export const SelfEmployedBusinessPage = () => {
    const openDialog = useDialog();
    const navigate = useNavigate();
    const [currentCity] = useCurrentCity();
    const [data] = useSelfBusinessItems();
    const [search, setSearch] = useSelfBusinessSearch();
    return (_jsx(Layout, { section: AppSection.SelfEmployed, loading: !data, children: _jsxs(Flex, { column: true, height: "100%", children: [_jsx(FlexSpan, { children: _jsxs(Flex, { alignItems: "center", mb: "2rem", children: [_jsx(FlexSpan, { mr: "3.5rem", flexGrow: 1, alignSelf: "flex-start", children: _jsx(Stepper, { activeStep: 0, hideButtons: true, steps: [
                                        { title: "Выбор бизнеса" },
                                        { title: "Подбор локации" },
                                        { title: "Бизнес-план" },
                                    ] }) }), _jsx(FlexSpan, { width: "22.5rem", children: _jsx(SearchInput, { value: search, onChange: setSearch }) })] }) }), _jsx(FlexSpan, { flexGrow: 1, overflow: "auto", p: "0 1.5rem 0 0.5rem", children: _jsx(Flex, { flexWrap: "wrap", mb: "0.25rem", pt: "0.5rem", children: _jsx(Flex, { column: true, mb: "1.5rem", children: _jsx(ListItemConceptList, { children: data?.reduce((acc, curr) => {
                                    return [
                                        ...acc,
                                        ...curr.business_concepts.map(item => ({ ...item, businessId: curr.id })),
                                    ];
                                }, [])
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .filter(concept => !search || search.length < MIN_SEARCH_LENGTH || concept.name.toLowerCase().includes(search.toLowerCase()))
                                    .map(concept => {
                                    return (_jsx(BusinessConceptContainer, { onClick: () => {
                                            navigate(generatePath(SelfEmployedLocationUrl.path, {
                                                cityId: currentCity?.id,
                                                businessId: concept.businessId,
                                                conceptId: concept.id,
                                            }));
                                        }, alignItems: "center", justifyContent: "space-between", children: _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { alignItems: "center", justifyContent: "space-between", children: [_jsx(FlexSpan, { mr: "1rem", children: _jsx(CategoryIcon, { path: concept.ico, size: "2rem" }) }), _jsx(FlexSpan, { pr: "3rem", flexGrow: 1, minWidth: "20rem", children: _jsx(H4, { children: _jsx(EllipsisText, { lines: 1, children: highlightSearch(search, concept.name, MIN_SEARCH_LENGTH) }) }) }), _jsx(FlexSpan, { pl: "3rem", minWidth: "15rem", children: _jsx(Link, { icon: "info_modal2", iconPlacement: "right", onClick: e => {
                                                                e.stopPropagation();
                                                                openDialog(DIALOGS.BUSINESS_INFO_DIALOG, { businessConcept: concept });
                                                            }, children: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435 \u043E \u0431\u0438\u0437\u043D\u0435\u0441\u0435" }) })] }) }) }, `business-concept-${concept.id}`));
                                }) }) }) }) })] }) }));
};
