import styled from "styled-components";
import { Flex } from "../../../ui";
export const CurrentFeatureCardWrapper = styled(Flex) `
  flex-direction: column;
  align-items: center;
`;
export const CurrentFeatureCardContainer = styled.div `
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background: #ffffff;
  box-shadow: 0 0.25rem 1.75rem 0 rgba(63, 67, 106, 0.16);
  transform-origin: 100% 100%;
  width: 22.5rem;
  height: 25rem;
  z-index: 100;
  overflow: hidden;
`;
export const CurrentFeatureCardHeader = styled(Flex) `
  align-items: center;
  background-color: #F9F9F8;
  padding: 1rem 1.25rem;
  box-sizing: border-box;
`;
export const CurrentFeatureCardTitle = styled.div `
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`;
export const CurrentFeatureCardAddress = styled.div `
  color: #9F9F9F;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
export const CurrentFeatureCardBody = styled(Flex) `
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0.5rem 1.25rem 0 1rem;
  box-sizing: border-box;
  margin: 0.5rem 0 1rem;
  width: calc(100% - 0.5rem);
`;
export const CurrentFeatureCardFooter = styled(Flex) `
  padding: 1rem 1.25rem;
  box-sizing: border-box;
`;
export const CurrentCardAttributeContainer = styled(Flex) `
  flex-direction: column;
  
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;
export const CurrentCardAttributeName = styled.div `
  color: #9F9F9F;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
export const ArrowBottom = styled.div `
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #ffffff;
  box-shadow: 0 3px 8px 0 rgba(48, 69, 79, 0.06);
  z-index: 101;
`;
