import styled from "styled-components";
import { space } from "styled-system";
import { Icon, Icon as StyledIcon } from "../Icon/styled";
const BaseButton = styled.button `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${({ buttonSize }) => (buttonSize === "xl"
    ? "1rem"
    : buttonSize === "l" || buttonSize === "m"
        ? "0.75rem"
        : buttonSize === "s"
            ? "0.62rem"
            : buttonSize === "xs"
                ? "0.38rem"
                : "1rem")} 1.5rem;
  border-radius: 0.5rem;
  transition: 0.15s ease-in-out;
  font-weight: 400;
  font-size: ${({ buttonSize }) => buttonSize === undefined || buttonSize === "xl" || buttonSize === "l" ? "1rem" : "0.875rem"};
  line-height: ${({ buttonSize }) => buttonSize === undefined || buttonSize === "xl" || buttonSize === "l" ? "1.5rem" : "1.25rem"};
  box-sizing: border-box;
  outline: none;
  border: 1px solid transparent;
  user-select: none;
  cursor: pointer;
  color: ${({ color }) => color};
  ${space}
  
  &[disabled] {
    pointer-events: none;
  }

  &:focus {
    outline: 4px solid #CEC3F8;
  }

  ${StyledIcon} {
    transition: none;
  }
`;
export const ButtonWrapper = styled.span `
  position: relative;
  display: inline-block;
  width: ${({ width }) => (width ? (typeof width === "string" ? width : `${width}px`) : "auto")};
  height: ${({ height }) => (height ? (typeof height === "string" ? height : `${height}px`) : "auto")};
  cursor: ${({ disabled }) => disabled ? "not-allowed" : undefined};
  
  & > span {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  
  &:hover {
    ${Icon} {      
      svg {
        transition: .1s ease-in-out;
        color: ${({ iconHoverColor }) => iconHoverColor};
      }
    }
  }
`;
export const DisabledAnchor = styled.span `
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
`;
export const PrimaryButtonContainer = styled(BaseButton) `
  background-color: #9D87F1;
  color: ${({ fontColor }) => fontColor ?? "#ffffff"};
  
  &[disabled] {
    background-color: #CEC3F8;
  }

  &:hover {
    background-color: #7E6CC1;
  }
  
  &:active {
    background-color: #6E5CB2;
  }
`;
export const OutlineButtonContainer = styled(BaseButton) `
  background-color: transparent;
  color: ${({ fontColor }) => fontColor ?? "#000000"};
  border: 1px solid #9D87F1;
  
  &[disabled] {
    color: #9F9F9F;
    border-color: #E3E3E3;
  }

  &:hover {
    color: #7E6CC1;
    border-color: #7E6CC1;
  }
  
  &:active {
    color: #6E5CB2;
    border-color: #6E5CB2;
  }
  
  &:focus {
    border-color: #9D87F1;
    color: #000000;
  }
`;
export const OutlineWhiteButtonContainer = styled(BaseButton) `
  background-color: transparent;
  color: ${({ fontColor }) => fontColor ?? "#FFFFFF"};
  border: 1px solid #FFFFFF;
  
  &[disabled] {
    opacity: 0.6;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.80);
    border-color: rgba(255, 255, 255, 0.80);
  }
  
  &:active {
    color: rgba(255, 255, 255, 0.60);
    border-color: rgba(255, 255, 255, 0.60);
  }
`;
export const WhiteButtonContainer = styled(BaseButton) `
  background-color: #FFFFFF;
  color: ${({ fontColor }) => fontColor ?? "#000000"};
  
  &[disabled] {
    background-color: #F3F3F3;
    color: #9F9F9F;
  }

  &:hover {
    background-color: #F3F3F3;
    color: #7E6CC1;
  }
  
  &:active {
    background-color: #F3F3F3;
    color: #6E5CB2;
  }
`;
export const YellowButtonContainer = styled(BaseButton) `
  background-color: #FFD633;
  color: ${({ fontColor }) => fontColor ?? "#000000"};
  
  &[disabled] {
    background-color: #F6C544;
    color: rgba(0, 0, 0, 0.60);
  }

  &:hover {
    background-color: #F6C544;
    color: #000000;
  }
  
  &:active {
    background-color: #F6B148;
    color: #000000;
  }
`;
export const GrayButtonContainer = styled(BaseButton) `
  background-color: #F3F3F3;
  color: ${({ fontColor }) => fontColor ?? "#000000"};
  
  &[disabled] {
    background-color: #E3E3E3;
    color: #666666;
  }

  &:hover {
    background-color: #EBE7FC;
    color: #6E5CB2;
  }
  
  &:active {
    background-color: #EBE7FC;
    color: #60519C;
  }
`;
