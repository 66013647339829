import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Address, DetailsInnerSection, Label, LargeValue, Subtitle } from "./styled";
import { Badge, Link, Divider, Flex, FlexSpan, H4, EllipsisText, Switch } from "../../../ui";
import { HorizontalBarChart } from "../../HorizontalBarChart";
import { PointDetailsBlock } from "../../PointDetailsBlock";
import { PointDetailsBadge } from "../../PointDetailsBadge";
import { InfoIcon } from "../../InfoIcon";
import { DIALOGS } from "../../../constants/dialogs";
import { useDialog, useRenderTooltip } from "../../../hooks";
import { formatNumber } from "../../../utils";
export const DemandBlock = ({ data }) => {
    const openDialog = useDialog();
    const [searchParams, setSearchParams] = useSearchParams();
    const urlExcludedCompetitors = useMemo(() => searchParams.get("excludedCompetitors"), [searchParams]);
    const renderTooltip = useRenderTooltip();
    const excludedCompetitors = useMemo(() => (urlExcludedCompetitors ? JSON.parse(urlExcludedCompetitors) : null), [urlExcludedCompetitors]);
    const newBuildings = searchParams.get("newBuildings");
    const processedCompetitors = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.statistic.competitors
            .filter(item => !(excludedCompetitors || []).includes(item.id))
            .sort((a, b) => {
            return a.properties.dist - b.properties.dist;
        });
    }, [data, excludedCompetitors]);
    return (_jsxs(PointDetailsBlock, { noPadding: true, title: (_jsxs(FlexSpan, { display: "inline-flex", alignItems: "center", children: ["\u0421\u043F\u0440\u043E\u0441", _jsx(InfoIcon, { containerStyle: { position: "relative", top: "0.0625rem" }, content: "Платежеспособный спрос всех потребителей в локации, " +
                        "ограниченной зоной обслуживания новой торговой / сервисной точки (емкость рынка)." })] })), subtitle: _jsx(Subtitle, { children: "\u0442\u044B\u0441. \u0440\u0443\u0431./\u0433\u043E\u0434" }), titleExtra: _jsx(LargeValue, { children: formatNumber(Math.round(data.statistic.demand.values[0].value)) }), icon: "dash", children: [_jsxs(DetailsInnerSection, { p: "0 1rem 1rem 1rem", children: [data.statistic.supply.values.some(value => value.value > 0) && (_jsx(Flex, { column: true, mb: "1rem", children: _jsx(FlexSpan, { children: _jsx(HorizontalBarChart, { withoutXScale: true, withTooltip: true, stackedTooltip: true, renderTooltip: renderTooltip, data: [
                                    [
                                        {
                                            name: "Реализованный",
                                            value: +data.statistic.supply.values[0]?.value.toFixed(2),
                                            color: "#9D87F1",
                                        },
                                        {
                                            name: "Нереализованный",
                                            value: +data.statistic.supply.values[1]?.value.toFixed(2),
                                            color: "#FFD633",
                                        },
                                    ],
                                ] }) }) })), _jsxs(Flex, { justifyContent: "space-between", children: [_jsxs(FlexSpan, { children: [_jsx(H4, { children: formatNumber(data.statistic.supply.values[0]?.value) }), _jsx(Label, { children: "\u0420\u0435\u0430\u043B\u0438\u0437\u043E\u0432\u0430\u043D\u043D\u044B\u0439" })] }), _jsxs(FlexSpan, { display: "flex", flexDirection: "column", alignItems: "end", children: [_jsx(H4, { children: formatNumber(data.statistic.supply.values[1]?.value) }), _jsx(Label, { children: "\u041D\u0435\u0440\u0435\u0430\u043B\u0438\u0437\u043E\u0432\u0430\u043D\u043D\u044B\u0439" })] })] })] }), data.statistic.dev_available && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsxs(DetailsInnerSection, { p: "1rem", children: [_jsxs(Flex, { alignItems: "center", mb: "0.75rem", children: [_jsx(FlexSpan, { flexGrow: 1, children: _jsxs(FlexSpan, { display: "inline-flex", alignItems: "center", children: [_jsx(H4, { children: "\u041D\u043E\u0432\u043E\u0441\u0442\u0440\u043E\u0439\u043A\u0438" }), _jsx(InfoIcon, { containerStyle: { position: "relative", top: "0.0625rem" }, content: "Многоквартирные жилые здания, расположенные в зоне обслуживания " +
                                                        "новой торговой/сервисной точки, которые строятся в настоящее время " +
                                                        "или будут построены в ближайшие 3 года (данные ЕИСЖС)." })] }) }), _jsx(FlexSpan, { ml: "0.5rem", children: _jsx(Switch, { checked: newBuildings === "true", onChange: () => {
                                                setSearchParams({
                                                    ...Object.fromEntries(searchParams.entries()),
                                                    newBuildings: newBuildings === "true" ? "false" : "true",
                                                });
                                            } }) })] }), _jsx(Label, { children: "\u0423\u0447\u0438\u0442\u044B\u0432\u0430\u0442\u044C \u043D\u043E\u0432\u043E\u0441\u0442\u0440\u043E\u0439\u043A\u0438, \u043F\u0440\u0438\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044E\u0449\u0438\u0435 \u0432 \u043B\u043E\u043A\u0430\u0446\u0438\u0438" })] })] })), data.statistic.competitors.length > 1 && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsxs(DetailsInnerSection, { p: "1rem", children: [_jsxs(Flex, { alignItems: "center", mb: "0.75rem", children: [_jsx(FlexSpan, { children: _jsx(H4, { children: "\u041A\u043E\u043D\u043A\u0443\u0440\u0435\u043D\u0442\u044B" }) }), _jsx(FlexSpan, { ml: "0.5rem", children: _jsx(Badge, { small: true, backgroundColor: "#FFD633", children: processedCompetitors.length === 0
                                                ? 0
                                                : data.statistic.competitors.length > processedCompetitors.length
                                                    ? `${processedCompetitors.length} из ${data.statistic.competitors.length}`
                                                    : data.statistic.competitors.length }) }), _jsx(FlexSpan, { display: "flex", children: _jsx(InfoIcon, { containerStyle: { position: "relative", top: "0.0625rem" }, content: "Торговые/сервисные точки, предлагающие полностью " +
                                                "или частично схожий ассортимент товаров и услуг потенциальным " +
                                                "потребителям новой торговой/сервисной точки." }) })] }), processedCompetitors.length > 0 && (_jsx(PointDetailsBadge, { faded: true, width: "100%", title: _jsxs(Flex, { justifyContent: "space-between", children: [_jsx(FlexSpan, { flexGrow: 1, mr: "1rem", children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { children: _jsx(EllipsisText, { children: processedCompetitors[0].properties.name }) }), _jsx(FlexSpan, { children: _jsx(Address, { children: processedCompetitors[0].properties.address }) })] }) }), _jsx(FlexSpan, { children: _jsxs(Badge, { backgroundColor: "#F3F3F3", color: "#000000", small: true, children: [formatNumber(processedCompetitors[0].properties.dist), "\u043C"] }) })] }), listItems: [
                                    {
                                        label: "Тип",
                                        value: data.statistic.calc_styles.find(item => item.styleId === processedCompetitors[0].properties.styleId)?.legendLabel,
                                    },
                                ], listStyle: "row" })), _jsx(Flex, { justifyContent: "center", children: _jsx(FlexSpan, { children: _jsx(Link, { icon: "right_2", iconPlacement: "right", onClick: () => openDialog(DIALOGS.COMPETITORS_DIALOG, {
                                            statistic: data.statistic,
                                        }), children: "\u0421\u043F\u0438\u0441\u043E\u043A \u043A\u043E\u043D\u043A\u0443\u0440\u0435\u043D\u0442\u043E\u0432" }) }) })] })] }))] }));
};
