import styled from "styled-components";
export const GanttMonthsTitle = styled.div `
  color: #9F9F9F;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: right;
`;
export const GanttGroupItemTitle = styled.div `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-align: right;
`;
export const GanttMonthLabel = styled.div `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-align: center;
`;
export const GanttMonthBar = styled.div `
  height: 0.5rem;
  border-top-left-radius: ${({ active, hasLeftNeighbor }) => active && hasLeftNeighbor ? 0 : "0.5rem"};
  border-bottom-left-radius: ${({ active, hasLeftNeighbor }) => active && hasLeftNeighbor ? 0 : "0.5rem"};
  border-top-right-radius: ${({ active, hasRightNeighbor }) => active && hasRightNeighbor ? 0 : "0.5rem"};
  border-bottom-right-radius: ${({ active, hasRightNeighbor }) => active && hasRightNeighbor ? 0 : "0.5rem"};
  margin-left: ${({ active, hasLeftNeighbor }) => active && hasLeftNeighbor ? 0 : "0.125rem"};
  margin-right: ${({ active, hasRightNeighbor }) => active && hasRightNeighbor ? 0 : "0.125rem"};
  background: ${({ active, barColor }) => active ? (barColor ?? "#9D87F1") : "#F0F1F3"};
  box-sizing: border-box;
`;
