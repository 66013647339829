import styled, { css, keyframes } from "styled-components";
import { flexbox } from "styled-system";
const animation = keyframes `
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const PopoverAnchor = styled.div `
  display: flex;
`;
export const PopoverWrapper = styled.div `
  display: inline-block;
  position: relative;
`;
export const PopoverChildrenWrapper = styled.div `
  display: flex;
  position: absolute;
  top: ${({ top }) => top !== undefined ? `${top}px` : 0};
  left: ${({ left }) => left !== undefined ? `${left}px` : 0};
  width: ${({ width }) => width !== undefined ? `${width}px` : 0};
  height: ${({ height }) => height !== undefined ? `${height}px` : 0};
  animation: ${({ animateFadeIn }) => animateFadeIn ? css `${animation} 100ms ease-in` : undefined};
  ${flexbox}
`;
export const PopoverChildrenContainer = styled.div `
  position: absolute;
  top: ${({ top }) => top !== undefined ? `${top}px` : undefined};
  right: ${({ right }) => right !== undefined ? `${right}px` : undefined};
  bottom: ${({ bottom }) => bottom !== undefined ? `${bottom}px` : undefined};
  left: ${({ left }) => left !== undefined ? `${left}px` : undefined};
  width: auto;
  height: fit-content;
  border-radius: 0.75rem;
  background-color: #ffffff;
  box-shadow: 0 0.24rem 2.75rem 0 rgba(63, 67, 106, 0.30);
  padding: 0.5rem;
  box-sizing: border-box;
  z-index: 501;
`;
