import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { PopoverAnchor, PopoverChildrenContainer, PopoverChildrenWrapper, PopoverWrapper } from "./styled";
import { useClickOutside, useResize } from "../../hooks";
export const Popover = ({ id, anchor, isOpen, onOpen, onClose, placement = "bottom-left", offsetX, offsetY, disabled, animateFadeIn, anchorStyle, wrapperStyle, containerStyle, children, }) => {
    const [selfOpen, setSelfOpen] = useState(isOpen ?? false);
    const childrenRef = useRef(null);
    const childrenClickedRef = useRef(false);
    const [anchorRect, setAnchorRect] = useState({});
    const wrapperRef = useClickOutside(() => {
        if (selfOpen && !childrenClickedRef.current) {
            setSelfOpen(false);
            onClose?.();
        }
        childrenClickedRef.current = false;
    }, [selfOpen]);
    const updateOffset = useCallback(() => {
        if (childrenRef.current) {
            const { left, top, width, height } = childrenRef.current.getBoundingClientRect();
            setAnchorRect({ top, left, width, height });
        }
    }, []);
    const onChildrenClick = useCallback(() => {
        childrenClickedRef.current = true;
    }, []);
    useResize(updateOffset, 1);
    useEffect(() => {
        setSelfOpen(isOpen);
    }, [isOpen]);
    useEffect(() => {
        if (selfOpen) {
            updateOffset();
            onOpen?.();
        }
    }, [selfOpen]); // eslint-disable-line
    useLayoutEffect(() => {
        updateOffset();
    }, []); // eslint-disable-line
    return (_jsxs(PopoverWrapper, { ref: wrapperRef, style: wrapperStyle, children: [_jsx(PopoverAnchor, { ref: childrenRef, onClick: () => !disabled && setSelfOpen(true), style: anchorStyle, children: anchor }), selfOpen && Object.keys(anchorRect).length > 0 &&
                createPortal(_jsx(PopoverChildrenWrapper, { top: anchorRect.top, left: anchorRect.left, width: anchorRect.width, height: anchorRect.height, alignItems: ["top-left", "top-right"].includes(placement) ? "flex-end" : "flex-start", justifyContent: (["top", "bottom"].includes(placement)
                        ? "center"
                        : ["top-left", "bottom-left"].includes(placement)
                            ? "left"
                            : "right"), animateFadeIn: animateFadeIn, onClick: onChildrenClick, children: _jsx(PopoverChildrenContainer, { id: `msp-bn-popover${id ? `-${id}` : ""}`, top: ["bottom-left", "bottom-right", "bottom"].includes(placement) ? anchorRect.height + (offsetY ?? 0) : undefined, right: ["top-right", "bottom-right"].includes(placement) ? offsetX : undefined, bottom: ["top-left", "top-right", "top"].includes(placement) ? anchorRect.height + (offsetY ?? 0) : undefined, left: ["top-left", "bottom-left"].includes(placement) ? offsetX : undefined, style: containerStyle, children: children }) }), document.body)] }));
};
