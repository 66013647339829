export const loadScript = (url) => {
    return new Promise((resolve, reject) => {
        let ready = false;
        const tags = document.getElementsByTagName("script")[0];
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.async = true;
        script.onload = function checkReady() {
            if (!ready) {
                ready = true;
                resolve(this);
            }
        };
        script.onerror = reject;
        script.onabort = reject;
        tags.parentNode.insertBefore(script, tags);
    });
};
