export const DIALOGS = {
    AUTHORIZATION_NEEDED_DIALOG: "AUTHORIZATION_NEEDED_DIALOG",
    CITY_PICKER_DIALOG: "CITY_PICKER_DIALOG",
    BUSINESS_CARD_DIALOG: "BUSINESS_CARD_DIALOG",
    PROM_BUSINESS_CARD_DIALOG: "PROM_BUSINESS_CARD_DIALOG",
    BUSINESS_INFO_DIALOG: "BUSINESS_INFO_DIALOG",
    COMPETITORS_DIALOG: "COMPETITORS_DIALOG",
    BEFORE_BUSINESS_PLAN_DIALOG: "BEFORE_BUSINESS_PLAN_DIALOG",
    BEFORE_PROM_BUSINESS_PLAN_DIALOG: "BEFORE_PROM_BUSINESS_PLAN_DIALOG",
    DOCUMENTS_LIST_DIALOG: "DOCUMENTS_LIST_DIALOG",
    LOCATION_SETTINGS_DIALOG: "RETAIL_LOCATION_SETTINGS_DIALOG",
    PROM_BUSINESS_PICKER_DIALOG: "PROM_BUSINESS_PICKER_DIALOG",
    RETAIL_INTEGRAL_INFO_DIALOG: "RETAIL_INTEGRAL_INFO_DIALOG",
    LOCATIONS_COMPARE_PARAMS_DIALOG: "LOCATION_COMPARE_PARAMS_DIALOG",
};
