import styled from "styled-components";
import { HorizontalBarChart as HorizontalBarChartUI, horizontalBarChartClassNames as classNames } from "@evergis/charts";
export const StyledHorizontalBarChart = styled(HorizontalBarChartUI) `
  .${classNames.horizontalBarChartBarFlex} {
    justify-content: flex-end;
    height: 0.5rem;
    
    .${classNames.horizontalBarChartStackWrapper} {
      justify-content: flex-end;
      cursor: pointer;
    }
    
    .${classNames.horizontalBarChartBar} {
      cursor: pointer;
      min-width: 0.5rem;
    }
    
    .${classNames.horizontalBarChartBarOnly} {
      border-radius: 0.25rem;
    }
    
    .${classNames.horizontalBarChartBarFirst} {
      border-radius: 0.25rem 0 0 0.25rem;
    }
    
    .${classNames.horizontalBarChartBarLast} {
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }

  .${classNames.horizontalBarChartLabelTd} {
    display: none;
  }

  td {
    padding-bottom: 0.25rem;
  }

  tr:last-of-type {
    td {
      padding-bottom: 0;
    }
  }
`;
