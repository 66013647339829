import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DetailsInnerSection, Label, LargeValue, Subtitle } from "./styled";
import { Flex, FlexSpan, H4 } from "../../../../ui";
import { PointDetailsBlock } from "../../../../components/PointDetailsBlock";
import { HorizontalBarChart } from "../../../../components/HorizontalBarChart";
import { useRenderTooltip } from "../../../../hooks";
import { formatNumber } from "../../../../utils";
export const FormatBlock = ({ data }) => {
    const renderTooltip = useRenderTooltip();
    return (_jsx(PointDetailsBlock, { noPadding: true, title: "\u0420\u044B\u043D\u043E\u0447\u043D\u0430\u044F \u043D\u0438\u0448\u0430", subtitle: _jsx(Subtitle, { children: "\u043C\u043B\u043D \u0440\u0443\u0431." }), titleExtra: _jsx(LargeValue, { children: formatNumber(Math.round(data?.statistic?.demand.values[0].value)) }), icon: "dash", children: _jsxs(DetailsInnerSection, { p: "0 1rem 1rem 1rem", children: [data?.statistic?.supply.values.some(value => value.value > 0) && (_jsx(Flex, { column: true, mb: "1rem", children: _jsx(FlexSpan, { children: _jsx(HorizontalBarChart, { withoutXScale: true, withTooltip: true, stackedTooltip: true, renderTooltip: renderTooltip, data: [
                                [
                                    { name: "Предложение", value: +data?.statistic?.supply.values[0]?.value.toFixed(2), color: "#9D87F1" },
                                    { name: "Потребление", value: +data?.statistic?.consumption.values[0]?.value.toFixed(2), color: "#FFD633" },
                                ],
                            ] }) }) })), _jsxs(Flex, { justifyContent: "space-between", children: [_jsxs(FlexSpan, { children: [_jsx(H4, { children: formatNumber(data?.statistic?.supply.values[0]?.value) }), _jsx(Label, { children: "\u041F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0435" })] }), _jsxs(FlexSpan, { display: "flex", flexDirection: "column", alignItems: "end", children: [_jsx(H4, { children: formatNumber(data?.statistic?.consumption.values[0]?.value) }), _jsx(Label, { children: "\u041F\u043E\u0442\u0440\u0435\u0431\u043B\u0435\u043D\u0438\u0435" })] })] })] }) }));
};
