import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StyledCard } from "./styled";
import { Badge, Flex, FlexSpan, H3 } from "../../../../ui";
import { CategoryIcon } from "../../../../components/CategoryIcon";
import { DIALOGS } from "../../../../constants/dialogs";
import { EnterpriseLocationUrl } from "../../../../constants/routes";
import { useDialog } from "../../../../hooks";
export const PromBusinessCard = ({ businessItem }) => {
    const openDialog = useDialog();
    return (_jsxs(StyledCard, { flat: true, height: "12rem", onClick: () => {
            openDialog(DIALOGS.PROM_BUSINESS_CARD_DIALOG, {
                businessItem,
                forwardPath: EnterpriseLocationUrl.path,
            });
        }, children: [_jsxs(Flex, { justifyContent: "space-between", mb: "2rem", flexGrow: 1, children: [_jsx(FlexSpan, { children: _jsx(CategoryIcon, { path: businessItem.ico, size: "3rem" }) }), _jsx(FlexSpan, { children: _jsx(Badge, { children: businessItem.business_concepts.length }) })] }), _jsx(H3, { children: businessItem.name })] }));
};
