export const STORAGE_TOKEN = "msp-bn";
export const STORAGE_CITY = "msp-city";
export const STORAGE_SUBJECT = "msp-subject";
export const EMPTY_VALUE = "—";
export const MIN_SEARCH_LENGTH = 2;
export const COMPETITORS_LAYER_ID = 10;
export const DEFAULT_SUBJECT_ID = 85;
export const MONTH_SHORT_NAMES = [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
];
export const MONTH_FULL_NAMES = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
];
export const SN_USER_GROUP_NAME = "social_network_user";
export const ENTREPRENEUR_FORM_ID = 1;
