import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { SideBar } from "../SideBar";
import { BackButton, Divider, Flex, FlexSpan, OutlineButton, PrimaryButton } from "../../ui";
import { MobileFormItem } from "./MobileFormItem";
export const MobileForm = ({ data, defaultParams, items = [], onClose, onApply }) => {
    const [newValues, setNewValues] = useState({});
    return (_jsx(SideBar, { fullscreen: true, children: _jsxs(Flex, { column: true, height: "100%", children: [_jsx(FlexSpan, { p: "1rem 1.25rem 2.5rem", children: _jsx(BackButton, { label: "\u041F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u044B", onClick: onClose }) }), _jsx(FlexSpan, { flexGrow: 1, p: "0 2.5rem 1.5rem", height: "100%", overflowY: "auto", children: _jsx(Flex, { column: true, children: items.map((item, index) => (_jsx(FlexSpan, { width: "100%", mb: index < items.length - 1 ? "1.5rem" : 0, children: _jsx(MobileFormItem, { data: data, defaultParams: defaultParams, onValuesChange: values => {
                                    setNewValues(prevState => ({ ...prevState, ...values }));
                                }, ...item }) }, `mobile-form-item-${index}`))) }) }), _jsx(FlexSpan, { children: _jsx(Divider, {}) }), _jsx(FlexSpan, { p: "1.5rem 2.5rem", children: _jsxs(Flex, { justifyContent: "end", gap: "1rem", children: [_jsx(FlexSpan, { children: _jsx(OutlineButton, { buttonSize: "s", onClick: onClose, children: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C" }) }), _jsx(FlexSpan, { children: _jsx(PrimaryButton, { buttonSize: "s", onClick: () => onApply?.(newValues), children: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C \u0438 \u043F\u0435\u0440\u0435\u0441\u0447\u0438\u0442\u0430\u0442\u044C" }) })] }) })] }) }));
};
