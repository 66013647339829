export var YandexLocales;
(function (YandexLocales) {
    YandexLocales["RU"] = "ru_RU";
    YandexLocales["EN"] = "en_US";
})(YandexLocales || (YandexLocales = {}));
export var YandexMapType;
(function (YandexMapType) {
    YandexMapType["Default"] = "yandex#map";
    YandexMapType["Hybrid"] = "yandex#hybrid";
})(YandexMapType || (YandexMapType = {}));
