import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { isNil } from "lodash";
import { Flex, FlexSpan, Icon, Modal, OutlineButton, PrimaryButton, Select } from "../../../ui";
import { AlertMessage } from "./styled";
import { EMPTY_VALUE } from "../../../constants/common";
import { useCurrentCity, useDialogProps, useDialogReset, useUpdatedLocationParams, useUser } from "../../../hooks";
import { getWktPoint } from "../../../utils";
import { api } from "../../../api";
import { AppSection } from "../../../slices/app/types";
export const LocationSettingsDialog = () => {
    const closeDialog = useDialogReset();
    const [user] = useUser();
    const [searchParams, setSearchParams] = useSearchParams();
    const [, setUpdatedParams] = useUpdatedLocationParams();
    const [currentCity] = useCurrentCity();
    const urlPoint = useMemo(() => searchParams.get("point"), [searchParams]);
    const point = useMemo(() => !isNil(urlPoint) ? JSON.parse(urlPoint) : null, [urlPoint]);
    const urlZone = useMemo(() => searchParams.get("zone"), [searchParams]);
    const urlFranchise = useMemo(() => searchParams.get("franchise"), [searchParams]);
    const urlExcludedCompetitors = useMemo(() => searchParams.get("excludedCompetitors"), [searchParams]);
    const newBuildings = searchParams.get("newBuildings");
    const [{ section, formats, currentFormatId, currentSize }] = useDialogProps();
    const [formatId, setFormatId] = useState(currentFormatId);
    const [size, setSize] = useState(currentSize);
    const [zone, setZone] = useState(urlZone);
    const excludedCompetitors = useMemo(() => urlExcludedCompetitors ? JSON.parse(urlExcludedCompetitors) : null, [urlExcludedCompetitors]);
    const [franchiseId, setFranchiseId] = useState(urlFranchise ? +urlFranchise : undefined);
    const formatsOptions = useMemo(() => formats.map(item => ({ value: item.id, label: item.name })), [formats]);
    const [sizeOptions, setSizeOptions] = useState([]);
    const [zoneOptions, setZoneOptions] = useState([]);
    const [franchiseOptions, setFranchiseOptions] = useState([]);
    useEffect(() => {
        if (section === AppSection.Enterprise || !currentCity || !zone) {
            return;
        }
        const newFranchiseOptions = formats.find(item => item.id === formatId)?.franchises?.map(item => ({ value: item.id, label: item.name })) ?? [];
        setFranchiseOptions(newFranchiseOptions);
        if (!newFranchiseOptions.find(item => item.value === franchiseId)) {
            setFranchiseId(undefined);
        }
        (section === AppSection.Retail
            ? api.statistic.getStatistic(formatId, currentCity.id, getWktPoint(point), excludedCompetitors, zone, newBuildings, user.guid)
            : api.statistic.getSelfStatistic(formatId, currentCity.id, getWktPoint(point), excludedCompetitors, zone, newBuildings, user.guid)).then(statistics => {
            const newSizeOptions = statistics.businessplans.map(item => ({ value: item.id, label: item.name }));
            setSizeOptions(newSizeOptions);
            setZoneOptions(statistics.zone?.values.map(item => ({ value: item.value, label: item.name })));
            setSize(newSizeOptions.find(item => item.value === currentSize) ? currentSize : newSizeOptions?.[0]?.value);
        });
    }, [formatId]); // eslint-disable-line
    return (_jsx(Modal, { title: "\u041F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u044B", width: "25rem", content: (_jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { pb: "0.25rem", mb: "1.25rem", children: _jsx(Select, { label: section === AppSection.Enterprise ? "Площадь" : "Ценовая ниша", options: formatsOptions, value: formatsOptions.find(item => item.value === formatId), onChange: option => setFormatId(option.value), disabled: formatsOptions.length <= 1 }) }), section !== AppSection.Enterprise && (_jsx(FlexSpan, { pb: "0.25rem", mb: "1.25rem", children: _jsx(Select, { label: "\u041C\u0430\u0441\u0448\u0442\u0430\u0431", options: sizeOptions, value: sizeOptions.find(item => item.value === size), onChange: option => setSize(option.value), disabled: sizeOptions.length <= 1, autoMenuWidth: true }) })), section !== AppSection.Enterprise && (_jsx(FlexSpan, { pb: "0.25rem", mb: "1.25rem", children: _jsx(Select, { label: "\u0417\u043E\u043D\u0430 \u043E\u0431\u0441\u043B\u0443\u0436\u0438\u0432\u0430\u043D\u0438\u044F", options: zoneOptions, value: zoneOptions.find(item => item.value === zone), onChange: option => setZone(option.value), disabled: zoneOptions.length <= 1 }) })), section === AppSection.Retail && (_jsx(FlexSpan, { pb: "0.25rem", children: _jsx(Select, { label: "\u0424\u0440\u0430\u043D\u0448\u0438\u0437\u0430", options: franchiseOptions.length > 0 ? [
                            { value: "", label: EMPTY_VALUE },
                            ...franchiseOptions,
                        ] : franchiseOptions, value: franchiseId ? franchiseOptions.find(item => item.value === franchiseId) : null, onChange: option => setFranchiseId(option.value), disabled: franchiseOptions.length <= 1 }) })), section === AppSection.Enterprise && (_jsx(FlexSpan, { children: _jsxs(AlertMessage, { children: [_jsx(FlexSpan, { mr: "0.75rem", children: _jsx(Icon, { icon: "alert_symbol" }) }), _jsx(FlexSpan, { children: "\u041F\u0440\u0438 \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u0438 \u043F\u043B\u043E\u0449\u0430\u0434\u0438, \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0437\u0430\u043D\u043E\u0432\u043E \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u043B\u043E\u0449\u0430\u0434\u043A\u0443" })] }) }))] })), footer: (_jsxs(Flex, { alignItems: "center", gap: "1rem", justifyContent: "right", children: [_jsx(FlexSpan, { children: _jsx(OutlineButton, { buttonSize: "s", onClick: closeDialog, children: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C" }) }), _jsx(FlexSpan, { children: _jsx(PrimaryButton, { buttonSize: "s", onClick: () => {
                            if (section !== AppSection.Enterprise) {
                                setSearchParams({
                                    ...Object.fromEntries(searchParams.entries()),
                                    formatId: formatId.toString(),
                                    size: size?.toString() ?? "",
                                    zone,
                                    excludedCompetitors: "",
                                    franchise: franchiseId?.toString() ?? "",
                                });
                                if (formatId !== currentFormatId || size !== currentSize) {
                                    setUpdatedParams({
                                        formatId: formatId !== currentFormatId,
                                        size: size !== currentSize,
                                    });
                                }
                            }
                            if (section === AppSection.Enterprise) {
                                if (formatId !== currentFormatId) {
                                    const newSearchParam = { ...Object.fromEntries(searchParams.entries()) };
                                    delete newSearchParam.objectId;
                                    setSearchParams({
                                        ...newSearchParam,
                                        formatId: formatId.toString(),
                                    });
                                    setUpdatedParams({
                                        formatId: true,
                                    });
                                }
                            }
                            closeDialog();
                        }, children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C" }) })] })) }));
};
