import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Flex, FlexSpan, Icon, PrimaryButton } from "../../../../ui";
import { EnterpriseLocationUrl, RetailLocationUrl } from "../../../../constants/routes";
import { useCurrentCity, useCurrentFeature, useCurrentSubject, useDialogProps, useDialogReset, useOverlayFeature, useSection, useSubjectList, } from "../../../../hooks";
import { AppSection } from "../../../../slices/app/types";
export const BusinessInfoDialogFooter = () => {
    const [{ businessConcept, businessId }] = useDialogProps();
    const [currentCity] = useCurrentCity();
    const [subjectList] = useSubjectList();
    const [, setCurrentSubject] = useCurrentSubject();
    const [currentFeature] = useCurrentFeature();
    const [, setOverlayFeature] = useOverlayFeature();
    const closeDialog = useDialogReset();
    const navigate = useNavigate();
    const [section] = useSection();
    const goToLocation = useCallback(() => {
        if (section === AppSection.RetailIntegral) {
            setOverlayFeature(currentFeature);
            navigate(generatePath(RetailLocationUrl.path, {
                cityId: currentCity.id,
                businessId,
                conceptId: businessConcept?.id,
            }));
        }
        if (section === AppSection.EnterpriseIntegral) {
            setCurrentSubject(subjectList.features.find(item => item.id === currentFeature.id));
            navigate(generatePath(EnterpriseLocationUrl.path, {
                subjectId: currentFeature.id,
                businessId,
                conceptId: businessConcept?.id,
            }));
        }
        closeDialog();
    }, [currentFeature, section, closeDialog, navigate, currentCity, businessId, businessConcept, subjectList]); // eslint-disable-line
    return (_jsxs(Flex, { children: [_jsx(FlexSpan, { children: _jsx(Icon, { icon: "info", size: 20, color: "#7E6CC1" }) }), _jsx(FlexSpan, { margin: "0 1.625rem 0 1.375rem", fontSize: "1rem", children: "\u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u043F\u043E\u0434\u043E\u0431\u0440\u0430\u0442\u044C \u043B\u043E\u043A\u0430\u0446\u0438\u044E \u0434\u043B\u044F \u0434\u0430\u043D\u043D\u043E\u0433\u043E \u0431\u0438\u0437\u043D\u0435\u0441\u0430 \u0438\u00A0\u0440\u0430\u0441\u0447\u0438\u0442\u0430\u0442\u044C \u0431\u0438\u0437\u043D\u0435\u0441-\u043F\u043B\u0430\u043D" }), _jsx(FlexSpan, { children: _jsx(PrimaryButton, { buttonSize: "s", noWrap: true, onClick: goToLocation, disabled: !currentCity, children: "\u041F\u043E\u0434\u043E\u0431\u0440\u0430\u0442\u044C \u043B\u043E\u043A\u0430\u0446\u0438\u044E" }) })] }));
};
