import styled from "styled-components";
import { Flex, FlexSpan } from "../../ui";
export const PointDetailsBadgeContainer = styled(FlexSpan) `
  position: relative;
  border-radius: 0.5rem;
  padding: 0.66rem 0.75rem;
  box-sizing: border-box;
  background-color: #ffffff;
`;
export const PointDetailsBadgeTitle = styled.div `
  position: relative;
  top: -0.125rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;
export const PointDetailsList = styled(Flex) `
  align-items: baseline;
  gap: 0.12rem;
`;
export const PointDetailsListLabel = styled.span `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #666666;
`;
export const PointDetailsListValue = styled.span `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 0.25rem;
`;
export const Fader = styled.div `
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(240, 241, 243, 0.50) 0%, #F0F1F3 100%);
`;
