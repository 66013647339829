import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useRef, useState } from "react";
import { Flex, FlexSpan } from "../Grid";
import { H5 } from "../Typography";
import { TableBodyCell, TableHeaderCell, TableHeaderSubtitle, TableWrapper } from "./styled";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
export const Table = ({ width, stickyFirstColumn, headCellHeight, bodyCellHeight, cellGap, columns = [], rows = [], }) => {
    const stickyFirstColHeaderRef = useRef(null);
    const [stickyHeader, setStickyHeader] = useState(null);
    const [stickyHeaderHeight, setStickyHeaderHeight] = useState();
    useLayoutEffect(() => {
        if (stickyFirstColHeaderRef.current && stickyHeader) {
            setStickyHeaderHeight(Math.max(stickyFirstColHeaderRef.current?.getBoundingClientRect().height, stickyHeader?.getBoundingClientRect().height));
        }
    }, [stickyHeader]);
    return (_jsx(TableWrapper, { width: width, children: _jsx(FlexSpan, { overflowX: !stickyFirstColumn ? "auto" : undefined, children: stickyFirstColumn && columns.length > 0 ? (_jsx(_Fragment, { children: _jsxs(Flex, { gap: cellGap ?? "0.25rem", width: "100%", children: [_jsxs(FlexSpan, { children: [_jsx(TableHeaderCell, { width: columns[0].width, align: columns[0].align, ref: stickyFirstColHeaderRef, height: headCellHeight ?? stickyHeaderHeight, isCustomRender: columns[0].renderHead !== undefined, mb: `calc(${cellGap ?? "0.25rem"} + 0.5rem)`, children: columns[0].renderHead !== undefined
                                        ? columns[0].renderHead(columns[0])
                                        : (_jsxs(_Fragment, { children: [_jsx(H5, { children: columns[0].title }), columns[0].subtitle && (_jsx(TableHeaderSubtitle, { children: columns[0].subtitle }))] })) }), rows.map((row, rowId) => {
                                    return (_jsx(Flex, { gap: cellGap ?? "0.25rem", children: _jsx(TableBodyCell, { align: columns[0].align, width: columns[0].width, height: bodyCellHeight, style: columns[0].bodyCellStyle, isCustomRender: columns[0].renderBody !== undefined, mb: cellGap ?? "0.25rem", onClick: e => row.onColumnClick?.(0, e), children: columns[0].renderBody !== undefined
                                                ? columns[0].renderBody(row, rowId)
                                                : row.data[columns[0].id] }) }, `table-body-sticky-row-${rowId}`));
                                })] }), _jsxs(FlexSpan, { flexGrow: 1, overflowX: "auto", children: [_jsx(TableHeader, { columns: columns.slice(1), cellHeight: headCellHeight ?? stickyHeaderHeight, cellGap: cellGap, onRender: setStickyHeader }), _jsx(TableBody, { bodyCellHeight: bodyCellHeight, columns: columns.slice(1), cellGap: cellGap, rows: rows })] })] }) })) : (_jsxs(_Fragment, { children: [_jsx(TableHeader, { cellHeight: headCellHeight, columns: columns, cellGap: cellGap }), _jsx(TableBody, { bodyCellHeight: bodyCellHeight, cellGap: cellGap, columns: columns, rows: rows })] })) }) }));
};
