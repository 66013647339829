import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, FlexSpan } from "../Grid";
import { MenuItemContainer } from "./styled";
import { Divider } from "../Divider";
export const Menu = ({ items = [], width, onPick }) => {
    return (_jsx(Flex, { column: true, width: width, children: items.map((item, itemIndex) => {
            if (item.divider) {
                return (_jsx(Divider, { mb: itemIndex < items.length - 1 && "0.25rem", mt: itemIndex === items.length - 1 && "0.25rem" }, `menu-item-${itemIndex}-divider`));
            }
            return (_jsx(MenuItemContainer, { active: item.active, onClick: e => {
                    e.stopPropagation();
                    if (!item.disabled) {
                        onPick && onPick(item);
                    }
                }, disabled: item.disabled, children: _jsxs(Flex, { alignItems: "center", children: [item.prefix !== undefined && (_jsx(FlexSpan, { mr: "1rem", display: "flex", children: item.prefix })), item.label !== undefined && (_jsx(FlexSpan, { flexGrow: 1, children: item.label }))] }) }, `menu-item-${itemIndex}`));
        }) }));
};
