export class Analytic {
    http;
    constructor(http) {
        this.http = http;
    }
    getAnalytic(formatId, cityId, point, zone, guid, signal) {
        return this.http.get(`/api/analytic/${formatId}/${cityId}`, {
            point,
            zone,
            guid,
        }, signal).json();
    }
    getSelfAnalytic(formatId, cityId, point, zone, guid, signal) {
        return this.http.get(`/api/self_analytic/${formatId}/${cityId}`, {
            point,
            zone,
            guid,
        }, signal).json();
    }
    getPromAnalytic(formatId, objectId, guid, signal) {
        return this.http.get(`/api/prom_analytic/${formatId}/${objectId}`, {
            guid,
        }, signal).json();
    }
}
