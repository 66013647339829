import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetDialog, setCurrentDialog, setDialogProps } from "../slices/dialog";
import { getDialogProps } from "../selectors/dialog";
export const useDialog = () => {
    const dispatch = useDispatch();
    return useCallback((dialogName, dialogProps) => {
        dispatch(setCurrentDialog(dialogName));
        dispatch(setDialogProps(dialogProps));
    }, [dispatch]);
};
export const useDialogReset = () => {
    const dispatch = useDispatch();
    return useCallback((e) => {
        if (e instanceof Event) {
            e.preventDefault();
            e.stopPropagation();
        }
        dispatch(resetDialog());
    }, [dispatch]);
};
export const useDialogProps = () => {
    const dispatch = useDispatch();
    const dialogProps = useSelector(getDialogProps);
    const updateDialogProps = useCallback((value) => {
        dispatch(setDialogProps(value));
    }, [dispatch]);
    return [(dialogProps ?? {}), updateDialogProps];
};
