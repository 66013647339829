import ky from "ky";
import { stringify } from "query-string";
import { STORAGE_TOKEN } from "../constants/common";
export class HttpClient {
    prefixUrl;
    ky;
    constructor(prefixUrl) {
        this.prefixUrl = prefixUrl;
        this.ky = ky.extend({ prefixUrl });
    }
    extend(options) {
        this.ky = ky.extend({
            ...options,
            hooks: {
                beforeRequest: [
                    request => {
                        const token = window.localStorage.getItem(STORAGE_TOKEN);
                        request.headers.set("Authorization", `Bearer ${token || ""}`);
                    },
                ],
                afterResponse: [
                    async (request, options, response) => {
                        if (response.status === 402) {
                            response.json().then((error) => {
                                if (error?.url) {
                                    window.location.href = error.url;
                                }
                            });
                        }
                    },
                ],
            },
        });
    }
    get(url, params, signal) {
        return this.ky.get(this.stripSlashes(url), {
            searchParams: qs(params),
            signal,
        });
    }
    post(url, body, params) {
        const options = kyOptions(params, body);
        return this.ky.post(this.stripSlashes(url), options);
    }
    put(url, body, params) {
        const options = kyOptions(params, body);
        return this.ky.put(this.stripSlashes(url), options);
    }
    patch(url, body, params) {
        const options = kyOptions(params, body);
        return this.ky.patch(this.stripSlashes(url), options);
    }
    delete(url, params) {
        return this.ky.delete(this.stripSlashes(url), {
            searchParams: qs(params),
        });
    }
    createUrl(url, params) {
        const queryString = params ? `?${qs(params)}` : "";
        return this.prefixUrl + this.stripSlashes(url) + queryString;
    }
    stripSlashes(url) {
        if (this.prefixUrl) {
            return url.replace(/^\//g, "");
        }
        return url;
    }
}
function kyOptions(params, body) {
    const options = { searchParams: qs(params) };
    if (!isNotObject(body) || Array.isArray(body)) {
        options.json = body;
    }
    else {
        options.body = body;
    }
    return options;
}
function isNotObject(value) {
    return !(value !== void 0 && typeof value === "object" && value !== null && value.constructor === Object);
}
function qs(params = {}) {
    return stringify(params, { arrayFormat: "comma" });
}
export function toFormData(input) {
    return Object.keys(input).reduce((data, key) => {
        data.append(key, input[key]);
        return data;
    }, new FormData());
}
