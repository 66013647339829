import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, FlexSpan, H3 } from "../../../../../ui";
import { PromBusinessFormat } from "../PromBusinessFormat";
import { SeasonBarChart } from "../SeasonBarChart";
import { SubjectsProfitList } from "../SubjectsProfitList";
export const PromBusinessInfoShort = ({ cardInfo }) => {
    if (!cardInfo) {
        return null;
    }
    const { description, business_formats, season_price, season_text, season, profit_subjects } = cardInfo;
    return (_jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { mb: "1rem", children: _jsx(H3, { children: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" }) }), _jsx(FlexSpan, { mb: "1.5rem", children: description }), _jsx(FlexSpan, { mb: "1rem", children: _jsx(H3, { children: "\u0414\u043E\u0441\u0442\u0443\u043F\u043D\u044B\u0435 \u0444\u043E\u0440\u043C\u0430\u0442\u044B \u043F\u0440\u0435\u0434\u043F\u0440\u0438\u044F\u0442\u0438\u0439" }) }), _jsx(Flex, { mb: "1.5rem", flexWrap: "wrap", gap: "1rem", children: business_formats.map(format => (_jsx(FlexSpan, { width: "calc(50% - 0.5rem)", children: _jsx(PromBusinessFormat, { format: format }, format.id) }))) }), _jsx(FlexSpan, { mb: "1rem", children: _jsx(H3, { children: "\u041F\u0440\u043E\u0433\u043D\u043E\u0437 \u043F\u0440\u0438\u0431\u044B\u043B\u044C\u043D\u043E\u0441\u0442\u0438 \u0438\u043D\u0432\u0435\u0441\u0442\u0438\u0446\u0438\u0439 \u0434\u0430\u043D\u043D\u043E\u0433\u043E \u0432\u0438\u0434\u0430 \u0431\u0438\u0437\u043D\u0435\u0441\u0430 \u0432 \u0440\u0435\u0433\u0438\u043E\u043D\u0430\u0445" }) }), _jsx(Flex, { mb: "1.5rem", children: _jsx(SubjectsProfitList, { data: profit_subjects }) }), _jsx(FlexSpan, { mb: "1rem", children: _jsx(H3, { children: "\u0421\u0435\u0437\u043E\u043D\u043D\u043E\u0441\u0442\u044C \u043F\u043E\u0442\u0440\u0435\u0431\u043B\u0435\u043D\u0438\u044F" }) }), _jsx(FlexSpan, { mb: "1rem", children: _jsx(SeasonBarChart, { season: season }) }), _jsx(FlexSpan, { mb: "1.5rem", children: season_text }), _jsx(FlexSpan, { mb: "1rem", children: _jsx(H3, { children: "\u0421\u0435\u0437\u043E\u043D\u043D\u043E\u0441\u0442\u044C \u0446\u0435\u043D" }) }), _jsx(FlexSpan, { mb: "1.5rem", children: season_price })] }));
};
