import styled from "styled-components";
import { space } from "styled-system";
export const PaginationNav = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  border: 1px solid #F3F3F3;
  background-color: #FFFFFF;
  box-shadow: 0 0 1rem 0 rgba(63, 67, 106, 0.12);
  color: #9D87F1;
  cursor: pointer;
  outline: none;
  ${space}
  
  &:not([disabled]) {
    &:hover {
      box-shadow: 0 0.125rem 0.6875rem 0 rgba(63, 67, 106, 0.18);
      color: #7E6CC1;
    }
  }
  
  &:active {
    color: #6E5CB2;
  }
  
  &:focus {
    box-shadow: none;
    border: 0.25rem solid #CEC3F8;
  }
  
  &[disabled] {
    color: #9F9F9F;
    cursor: not-allowed;
  }
`;
export const PaginationItem = styled.div `
  width: ${({ active }) => active ? "1.875rem" : "6px"};
  height: 6px;
  background-color: ${({ disabled }) => disabled ? "#9F9F9F" : "#9D87F1"};
  opacity: ${({ active }) => active ? 1 : 0.3};
  border-radius: 6px;
  cursor: pointer;
  transition: width 0.2s ease-in-out;

  &:not([disabled]) {
    &:hover {
      opacity: 1;
    }
  }

  &[disabled] {
    cursor: not-allowed;
  }
`;
