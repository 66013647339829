import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { createPortal } from "react-dom";
import { Flex, FlexSpan, H3, List, Tabs } from "../../../../../ui";
import { PromBusinessFormat } from "../PromBusinessFormat";
import { PromBusinessInfoShort } from "../PromBusinessInfoShort";
var PromBusinessInfoTabs;
(function (PromBusinessInfoTabs) {
    PromBusinessInfoTabs[PromBusinessInfoTabs["General"] = 0] = "General";
    PromBusinessInfoTabs[PromBusinessInfoTabs["Characteristics"] = 1] = "Characteristics";
})(PromBusinessInfoTabs || (PromBusinessInfoTabs = {}));
export const PromBusinessInfoExtended = ({ cardInfo, headerRef }) => {
    const [currentTab, setCurrentTab] = useState(PromBusinessInfoTabs.General);
    if (!cardInfo) {
        return null;
    }
    const { business_format, target, product, strategy } = cardInfo;
    return (_jsxs(Flex, { column: true, children: [createPortal(_jsx(FlexSpan, { mb: "1.5rem", children: _jsx(Tabs, { defaultValue: PromBusinessInfoTabs.General, items: [
                        { value: PromBusinessInfoTabs.General, label: "Общая информация" },
                        { value: PromBusinessInfoTabs.Characteristics, label: "Характеристики" },
                    ], onChange: (tab) => setCurrentTab(tab) }) }), headerRef.current), currentTab === PromBusinessInfoTabs.General && _jsx(PromBusinessInfoShort, { cardInfo: cardInfo }), currentTab === PromBusinessInfoTabs.Characteristics && (_jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { mb: "1rem", children: _jsx(H3, { children: "\u0424\u043E\u0440\u043C\u0430\u0442 \u043F\u0440\u0435\u0434\u043F\u0440\u0438\u044F\u0442\u0438\u044F" }) }), _jsx(FlexSpan, { mb: "1.5rem", children: _jsx(PromBusinessFormat, { format: business_format }) }), _jsx(FlexSpan, { mb: "1rem", children: _jsx(H3, { children: "\u0426\u0435\u043B\u0438 \u043F\u0440\u043E\u0435\u043A\u0442\u0430" }) }), _jsx(FlexSpan, { mb: "1.5rem", children: _jsx(List, { children: target.map(name => (_jsx("li", { children: name }, name))) }) }), _jsx(FlexSpan, { mb: "1rem", children: _jsx(H3, { children: "\u0410\u0441\u0441\u043E\u0440\u0442\u0438\u043C\u0435\u043D\u0442" }) }), _jsx(FlexSpan, { mb: "1.5rem", children: _jsx(List, { children: product.map(name => (_jsx("li", { children: name }, name))) }) }), _jsx(FlexSpan, { mb: "1rem", children: _jsx(H3, { children: "\u0421\u0442\u0440\u0430\u0442\u0435\u0433\u0438\u044F \u043F\u0440\u043E\u0435\u043A\u0442\u0430" }) }), _jsx(FlexSpan, { mb: "1.5rem", children: _jsx(List, { children: strategy.map(name => (_jsx("li", { children: name }, name))) }) })] }))] }));
};
