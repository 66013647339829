import { ShadowedPointSymbol, ShadowedPolySymbol } from "@evergis/react";
export const lengthSymbol = new ShadowedPolySymbol({
    strokeWidth: 2,
    shadowSize: 0,
    shadowColor: "rgb(157, 135, 241)",
    strokeColor: "rgb(157, 135, 241)",
    vertexShadowSize: 0,
    showVertex: true,
    enclose: false,
});
export const areaSymbol = new ShadowedPolySymbol({
    strokeWidth: 2,
    shadowSize: 0,
    shadowColor: "rgb(157, 135, 241)",
    strokeColor: "rgb(157, 135, 241)",
    vertexShadowSize: 0,
    fillColor: "rgba(96, 81, 156, 0.2)",
    enclose: true,
    showVertex: true,
});
export const snapSymbol = new ShadowedPointSymbol({
    size: 10,
    strokeWidth: 2,
    shadowSize: 0,
    fillColor: "#ffffff",
    shadowColor: "rgb(157, 135, 241)",
    strokeColor: "rgb(157, 135, 241)",
});
