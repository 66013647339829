import styled, { createGlobalStyle } from "styled-components";
import { LineChart, lineChartClassNames } from "@evergis/charts";
export const StyledLineChart = styled(LineChart) `
  display: inline-block;
  
  &:not(:first-child) {
    margin-left: 20px;
  }
  
  .${lineChartClassNames.lineChartYScaleGlobal},
    .${lineChartClassNames.lineChartXScaleGlobal} {
    .domain {
      visibility: hidden;
    }
    
    .tick {
      line {
        visibility: hidden;
      }
          
      text {
        font-size: 0.5625rem;
        font-weight: 500;
      }
      
      :first-of-type {
        text {
          text-anchor: start;
        }
      }
      
      :last-of-type {
        text {
          text-anchor: end;
        }
      }
    }
  }
  
  .${lineChartClassNames.lineChartLinesGlobal} {
    display: none;
  }
  
  .${lineChartClassNames.lineChartArea} {
    fill-opacity: 1;
  }

  .${lineChartClassNames.lineChartMouseLine} {
    stroke-width: 2px;
    stroke: rgba(149, 149, 149, 0.36);
  }
`;
export const StyledSimpleLineChart = styled(StyledLineChart) `
  .${lineChartClassNames.lineChartLinesGlobal} {
    display: block;
  }

  .${lineChartClassNames.lineChartMouseLine} {
    stroke-width: 1px;
    stroke: rgba(149, 149, 149, 0.24);
  }
`;
export const LineChartTooltip = styled.div `
  position: relative;
  border-radius: 0.25rem;
  background-color: rgba(28, 33, 48, 0.9);
  padding: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.15);
  
  :before {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: 0;
  }
`;
export const LineChartTooltipTitle = styled.div `
  color: #fff;
  font-weight: bold;
  margin-bottom: 6px;
`;
export const LineChartTooltipFlex = styled.div `
  display: flex;
  justify-content: space-between;
  
  div:first-child {
    margin-right: 12px;
  }
  
  :not(:last-child) {
    margin-bottom: 4px;
  }
`;
export const LineChartTooltipStyles = createGlobalStyle `
  .lineChartTooltip.${lineChartClassNames.lineChartMouseLabel} {
    .${lineChartClassNames.lineChartLabelFlex} {
      ${LineChartTooltip} {
        margin: 0 0 0 5px;
        :before {
          left: 0;
          top: 50%;
          transform: translate(-100%, -50%);
          border-top: 3px solid transparent;
          border-right: 5px solid rgba(28, 33, 48, 0.9);
          border-bottom: 5px solid transparent;
        }
      }
    }
  }
`;
