export const INIT_LOCATIONS_COMPARE_PARAMS = {
    "Рыночная ниша": [
        {
            key: "competitors",
            name: "Конкуренты, ед.",
            description: "Торговые/сервисные точки с полностью или частично схожим ассортиментом товаров и услуг потенциальным потребителям новой торговой/сервисной точки.",
            visible: true,
        },
        {
            key: "house_avg_check",
            name: "Средний чек жителей, руб.",
            description: "Сумма чеков клиентов данного вида бизнеса за год среди населения в зоне обсуживания новой торговой/сервисной точки, деленная на их кол-во (на основе данных ФНС РФ о тратах населения и исследованиях Корпорации МСП).",
            visible: true,
        },
        {
            key: "house_pen",
            name: "Доля покупателей среди жителей, %",
            description: "Кол-во клиентов данного вида бизнеса среди жителей, отнесенное к общему кол-ву населения в зоне обслуживания новой торговой/сервисной точки (на основе данных ФНС РФ о тратах населения и исследованиях Корпорации МСП).",
            visible: true,
        },
        {
            key: "bc_avg_check",
            name: "Средний чек офисных служащих, %",
            description: "Сумма чеков клиентов данного вида бизнеса за год среди офисных служащих в зоне обсуживания новой торговой/сервисной точки, деленная на их количество (на основе данных ФНС РФ о тратах населения и исследованиях Корпорации МСП).",
            visible: true,
        },
        {
            key: "bc_pen",
            name: "Доля покупателей среди офисных служащих, %",
            description: "Кол-во клиентов данного вида бизнеса среди офисных служащих, отнесенное к общему количеству офисных служащих в зоне обслуживания новой торговой/сервисной точки (на основе данных ФНС РФ о тратах населения и исследованиях Корпорации МСП).",
            visible: true,
        },
    ],
    "Потребители": [
        {
            key: "house_hh_count",
            name: "Домохозяйства, ед.",
            description: "Кол-во частных домов и квартир в многоквартирных домах в зоне обслуживания новой торговой/сервисной трочки (на основе данных 2ГИС).",
            visible: true,
        },
        {
            key: "house_people_count",
            subKeys: [
                {
                    key: "house_people_group_3",
                    name: "Высокодоходные",
                    color: "#4DAEF8",
                },
                {
                    key: "house_people_group_2",
                    name: "Среднеобеспеченные",
                    color: "#219653",
                },
                {
                    key: "house_people_group_1",
                    name: "Малообеспеченные",
                    color: "#F09537",
                },
                {
                    key: "house_people_group_0",
                    name: "Низкодоходные",
                    color: "#EA394B",
                },
            ],
            name: "Жители, чел.",
            description: "Кол-во жителей, проживающих в многоквартирных и частных домах в зоне обслуживания новой торговой/сервисной трочки (на основе данных 2ГИС и статистики Росстата).",
            visible: true,
        },
        {
            key: "bc_people_count",
            subKeys: [
                {
                    key: "bc_people_group_3",
                    name: "Высокодоходные",
                    color: "#4DAEF8",
                },
                {
                    key: "bc_people_group_2",
                    name: "Среднеобеспеченные",
                    color: "#219653",
                },
                {
                    key: "bc_people_group_1",
                    name: "Малообеспеченные",
                    color: "#F09537",
                },
                {
                    key: "bc_people_group_0",
                    name: "Низкодоходные",
                    color: "#EA394B",
                },
            ],
            name: "Офисные служащие, чел.",
            description: "Кол-во офисных служащих, работающих в бизнес-центрах в зоне обслуживания новой торговой/сервисной точки (на основе данных 2ГИС и исследованиях Корпорации МСП).",
            visible: true,
        },
        {
            key: "people_salary",
            name: "Средний доход потребителей, руб./чел. в месяц",
            description: "Среднедушевой доход жителей и офисных служащих в зоне обслуживания новой торговой/сервисной точки. Рассчитывается как средневзвешенное значение по 4-м доходным группам: высокодоходные, среднеобеспеченные, малообеспеченные, низкодоходные. В качестве веса используется оценка кол-ва потребителей в каждой доходной группе (на основе статистики Росстата и исследованиях Корпорации МСП).",
            visible: true,
        },
    ],
    "Недвижимость": [
        {
            key: "house_apt_count",
            name: "Многоквартирные дома, ед.",
            description: "Кол-во многоквартирных жилых зданий в зоне обслуживания новой торговой/сервисной точки (на основе данных 2ГИС).",
            visible: true,
        },
        {
            key: "dev_count",
            name: "Новостройки в течение 3-х лет, ед.",
            description: "Кол-во многоквартирных жилых зданий в зоне обслуживания новой торговой/сервисной трочки, которые строятся в настоящее время или будут построены в ближайшие 3 года (данные ЕИСЖС).",
            visible: true,
        },
        {
            key: "house_priv_count",
            name: "Частные дома, ед.",
            description: "Кол-во частных домов (малоэтажных домостроений) в зоне обслуживания новой торговой/сервисной трочки (на основе данных 2ГИС).",
            visible: true,
        },
        {
            key: "bc_count",
            name: "Офисные здания, ед.",
            description: "Кол-во офисных зданий и бизнес-центров в зоне обслуживания новой торговой/сервисной точки (на основе данных 2ГИС).",
            visible: true,
        },
        {
            key: "house_cost",
            name: "Средневзвешенная стоимость жилой недвижимости, тыс. руб./кв. м",
            description: "Средневзвешенное значение цены покупки 1 кв.м. жилой недвижимости во всех домах в зоне обслуживания новой торговой/сервисной точки. В качестве веса  используется оценка кол-ва квартир в доме (на основе данных 2ГИС и исследованиях Корпорации МСП).",
            visible: true,
        },
    ],
    "Генераторы потока": [
        {
            key: "tc_count",
            name: "ТЦ и ТРЦ, ед.",
            description: "Кол-во торговых и торгово-развлекательных центров (генераторов потока местных жителей) в зоне обслуживания новой торговой/сервисной точки.",
            visible: true,
        },
        {
            key: "local_count",
            name: "Достопримечательности, ед.",
            description: "Кол-во крупных культурных, спортивных, транспортных, социальных и туристических объектов (генераторов потока приезжих) в зоне обслуживания новой торговой/сервисной точки.",
            visible: true,
        },
    ],
    "Транспортная инфраструктура": [
        {
            key: "trans_distribution_total",
            name: "Остановки, ед.",
            description: "Кол-во остановок общественного транспорта в пешей доступности (350 м вокруг новой торговой/сервисной точки).",
            visible: true,
        },
        {
            key: "trans_distribution_metro",
            name: "Станции метро, ед.",
            description: "Кол-во станций метрополитена, расположенных в пешей доступности (350 м вокруг новой торговой/сервисной точки).",
            visible: true,
        },
        {
            key: "trans_parking_count",
            name: "Парковочные машиноместа, ед.",
            description: "Кол-во парковочных машиномест в пешей доступности (350 м вокруг новой торговой/сервисной точки).",
            visible: true,
        },
    ],
};
export const LOCATIONS_COMPARE_PARAM_RENDERS = {
    competitors: details => details?.statistic.competitors.length,
    house_avg_check: details => details?.analytic.house_avg_check,
    house_pen: details => details !== undefined ? details?.analytic.house_pen * 100 : undefined,
    bc_avg_check: details => details?.analytic.bc_avg_check,
    bc_pen: details => details !== undefined ? details?.analytic.bc_pen * 100 : undefined,
    house_hh_count: details => details?.analytic.house_hh_count,
    house_people_count: details => details?.analytic.house_people_count,
    house_people_group_0: details => details?.analytic.house_people_group[0],
    house_people_group_1: details => details?.analytic.house_people_group[1],
    house_people_group_2: details => details?.analytic.house_people_group[2],
    house_people_group_3: details => details?.analytic.house_people_group[3],
    bc_people_count: details => details?.analytic.bc_people_count,
    bc_people_group_0: details => details?.analytic.bc_people_group[0],
    bc_people_group_1: details => details?.analytic.bc_people_group[1],
    bc_people_group_2: details => details?.analytic.bc_people_group[2],
    bc_people_group_3: details => details?.analytic.bc_people_group[3],
    dev_count: details => details?.analytic.dev_count,
    house_apt_count: details => details?.analytic.house_apt_count,
    house_priv_count: details => details?.analytic.house_priv_count,
    bc_count: details => details?.analytic.bc_count,
    trans_distribution_total: details => details?.analytic.trans_distribution.reduce((acc, curr) => {
        return acc + curr.count;
    }, 0),
    trans_distribution_metro: details => details?.analytic.trans_distribution.reduce((acc, curr) => {
        return !["Метро", "metro"].includes(curr.type) ? acc : acc + curr.count;
    }, 0),
    trans_parking_count: details => details?.analytic.trans_parking_count,
    tc_count: details => details?.analytic.tc_count,
    local_count: details => details?.analytic.local_count,
    people_salary: details => details?.analytic.people_salary,
    house_cost: details => details?.analytic.house_cost,
};
export const INIT_AREA_COMPARE_PARAMS = {
    "Жители": [
        {
            key: "house_people_count",
            name: "Количество взрослого населения (18+), чел.",
            description: "Численность населения в возрасте 18 лет и старше, проживающего в локации (данные Росстата и 2ГИС).",
            visible: true,
        },
        {
            key: "house_salary",
            name: "Средний уровень дохода жителей, руб./чел. в месяц",
            description: "Среднедушевой доход населения, проживающего в локации. Рассчитывается как средневзвешенное значение по 4-м доходным группам: высокодоходные, среднеобеспеченные, малообеспеченные, низкодоходные. В качестве веса используется оценка кол-ва потребителей в каждой доходной группе (на основе данных статистики Росстата и исследованиях Корпорации МСП).",
            visible: true,
        },
        {
            key: "people_density",
            name: "Плотность потребителей, чел./кв. км",
            description: "Кол-во жителей и офисных служащих, отнесенное к площади локации (на основе данных статистики Росстата, данных 2ГИС и исследованиях Корпорации МСП).",
            visible: true,
        },
        {
            key: "house_cost",
            name: "Средневзвешенная стоимость жилой недвижимости, тыс. руб./кв. м",
            description: "Средневзвешенное значение цены покупки 1 кв.м жилой недвижимости во всех домах в локации. В качестве веса используется оценка кол-ва квартир в доме (на основе данных 2ГИС и исследованиях Корпорации МСП).",
            visible: true,
        },
        {
            key: "house_hh_count",
            name: "Количество квартир, ед.",
            description: "Кол-во квартир во всех многоквартирных домах в локации (данные 2ГИС).",
            visible: true,
        },
        {
            key: "house_apt_count",
            name: "Количество многоквартирных жилых домов, ед.",
            description: "Кол-во многоквартирных жилых зданий в локации (данные 2ГИС).",
            visible: true,
        },
        {
            key: "house_priv_count",
            name: "Количество частных жилых домов, ед.",
            description: "Кол-во частных домов (малоэтажных домостроений) в локации (данные 2ГИС).",
            visible: true,
        },
        {
            key: "dev_hh_count",
            name: "Количество квартир в новостройках, ед.",
            description: "Кол-во квартир в многоквартирных жилых зданиях в локации, которые строятся в настоящее время или будут построены в ближайшие 3 года (данные ЕИСЖС).",
            visible: true,
        },
    ],
    "Офисные служащие": [
        {
            key: "bc_people_count",
            name: "Количество работающих, чел.",
            description: "Численность офисных служащих, работающих в офисных зданиях в локации (на основе данных 2ГИС и исследованиях Корпорации МСП).",
            visible: true,
        },
        {
            key: "bc_salary",
            name: "Средний уровень дохода работающих, руб./чел. в месяц",
            description: "Среднедушевой доход офисных служащих, работающих в локации. Рассчитывается как средневзвешенное значение по 4-м доходным группам: высокодоходные, среднеобеспеченные, малообеспеченные, низкодоходные. В качестве веса используется оценка кол-ва потребителей в каждой доходной группе (на основе данных статистики Росстата и исследованиях Корпорации МСП).",
            visible: true,
        },
        {
            key: "bc_count",
            name: "Количество офисных зданий, ед.",
            description: "Кол-во офисных зданий и бизнес-центров в локации (данные 2ГИС).",
            visible: true,
        },
    ],
    "Приезжие": [
        {
            key: "trans_road_length",
            name: "Суммарная протяженность автомобильных дорог на территории, км",
            description: "Сумма длин всех автодорог в границах локации с учетом полосности (данные 2ГИС).",
            visible: true,
        },
        {
            key: "tc_square",
            name: "Суммарная площадь ТЦ и ТРЦ, кв. м",
            description: "Общая площадь всех торговых и торгово-развлекательных центров (генераторов потока местных жителей) в локации.",
            visible: true,
        },
        {
            key: "tc_count",
            name: "Количество ТЦ/ТРЦ, ед.",
            description: "Кол-во торговых и торгово-развлекательных центров (генераторов потока местных жителей) в локации.",
            visible: true,
        },
    ],
    "Туристы": [
        {
            key: "tourist_count",
            name: "Количество объектов достопримечательностей, ед.",
            description: "Кол-во крупных культурных, спортивных, транспортных, социальных и туристических объектов (генераторов потока приезжих) в локации.",
            visible: true,
        },
        {
            key: "trans_route_count",
            name: "Количество уникальных маршрутов городского общественного транспорта, ед.",
            description: "Кол-во уникальных маршрутов всех видов городского наземного общественного транспорта, остановки которых находятся в локации.",
            visible: true,
        },
        {
            key: "trans_parking_capacity",
            name: "Вместимость автомобильных парковок, машиномест",
            description: "Кол-во парковочных машиномест, расположенных в локации.",
            visible: true,
        },
    ],
};
export const AREA_COMPARE_PARAM_RENDERS = {
    house_people_count: details => details?.analytic.house_people_count,
    house_salary: details => details?.analytic.house_salary,
    people_density: details => details?.analytic.people_density,
    house_cost: details => details?.analytic.house_cost,
    house_hh_count: details => details?.analytic.house_hh_count,
    house_apt_count: details => details?.analytic.house_apt_count,
    house_priv_count: details => details?.analytic.house_priv_count,
    dev_hh_count: details => details?.analytic.dev_hh_count,
    bc_people_count: details => details?.analytic.bc_people_count,
    bc_salary: details => details?.analytic.bc_salary,
    bc_count: details => details?.analytic.bc_count,
    trans_road_length: details => details?.analytic.trans_road_length,
    tc_square: details => details?.analytic.tc_square,
    tc_count: details => details?.analytic.tc_count,
    tourist_count: details => details?.analytic.tourist_count,
    trans_route_count: details => details?.analytic.trans_route_count,
    trans_parking_capacity: details => details?.analytic.trans_parking_capacity,
};
