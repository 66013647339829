import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Flex, FlexSpan, H4, Icon } from "../../../ui";
import { PromBusinessDescription, PromBusinessPickerContainer } from "./styled";
import { DIALOGS } from "../../../constants/dialogs";
import { useDialog, usePromConceptItem } from "../../../hooks";
import allBusinessTypes from "../../../assets/images/all-business-types.svg";
export const PromBusinessPicker = () => {
    const openDialog = useDialog();
    const [conceptItem] = usePromConceptItem();
    const onClick = useCallback(() => {
        openDialog(DIALOGS.PROM_BUSINESS_PICKER_DIALOG);
    }, [openDialog]);
    return (_jsxs(PromBusinessPickerContainer, { onClick: onClick, children: [_jsx(FlexSpan, { mr: "1rem", children: _jsx("img", { src: allBusinessTypes, alt: "" }) }), _jsxs(Flex, { column: true, children: [_jsx(H4, { children: conceptItem?.name || "Все виды бизнеса" }), _jsx(PromBusinessDescription, { mt: "0.5rem", children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0431\u0438\u0437\u043D\u0435\u0441, \u0447\u0442\u043E\u0431\u044B \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043A\u0443 \u043F\u043E \u043D\u0435\u043C\u0443" })] }), _jsx(FlexSpan, { alignSelf: "center", mt: "0.5rem", children: _jsx(Icon, { icon: "chevron-small-right", size: 24, color: "#9F9F9F" }) })] }));
};
