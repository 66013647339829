import * as process from "process";
import { Api } from './Api';
const trimSlashes = (str) => str.replace(/^\/|\/$/g, "");
const joinUrlParts = (...parts) => parts
    .map(p => trimSlashes(p))
    .filter(p => !!p)
    .join("/");
export const apiPath = process.env.NODE_ENV === "development"
    ? `/${joinUrlParts(process.env.API_PROXY_PREFIX || "")}/`
    : process.env.API_URL;
export const api = new Api(apiPath || "/");
export * from "./data-contracts";
