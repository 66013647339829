import { useEffect, useRef, useState } from "react";
import { useMapContext } from "@evergis/react";
import { FeatureLayer } from "@evergis/sgis";
export const SimpleFeaturesLayer = ({ features }) => {
    const { map } = useMapContext();
    const [initialized, setInitialized] = useState(false);
    const layer = useRef();
    const currentFeatures = useRef([]);
    useEffect(() => {
        if (!initialized || !features) {
            return;
        }
        if (currentFeatures.current.length) {
            layer.current.remove(currentFeatures.current);
        }
        currentFeatures.current = features;
        layer.current.add(features);
    }, [initialized, features]);
    useEffect(() => {
        layer.current = new FeatureLayer();
        map.addLayer(layer.current);
        setInitialized(true);
        return () => {
            setInitialized(false);
            map.removeLayer(layer.current);
        };
    }, []); // eslint-disable-line
    return null;
};
