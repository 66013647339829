import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useCallback } from "react";
import { ModalContainer, ModalWrap, Overflow, Container, TitleContainer, Content, Footer } from "./styled";
import { H2 } from "../Typography";
import { IconButton } from "../IconButton";
import { useCloseOnEscape, useDialogReset } from "../../hooks";
export const ModalContext = createContext({});
export const Modal = ({ width, maxWidth, height, maxHeight, onClose, maskClosable = true, hideMask, zIndex, isFixed, title, closable = true, paddingTitle, paddingContent, content, footer, footerJustify, preventDefaultClose, keyboard = true, contextValue, titleStyle, }) => {
    const closeDialog = useDialogReset();
    const onCloseDialog = useCallback(() => {
        if (closable) {
            !preventDefaultClose && closeDialog();
            onClose?.();
        }
    }, [closable, closeDialog, onClose, preventDefaultClose]);
    useCloseOnEscape(keyboard ? onCloseDialog : undefined);
    return (_jsx(ModalContext.Provider, { value: contextValue, children: _jsxs(ModalContainer, { zIndex: zIndex, isFixed: isFixed, children: [!hideMask && _jsx(Overflow, { onClick: () => maskClosable && onCloseDialog() }), _jsx(ModalWrap, { width: width, maxWidth: maxWidth, height: height, maxHeight: maxHeight, children: _jsxs(Container, { children: [_jsxs(TitleContainer, { padding: paddingTitle, style: titleStyle, children: [_jsx(H2, { children: title }), closable && _jsx(IconButton, { onClick: onCloseDialog, icon: "close", size: 20 })] }), _jsx(Content, { padding: paddingContent, children: content }), footer && _jsx(Footer, { footerJustify: footerJustify, children: footer })] }) })] }) }));
};
