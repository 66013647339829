import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, FlexSpan, H3, H5, List } from "../../../../../ui";
import { CategoryIcon } from "../../../../CategoryIcon";
import { ConceptColumn } from "../styled";
export const BusinessInfoShort = ({ cardInfo }) => {
    if (!cardInfo) {
        return null;
    }
    const { description, business_formats } = cardInfo;
    return (_jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { mb: "1rem", children: _jsx(H3, { children: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" }) }), _jsx(FlexSpan, { mb: "1.5rem", children: description }), _jsx(FlexSpan, { mb: "1rem", children: _jsx(H3, { children: "\u0414\u043E\u0441\u0442\u0443\u043F\u043D\u044B\u0435 \u0446\u0435\u043D\u043E\u0432\u044B\u0435 \u043D\u0438\u0448\u0438 \u0438 \u043C\u0430\u0441\u0448\u0442\u0430\u0431\u044B \u0431\u0438\u0437\u043D\u0435\u0441\u0430" }) }), _jsx(Flex, { flexWrap: "wrap", children: business_formats.map(item => (_jsxs(ConceptColumn, { children: [_jsxs(H5, { children: [item.name, " \u043D\u0438\u0448\u0430"] }), _jsx(Flex, { mt: "0.5rem", children: _jsx(List, { children: item.business_sizes.map(({ id, name }) => (_jsx("li", { children: name }, id))) }) }), !!item.franchises?.length && (_jsxs(_Fragment, { children: [_jsx(Flex, { mb: "0.5rem", mt: "1rem", children: _jsx(H5, { children: "\u0414\u043E\u0441\u0442\u0443\u043F\u043D\u044B\u0435 \u0444\u0440\u0430\u043D\u0448\u0438\u0437\u044B" }) }), item.franchises.map((franchise, franchiseIndex) => (_jsxs(Flex, { alignItems: "center", mb: franchiseIndex < item.franchises.length - 1 ? "1rem" : 0, children: [_jsx(FlexSpan, { mr: "0.5rem", children: _jsx(CategoryIcon, { path: franchise.ico }) }), _jsx(FlexSpan, { children: franchise.name })] }, `franchise-item-${franchise.id}`)))] }))] }, item.name))) })] }));
};
