import { orderBy } from "lodash";
export const orderGanttEvents = (events) => {
    const eventsOrder = {
        reg: 1,
        deposit: 2,
        docs: 3,
        credit: 4,
        repair: 5,
        equipment: 6,
        recruit: 7,
        product: 8,
        internet: 9,
        security: 10,
        contract: 11,
        soft: 12,
        adv: 13,
        power_start: 14,
        power_full: 15,
    };
    return orderBy(events.map(item => ({ ...item, sortBy: eventsOrder[item.type] })), "sortBy", ["asc"])
        .map(item => ({ ...item, sortBy: undefined }));
};
export const getGanttChartGroups = (params) => {
    if (params === undefined) {
        return [];
    }
    return orderGanttEvents(params.event_list).reduce((acc, curr) => {
        if (curr.type === "credit" && !params.credit_flag) {
            return acc;
        }
        return [
            ...acc,
            {
                items: [
                    {
                        name: curr.name,
                        months: curr.month,
                        barColor: curr.type === "power_full" ? "#FFD633" : undefined,
                    },
                ],
            },
        ];
    }, []);
};
export const getGanttGroupsFromEvents = (events) => {
    if (events === undefined) {
        return [];
    }
    return [
        {
            name: events.name,
            items: events.months.reduce((acc, curr) => {
                if (acc.find(item => item.name === curr.name)) {
                    return acc.map(item => item.name === curr.name
                        ? {
                            name: item.name,
                            months: [...item.months, curr.month],
                            barColor: item.name === "Выход на плановую проектную мощность" ? "#FFD633" : undefined,
                        } : item);
                }
                return [
                    ...acc,
                    {
                        name: curr.name,
                        months: [curr.month],
                        barColor: curr.name === "Выход на плановую проектную мощность" ? "#FFD633" : undefined,
                    },
                ];
            }, []),
        },
    ];
};
