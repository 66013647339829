import styled from "styled-components";
import { Flex } from "../../../ui";
import { CategoryIconContainer } from "../../../components/CategoryIcon/styled";
export const MapContainer = styled.div `
  position: relative;
  width: 100%;
  height: calc(100% - 0.25rem);
`;
export const CardHeader = styled(Flex) `
  align-items: center;
  width: calc(100% + 2rem);
  min-height: 3.5rem;
  margin: -0.75rem -1rem 1rem;
  padding: 0 1.25rem;
  background-color: #f9f9f8;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`;
export const EmptyPlaceholder = styled.div `
  max-width: 15.5rem;
  margin: 1rem auto 0;
  text-align: center;
  font-weight: 500;
`;
export const BusinessRetailListContainer = styled(Flex) `
  flex-direction: column;
  height: 100%;
  margin-top: 1rem;
  overflow-y: auto;
`;
export const BusinessRetailItem = styled(Flex) `
  cursor: pointer;
  padding: 1rem;
  transition: background-color 0.3s;
  border-radius: 0.75rem;

  ${CategoryIconContainer} {
    margin-right: 0.75rem;
  }

  &:hover {
    background-color: #f7f4ff;
  }
`;
export const BusinessRetailDescription = styled.div `
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #666;
`;
