import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isNil } from "lodash";
import { Flex, FlexSpan, H3 } from "../../ui";
import { ListItemContainer, ListItemLabel } from "./styled";
import { EMPTY_VALUE } from "../../constants/common";
import { formatNumber } from "../../utils";
export const PointDetailsList = ({ items = [] }) => {
    return (_jsx(Flex, { column: true, children: items.map((item, itemIndex) => {
            return (_jsx(ListItemContainer, { children: _jsxs(Flex, { alignItems: "end", justifyContent: "space-between", children: [_jsx(FlexSpan, { flexGrow: 1, children: _jsx(ListItemLabel, { children: item.label }) }), _jsx(FlexSpan, { ml: "1.5rem", textAlign: "right", children: _jsx(H3, { children: !isNil(item.value)
                                    ? formatNumber(item.value, !item.noRound)
                                    : EMPTY_VALUE }) })] }) }, `point-details-block-list-item-${itemIndex}`));
        }) }));
};
