import styled from "styled-components";
import { Flex, FlexSpan } from "../Grid";
const setWidth = (width, defaultWidth) => typeof width === "number" ? `${width}px` : typeof width === "undefined" ? defaultWidth : width;
const setHeight = (height, defaultHeight) => typeof height === "number" ? `${height}px` : typeof height === "undefined" ? defaultHeight : height;
export const TableWrapper = styled(Flex) `
  flex-direction: column;
  overflow: hidden;
  width: ${({ width }) => setWidth(width)};
`;
export const TableHeaderCell = styled(FlexSpan) `
  display: flex;
  flex-direction: column;
  align-items: ${({ isCustomRender, align }) => isCustomRender
    ? undefined
    : align === undefined || align === "left" ? "start" : align === "center" ? "center" : "end"};
  border-radius: 0.5rem;
  background: ${({ isCustomRender }) => !isCustomRender && "rgba(84, 173, 255, 0.20)"};
  padding: ${({ isCustomRender }) => !isCustomRender && "1rem"};
  width: ${({ width }) => setWidth(width, "4rem")};
  min-width: ${({ width }) => setWidth(width, "4rem")};
  height: ${({ height }) => setHeight(height)};
  min-height: ${({ height }) => setHeight(height)};
  max-height: ${({ height }) => setHeight(height)};
`;
export const TableHeaderSubtitle = styled.div `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-top: 0.5rem;
`;
export const TableBodyCell = styled(FlexSpan) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ isCustomRender, align }) => isCustomRender
    ? undefined
    : align === undefined || align === "left" ? "start" : align === "center" ? "center" : "end"};
  border-radius: ${({ isCustomRender }) => !isCustomRender && "0.5rem"};
  background: ${({ isCustomRender }) => !isCustomRender && "rgba(84, 173, 255, 0.05)"};
  padding: ${({ isCustomRender }) => !isCustomRender && "1rem"};
  width: ${({ width }) => setWidth(width, "4rem")};
  min-width: ${({ width }) => setWidth(width, "4rem")};
  height: ${({ height }) => setHeight(height, "3rem")};
  min-height: ${({ height }) => setHeight(height, "3rem")};
  max-height: ${({ height }) => setHeight(height, "3rem")};
  overflow: hidden;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;
