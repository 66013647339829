import styled from "styled-components";
import { FlexSpan } from "../Grid";
export const DayContainer = styled(FlexSpan) `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border: 1px solid ${({ today }) => today ? "#9D87F1" : "transparent"};
  background-color: ${({ active }) => active ? "#9D87F1" : "transparent"};
  color: ${({ disabled, active, anotherMonth }) => disabled ? "#E2E5EA" : active ? "#FFFFFF" : anotherMonth ? "#9F9F9F" : "initial"};
  cursor: pointer;
  width: calc(100% - 0.25rem);
  padding: 0.25rem 0;
  pointer-events: ${({ disabled }) => disabled ? "none" : undefined};
  
  &:hover {
    color: ${({ active }) => !active && "#6E5CB2"};
  }
`;
