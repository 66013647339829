import { createSlice } from "@reduxjs/toolkit";
import { BaseMapName } from "../../constants/baseMaps";
const initialState = {
    baseMapName: BaseMapName.DoubleGis,
    measurerActive: false,
};
const slice = createSlice({
    name: "map",
    initialState,
    reducers: {
        setBaseMapName: (state, action) => {
            state.baseMapName = action.payload;
        },
        setCurrentFeature: (state, action) => {
            state.currentFeature = action.payload;
        },
        setOverlayFeature: (state, action) => {
            state.overlayFeature = action.payload;
        },
        setMeasurerActive: (state, action) => {
            state.measurerActive = action.payload;
        },
    },
});
export const { setBaseMapName, setCurrentFeature, setOverlayFeature, setMeasurerActive, } = slice.actions;
export default slice.reducer;
