export const getTransportIcon = (type) => {
    switch (type) {
        case "bus":
        case "Автобус":
            return {
                icon: "ic_bus",
                color: "#4DAEF8",
            };
        case "tram":
        case "Трамвай":
            return {
                icon: "ic_tram",
                color: "#E34F84",
            };
        case "metro":
        case "Метро":
            return {
                icon: "ic_metro",
                color: "#EA394B",
            };
        case "suburban_train":
        case "Электропоезд":
            return {
                icon: "ic_train",
                color: "#23CAE0",
            };
        case "funicular_railway":
        case "Фуникулёр":
            return {
                icon: "ic_funic",
                color: "#F6C544",
            };
        case "monorail":
        case "Монорельс":
            return {
                icon: "ic_mono",
                color: "#F09537",
            };
        case "river_transport":
        case "Водный транспорт":
        case "Водный":
            return {
                icon: "ic_ship",
                color: "#6EE5B9",
            };
        case "cable_car":
        case "Канатная дорога":
            return {
                icon: "ic_kanat",
                color: "#C333F0",
            };
        case "Парковки":
            return {
                icon: "ic_parking",
                color: "#425EF5",
            };
        default:
            return null;
    }
};
