import styled from "styled-components";
import { Flex, FlexSpan } from "../../ui";
export const StepDescription = styled.span `
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  color: #9F9F9F;
`;
export const StepButton = styled(Flex) `
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.375rem;
  border: 1px solid #E3E3E3;
  background-color: ${({ disabled }) => disabled ? "#F9F9F9" : "#ffffff"};
  color: #9F9F9F;
  opacity: ${({ disabled }) => disabled && 0.6};
  cursor: pointer;
  pointer-events: ${({ disabled }) => disabled && "none"};
  
  &:hover {
    border-color: #9D87F1;
    color: #7E6CC1;
  }

  &:active {
    border-color: #7E6CC1;
    color: #7E6CC1;
  }
`;
export const StepperPopoverItem = styled(Flex) `
  align-items: center;
  
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
`;
export const StepperPopoverItemBody = styled(FlexSpan) `
  flex-grow: 1;
  color: ${({ active }) => active ? "initial" : "#E2E5EA"};
  
  ${StepDescription} {
    color: ${({ active }) => !active && "#E2E5EA"};
  }
`;
