export class PromCard {
    http;
    constructor(http) {
        this.http = http;
    }
    getShortCard(conceptId, guid) {
        return this.http.get(`/api/prom_card/${conceptId}`, { guid }).json();
    }
    getExtendedCard(conceptId, formatId, guid) {
        return this.http.get(`/api/prom_card/${conceptId}/${formatId}`, { guid }).json();
    }
}
