import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex } from "../Grid";
import { TableBodyCell } from "./styled";
export const TableBody = ({ bodyCellHeight, cellGap, columns = [], rows = [] }) => {
    return (_jsx(_Fragment, { children: rows.map((row, rowId) => {
            return (_jsx(Flex, { gap: cellGap ?? "0.25rem", children: columns.map((column, columnId) => {
                    return (_jsx(TableBodyCell, { align: column.align, width: column.width, height: bodyCellHeight, isCustomRender: column.renderBody !== undefined, style: column.bodyCellStyle, mb: rowId < rows.length - 1 ? cellGap ?? "0.25rem" : undefined, children: column.renderBody !== undefined
                            ? column.renderBody(row, rowId)
                            : row.data[column.id] }, `table-body-row-${rowId}-column-${columnId}`));
                }) }, `table-body-row-${rowId}`));
        }) }));
};
