import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Overflow, SideBarContainer, SideBarWrap } from "./styled";
import { useClickOutside } from "../../hooks";
export const SideBar = ({ placement = "left", mask = true, maskClosable = true, fullscreen, onClose, children, }) => {
    const [initialized, setInitialized] = useState(false);
    const sideBarRef = useClickOutside(() => initialized && onClose?.());
    useEffect(() => {
        setInitialized(true);
    }, []);
    return (_jsxs(SideBarContainer, { ref: sideBarRef, fullscreen: fullscreen, children: [_jsx(Overflow, { onClick: () => maskClosable && onClose?.(), mask: mask }), _jsx(SideBarWrap, { placement: placement, children: children })] }));
};
