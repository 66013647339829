import styled from "styled-components";
import { FlexSpan } from "../Grid";
export const MenuItemContainer = styled(FlexSpan) `
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  background: ${({ active, disabled }) => !disabled && active && "#F7F4FF"};
  color: ${({ active }) => active && "#9D87F1"};
  opacity: ${({ disabled }) => disabled && 0.4};
  
  &:not(:last-of-type) {
    margin-bottom: 0.25rem;
  }
  
  &[disabled] {
    cursor: not-allowed;
  }
  
  &:not([disabled]) {
    &:hover {
      background: #F7F4FF;
      color: #9D87F1;
    }
  }
`;
