import { jsx as _jsx } from "react/jsx-runtime";
import { Flex, FlexSpan } from "../../../ui";
import { HorizontalBarChart } from "../../HorizontalBarChart";
import { PointDetailsList } from "../../PointDetailsList";
import { PointDetailsBlock } from "../../PointDetailsBlock";
import { useRenderTooltip } from "../../../hooks";
export const HouseholdsBlock = ({ data }) => {
    const renderTooltip = useRenderTooltip();
    return (_jsx(PointDetailsBlock, { title: "\u0416\u0438\u0442\u0435\u043B\u0438", titleExtra: data.analytic.house_people_group.some(item => item > 0) && (_jsx(Flex, { column: true, width: "5rem", children: _jsx(FlexSpan, { children: _jsx(HorizontalBarChart, { withoutXScale: true, withTooltip: true, stackedTooltip: true, renderTooltip: bars => renderTooltip(bars, { unit: "чел." }), data: [
                        [
                            { name: "Высокодоходные", value: +data.analytic.house_people_group[3].toFixed(2), color: "#4DAEF8" },
                            { name: "Среднеобеспеченные", value: +data.analytic.house_people_group[2].toFixed(2), color: "#6EE5B9" },
                            { name: "Малообеспеченные", value: +data.analytic.house_people_group[1].toFixed(2), color: "#F6C544" },
                            { name: "Низкодоходные", value: +data.analytic.house_people_group[0].toFixed(2), color: "#EA394B" },
                        ],
                    ] }) }) })), icon: "people", children: _jsx(PointDetailsList, { items: [
                { label: "Всего проживающих, чел", value: data.analytic.house_people_count },
                { label: "Доля покупателей, %", value: data.analytic.house_pen * 100, noRound: true },
                { label: "Средний чек, руб.", value: data.analytic.house_avg_check },
                { label: "Многоквартирных жилых домов", value: data.analytic.house_apt_count },
                { label: "Частных домов", value: data.analytic.house_priv_count },
                { label: "Домохозяйств", value: data.analytic.house_hh_count },
            ] }) }));
};
