import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRetailBusinessItems } from "../../../selectors/dict";
import { setRetailBusinessItems } from "../../../slices/dict";
export const useRetailBusinessItems = () => {
    const dispatch = useDispatch();
    const businessItems = useSelector(getRetailBusinessItems);
    const update = useCallback((value) => {
        dispatch(setRetailBusinessItems(value));
    }, [dispatch]);
    return [businessItems, update];
};
