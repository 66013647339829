import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { CityPickerContainer } from "./styled";
import { FlexSpan, Icon, EllipsisText } from "../../../../ui";
import { DIALOGS } from "../../../../constants/dialogs";
import { useCurrentCity, useCurrentSubject, useDialog, useSection } from "../../../../hooks";
import { AppSection } from "../../../../slices/app/types";
export const CityPicker = () => {
    const [section] = useSection();
    const [currentCity] = useCurrentCity();
    const [currentSubject] = useCurrentSubject();
    const openDialog = useDialog();
    const currentEntity = useMemo(() => {
        return section === AppSection.Enterprise ? currentSubject : currentCity;
    }, [section, currentCity, currentSubject]);
    if (!currentEntity) {
        return null;
    }
    return (_jsxs(CityPickerContainer, { onClick: () => openDialog(DIALOGS.CITY_PICKER_DIALOG), children: [_jsx(FlexSpan, { mr: "0.5rem", children: _jsx(Icon, { icon: "location", size: 24 }) }), _jsx(FlexSpan, { children: _jsx(EllipsisText, { lines: 1, children: currentEntity?.properties.name }) })] }));
};
