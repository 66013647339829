import { BaseMapName } from "../../../../../constants/baseMaps";
export const BASE_MAPS_LIST = [
    {
        name: BaseMapName.DoubleGis,
        title: "Карты 2ГИС",
        sourceUrl: "https://tile0.maps.2gis.com/tiles?x={2}&y={3}&z={1}&ts=online_sd&layerType=nc",
    },
    {
        name: BaseMapName.YMapsSchema,
        title: "Яндекс.Карты (Схема)",
        sourceUrl: "https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=23.11.01-0-b231030182430&x={2}&y={3}&z={1}&lang=ru_RU",
    },
    {
        name: BaseMapName.YMapsHybrid,
        title: "Яндекс.Карты (Гибрид)",
        sourceUrl: "https://core-renderer-tiles.maps.yandex.net/tiles?l=skl&v=22.01.29-1-b211218133230&x={2}&y={3}&z={1}&lang=ru_RU",
    },
    {
        name: BaseMapName.YMapsEng,
        title: "Yandex Map (Eng)",
        sourceUrl: "https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=23.11.01-0-b231030182430&x={2}&y={3}&z={1}&lang=en_US",
    },
];
