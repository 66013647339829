export const CONSUMER_COLORS = ["#23CAE0", "#425EF5"];
export const CHARTS_DYNAMIC_COLORS = [
    "#E34F84",
    "#8730F5",
    "#62ACFF",
    "#F6C544",
    "#EA394B",
    "#C333F0",
    "#425EF5",
    "#4DAEF8",
    "#23CAE0",
    "#6EE5B9",
    "#9BFB4E",
    "#FBEA4E",
    "#F09537",
    "#EB4E27",
    "#094760",
    "#1C8DAA",
];
