import styled from "styled-components";
import { margin, opacity } from "styled-system";
export const Divider = styled.div `
  display: ${({ vertical }) => vertical ? "inline-block" : "block"};
  width: ${({ vertical }) => vertical ? "0.0625rem" : "100%"};
  height: ${({ vertical, height }) => vertical ? (height ?? "0.55lh") : "0.0625rem"};
  background-color: ${({ vertical, color }) => color ?? (vertical ? "#D9D9D9" : "#E3E3E3")};
  ${margin}
  ${opacity}
`;
