import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Divider, EllipsisText, Flex, FlexSpan, H1, H2, Icon, IconButton, Link, YellowButton, } from "../../../../ui";
import { BusinessPlanHeaderContainer, BusinessPlanHeaderLabel } from "./styled";
import { DIALOGS } from "../../../../constants/dialogs";
import { useDialog, useRetailBusinessItems, useUser } from "../../../../hooks";
import { formatNumber, getWktPoint } from "../../../../utils";
import { syncRetailGanttEvents } from "../utils";
import { api } from "../../../../api";
import retailBusinessPlanBack from "../../../../assets/images/retail-bp-back.jpg";
export const BusinessPlanHeader = ({ data, docs, defaultParams, defaultRequestParams, requestValues, }) => {
    const openDialog = useDialog();
    const [user] = useUser();
    const { conceptId, businessId, cityId } = useParams();
    const [retailBusinessItems] = useRetailBusinessItems();
    const businessConcept = useMemo(() => {
        return retailBusinessItems?.find(item => item.id === Number(businessId))?.business_concepts?.find(concept => concept.id === Number(conceptId));
    }, [businessId, retailBusinessItems, conceptId]);
    const [searchParams] = useSearchParams();
    const point = searchParams.get("point");
    const size = Number(searchParams.get("size"));
    const onDownload = useCallback(() => {
        api.businessPlan.createRetailBusinessPlan(Number(cityId), size, {
            ...defaultRequestParams,
        }, {
            ...requestValues,
            event_list: syncRetailGanttEvents(requestValues, data?.parameters?.event_list, defaultParams?.constants),
            save_flag: true,
        }).then(response => {
            window.open(`${process.env.NODE_ENV === "development" ? process.env.API_URL : "/"}api/retail_bp/pdf/${cityId}/${size}?point=${getWktPoint(JSON.parse(point))}&bp_id=${response.bp_id}&guid=${user.guid}`, "new");
        });
    }, [cityId, data, defaultParams, defaultRequestParams, point, requestValues, size, user.guid]);
    if (!data) {
        return null;
    }
    return (_jsxs(BusinessPlanHeaderContainer, { backgroundImage: retailBusinessPlanBack, flat: true, children: [_jsxs(Flex, { justifyContent: "space-between", children: [_jsxs(FlexSpan, { mr: "2rem", flexGrow: 1, children: [_jsx(H2, { children: _jsx(EllipsisText, { lines: 1, children: data.info.business_concept.name }) }), _jsxs(Flex, { mt: "0.25rem", alignItems: "center", children: [_jsx(FlexSpan, { children: _jsxs(BusinessPlanHeaderLabel, { children: [data.info.business_format.name, " \u043D\u0438\u0448\u0430"] }) }), _jsx(FlexSpan, { children: _jsx(Divider, { vertical: true, color: "rgba(255, 255, 255, 0.15)", m: "0 0.5rem" }) }), _jsx(FlexSpan, { children: _jsx(Link, { icon: "info_modal2", iconPlacement: "right", onClick: () => openDialog(DIALOGS.BUSINESS_INFO_DIALOG, { businessConcept }), children: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u0431\u0438\u0437\u043D\u0435\u0441\u0430" }) })] })] }), _jsx(FlexSpan, { children: _jsx(YellowButton, { icon: "download", noWrap: true, onClick: onDownload, disabled: !data.is_download, disabledTooltipPlacement: "bottom", disabledTooltip: !data.is_download
                                ? "Срок окупаемости вложений в бизнес по выбранному типовому формату превышает 5 лет" +
                                    " в связи с высокой стоимостью аренды помещения, большим фондом оплаты труда" +
                                    " и значительным объемом инвестиций. Рекомендуем выбрать другой типовой формат," +
                                    " другой вид бизнеса или другое месторасположение."
                                : undefined, children: "\u0421\u043A\u0430\u0447\u0430\u0442\u044C \u0431\u0438\u0437\u043D\u0435\u0441-\u043F\u043B\u0430\u043D" }) })] }), _jsxs(Flex, { mt: "1rem", justifyContent: "space-between", children: [_jsx(FlexSpan, { flexGrow: 1, children: _jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { display: "flex", mr: "0.25rem", children: _jsx(Icon, { icon: "location" }) }), _jsx(FlexSpan, { mr: "0.25rem", children: _jsx(EllipsisText, { lines: 1, children: data.address }) })] }) }), _jsx(FlexSpan, { ml: "3rem", children: _jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { display: "flex", mr: "0.25rem", children: _jsx(Icon, { icon: "document" }) }), _jsx(FlexSpan, { mr: "0.25rem", children: "\u0414\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B" }), _jsx(FlexSpan, { display: "flex", children: _jsx(IconButton, { icon: "info_modal2", onClick: () => {
                                            openDialog(DIALOGS.DOCUMENTS_LIST_DIALOG, { data: docs });
                                        } }) })] }) })] }), _jsxs(Flex, { mt: "1.5rem", gap: "2rem", children: [_jsxs(FlexSpan, { width: "calc(25% - 1rem)", children: [_jsx(H1, { children: formatNumber(data.bp?.invest) }), _jsx(BusinessPlanHeaderLabel, { children: "\u041E\u0431\u044A\u0435\u043C \u0432\u043B\u043E\u0436\u0435\u043D\u0438\u0439, \u0442\u044B\u0441. \u0440\u0443\u0431." })] }), _jsxs(FlexSpan, { width: "calc(25% - 1rem)", children: [_jsx(H1, { children: formatNumber(data.bp?.dpp) || "более 60" }), _jsx(BusinessPlanHeaderLabel, { children: "\u0421\u0440\u043E\u043A \u043E\u043A\u0443\u043F\u0430\u0435\u043C\u043E\u0441\u0442\u0438, \u043C\u0435\u0441\u044F\u0446\u0435\u0432" })] }), _jsxs(FlexSpan, { width: "calc(25% - 1rem)", children: [_jsx(H1, { children: formatNumber(data.bp?.roi * 100, false) }), _jsx(BusinessPlanHeaderLabel, { children: "\u0420\u0435\u043D\u0442\u0430\u0431\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u0438\u043D\u0432\u0435\u0441\u0442\u0438\u0446\u0438\u0439 \u0437\u0430 5 \u043B\u0435\u0442, %" })] }), _jsxs(FlexSpan, { width: "calc(25% - 1rem)", children: [_jsx(H1, { children: formatNumber(data.bp?.npv, false) }), _jsx(BusinessPlanHeaderLabel, { children: "\u0427\u0438\u0441\u0442\u044B\u0439 \u0434\u0435\u043D\u0435\u0436\u043D\u044B\u0439 \u0434\u043E\u0445\u043E\u0434 \u0437\u0430 5 \u043B\u0435\u0442, \u0442\u044B\u0441. \u0440\u0443\u0431." })] })] })] }));
};
