import styled from "styled-components";
import { Icon } from "../Icon/styled";
export const IconButtonContainer = styled.button `
  display: inline-flex;
  border: none;
  background: none;
  transition: 0.15s ease-in-out;
  padding: 0;
  margin: 0;
  cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:not([disabled]) {
    ${Icon} {
      color: ${({ active, color, hoverColor }) => active ? (hoverColor ?? "#6E5CB2") : (color ?? "#9D87F1")};
    }
    
    &:hover,
    &:active {
      & * {
        cursor: pointer;
      }
      
      ${Icon} {
        color: ${({ hoverColor }) => hoverColor ?? "#6E5CB2"};
      }
    }
  }

  &[disabled] {
    ${Icon} {
      color: #9F9F9F;
    }
  }
`;
