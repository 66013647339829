/* eslint-disable */
export class Vector {
    x;
    y;
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
    add(vector) {
        return new Vector(this.x + vector.x, this.y + vector.y);
    }
    subtract(vector) {
        return new Vector(this.x - vector.x, this.y - vector.y);
    }
    angle() {
        return Math.atan2(this.y, this.x);
    }
    static fromAngle(angle, magnitude) {
        return new Vector(magnitude * Math.cos(angle), magnitude * Math.sin(angle));
    }
}
export class Path {
    commands;
    constructor(commands) {
        this.commands = commands;
    }
    toString() {
        return this.commands.join("");
    }
    static M(point) {
        return `M${point.x} ${point.y}`;
    }
    static Q(control, to) {
        return `Q${control.x} ${control.y} ${to.x} ${to.y}`;
    }
    static L(point) {
        return `L${point.x} ${point.y}`;
    }
    static Z() {
        return "Z";
    }
}
export const roundedRect = ({ width, height, x, y, tlr, trr, brr, blr, }) => {
    const x1 = x;
    const x2 = x + width;
    const y1 = y + height;
    const y2 = y;
    return new Path([
        Path.M(new Vector(x1, y1 - blr)),
        Path.Q(new Vector(x1, y1), new Vector(x1 + blr, y1)),
        Path.L(new Vector(x1 + blr, y1)),
        Path.L(new Vector(x2 - brr, y1)),
        Path.Q(new Vector(x2, y1), new Vector(x2, y1 - brr)),
        Path.L(new Vector(x2, y1 - brr)),
        Path.L(new Vector(x2, y2 + trr)),
        Path.Q(new Vector(x2, y2), new Vector(x2 - trr, y2)),
        Path.L(new Vector(x2 - trr, y2)),
        Path.L(new Vector(x1 + tlr, y2)),
        Path.Q(new Vector(x1, y2), new Vector(x1, y2 + trr)),
    ]).toString();
};
