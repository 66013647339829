export class Geocoder {
    http;
    constructor(http) {
        this.http = http;
    }
    getAddress(search, cityId, guid) {
        return this.http.get(`/api/gc_address`, {
            geocode: search ?? "",
            city: cityId,
            guid,
        }).json();
    }
}
