import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPromBusinessItems } from "../../../selectors/dict";
import { setPromBusinessItems } from "../../../slices/dict";
export const usePromBusinessItems = () => {
    const dispatch = useDispatch();
    const businessItems = useSelector(getPromBusinessItems);
    const update = useCallback((value) => {
        dispatch(setPromBusinessItems(value));
    }, [dispatch]);
    return [businessItems, update];
};
