import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes } from "react-router-dom";
import { MapProvider } from "@evergis/react";
import { Dialogs } from "../components/Dialogs";
import { MainPage } from "./MainPage";
import { RetailBusinessPage } from "./Retail/RetailBusinessPage";
import { RetailLocationPage } from "./Retail/RetailLocationPage";
import { RetailBusinessPlanPage } from "./Retail/RetailBusinessPlanPage";
import { RetailIntegralLocationPage } from "./RetailIntegral/RetailIntegralLocationPage";
import { SelfEmployedBusinessPage } from "./SelfEmployed/SelfEmployedBusinessPage";
import { SelfEmployedLocationPage } from "./SelfEmployed/SelfEmployedLocationPage";
import { SelfEmployedBusinessPlanPage } from "./SelfEmployed/SelfEmployedBusinessPlanPage";
import { EnterpriseBusinessPage } from "./Enterprise/EnterpriseBusinessPage";
import { EnterpriseLocationPage } from "./Enterprise/EnterpriseLocationPage";
import { EnterpriseBusinessPlanPage } from "./Enterprise/EnterpriseBusinessPlanPage";
import { EnterpriseIntegralLocationPage } from "./EnterpriseIntegral/EnterpriseIntegralLocationPage";
import { AttractivenessPage } from "./AttractivenessPage";
import { HelpPage } from "./HelpPage";
import { AttractivenessUrl, EnterpriseBusinessPlanUrl, EnterpriseBusinessUrl, EnterpriseIntegralLocationUrl, EnterpriseLocationUrl, HelpPageUrl, MainPageUrl, RetailBusinessPlanUrl, RetailBusinessUrl, RetailIntegralLocationUrl, RetailLocationUrl, SelfEmployedBusinessPlanUrl, SelfEmployedBusinessUrl, SelfEmployedLocationUrl, } from "../constants/routes";
import { map } from "../evergis/map";
import { useAppHeight, useAuth, usePageLoad } from "../hooks";
export const Pages = () => {
    useAuth();
    useAppHeight();
    usePageLoad();
    return (_jsxs(MapProvider, { map: map, children: [_jsx(Dialogs, {}), _jsxs(Routes, { children: [_jsx(Route, { path: MainPageUrl.path, element: _jsx(MainPage, {}) }), _jsx(Route, { path: RetailBusinessUrl.path, element: _jsx(RetailBusinessPage, {}) }), _jsx(Route, { path: RetailLocationUrl.path, element: _jsx(RetailLocationPage, {}) }), _jsx(Route, { path: RetailBusinessPlanUrl.path, element: _jsx(RetailBusinessPlanPage, {}) }), _jsx(Route, { path: RetailIntegralLocationUrl.path, element: _jsx(RetailIntegralLocationPage, {}) }), _jsx(Route, { path: SelfEmployedBusinessUrl.path, element: _jsx(SelfEmployedBusinessPage, {}) }), _jsx(Route, { path: SelfEmployedLocationUrl.path, element: _jsx(SelfEmployedLocationPage, {}) }), _jsx(Route, { path: SelfEmployedBusinessPlanUrl.path, element: _jsx(SelfEmployedBusinessPlanPage, {}) }), _jsx(Route, { path: EnterpriseBusinessUrl.path, element: _jsx(EnterpriseBusinessPage, {}) }), _jsx(Route, { path: EnterpriseLocationUrl.path, element: _jsx(EnterpriseLocationPage, {}) }), _jsx(Route, { path: EnterpriseBusinessPlanUrl.path, element: _jsx(EnterpriseBusinessPlanPage, {}) }), _jsx(Route, { path: EnterpriseIntegralLocationUrl.path, element: _jsx(EnterpriseIntegralLocationPage, {}) }), _jsx(Route, { path: AttractivenessUrl.path, element: _jsx(AttractivenessPage, {}) }), _jsx(Route, { path: HelpPageUrl.path, element: _jsx(HelpPage, {}) })] })] }));
};
