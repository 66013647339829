export class Statistic {
    http;
    constructor(http) {
        this.http = http;
    }
    getStatistic(formatId, cityId, point, disableCompetitors, zone, devEnable, guid, signal) {
        return this.http.get(`/api/statistic/${formatId}/${cityId}`, {
            point,
            disable_competitors: disableCompetitors?.join(","),
            zone,
            dev_enable: devEnable ?? "false",
            guid,
        }, signal).json();
    }
    getSelfStatistic(formatId, cityId, point, disableCompetitors, zone, devEnable, guid, signal) {
        return this.http.get(`/api/self_statistic/${formatId}/${cityId}`, {
            point,
            disable_competitors: disableCompetitors?.join(","),
            zone,
            dev_enable: devEnable ?? "false",
            guid,
        }, signal).json();
    }
    getPromStatistic(formatId, objectId, guid, signal) {
        return this.http.get(`/api/prom_statistic/${formatId}/${objectId}`, { guid }, signal).json();
    }
}
