import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STORAGE_SUBJECT } from "../../constants/common";
import { getCurrentSubject } from "../../selectors/app";
import { setCurrentSubject } from "../../slices/app";
export const useCurrentSubject = () => {
    const dispatch = useDispatch();
    const subject = useSelector(getCurrentSubject);
    const update = useCallback((value) => {
        window.localStorage.setItem(STORAGE_SUBJECT, JSON.stringify(value));
        dispatch(setCurrentSubject(value));
    }, [dispatch]);
    return [subject, update];
};
