import { useCallback, useEffect } from "react";
export const useCloseOnEscape = (onClose) => {
    const onKeyDown = useCallback((e) => {
        if (e && e.code === "Escape") {
            onClose?.();
        }
    }, [onClose]);
    useEffect(() => {
        document.body.addEventListener("keydown", onKeyDown);
        return () => {
            document.body.removeEventListener("keydown", onKeyDown);
        };
    }, []); // eslint-disable-line
};
