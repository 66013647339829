export class Business {
    http;
    constructor(http) {
        this.http = http;
    }
    getBusiness(guid) {
        return this.http.get(`/api/business`, { guid }).json();
    }
    getBusinessPriceRange(cityId, guid) {
        return this.http.get(`/api/business_price_range/${cityId}`, { guid }).json();
    }
    getSelfEmployedBusiness(guid) {
        return this.http.get(`/api/self_business`, { guid }).json();
    }
    getPromEmployedBusiness(guid) {
        return this.http.get(`/api/prom_business`, { guid }).json();
    }
}
