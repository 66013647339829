import styled from "styled-components";
import { Flex, H4 } from "../../../ui";
export const ListItemConceptList = styled(Flex) `
  flex-direction: column;
  box-sizing: border-box;
`;
export const BusinessConceptContainer = styled(Flex) `
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 0.5rem;
  width: 100%;
  align-items: flex-end;

  &:hover {
    background-color: #ffffff;
    box-shadow: 0 0 1rem 0 rgba(63, 67, 106, 0.12);
  }
  
  ${H4} {
    font-weight: 500;
  }
`;
