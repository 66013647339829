import styled from "styled-components";
import { Flex } from "../../ui";
import { SCREEN_WIDTH_DESKTOP } from "../../constants/layout";
export const MapContainer = styled.div `
  position: relative;
  width: 100%;
  height: calc(100% - 0.25rem);
`;
export const PickPointContainer = styled(Flex) `
  display: inline-flex;
  width: 20rem;
  position: absolute;
  top: 5rem;
  left: 1.5rem;
  padding: 1rem;
  box-sizing: border-box;
  align-items: flex-start;
  border-radius: 0.75rem;
  background: #ffd633;

  @media screen and (max-width: ${SCREEN_WIDTH_DESKTOP}px) {
    left: ${({ isLocationPage }) => !isLocationPage ? "24.5rem" : "1rem"};
    top: unset;
    bottom: ${({ isLocationPage }) => isLocationPage && "1rem"};
    width: 22.5rem;
  }
`;
