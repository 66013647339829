import styled from "styled-components";
export const MeasurerTooltip = styled.div `
  display: flex;
  align-items: center;
  gap: 0 0.25rem;
  padding: 0.25rem 0.5rem;
  color: white;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0.25rem 0.5rem rgba(63, 67, 106, 0.16);
  white-space: nowrap;
`;
