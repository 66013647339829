import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { default as ReactSelect, components } from "react-select";
import { SelectLabel, SelectErrorMessage, setHeight, setWidth, SelectWrapperContainer } from "./styled";
import { FlexSpan } from "../Grid";
import { IconButton } from "../IconButton";
const MultiValueRemove = (props) => {
    return (_jsx(components.MultiValueRemove, { ...props, children: _jsx(IconButton, { icon: "close", size: 12 }) }));
};
export const Select = ({ placeholder, noOptionsMessage, loadingMessage, isLoading, onChange, disabled, width, autoMenuWidth, height, hideDropdownIndicator, isSearchable = false, error, errorMessage, label, labelExtra, isMulti, ...rest }) => {
    return (_jsxs(SelectWrapperContainer, { width: width, children: [label && (_jsxs(SelectLabel, { children: [_jsx(FlexSpan, { children: label }), labelExtra && (_jsx(FlexSpan, { display: "flex", ml: "0.25rem", children: labelExtra }))] })), _jsx(ReactSelect, { isDisabled: disabled, placeholder: typeof placeholder === "string" ? placeholder : "Выберите...", noOptionsMessage: noOptionsMessage || (() => "Пусто"), loadingMessage: loadingMessage || (() => "Загрузка..."), isLoading: isLoading || false, isSearchable: isSearchable, isMulti: isMulti, onChange: value => value && onChange && onChange(value), components: { MultiValueRemove }, styles: {
                    singleValue: (base) => ({
                        ...base,
                        height: "100%",
                        fontSize: "1.125rem",
                        fontWeight: 100,
                        lineHeight: "140%",
                        color: error ? "#F1274C" : "#000000",
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: error ? "#F1274C" : "#9F9F9F",
                        fontSize: "1.125rem",
                        fontWeight: 100,
                        lineHeight: "140%",
                    }),
                    control: (base, { isDisabled, isFocused }) => ({
                        ...base,
                        border: error ? "1px solid #F1274C" : "1px solid #E3E3E3",
                        ":hover": {
                            border: error ? "1px solid #F1274C" : isFocused ? "1px solid #9D87F1" : "1px solid #9F9F9F",
                        },
                        outline: isFocused ? "4px solid #CEC3F8" : "none",
                        boxShadow: "none",
                        borderRadius: "0.375rem",
                        backgroundColor: isDisabled ? "#F9F9F9" : "#ffffff",
                        height: isMulti ? "auto" : setHeight(height),
                        minHeight: isMulti ? "2.75rem" : undefined,
                    }),
                    indicatorSeparator: () => ({
                        display: "none",
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: "0 1rem",
                    }),
                    container: (base) => ({
                        ...base,
                        width: setWidth(width),
                    }),
                    option: (base, { isSelected }) => ({
                        ...base,
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        backgroundColor: isSelected ? "#EBE7FC" : "#ffffff",
                        fontSize: "1.125rem",
                        fontWeight: 100,
                        lineHeight: "140%",
                        color: "#000000",
                        ":not(:last-child)": {
                            marginBottom: "0.125rem",
                        },
                        ":hover": {
                            backgroundColor: "#EBE7FC",
                        },
                        ":active": {
                            backgroundColor: "#EBE7FC",
                        },
                    }),
                    menu: (base) => (autoMenuWidth ? {
                        ...base,
                        width: "auto",
                        minWidth: setWidth(width),
                    } : base),
                    menuPortal: (base) => ({
                        ...base,
                        zIndex: 1000,
                    }),
                    multiValue: (base) => ({
                        ...base,
                        backgroundColor: "#EBE7FC",
                        padding: "0 0.5rem",
                        borderRadius: "0.25rem",
                    }),
                    multiValueRemove: (base) => ({
                        ...base,
                        ":hover": {
                            backgroundColor: "transparent",
                        },
                    }),
                }, menuPortalTarget: document.body, ...rest }), errorMessage && _jsx(SelectErrorMessage, { children: errorMessage })] }));
};
