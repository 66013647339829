import { ExtendedHttpClient } from "./ExtendedHttpClient";
import { Analytic } from "./resources/Analytic";
import { Auth } from "./resources/Auth";
import { Business } from "./resources/Business";
import { BusinessPlan } from "./resources/BusinessPlan";
import { City } from "./resources/City";
import { Geocoder } from "./resources/Geocoder";
import { PromCard } from "./resources/PromCard";
import { RetailCard } from "./resources/RetailCard";
import { SelfCard } from "./resources/SelfCard";
import { Service } from "./resources/Service";
import { Statistic } from "./resources/Statistic";
export class Api {
    http;
    url;
    analytic;
    auth;
    business;
    businessPlan;
    city;
    geocoder;
    promCard;
    retailCard;
    selfCard;
    service;
    statistic;
    constructor(url) {
        this.http = new ExtendedHttpClient({ prefixUrl: url });
        this.url = url;
        this.analytic = new Analytic(this.http);
        this.auth = new Auth(this.http);
        this.business = new Business(this.http);
        this.businessPlan = new BusinessPlan(this.http);
        this.city = new City(this.http);
        this.geocoder = new Geocoder(this.http);
        this.promCard = new PromCard(this.http);
        this.retailCard = new RetailCard(this.http);
        this.selfCard = new SelfCard(this.http);
        this.service = new Service(this.http);
        this.statistic = new Statistic(this.http);
    }
}
