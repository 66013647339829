import styled from "styled-components";
import { Flex } from "../../ui";
import { SCREEN_WIDTH_DESKTOP } from "../../constants/layout";
export const FloatLeftPanel = styled(Flex) `
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: ${SCREEN_WIDTH_DESKTOP}px) {
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #FFFFFF;
    box-shadow: 0 0.25rem 1.75rem 0 rgba(63, 67, 106, 0.16);
    height: ${({ isMaxHeight }) => isMaxHeight ? "100%" : "auto"};
  }
`;
