import styled, { keyframes } from "styled-components";
const animation = keyframes `
  from{
    transform: rotate(0deg);
  }
  
  to{
    transform: rotate(360deg);
  }
`;
export const LoaderContainer = styled.span `
  img {
    animation: ${animation} ${({ speed }) => speed ?? 2000}ms linear infinite;
  }
`;
