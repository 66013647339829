import styled from "styled-components";
import { space, flexbox, layout } from "styled-system";
export const PointDetailsIcon = styled.div `
  color: #9D87F1;
`;
export const PointDetailsBlockContainer = styled.div `
  border-radius: 0.5rem;
  background: #F0F1F3;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${flexbox}
  ${layout}
  
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;
export const PointDetailsBody = styled.div `
  margin-top: 1rem;
  padding: ${({ noPadding }) => noPadding ? 0 : "0 1rem 1rem"};
`;
