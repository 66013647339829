import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, FlexSpan, Loader } from "../../ui";
import { PointLocationPlaceholderLabel } from "./styled";
import position from "../../assets/images/position.svg";
export const PointLocationPlaceholder = ({ hidden, loading, children }) => {
    if (hidden) {
        return null;
    }
    return (_jsx(Flex, { column: true, alignItems: "center", "data-hide-on-mobile": true, children: _jsxs(FlexSpan, { display: "flex", flexDirection: "column", alignItems: "center", children: [!loading && _jsx("img", { src: position, width: "40px", height: "40px", alt: "" }), _jsx(PointLocationPlaceholderLabel, { children: loading ? _jsx(Loader, {}) : children })] }) }));
};
