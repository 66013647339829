import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { BusinessConceptDescription, BusinessConceptRatingContainer, BusinessConceptRatingItem } from "./styled";
import { Flex, FlexSpan, H4 } from "../../../ui";
import { CategoryIcon } from "../../../components/CategoryIcon";
import { DIALOGS } from "../../../constants/dialogs";
import { useDialog, usePromBusinessItems } from "../../../hooks";
export const BusinessConceptRatingList = ({ concepts }) => {
    const [businessItems] = usePromBusinessItems();
    const openDialog = useDialog();
    const onClick = useCallback((businessItem, conceptId) => {
        const businessConcept = businessItem.business_concepts?.find(concept => concept.id === Number(conceptId));
        openDialog(DIALOGS.BUSINESS_INFO_DIALOG, {
            businessConcept,
            businessId: businessItem.id,
        });
    }, [openDialog]);
    return (_jsx(BusinessConceptRatingContainer, { children: concepts.map(concept => {
            const businessItem = businessItems.find(({ business_concepts }) => business_concepts.some(item => item.id === concept.id));
            return (_jsxs(BusinessConceptRatingItem, { onClick: () => onClick(businessItem, concept.id), children: [_jsx(FlexSpan, { mr: "0.5rem", children: _jsx(CategoryIcon, { path: businessItem.ico }) }), _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { mt: "0.25rem", children: _jsx(H4, { children: concept.name }) }), _jsxs(Flex, { justifyContent: "space-between", mt: "0.25rem", children: [_jsx(BusinessConceptDescription, { children: "\u041F\u0440\u0438\u0431\u044B\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u0438\u043D\u0432\u0435\u0441\u0442\u0438\u0446\u0438\u0439" }), _jsx(BusinessConceptDescription, { accent: true, children: concept.weight.toFixed(1) })] })] })] }, concept.id));
        }) }));
};
