import styled from "styled-components";
export const ColorBox = styled.div `
  border-radius: 0.125rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${({ color }) => color};
`;
export const LegendTitle = styled.div `
  font-size: 0.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
