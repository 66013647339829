import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { isNumber } from "lodash";
import { Polygon } from "@evergis/sgis/es/features/Polygon";
import { circle as turfCircle, polygon as turfPolygon, buffer as turfBuffer, toWgs84, centroid as turfCentroid } from "@turf/turf";
import { wgs84 } from "@evergis/sgis/es/Crs";
import { getCrs, LabelSymbol, SrId } from "@evergis/react";
import { PointFeature } from "@evergis/sgis/es/features/PointFeature";
import { LabelFeature } from "@evergis/sgis/es/features/Label";
import { PointSymbol } from "@evergis/sgis/es/symbols/point/Point";
import { PolygonSymbol } from "@evergis/sgis/es/symbols/polygon/Simple";
import { StaticImageSymbol } from "@evergis/sgis/es/symbols/point/StaticImageSymbol";
import { DEFAULT_POLYGON_SYMBOL } from "../constants/map";
import { hex2rgb } from "./format";
import pin from "../assets/images/pin.svg";
const DynamicPointFeatureContainer = styled.div ``;
const HexLabelContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #2A3591;
  -webkit-text-stroke: 1.5px rgb(255 255 255 / 80%);
  font-family: "Roboto", serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;
export const getPointFeature = (coordinates, size, offset) => {
    return new PointFeature([coordinates[1], coordinates[0]], {
        symbol: new PointSymbol({
            size: size !== undefined ? size + 4 : 30,
            strokeWidth: 4,
            fillColor: "rgba(126, 108, 193, 0.25)",
            strokeColor: "#FFD633",
            offset: offset ?? [0, 0],
        }),
    }).projectTo(wgs84);
};
export const getCircleFeatureWithRadius = (coordinates, radius) => {
    return new Polygon(turfCircle(coordinates, radius, { units: "meters" }).geometry.coordinates, {
        symbol: new PolygonSymbol(DEFAULT_POLYGON_SYMBOL),
    }).projectTo(wgs84);
};
export const getPolygonFeature = (coordinates, style) => {
    return new Polygon(coordinates, {
        symbol: new PolygonSymbol(style !== undefined
            ? style.fillColor !== undefined ? {
                ...style,
                fillColor: isNumber(style.fillOpacity)
                    ? `rgba(${hex2rgb(style.fillColor).r}, ${hex2rgb(style.fillColor).g}, ${hex2rgb(style.fillColor).b}, ${style.fillOpacity})`
                    : style.fillColor,
            } : style
            : DEFAULT_POLYGON_SYMBOL),
    }).projectTo(wgs84);
};
export const getCircleBbox = (coordinates, radius, crs) => {
    return new Polygon(turfCircle(coordinates, radius, { units: "meters" }).geometry.coordinates, { crs: crs ?? getCrs(SrId.Wgs84) }).bbox;
};
export const getPolyBbox = (coordinates, bufferRadius, crs) => {
    return new Polygon(turfBuffer(turfPolygon(coordinates).geometry, bufferRadius, { units: "meters" }).geometry.coordinates, { crs: crs ?? getCrs(SrId.Wgs84) }).bbox;
};
export const getDynamicPointFeature = (feature, size, anchorPoint, source, onClickCallback) => {
    return new LabelFeature(feature.geometry.coordinates, {
        crs: wgs84,
        symbol: new LabelSymbol({
            component: () => (_jsx(DynamicPointFeatureContainer, { onClick: () => onClickCallback && onClickCallback(feature), children: _jsx("img", { src: source, width: size?.[0], height: size?.[1], alt: "" }) })),
            offset: [-anchorPoint[0], -anchorPoint[1]],
        }),
    }).projectTo(wgs84);
};
export const getHexLabel = (coordinates, label) => {
    return new LabelFeature(turfCentroid(turfPolygon(coordinates))?.geometry?.coordinates, {
        crs: wgs84,
        symbol: new LabelSymbol({
            component: () => (_jsx(HexLabelContainer, { children: label })),
            offset: [-12, -12],
        }),
    }).projectTo(wgs84);
};
export const getWktBboxPolygon = (coordinates) => {
    return `POLYGON((${coordinates[0]} ${coordinates[1]}, ${coordinates[0]} ${coordinates[3]}, ${coordinates[2]} ${coordinates[3]}, ${coordinates[2]} ${coordinates[1]}, ${coordinates[0]} ${coordinates[1]}))`;
};
export const getWktPoint = (point) => {
    if (!point) {
        return "";
    }
    const wgsMarker = toWgs84(point);
    return `POINT(${wgsMarker[0]} ${wgsMarker[1]})`;
};
export const getMarkerSymbol = () => {
    return new StaticImageSymbol({
        width: 28,
        height: 36,
        anchorPoint: [14, 36],
        source: pin,
    });
};
export const getPolygonHalo = (coordinates) => {
    return getPolygonFeature(coordinates, {
        strokeColor: "rgba(254, 206, 65, 0.3)",
        strokeWidth: 12,
        isOutsideStroke: true,
    });
};
