import { jsx as _jsx } from "react/jsx-runtime";
import RcSlider from "rc-slider";
import { isNil, throttle } from "lodash";
import { SliderContainer } from "./styled";
import "rc-slider/assets/index.css";
export const Slider = ({ className, onChange, style, min, max, noRail, noMarks, markFormatFunc, ...rest }) => {
    return (_jsx(SliderContainer, { style: style, className: className, noRail: noRail, noMarks: noMarks, children: _jsx(RcSlider, { marks: !isNil(min) && !isNil(max)
                ? {
                    [min]: markFormatFunc ? markFormatFunc(min) : min.toString(),
                    [max]: markFormatFunc ? markFormatFunc(max) : max.toString(),
                }
                : {}, min: min, max: max, ...rest, onChange: throttle(value => {
                onChange && onChange(value);
            }, 24) }) }));
};
