import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    retail: {},
    self: {},
    prom: {},
};
const slice = createSlice({
    name: "dict",
    initialState,
    reducers: {
        setCityList: (state, action) => {
            state.cityList = action.payload;
        },
        setSubjectList: (state, action) => {
            state.subjectList = action.payload;
        },
        setRetailBusinessItems: (state, action) => {
            state.retail.businessItems = action.payload;
        },
        setRetailBusinessPriceRange: (state, action) => {
            state.retail.businessPriceRange = action.payload;
        },
        setSelfBusinessItems: (state, action) => {
            state.self.businessItems = action.payload;
        },
        setPromBusinessItems: (state, action) => {
            state.prom.businessItems = action.payload;
        },
        setPromConceptItem: (state, action) => {
            state.prom.conceptItem = action.payload;
        },
    },
});
export const { setCityList, setSubjectList, setRetailBusinessItems, setRetailBusinessPriceRange, setSelfBusinessItems, setPromBusinessItems, setPromConceptItem, } = slice.actions;
export default slice.reducer;
