import styled from "styled-components";
import { Flex, FlexSpan } from "../../../ui";
export const LocationsCompareWrapper = styled.div `
  position: absolute;
  left: 0;
  top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(var(--app-height) - 5rem);
  background-color: #f9f9f9;
  z-index: 3;
`;
export const LocationsCompareContainer = styled.div `
  z-index: 4;
  background-color: #FFFFFF;
  width: calc(100vw - 3.5rem);
  max-width: calc(100rem - 3.5rem);
  height: calc(var(--app-height) - 8.5rem);
  box-shadow: 0 0 1rem 0 rgba(63, 67, 106, 0.12);
  border-radius: 0.5rem;
`;
export const LocationAdd = styled(Flex) `
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #8B8B8B;
  cursor: pointer;
  transition: .1s ease-in-out;
  
  &:hover {
    color: #9D87F1;
  }
`;
export const CompareTableCell = styled(FlexSpan) `
  height: ${({ isHeader }) => isHeader ? "3rem" : "2rem"};
  min-height: ${({ isHeader }) => isHeader ? "3rem" : "2rem"};
  max-height: ${({ isHeader }) => isHeader ? "3rem" : "2rem"};
  line-height: normal;
  padding: 0.5rem 1.25rem;
  z-index: ${({ isFirstCol }) => isFirstCol && 1};
  background-color: ${({ hovered }) => hovered ? "#F0F1F3" : "#ffffff"};
  width: 100%;
`;
export const CompareTableParamLabel = styled(FlexSpan) `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.54;
`;
export const CompareTableParamValue = styled(FlexSpan) `
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  color: ${({ isMax }) => isMax && "#9D87F1"};
`;
export const CompareTableParamValueIconContainer = styled.span `
  color: #9D87F1;
  
  &:hover {
    color: #6E5CB2;
  }
`;
export const CompareTableParamValueSubKeyBar = styled.div `
  width: ${({ value }) => `${value ?? 0}px`};
  height: 0.375rem;
  background-color: ${({ color }) => color ?? "#000000"};
  border-radius: 0.25rem;
`;
export const DeleteArea = styled(FlexSpan) `
  display: flex;
  visibility: hidden;
`;
export const AreaHeader = styled(FlexSpan) `
  flex-grow: 1;

  &:hover {
    ${DeleteArea} {
      visibility: ${({ noDelete }) => !noDelete && "visible"};
    }
  }
`;
