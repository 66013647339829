import styled from "styled-components";
import { space } from "styled-system";
export const BadgeContainer = styled.span `
  display: inline-flex;
  align-items: center;
  border-radius: 2.5rem;
  padding: ${({ small }) => small ? "calc(0.06rem - 2px) calc(0.5rem - 2px)" : "calc(0.44rem - 2px) calc(0.75rem - 2px)"};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ backgroundColor }) => backgroundColor ?? "#E3E3E3"};
  color: ${({ color }) => color ?? "#000000"};
  font-size: ${({ small }) => small ? "0.625rem" : "0.875rem"};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ small }) => small ? "0.875rem" : "normal"};
  white-space: nowrap;
  ${space}
`;
