import styled from "styled-components";
import { Flex } from "../../../../ui";
import { Icon } from "../../../../ui/Icon/styled";
export const CityPickerContainer = styled(Flex) `
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  transition: 0.1s ease-in-out;
  
  * {
    cursor: pointer;
  }
  
  &:hover {
    color: #B19FF4;
  }

  &:active {
    color: #B19FF4;
  }
  
  ${Icon} {
    position: relative;
    top: 0.0625rem;
    transition: none;
  }
`;
