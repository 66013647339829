import styled from "styled-components";
import { Flex } from "../../ui";
export const MapControl = styled(Flex) `
  z-index: 2;
  display: inline-flex;
  width: ${({ width }) => width ?? "auto"};
  height: ${({ height }) => height};
  position: absolute;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  border-radius: 0.5rem;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.6875rem 0 rgba(0, 0, 0, 0.08);
  padding: ${({ padding }) => padding || "0.88rem"};
  box-sizing: border-box;
  transition: 0.15s ease-in-out;
  transition-property: width, height, padding;
`;
