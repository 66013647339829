import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useCallback, useState } from "react";
import { Symbol } from "@evergis/react";
import { H3Symbol } from "@evergis/sgis/es/symbols/H3Symbol";
import { LocationsCompareWrapper, LocationsCompareContainer, LocationAdd, CompareTableCell, AreaHeader, DeleteArea, } from "./styled";
import { BackButton, EllipsisText, Flex, FlexSpan, Icon, IconButton, Link, Tooltip, } from "../../../ui";
import { AreaOpenBusinessButton } from "../AreaOpenBusinessButton";
import { AreaRating } from "../AreaRating";
import { useAreaCompareParams, useCloseOnEscape, useCurrentCity } from "../../../hooks";
import { useCompareTableData } from "./hooks/useCompareTableData";
import { generateExcel } from "./utils";
// eslint-disable-next-line max-lines-per-function
export const AreasCompare = ({ heatMap, heatMapTurf, businessAreas, onDeleteArea, onClose, onGoToArea, }) => {
    const [hovered, setHovered] = useState();
    const compareTableData = useCompareTableData(businessAreas);
    const [areaCompareParams] = useAreaCompareParams();
    const [currentCity] = useCurrentCity();
    const checkDataHeader = useCallback(rowIndex => {
        return compareTableData[rowIndex]?.filter(Boolean).length === 1;
    }, [compareTableData]);
    useCloseOnEscape(onClose);
    return (_jsx(LocationsCompareWrapper, { children: _jsx(LocationsCompareContainer, { children: _jsxs(Flex, { height: "100%", overflowY: "auto", borderRadius: "0.5rem", children: [_jsx(FlexSpan, { width: "20.5rem", minWidth: "20.5rem", height: "100%", position: "sticky", left: 0, backgroundColor: "#ffffff", zIndex: 2, children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { width: "100%", height: "9.25rem", position: "sticky", top: 0, borderRight: "1px solid #D9D9D9", borderBottom: "1px solid #D9D9D9", backgroundColor: "#ffffff", padding: "1rem", zIndex: 2, children: _jsx(Flex, { column: true, height: "100%", children: _jsx(FlexSpan, { flexGrow: 1, width: "100%", children: _jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { flexGrow: 1, children: _jsx(BackButton, { label: "\u041D\u0430\u0437\u0430\u0434", onClick: onClose }) }), _jsx(FlexSpan, { children: _jsx(Tooltip, { content: "\u0421\u043A\u0430\u0447\u0430\u0442\u044C \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442", children: _jsx(IconButton, { icon: "download", size: 20, onClick: () => {
                                                                    generateExcel(`Отчет о сравнении локаций_${new Date().toLocaleDateString()} ${new Date()
                                                                        .toLocaleTimeString()
                                                                        .replace(/:/, ".")
                                                                        .replace(/:/, ".")
                                                                        .slice(0, -3)}`, currentCity, businessAreas, areaCompareParams);
                                                                } }) }) })] }) }) }) }), _jsx(FlexSpan, { width: "100%", borderRight: "1px solid #D9D9D9", children: _jsx(Flex, { column: true, children: compareTableData.map((row, rowIndex) => {
                                            return (_jsx(CompareTableCell, { onMouseOver: () => setHovered(checkDataHeader(rowIndex) ? undefined : rowIndex), onMouseLeave: () => setHovered(undefined), hovered: hovered === rowIndex, isHeader: checkDataHeader(rowIndex), isFirstCol: true, children: checkDataHeader(rowIndex) ? (_jsx(_Fragment, { children: row[0] })) : (_jsx(EllipsisText, { lines: 1, children: row[0] })) }, `compare-table-row-${rowIndex}-col-0`));
                                        }) }) })] }) }), businessAreas.map((area, areaIndex) => {
                        return (_jsxs(FlexSpan, { width: "calc((100% - 20.5rem) / 5)", minWidth: "12.5rem", height: "fit-content", zIndex: 1, position: "relative", children: [_jsx(Flex, { width: "100%", height: "9.25rem", position: "sticky", top: 0, borderRight: areaIndex < 5 ? "1px solid #D9D9D9" : "none", borderBottom: "1px solid #D9D9D9", backgroundColor: "#ffffff", padding: "1rem", children: _jsxs(Flex, { column: true, children: [_jsx(AreaHeader, { noDelete: businessAreas.length <= 1, children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { children: _jsxs(Flex, { gap: "0.5rem", mb: "0.5rem", alignItems: "center", children: [_jsx(FlexSpan, { display: "flex", children: _jsx(Flex, { width: "2rem", height: "2rem", style: {
                                                                                opacity: heatMap?.styles.find(style => style.styleId === area.s)?.fillOpacity ?? 1,
                                                                            }, children: _jsx(Symbol, { symbol: new H3Symbol(heatMap?.styles.find(style => style.styleId === area.s)), size: 32 }) }) }), _jsx(FlexSpan, { flexGrow: 1, fontWeight: 600, children: _jsx(Tooltip, { content: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u043B\u043E\u043A\u0430\u0446\u0438\u0438 \u043D\u0430 \u043A\u0430\u0440\u0442\u0435", children: _jsxs(Link, { idleColor: "#000000", onClick: () => {
                                                                                    onGoToArea && onGoToArea(areaIndex);
                                                                                    onClose?.();
                                                                                }, children: ["\u041B\u043E\u043A\u0430\u0446\u0438\u044F ", areaIndex + 1] }) }) }), _jsx(DeleteArea, { children: _jsx(Tooltip, { content: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0438\u0437 \u0441\u0440\u0430\u0432\u043D\u0435\u043D\u0438\u044F", children: _jsx(IconButton, { icon: "trash", onClick: () => onDeleteArea && onDeleteArea(areaIndex) }) }) })] }) }), _jsx(FlexSpan, { children: _jsx(Flex, { pl: "0.25rem", alignItems: "center", gap: "0.5rem", children: _jsx(FlexSpan, { children: _jsx(AreaRating, { rating: area.s }) }) }) })] }) }), _jsx(FlexSpan, { children: _jsx(AreaOpenBusinessButton, { width: "100%", buttonSize: "xs", style: { paddingLeft: 0, paddingRight: 0 }, feature: heatMapTurf?.features.find(item => item.properties.id === area.id), placement: "bottom" }) })] }) }), _jsx(Flex, { column: true, borderRight: areaIndex < 5 ? "1px solid #D9D9D9" : "none", children: _jsx(Flex, { column: true, children: compareTableData.map((row, rowIndex) => {
                                            return (_jsx(CompareTableCell, { onMouseOver: () => setHovered(checkDataHeader(rowIndex) ? undefined : rowIndex), onMouseLeave: () => setHovered(undefined), hovered: hovered === rowIndex, textAlign: "center", isHeader: checkDataHeader(rowIndex), children: row[areaIndex + 1] }, `compare-table-row-${rowIndex}-col-${areaIndex + 1}`));
                                        }) }) })] }, `area-${areaIndex}`));
                    }), Array(5 - businessAreas.length).fill(null).map((_, placeholderIndex) => {
                        return (_jsxs(FlexSpan, { width: "calc((100% - 20.5rem) / 5)", minWidth: "12.5rem", height: "fit-content", zIndex: 1, position: "relative", children: [_jsx(Flex, { width: "100%", height: "9.25rem", position: "sticky", top: 0, borderRight: placeholderIndex < 5 - businessAreas.length - 1 ? "1px solid #D9D9D9" : "none", borderBottom: "1px solid #D9D9D9", backgroundColor: "#ffffff", padding: "1rem", children: _jsxs(LocationAdd, { onClick: () => onClose?.(), children: [_jsx(FlexSpan, { children: _jsx(Icon, { icon: "point-add", size: 30 }) }), _jsx(FlexSpan, { fontWeight: 600, children: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043B\u043E\u043A\u0430\u0446\u0438\u044E" })] }) }), _jsx(Flex, { column: true, borderRight: placeholderIndex < 5 - businessAreas.length - 1 ? "1px solid #D9D9D9" : "none", children: _jsx(Flex, { column: true, children: compareTableData.map((row, rowIndex) => {
                                            return (_jsx(CompareTableCell, { isHeader: checkDataHeader(rowIndex) }, `compare-table-row-${rowIndex}-col-${businessAreas.length + placeholderIndex + 1}`));
                                        }) }) })] }, `location-placeholder-${placeholderIndex}`));
                    })] }) }) }));
};
