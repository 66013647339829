import styled from "styled-components";
import { Flex } from "../../ui";
export const TableRowMenuContainer = styled(Flex) `
  position: absolute;
  align-items: center;
  justify-content: center;
  top: ${({ top }) => top ?? "0.25rem"};
  left: ${({ left }) => left ?? "-0.55rem"};
  background-color: ${({ active }) => active ? "#F0F0F0" : "#F6F6F6"};
  border-radius: 0.375rem;
  width: 0.75rem;
  height: 2rem;
  cursor: pointer;
  color: ${({ active }) => active ? "#6E5CB2" : "#9F9F9F"};
  z-index: 1;
  
  &:hover {
    background-color: ${({ active }) => !active && "#F3F3F3"};
    color: ${({ active }) => !active && "#7E6CC1"};
  }
`;
