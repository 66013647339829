import { jsx as _jsx } from "react/jsx-runtime";
import { ListItemConceptList } from "./styled";
import { BusinessConcept } from "./BusinessConcept";
import { useRetailBusinessPriceRange } from "../../hooks";
export const BusinessListItem = ({ businessItem, filteredFormats = [], lightBackground, listStyle, compactMode, search, onConceptClick, }) => {
    const [businessPriceRange] = useRetailBusinessPriceRange();
    return (_jsx(ListItemConceptList, { style: listStyle, children: businessItem?.business_concepts
            .filter(concept => concept.business_formats.filter(format => filteredFormats.includes(format.id)).length)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(item => {
            const startRanges = item.business_formats.map(format => businessPriceRange.find(range => range.business_format === format.id)?.start_range);
            const stopRanges = item.business_formats.map(format => businessPriceRange.find(range => range.business_format === format.id)?.stop_range);
            return (_jsx(BusinessConcept, { businessItem: businessItem, search: search, concept: item, start: Math.min(...startRanges), stop: Math.max(...stopRanges), lightBackground: lightBackground, onClick: () => onConceptClick(item.id), compactMode: compactMode }, `business-list-concept-${item.id}`));
        }) }));
};
