import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, FlexSpan, Icon, Link, Modal } from "../../../ui";
import { BodyTextContainer } from "./styled";
import { trimSlashes } from "../../../utils";
import { api } from "../../../api";
export const AuthorizationNeededDialog = () => {
    return (_jsx(Modal, { title: (_jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { mb: "1rem", children: _jsx(Icon, { icon: "cancel_circle", size: 36, color: "#9D87F1" }) }), _jsx(FlexSpan, { children: "\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u0443\u0439\u0442\u0435\u0441\u044C" })] })), content: (_jsxs(BodyTextContainer, { children: ["\u0414\u043B\u044F \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u043D\u0438\u044F \u0441\u0435\u0440\u0432\u0438\u0441\u0430 \u0432\u0430\u043C \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E", " ", _jsx(Link, { style: { display: "contents" }, onClick: () => {
                        api.auth.clearSession().then(() => {
                            window.open(`${trimSlashes(process.env.PORTAL_URL)}/profile/`);
                        });
                    }, children: "\u0430\u0432\u0442\u043E\u0440\u0438\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u0447\u0435\u0440\u0435\u0437 \u0413\u043E\u0441\u0443\u0441\u043B\u0443\u0433\u0438." })] })), width: "43.75rem", titleStyle: { alignItems: "flex-start" } }));
};
