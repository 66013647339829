import { useEffect } from "react";
import { useAppInitialized, useSection, useUser } from "../../../hooks";
export const useUpdateSection = (outerSectionType) => {
    const [user] = useUser();
    const [appInitialized, setAppInitialized] = useAppInitialized();
    const [section, setSection] = useSection();
    useEffect(() => {
        if (!user.guid) {
            return;
        }
        if (section !== outerSectionType) {
            setSection(outerSectionType);
        }
        if (!appInitialized) {
            setAppInitialized(true);
        }
    }, [outerSectionType, section, user]); // eslint-disable-line
};
