import styled from "styled-components";
import { Flex, FlexSpan } from "../../../../ui";
export const TableHeaderCell = styled(FlexSpan) `
  border-radius: 0.5rem;
  background: rgba(84, 173, 255, 0.20);
  padding: 1rem;
`;
export const TableHeaderSubtitle = styled.div `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;
export const TableBodyCell = styled(FlexSpan) `
  border-radius: 0.5rem;
  background: rgba(84, 173, 255, 0.05);
  padding: 1rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;
export const TableFooter = styled(Flex) `
  border-radius: 0.5rem;
  background: rgba(84, 173, 255, 0.20);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  
  ${FlexSpan} {
    padding: 1rem;
  }
`;
