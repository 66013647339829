import * as d3 from "d3";
import { format } from "date-fns";
import { isNil } from "lodash";
const locale = d3.formatLocale({
    decimal: ".",
    thousands: " ",
    grouping: [3],
    currency: ["", ""],
});
export const formatNumber = (value, round = true, precision) => {
    if (isNil(value)) {
        return "";
    }
    return locale.format(`,.${precision ?? 2}~f`)(round ? Math.round(+value) : +value);
};
export const formatDate = (date) => {
    if (date === undefined) {
        return "";
    }
    return format(date, "dd.MM.yyyy");
};
export const getDateObject = (date) => {
    if (date === undefined) {
        return;
    }
    const paramDate = +date.split(".")?.[0];
    const paramMonth = +date.split(".")?.[1];
    const paramYear = +date.split(".")?.[2];
    return new Date(paramYear, paramMonth - 1, paramDate);
};
export const hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return { r, g, b };
};
