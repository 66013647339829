import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useState } from "react";
import { GeometryType } from "@evergis/api";
import { FeatureLayer, SrId, useMapContext, useMapView } from "@evergis/react";
import { booleanPointInPolygon, point, polygon, toWgs84 } from "@turf/turf";
import { FlexSpan, Loader } from "../../ui";
import { Layout } from "../../components/Layout";
import { Map } from "../../components/Map";
import { MapContainer, PickAreaContainer } from "./styled";
import { SimpleFeaturesLayer } from "../../components/SimpleFeaturesLayer";
import { FloatLeftPanel } from "../../components/FloatLeftPanel";
import { AreaCard } from "./AreaCard";
import { AreaDescription } from "./AreaDescription";
import { CompareAreasControl } from "./CompareAreasControl";
import { AreasCompare } from "./AreasCompare";
import { AreaOpenBusinessButton } from "./AreaOpenBusinessButton";
import { SimpleLegendControl } from "../../components/SimpleLegendControl";
import { COMPARE_POLYGON_SYMBOL, SELECTED_POLYGON_SYMBOL } from "../../constants/map";
import { MAP_CONTROL_LEFT_DESKTOP } from "../../constants/layout";
import { useCurrentCity, useCurrentFeature, useIsDesktop, useMeasurerActive, useResetOverlayFeatureOnLoad, } from "../../hooks";
import { getHexLabel, getPolyBbox, getPolygonHalo } from "../../utils/map";
import { getPolygonFeature } from "../../utils";
import { api } from "../../api";
import { AppSection } from "../../slices/app/types";
import { LegendSymbolType } from "../../components/SimpleLegendControl/types";
import positionHex from "../../assets/images/position-hex.svg";
let controller = null;
// eslint-disable-next-line max-lines-per-function
export const AttractivenessPage = () => {
    const { map, painter } = useMapContext();
    const isDesktop = useIsDesktop();
    const [heatMap, setHeatMap] = useState();
    const [heatMapTurf, setHeatMapTurf] = useState();
    const [loading, setLoading] = useState(true);
    const [currentCity] = useCurrentCity();
    const [clickPoint, setClickPoint] = useState();
    const [currentFeature, setCurrentFeature] = useCurrentFeature();
    const [areaDetails, setAreaDetails] = useState();
    const [businessAreas, setBusinessAreas] = useState([]);
    const [isCompareOpen, setIsCompareOpen] = useState(false);
    const [isMeasurerActive] = useMeasurerActive();
    const mapView = useMapView();
    const heatMapFeatures = useMemo(() => {
        if (!heatMap) {
            return [];
        }
        return heatMap.features.map(item => getPolygonFeature(item.g, {
            ...heatMap.styles.find(style => style.styleId === item.s),
        }));
    }, [heatMap]);
    const compareSymbols = useMemo(() => {
        return businessAreas.map(item => getPolygonFeature(item.g, {
            ...COMPARE_POLYGON_SYMBOL,
        }));
    }, [businessAreas]);
    const hexLabelSymbols = useMemo(() => {
        return businessAreas.reduce((acc, item, itemIndex) => {
            if (mapView.level < 13) {
                return acc;
            }
            return [
                ...acc,
                getHexLabel(item.g, itemIndex + 1),
            ];
        }, []);
    }, [businessAreas, mapView.level]);
    const onMapClick = useCallback(e => {
        if (isMeasurerActive) {
            return;
        }
        setClickPoint(toWgs84(e.point.position));
    }, [isMeasurerActive]);
    const renderCurrentFeature = useMemo(() => {
        if (!heatMap || !currentFeature) {
            return null;
        }
        return (_jsx(FeatureLayer, { features: [
                getPolygonHalo(currentFeature.geometry.coordinates),
                getPolygonFeature(currentFeature.geometry.coordinates, {
                    ...SELECTED_POLYGON_SYMBOL,
                }),
            ], zIndex: 101 }));
    }, [currentFeature, heatMap]);
    useResetOverlayFeatureOnLoad();
    useEffect(() => {
        if (!clickPoint || !heatMapTurf) {
            return;
        }
        const turfPoint = point(clickPoint);
        const checkPointInPolygons = heatMapTurf.features.some(feature => {
            if (booleanPointInPolygon(turfPoint, feature)) {
                setCurrentFeature(feature);
                return true;
            }
            return false;
        });
        if (!checkPointInPolygons && currentFeature !== undefined) {
            setCurrentFeature(undefined);
            setAreaDetails(undefined);
        }
    }, [clickPoint]); // eslint-disable-line
    useEffect(() => {
        if (!currentCity) {
            return;
        }
        if (!currentFeature) {
            setAreaDetails(undefined);
            return;
        }
        api.service.getHeatmapArea(currentCity.id, currentFeature.properties.id)
            .then(details => {
            setAreaDetails({ analytic: details });
        });
    }, [currentFeature]); // eslint-disable-line
    useEffect(() => {
        if (currentCity === undefined) {
            return;
        }
        controller?.abort();
        controller = new AbortController();
        setLoading(true);
        api.service.getHeatmapService(currentCity.id, controller.signal)
            .then(response => {
            setHeatMap(response);
            setHeatMapTurf({
                type: "FeatureCollection",
                features: response.features.map(item => ({
                    ...polygon(item.g),
                    properties: {
                        id: item.id,
                        s: item.s,
                        g: item.g,
                    },
                })),
            });
        })
            .catch(() => null)
            .finally(() => setLoading(false));
    }, [currentCity]); // eslint-disable-line
    useEffect(() => {
        setClickPoint(undefined);
        setCurrentFeature(undefined);
        setAreaDetails(undefined);
        setBusinessAreas([]);
        setIsCompareOpen(false);
    }, [heatMap, currentCity]); // eslint-disable-line
    useEffect(() => {
        setClickPoint(undefined);
    }, [isMeasurerActive]);
    useEffect(() => {
        map.on("click", onMapClick);
        return () => {
            map.off("click", onMapClick);
        };
    }, [isMeasurerActive]); // eslint-disable-line
    useEffect(() => {
        return () => {
            controller?.abort();
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Layout, { section: AppSection.Attractiveness, noPadding: !isDesktop, leftPanel: (_jsxs(FloatLeftPanel, { isMaxHeight: !!currentFeature, children: [areaDetails && currentFeature && (_jsx(FlexSpan, { children: _jsx(AreaCard, { businessAreas: businessAreas, areaStyle: heatMap?.styles.find(style => style.styleId === currentFeature.properties.s), onCompare: () => {
                                    if (businessAreas.findIndex(item => item.id === currentFeature.properties.id) > -1) {
                                        setBusinessAreas(businessAreas.filter(item => item.id !== currentFeature.properties.id));
                                    }
                                    else {
                                        setBusinessAreas([
                                            ...businessAreas,
                                            {
                                                id: currentFeature.properties.id,
                                                s: currentFeature.properties.s,
                                                g: currentFeature.properties.g,
                                                details: areaDetails,
                                            },
                                        ]);
                                    }
                                } }) })), _jsx(FlexSpan, { flexGrow: 1, display: !areaDetails ? "flex" : undefined, alignItems: !areaDetails && "center", justifyContent: !areaDetails && "center", overflow: "hidden", children: !areaDetails ? (_jsx(PickAreaContainer, { children: loading ? (_jsx(FlexSpan, { children: _jsx(Loader, {}) })) : (_jsxs(_Fragment, { children: [_jsx(FlexSpan, { display: "flex", children: _jsx("img", { src: positionHex, width: "50px", height: "50px", alt: "" }) }), _jsxs(FlexSpan, { fontSize: "0.875rem", fontWeight: 500, lineHeight: "150%", textAlign: "center", children: ["\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0442\u0435\u0440\u0440\u0438\u0442\u043E\u0440\u0438\u044E \u0434\u043B\u044F \u043E\u0446\u0435\u043D\u043A\u0438", _jsx("br", {}), "\u0445\u0430\u0440\u0430\u043A\u0442\u0435\u0440\u0438\u0441\u0442\u0438\u043A"] })] })) })) : (_jsx(AreaDescription, { details: areaDetails })) }), areaDetails && (_jsx(FlexSpan, { display: "flex", justifyContent: "center", mb: "0.25rem", children: _jsx(AreaOpenBusinessButton, { width: "calc(100% - 0.5rem)", buttonSize: isDesktop ? "xl" : "s", feature: currentFeature }) }))] })), children: _jsx(MapContainer, { children: _jsxs(Map, { width: "100%", height: "100%", extent: {
                            ...currentCity?.geometry,
                            coordinates: [...(currentCity?.geometry.coordinates ?? [])],
                            type: GeometryType.Polygon,
                            sr: SrId.Wgs84,
                        }, children: [isDesktop && heatMap && (_jsx(SimpleLegendControl, { bottom: "1.5rem", left: MAP_CONTROL_LEFT_DESKTOP, labelFrom: (_jsxs(FlexSpan, { children: ["\u041D\u0430\u0438\u043C\u0435\u043D\u0435\u0435", _jsx("br", {}), "\u043F\u0440\u0438\u0432\u043B\u0435\u043A\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439"] })), labelTo: (_jsxs(FlexSpan, { children: ["\u041D\u0430\u0438\u0431\u043E\u043B\u0435\u0435", _jsx("br", {}), "\u043F\u0440\u0438\u0432\u043B\u0435\u043A\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439"] })), symbolType: LegendSymbolType.Hexagon, symbols: [
                                    {
                                        color: heatMap.styles.find(item => item.styleId === 1)?.fillColor,
                                        opacity: heatMap.styles.find(item => item.styleId === 1)?.fillOpacity ?? 1,
                                    },
                                    {
                                        color: heatMap.styles.find(item => item.styleId === 2)?.fillColor,
                                        opacity: heatMap.styles.find(item => item.styleId === 2)?.fillOpacity ?? 1,
                                    },
                                    {
                                        color: heatMap.styles.find(item => item.styleId === 3)?.fillColor,
                                        opacity: heatMap.styles.find(item => item.styleId === 3)?.fillOpacity ?? 1,
                                    },
                                    {
                                        color: heatMap.styles.find(item => item.styleId === 4)?.fillColor,
                                        opacity: heatMap.styles.find(item => item.styleId === 4)?.fillOpacity ?? 1,
                                    },
                                    {
                                        color: heatMap.styles.find(item => item.styleId === 5)?.fillColor,
                                        opacity: heatMap.styles.find(item => item.styleId === 5)?.fillOpacity ?? 1,
                                    },
                                ] })), _jsx(SimpleFeaturesLayer, { features: heatMapFeatures }), _jsx(SimpleFeaturesLayer, { features: compareSymbols }), _jsx(SimpleFeaturesLayer, { features: hexLabelSymbols }), renderCurrentFeature, _jsx(CompareAreasControl, { businessAreas: businessAreas, onClick: () => setIsCompareOpen(true) })] }) }) }), isCompareOpen && (_jsx(AreasCompare, { heatMap: heatMap, heatMapTurf: heatMapTurf, businessAreas: businessAreas, onClose: () => setIsCompareOpen(false), onGoToArea: areaId => {
                    const outerArea = businessAreas[areaId];
                    if (outerArea !== undefined) {
                        setCurrentFeature(heatMapTurf.features.find(feature => feature.properties.id === outerArea.id));
                        painter.show(getPolyBbox(outerArea.g, 1000));
                    }
                }, onDeleteArea: areaIndex => {
                    setBusinessAreas(businessAreas.filter((_, itemIndex) => {
                        return itemIndex !== areaIndex;
                    }));
                } }))] }));
};
