import { BusinessForm } from "./Forms/BusinessForm";
import { CreditForm } from "./Forms/CreditForm";
import { LeasingForm } from "./Forms/LeasingForm";
export const FORM_PARAMS = [
    {
        title: "Параметры бизнеса",
        icon: "settings",
        mode: "Business",
        component: BusinessForm,
        keys: ["form", "taxation", "ownership", "equipment"],
        noReset: true,
    },
    {
        title: "Заемные средства",
        icon: "man-with-case",
        mode: "Credit",
        component: CreditForm,
        keys: ["credits", "credits_time", "credits_procent", "own_fund"],
        noReset: true,
    },
    {
        title: "Лизинг",
        icon: "dash",
        mode: "Leasing",
        component: LeasingForm,
        keys: ["equipment", "leasing_time", "leasing_procent", "leasing_payment"],
        noReset: true,
    },
];
export const CHART_NAMES = {
    0: "Выручка, млн. руб.",
    1: "Прибыль, млн. руб.",
};
