import styled from "styled-components";
import { FlexSpan } from "../Grid";
import { SCREEN_WIDTH_DESKTOP } from "../../constants/layout";
export const TabLabel = styled(FlexSpan) `
  position: relative;
`;
export const TabContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: ${({ menu }) => (menu ? "center" : "left")};
  padding: ${({ menu }) => (menu ? "0.5rem 1.25rem" : "0.5rem 0")};
  box-sizing: border-box;
  border-bottom: 0.125rem solid ${({ active, menu, empty, }) => (menu || empty ? "transparent" : active ? "#7E6CC1" : "#F3F3F3")};
  transition: 0.15s ease-in-out;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  background-color: ${({ active, menu, empty }) => (!menu || empty ? "transparent" : active ? "#7E6CC1" : "#F3F3F3")};
  color: ${({ active, menu, disabled }) => menu
    ? disabled
        ? "#9F9F9F"
        : active
            ? "#ffffff"
            : "#000000"
    : disabled
        ? "#ef8989"
        : active
            ? "#7E6CC1"
            : "#000000"};
  border-radius: ${({ menu }) => menu && "0.375rem"};
  pointer-events: ${({ empty }) => empty ? "none" : undefined};
  cursor: pointer;
  height: 2.625rem;
  flex-wrap: nowrap;
  white-space: nowrap;

  &[disabled] {
    cursor: not-allowed;
  }

  &:not([disabled]) {
    &:hover {
      background-color: ${({ menu, active }) => menu && !active && "#EBE7FC"};
      color: ${({ active }) => !active && "#6E5CB2"};
    }
  }

  @media screen and (max-width: ${SCREEN_WIDTH_DESKTOP}px) {
    height: ${({ menu }) => menu && "2rem"};
    font-size: ${({ menu }) => menu && "0.75rem"};
    line-height: ${({ menu }) => menu && "normal"};

    ${TabLabel} {
      top: ${({ menu }) => menu && "0.0625rem"};
    }
  }
`;
export const TabDivider = styled(TabContainer) `
  width: ${({ width }) => width ?? "2rem"};
  height: 2.625rem;
  border-color: ${({ forEmpty }) => (forEmpty ? "transparent" : undefined)};
  cursor: default;
`;
