import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { orderBy } from "lodash";
import { Layout } from "../../../components/Layout";
import { Divider, Flex, FlexSpan, PrimaryButton, Switch, Tooltip } from "../../../ui";
import { Stepper } from "../../../components/Stepper";
import { SearchInput } from "../../../components/SearchInput";
import { PromBusinessCard } from "./PromBusinessCard";
import { PromBusinessListItem } from "../../../components/PromBusinessListItem";
import { EnterpriseIntegralLocationUrl, EnterpriseLocationUrl } from "../../../constants/routes";
import { MIN_SEARCH_LENGTH } from "../../../constants/common";
import { useCurrentSubject, useIsDesktop, usePromBusinessDisplayMode, usePromBusinessItems, usePromBusinessSearch, } from "../../../hooks";
import { AppSection } from "../../../slices/app/types";
import { BusinessDisplayMode } from "../../../slices/filters/types";
export const EnterpriseBusinessPage = () => {
    const navigate = useNavigate();
    const isDesktop = useIsDesktop();
    const [businessItems] = usePromBusinessItems();
    const [currentSubject] = useCurrentSubject();
    const [search, setSearch] = usePromBusinessSearch();
    const [displayMode, setDisplayMode] = usePromBusinessDisplayMode();
    const renderCards = useCallback(() => {
        return [...businessItems ?? []].sort((a, b) => a.name.localeCompare(b.name)).map(businessItem => {
            return (_jsx(PromBusinessCard, { businessItem: businessItem }, `business-card-${businessItem.id}`));
        });
    }, [businessItems]);
    const renderListItems = useCallback(() => {
        return (_jsx(Flex, { column: true, mb: "1.5rem", pl: "0.5rem", children: orderBy([...businessItems ?? []].map(item => {
                return {
                    ...item,
                    business_concepts: item.business_concepts.filter(concept => {
                        return (!search
                            || search.length < MIN_SEARCH_LENGTH
                            || concept.name.toLowerCase().includes(search.toLowerCase()));
                    }),
                };
            }).reduce((acc, curr) => [...acc, ...curr.business_concepts.map(item => ({ ...curr, business_concepts: [item] }))], []), "business_concepts[0].name", ["asc"]).map(businessItem => {
                return (_jsx(PromBusinessListItem, { businessItem: businessItem, search: search, onConceptClick: concept => {
                        if (currentSubject) {
                            navigate(generatePath(EnterpriseLocationUrl.path, { subjectId: currentSubject.id, businessId: businessItem?.id, conceptId: concept.id }));
                        }
                    } }, `business-list-item-${businessItem.id}-${businessItem.business_concepts[0]?.id}`));
            }) }));
    }, [businessItems, currentSubject, navigate, search]);
    return (_jsx(Layout, { section: AppSection.Enterprise, children: _jsxs(Flex, { column: true, height: "100%", children: [_jsx(FlexSpan, { children: _jsxs(Flex, { alignItems: "center", mb: "2rem", children: [_jsx(FlexSpan, { mr: "3.5rem", flexGrow: 1, alignSelf: "flex-start", children: _jsx(Stepper, { activeStep: 0, hideButtons: true, steps: [
                                        { title: "Выбор бизнеса" },
                                        { title: "Выбор площадки" },
                                        { title: "Бизнес-план" },
                                    ] }) }), _jsx(FlexSpan, { mr: "1rem", children: _jsx(Switch, { label: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0441\u043F\u0438\u0441\u043A\u043E\u043C", labelPosition: "left", checked: displayMode === BusinessDisplayMode.List, onChange: () => setDisplayMode(displayMode === BusinessDisplayMode.Categories ? BusinessDisplayMode.List : BusinessDisplayMode.Categories) }) }), _jsx(FlexSpan, { m: "0 1.5rem", children: _jsx(Divider, { vertical: true, height: "2rem" }) }), _jsx(FlexSpan, { position: "relative", top: "-0.1875rem", children: _jsx(Tooltip, { content: "\u041F\u043E\u0434\u043E\u0431\u0440\u0430\u0442\u044C \u0440\u0435\u0433\u0438\u043E\u043D \u0434\u043B\u044F \u0437\u0430\u0434\u0430\u043D\u043D\u043E\u0433\u043E \u0432\u0438\u0434\u0430 \u0431\u0438\u0437\u043D\u0435\u0441\u0430 \u043F\u043E \u043F\u0440\u0438\u0431\u044B\u043B\u044C\u043D\u043E\u0441\u0442\u0438 \u0438\u043D\u0432\u0435\u0441\u0442\u0438\u0446\u0438\u0439", placement: "left", children: _jsx(PrimaryButton, { buttonSize: "s", onClick: () => navigate(EnterpriseIntegralLocationUrl.path), children: "\u0418\u043D\u0442\u0435\u0433\u0440\u0430\u043B\u044C\u043D\u0430\u044F \u043A\u0430\u0440\u0442\u0430" }) }) })] }) }), displayMode === BusinessDisplayMode.List && (_jsx(FlexSpan, { width: "100%", mb: "1.25rem", children: _jsx(SearchInput, { value: search, onChange: setSearch, autofocus: isDesktop }) })), _jsx(FlexSpan, { flexGrow: 1, overflow: "auto", children: _jsx(Flex, { flexWrap: "wrap", mb: "0.25rem", pt: "0.5rem", children: displayMode === BusinessDisplayMode.Categories ? renderCards() : renderListItems() }) })] }) }));
};
