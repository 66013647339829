import { jsx as _jsx } from "react/jsx-runtime";
import { generatePath, useNavigate } from "react-router-dom";
import { Modal } from "../../../ui";
import { BusinessListItem } from "../../BusinessListItem";
import { useCurrentCity, useDialogProps, useDialogReset } from "../../../hooks";
export const BusinessCardDialog = () => {
    const navigate = useNavigate();
    const [currentCity] = useCurrentCity();
    const closeDialog = useDialogReset();
    const [{ businessItem, filteredFormats, forwardPath }] = useDialogProps();
    return (_jsx(Modal, { width: "50vw", maxWidth: "50rem", paddingContent: "0 0 0 1rem", title: businessItem?.name, content: (_jsx(BusinessListItem, { compactMode: true, listStyle: { padding: 0 }, businessItem: businessItem, filteredFormats: filteredFormats, lightBackground: true, onConceptClick: conceptId => {
                closeDialog();
                if (forwardPath && currentCity && businessItem) {
                    navigate(generatePath(forwardPath, { cityId: currentCity.id, businessId: businessItem?.id, conceptId }));
                }
            } })) }));
};
