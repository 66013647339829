import styled from "styled-components";
import { Flex, H4 } from "../../../ui";
export const BusinessConceptContainer = styled(Flex) `
  flex-direction: column;
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 0.5rem;
  width: calc(100% - 1.5rem);
  align-items: flex-end;

  &:hover {
    background-color: ${({ lightBackground }) => lightBackground ? "#F7F4FF" : "#ffffff"};
    box-shadow: ${({ lightBackground }) => !lightBackground && "0 0 1rem 0 rgba(63, 67, 106, 0.12)"};
  }
  
  ${H4} {
    font-weight: 500;
  }
`;
