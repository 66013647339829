import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { changeProps } from "find-and";
import { InfoIcon } from "../../InfoIcon";
import { MapControl } from "../../MapControl";
import { Checkbox, Flex, FlexSpan, IconButton } from "../../../ui";
import { CheckboxLabel } from "./styled";
export const LegendControl = ({ legend, setLegend, ...props }) => {
    const [open, setOpen] = useState(false);
    useEffect(() => () => {
        setLegend(null);
    }, []);
    if (!legend) {
        return null;
    }
    return (_jsx(MapControl, { ...props, column: true, alignItems: "center", width: "20rem", children: _jsxs(FlexSpan, { width: "100%", children: [_jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { flexGrow: 1, children: "\u0414\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F" }), _jsx(FlexSpan, { display: "flex", children: _jsx(IconButton, { icon: open ? "close" : "more_hor", onClick: () => setOpen(!open) }) })] }), open && (_jsx(Flex, { column: true, children: legend.map((layer, layerIndex) => {
                        return (_jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { mt: "1rem", mb: layerIndex < legend.length - 1 || (layerIndex === legend.length - 1 && layer.styles.length > 0)
                                            ? "0.75rem"
                                            : 0, children: _jsx(Checkbox, { label: _jsxs(FlexSpan, { display: "inline-flex", alignItems: "center", children: [_jsx(CheckboxLabel, { children: layer.name }), layer.tooltip && _jsx(InfoIcon, { content: layer.tooltip })] }), checked: legend.find(item => item.name === layer.name)?.is_visible, onChange: () => setLegend(changeProps(legend, { name: layer.name }, { is_visible: !legend.find(item => item.name === layer.name)?.is_visible })) }) }), _jsx(FlexSpan, { children: _jsx(Flex, { column: true, children: layer.styles.map((style, styleIndex) => {
                                                return (_jsx(FlexSpan, { pl: "1.75rem", children: _jsxs(Flex, { mb: styleIndex < layer.styles.length - 1 ? "0.5rem" : undefined, alignItems: "center", children: [_jsx(FlexSpan, { width: "1rem", display: "flex", justifyContent: "center", mr: "0.5rem", children: _jsx("img", { src: `${process.env.API_URL || ""}${style.iconUrl}`, width: `${style.iconSize[0]}px`, alt: "" }) }), _jsx(FlexSpan, { children: style.legendLabel })] }) }, `legend-layer-${layerIndex}-style-${styleIndex}`));
                                            }) }) })] }) }, `legend-layer-${layerIndex}`));
                    }) }))] }) }));
};
