export const DEFAULT_POLYGON_SYMBOL = {
    fillColor: "rgba(126, 108, 193, 0.25)",
    strokeColor: "rgba(126, 108, 193, 1)",
    strokeWidth: 4,
    lineDash: [4, 8],
};
export const SELECTED_POLYGON_SYMBOL = {
    strokeColor: "#FFD633",
    strokeWidth: 6,
};
export const COMPARE_POLYGON_SYMBOL = {
    strokeColor: "#FFFFFF",
    strokeWidth: 4,
};
export const ORDERED_PINS = [
    {
        id: 1,
        content: "<path class=\"st0\" d=\"M8,4c2.5-2.5,6-4,9.6-4c3.6,0,7,1.4,9.6,4c2.5,2.5,4,6,4,9.6c0,10.2-12,21.1-12.5,21.6l-1,0.9l-1-0.9\n" +
            "\tC16,34.6,4,23.7,4,13.5C4,9.9,5.4,6.5,8,4z\"/>\n" +
            "<circle class=\"st1\" cx=\"17.5\" cy=\"13.5\" r=\"9.5\"/>\n" +
            "<path d=\"M14.6,16.3h2.2v-5.9l-2.2,1.6v-2L17,8.2h2v8.1h1.8V18h-6.2V16.3z\"/>",
    },
    {
        id: 2,
        content: "<path class=\"st0\" d=\"M8,4c2.5-2.5,6-4,9.6-4c3.6,0,7,1.4,9.6,4c2.5,2.5,4,6,4,9.6c0,10.2-12,21.1-12.5,21.6l-1,0.9l-1-0.9\n" +
            "\tC16,34.6,4,23.7,4,13.5C4,9.9,5.4,6.5,8,4z\"/>\n" +
            "<circle class=\"st1\" cx=\"17.5\" cy=\"13.5\" r=\"9.5\"/>\n" +
            "<path d=\"M13.4,16.5l3.4-3c0.3-0.3,0.6-0.5,0.8-0.7c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.5\n" +
            "\tc0-0.4-0.1-0.7-0.3-1c-0.2-0.3-0.6-0.4-1-0.4c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.4,0.7-0.4,1.4h-2.1c0-1.1,0.3-2,0.9-2.6\n" +
            "\tc0.6-0.6,1.6-1,2.8-1c0.6,0,1.1,0.1,1.5,0.2c0.4,0.1,0.8,0.4,1.1,0.6c0.3,0.3,0.5,0.6,0.6,1c0.1,0.4,0.2,0.8,0.2,1.2\n" +
            "\tc0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.6-0.5,0.9c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.3-0.6,0.5-0.9,0.8l-2,1.7h4.5V18h-7.4V16.5z\"/>",
    },
    {
        id: 3,
        content: "<path class=\"st0\" d=\"M8,4c2.5-2.5,6-4,9.6-4c3.6,0,7,1.4,9.6,4c2.5,2.5,4,6,4,9.6c0,10.2-12,21.1-12.5,21.6l-1,0.9l-1-0.9\n" +
            "\tC16,34.6,4,23.7,4,13.5C4,9.9,5.4,6.5,8,4z\"/>\n" +
            "<circle class=\"st1\" cx=\"17.5\" cy=\"13.5\" r=\"9.5\"/>\n" +
            "<path d=\"M17.1,18.2c-1.2,0-2.1-0.3-2.7-0.8c-0.6-0.6-0.9-1.3-0.9-2.3h2.1c0,0.5,0.1,0.8,0.4,1.1c0.3,0.2,0.6,0.3,1.2,0.3\n" +
            "\tc0.5,0,0.9-0.1,1.1-0.3c0.3-0.2,0.4-0.6,0.4-1c0-0.9-0.5-1.3-1.5-1.3h-0.9v-1.6h0.9c0.4,0,0.8-0.1,1-0.3c0.2-0.2,0.4-0.5,0.4-0.9\n" +
            "\tc0-0.4-0.1-0.6-0.3-0.9c-0.2-0.2-0.6-0.3-1.1-0.3c-0.5,0-0.8,0.1-1.1,0.3c-0.2,0.2-0.3,0.5-0.3,0.9h-2.1c0-1,0.3-1.7,0.9-2.2\n" +
            "\tC15.1,8.3,16,8,17.1,8c0.6,0,1.1,0.1,1.5,0.2c0.4,0.1,0.8,0.3,1.1,0.6c0.3,0.2,0.5,0.5,0.6,0.8c0.1,0.3,0.2,0.7,0.2,1.1\n" +
            "\tc0,0.5-0.2,1-0.5,1.4c-0.3,0.4-0.7,0.6-1.2,0.8c0.6,0.2,1,0.4,1.3,0.8c0.3,0.4,0.5,0.9,0.5,1.6c0,0.4-0.1,0.8-0.3,1.2\n" +
            "\tc-0.2,0.4-0.4,0.7-0.7,0.9c-0.3,0.3-0.7,0.5-1.1,0.6C18.2,18.1,17.7,18.2,17.1,18.2z\"/>",
    },
    {
        id: 4,
        content: "<path class=\"st0\" d=\"M8,4c2.5-2.5,6-4,9.6-4c3.6,0,7,1.4,9.6,4c2.5,2.5,4,6,4,9.6c0,10.2-12,21.1-12.5,21.6l-1,0.9l-1-0.9\n" +
            "\tC16,34.6,4,23.7,4,13.5C4,9.9,5.4,6.5,8,4z\"/>\n" +
            "<circle class=\"st1\" cx=\"17.5\" cy=\"13.5\" r=\"9.5\"/>\n" +
            "<path d=\"M17.9,15.9h-4.6v-1.5l4.6-6.3H20v6h1.1v1.7H20V18h-2.1V15.9z M17.9,14.2v-3.7l-2.7,3.7H17.9z\"/>",
    },
    {
        id: 5,
        content: "<path class=\"st0\" d=\"M8,4c2.5-2.5,6-4,9.6-4c3.6,0,7,1.4,9.6,4c2.5,2.5,4,6,4,9.6c0,10.2-12,21.1-12.5,21.6l-1,0.9l-1-0.9\n" +
            "\tC16,34.6,4,23.7,4,13.5C4,9.9,5.4,6.5,8,4z\"/>\n" +
            "<circle class=\"st1\" cx=\"17.5\" cy=\"13.5\" r=\"9.5\"/>\n" +
            "<path d=\"M17.2,18.2c-0.6,0-1.2-0.1-1.6-0.2c-0.4-0.2-0.8-0.4-1.1-0.6c-0.3-0.3-0.5-0.6-0.6-0.9c-0.1-0.4-0.2-0.7-0.2-1.1h2.1\n" +
            "\tc0,0.3,0.2,0.6,0.4,0.8c0.2,0.2,0.6,0.3,1,0.3c1,0,1.5-0.6,1.5-1.7c0-0.3,0-0.6-0.1-0.8c-0.1-0.2-0.2-0.4-0.3-0.5\n" +
            "\tc-0.1-0.1-0.3-0.2-0.5-0.3C17.6,13,17.4,13,17.2,13c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5h-2V8.2h6.4v1.7H16v1.8\n" +
            "\tc0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.6-0.1,1-0.1c0.5,0,0.9,0.1,1.3,0.2c0.4,0.1,0.7,0.4,1,0.6c0.3,0.3,0.5,0.6,0.6,1.1\n" +
            "\tc0.1,0.4,0.2,0.9,0.2,1.5c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.4,0.8-0.7,1.1c-0.3,0.3-0.7,0.5-1.1,0.7C18.3,18.1,17.8,18.2,17.2,18.2z\"\n" +
            "\t/>",
    },
];
