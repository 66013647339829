import styled from "styled-components";
export const YandexMapContainer = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  pointer-events: none;
`;
