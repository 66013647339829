export class City {
    http;
    constructor(http) {
        this.http = http;
    }
    getCityList(guid) {
        return this.http.get(`/api/city`, { guid }).json();
    }
    getSubjectList(guid) {
        return this.http.get(`/api/prom_subject`, { guid }).json();
    }
}
