import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { AuthorizationNeededDialog } from "./AuthorizationNeededDialog";
import { CityPickerDialog } from "./CityPickerDialog";
import { BusinessCardDialog } from "./BusinessCardDialog";
import { PromBusinessCardDialog } from "./PromBusinessCardDialog";
import { BusinessInfoDialog } from "./BusinessInfoDialog";
import { CompetitorsDialog } from "./CompetitorsDialog";
import { BeforeBusinessPlanDialog } from "./BeforeBusinessPlanDialog";
import { BeforePromBusinessPlanDialog } from "./BeforePromBusinessPlanDialog";
import { DocumentsListDialog } from "./DocumentsListDialog";
import { LocationSettingsDialog } from "./LocationSettingsDialog";
import { PromBusinessPickerDialog } from "./PromBusinessPickerDialog";
import { RetailIntegralInfoDialog } from "./RetailIntegralInfoDialog";
import { LocationsCompareParamsDialog } from "./LocationsCompareParamsDialog";
import { DIALOGS } from "../../constants/dialogs";
import { getCurrentDialog } from "../../selectors/dialog";
const DIALOGS_MAP = {
    [DIALOGS.AUTHORIZATION_NEEDED_DIALOG]: AuthorizationNeededDialog,
    [DIALOGS.CITY_PICKER_DIALOG]: CityPickerDialog,
    [DIALOGS.BUSINESS_CARD_DIALOG]: BusinessCardDialog,
    [DIALOGS.PROM_BUSINESS_CARD_DIALOG]: PromBusinessCardDialog,
    [DIALOGS.BUSINESS_INFO_DIALOG]: BusinessInfoDialog,
    [DIALOGS.COMPETITORS_DIALOG]: CompetitorsDialog,
    [DIALOGS.BEFORE_BUSINESS_PLAN_DIALOG]: BeforeBusinessPlanDialog,
    [DIALOGS.BEFORE_PROM_BUSINESS_PLAN_DIALOG]: BeforePromBusinessPlanDialog,
    [DIALOGS.DOCUMENTS_LIST_DIALOG]: DocumentsListDialog,
    [DIALOGS.LOCATION_SETTINGS_DIALOG]: LocationSettingsDialog,
    [DIALOGS.PROM_BUSINESS_PICKER_DIALOG]: PromBusinessPickerDialog,
    [DIALOGS.RETAIL_INTEGRAL_INFO_DIALOG]: RetailIntegralInfoDialog,
    [DIALOGS.LOCATIONS_COMPARE_PARAMS_DIALOG]: LocationsCompareParamsDialog,
};
export const Dialogs = () => {
    const currentDialog = useSelector(getCurrentDialog);
    const Dialog = DIALOGS_MAP[currentDialog];
    return Dialog ? _jsx(Dialog, {}) : null;
};
