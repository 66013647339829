import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsCompareParams } from "../../selectors/filters";
import { setLocationsCompareParams } from "../../slices/filters";
export const useLocationsCompareParams = () => {
    const dispatch = useDispatch();
    const locationsCompareParams = useSelector(getLocationsCompareParams);
    const update = useCallback((value) => {
        dispatch(setLocationsCompareParams(value));
    }, [dispatch]);
    return [locationsCompareParams, update];
};
