import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBaseMapName } from "../../selectors/map";
import { setBaseMapName } from "../../slices/map";
export const useBaseMapName = () => {
    const dispatch = useDispatch();
    const baseMapName = useSelector(getBaseMapName);
    const setName = useCallback((name) => {
        dispatch(setBaseMapName(name));
    }, [dispatch]);
    return [baseMapName, setName];
};
