import { changeProps, removeObject } from "find-and";
import { formatScheduleToMonths, orderGanttEvents } from "../../../utils";
export const syncRetailGanttEvents = (params, events, contstants) => {
    if (params === undefined || events === undefined) {
        return;
    }
    let result = events;
    const processEventType = (type, month) => {
        if (month === undefined) {
            return;
        }
        if (month.every(item => item === 0) || month.length === 0) {
            result = removeObject(result, { type });
            return;
        }
        if (result.findIndex(item => item.type === type) === -1) {
            result = [
                ...result,
                {
                    ...contstants.event_type.find(item => item.type === type),
                    id: -1 * (result.filter(item => item.id < 0).length + 1),
                    month,
                },
            ];
        }
        else {
            result = changeProps(result, { type }, { month });
        }
    };
    processEventType("credit", [params.credit_month]);
    // Schedule params (`InitialFinancingForm`)
    ["repair", "docs", "product", "reg", "soft", "deposit", "adv", "recruit"]
        .forEach(scheduleParam => {
        processEventType(scheduleParam, formatScheduleToMonths(params[`${scheduleParam}_schedule`]));
    });
    processEventType("power_start", [params.power_schedule.findIndex(item => item !== 0) + 1]);
    processEventType("power_full", [params.power_schedule.findIndex(item => item === 1) + 1]);
    processEventType("equipment", Array(12).fill(null).reduce((acc, _, index) => {
        if (params.equipment_list.some(item => item.schedule[index] !== 0)) {
            return [
                ...acc,
                index + 1,
            ];
        }
        return acc;
    }, []));
    return orderGanttEvents(result);
};
// eslint-disable-next-line
export const validateRequestValues = (values, defaultParams) => {
    return values;
};
