import styled from "styled-components";
import { Badge, Flex, FlexSpan, H4 } from "../../../ui";
export const PromBusinessTitle = styled(Flex) `
  align-items: center;
  justify-content: space-between;
`;
export const PromBusinessDescription = styled(FlexSpan) `
  font-size: 0.75rem;
  color: #666;
`;
export const PromBusinessRatingList = styled(Flex) `
  flex-grow: 1;
  flex-direction: column;
  margin-top: 1rem;
  overflow-y: auto;
`;
export const PromBusinessRatingItem = styled(Flex) `
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: 500;
  transition: background-color 0.3s;
  border-radius: 0.75rem;

  &:hover {
    background-color: #f7f4ff;
  }
`;
export const PromBusinessBadge = styled(Badge).attrs(() => ({ color: "#fff", backgroundColor: "#9D87F1" })) `
  padding: 0.375rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  line-height: 0.625rem;
`;
export const BusinessConceptRatingContainer = styled(Flex) `
  flex-direction: column;
  margin-top: 1rem;
  overflow-y: auto;
`;
export const BusinessConceptRatingItem = styled(Flex) `
  cursor: pointer;
  padding: 1rem 1rem 1rem 0.5rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f7f4ff;
  }
`;
export const BusinessConceptDescription = styled.div `
  font-size: 0.75rem;
  font-weight: ${({ accent }) => (accent ? 500 : 400)};
  color: ${({ accent }) => (accent ? "#000" : "#666")};
`;
export const PromBusinessPickerContainer = styled(Flex) `
  cursor: pointer;
  position: relative;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 0.5rem;

  &:hover ${H4} {
    transition: color 0.3s;
    color: #9d87f1;
  }
`;
