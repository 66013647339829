import styled from "styled-components";
import { Flex } from "../Grid";
export const setWidth = (width) => (typeof width === "number"
    ? `${width}px`
    : typeof width === "undefined" ? "100%" : width);
export const setHeight = (height) => (typeof height === "number"
    ? `${height}px`
    : typeof height === "undefined" ? "2.75rem" : height);
export const SelectWrapperContainer = styled.span `
  position: relative;
  display: inline-block;
  width: ${({ width }) => setWidth(width)};
`;
export const SelectLabel = styled(Flex) `
  align-items: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #9F9F9F;
  margin-bottom: 0.5rem;
`;
export const SelectErrorMessage = styled.div `
  position: absolute;
  color: #F1274C;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-top: 0.25rem;
`;
