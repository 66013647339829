import styled from "styled-components";
import { FlexSpan, H3 } from "../../ui";
export const ListItemContainer = styled(FlexSpan) `
  ${H3} {
    white-space: nowrap;
  }
  
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;
export const ListItemLabel = styled.div `
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #666666;
`;
