import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderContainer, HeaderWrapper, LogoContainer, MenuItem, MobileMenuContainer, } from "./styled";
import { Divider, Flex, FlexSpan, Icon, IconButton, Menu, OutlineWhiteButton, Popover } from "../../../ui";
import { CityPicker } from "./CityPicker";
import { SideBar } from "../../SideBar";
import { MENU_ITEMS } from "./constants";
import { MainPageUrl } from "../../../constants/routes";
import { useSection } from "../../../hooks";
import { AppSection } from "../../../slices/app/types";
import logo from "../../../assets/images/logotype.svg";
import mspLogo from "../../../assets/images/msp-logo.png";
export const Header = () => {
    const navigate = useNavigate();
    const [section] = useSection();
    const [moreMenuOpen, setMoreMenuOpen] = useState(false);
    const [sideBarOpen, setSideBarOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(HeaderWrapper, { children: _jsxs(HeaderContainer, { children: [_jsx(FlexSpan, { mr: "1.25rem", children: _jsx(LogoContainer, { children: _jsx("a", { href: "https://\u043C\u0441\u043F.\u0440\u0444", target: "_blank", rel: "noreferrer", children: _jsx("img", { src: logo, alt: "", width: "170px", height: "42px" }) }) }) }), _jsx(FlexSpan, { mr: "2rem", children: _jsx("img", { src: mspLogo, alt: "", width: "121px", height: "30px" }) }), _jsx(FlexSpan, { flexGrow: 1, mr: "2rem", children: _jsxs(Flex, { alignItems: "center", gap: "2rem", children: [Object.entries(MENU_ITEMS).reduce((acc, [, value], index) => {
                                        if (!value.isMoreMenuItem) {
                                            return [
                                                ...acc,
                                                _jsx(MenuItem, { hideOnMobile: value.hideOnMobile, children: _jsx("a", { href: value.link, target: "_blank", rel: "noreferrer", children: value.label }) }, `menu-item-not-more-${index}`),
                                            ];
                                        }
                                        return acc;
                                    }, []), Object.entries(MENU_ITEMS).filter(([, value]) => value.isMoreMenuItem).length > 0 && (_jsx(MenuItem, { hideOnMobile: true, children: _jsx(Popover, { anchor: (_jsx(IconButton, { icon: "more_hor", size: 24, active: moreMenuOpen, color: "#ffffff", hoverColor: "#B19FF4" })), offsetY: 12, offsetX: -12, animateFadeIn: true, isOpen: moreMenuOpen, onOpen: () => setMoreMenuOpen(true), onClose: () => setMoreMenuOpen(false), children: _jsx(Menu, { width: "16.5rem", onPick: item => {
                                                    setMoreMenuOpen(false);
                                                    if (item.value) {
                                                        window.open(item.value.link, "new");
                                                    }
                                                }, items: Object.entries(MENU_ITEMS).filter(([, value]) => value.isMoreMenuItem).map(([, value]) => ({
                                                    label: value.label,
                                                    value,
                                                })) }) }) }))] }) }), _jsx(FlexSpan, { children: _jsxs(Flex, { alignItems: "center", children: [_jsx(FlexSpan, { children: _jsxs(Flex, { alignItems: "center", gap: "1rem", children: [section !== undefined && section !== AppSection.EnterpriseIntegral && (_jsxs(_Fragment, { children: [_jsx(FlexSpan, { children: _jsx(CityPicker, {}) }), _jsx(FlexSpan, { children: _jsx(Divider, { vertical: true, height: "1.5rem", opacity: 0.12, mt: "0.25rem" }) })] })), section !== undefined && (_jsx(FlexSpan, { children: _jsx(OutlineWhiteButton, { onClick: () => navigate(MainPageUrl.path), buttonSize: "xs", pl: "1rem", children: _jsx(FlexSpan, { style: { whiteSpace: "nowrap" }, children: _jsxs(Flex, { alignItems: "center", gap: "0.35rem", children: [_jsx(FlexSpan, { display: "flex", children: _jsx(Icon, { icon: "chevron-small-left" }) }), _jsx(FlexSpan, { children: "\u041D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E \u0441\u0435\u0440\u0432\u0438\u0441\u0430" })] }) }) }) }))] }) }), _jsx(MobileMenuContainer, { children: _jsx(IconButton, { icon: sideBarOpen ? "close" : "menu", size: 28, color: "#ffffff", hoverColor: "#B19FF4", active: sideBarOpen, onClick: () => setSideBarOpen(true) }) })] }) })] }) }), sideBarOpen && (_jsx(SideBar, { isOpen: sideBarOpen, onClose: () => setSideBarOpen(false), placement: "right", children: _jsx(Flex, { column: true, padding: "1rem", children: _jsx(FlexSpan, { children: _jsx(Menu, { onPick: item => {
                                setSideBarOpen(false);
                                if (MENU_ITEMS[item.value]) {
                                    window.open(MENU_ITEMS[item.value].link, "new");
                                }
                            }, items: Object.entries(MENU_ITEMS).reduce((acc, [key, value]) => {
                                return value.hideOnMobile ? [
                                    ...acc,
                                    {
                                        label: value.label,
                                        value: key,
                                        prefix: value.prefixIcon
                                            ? _jsx(Icon, { icon: value.prefixIcon, size: 20, color: "#9D87F1" })
                                            : undefined,
                                    },
                                ] : acc;
                            }, []) }) }) }) }))] }));
};
