import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { format, setMonth } from "date-fns";
import { ru } from "date-fns/locale";
import { FlexSpan } from "../../../../../ui";
import { SimpleBarChart, TooltipValue, TooltipWrapper } from "./styled";
import { formatNumber } from "../../../../../utils";
export const SeasonBarChart = ({ season }) => {
    const barsData = useMemo(() => {
        return season?.map((value, index) => {
            const groups = { [index]: [value] };
            return { groupName: format(setMonth(new Date(), index), "MMM", { locale: ru }), ...groups };
        });
    }, [season]);
    const customXScale = useCallback(xScale => {
        xScale.paddingInner(16);
    }, []);
    const renderTooltip = useCallback((group) => (_jsxs(TooltipWrapper, { children: [_jsx(FlexSpan, { children: format(setMonth(new Date(), group[0].groupIndex), "LLLL", { locale: ru }) }), _jsxs(TooltipValue, { children: [formatNumber(group[0].value * 100, false), " %"] })] })), []);
    return (_jsx(SimpleBarChart, { height: 72, data: barsData, colors: {
            0: "#9D87F1",
        }, drawGridX: true, barWidth: 24, dynamicTooltipEnable: true, renderTooltip: renderTooltip, customXScale: customXScale }));
};
