import { createSlice } from "@reduxjs/toolkit";
const initialState = {};
const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
});
export const { setUser } = slice.actions;
export default slice.reducer;
