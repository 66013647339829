import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Flex, FlexSpan, GrayButton, H4, Icon, IconButton, Popover, PrimaryButton } from "../../ui";
import { StepButton, StepDescription, StepperPopoverItem, StepperPopoverItemBody } from "./styled";
export const Stepper = ({ steps = [], activeStep = 0, hideButtons = false }) => {
    const [open, setOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    if (!steps.length) {
        return null;
    }
    return (_jsxs(Flex, { alignItems: "center", onMouseOver: () => !hovered && setHovered(true), onMouseLeave: () => setHovered(false), children: [_jsx(FlexSpan, { mr: "0.75rem", children: _jsx(Popover, { placement: "bottom-left", isOpen: open, offsetX: -20, offsetY: -64, onClose: () => setOpen(false), anchor: (_jsx(PrimaryButton, { buttonSize: "s", pl: 0, pr: 0, width: "2.75rem", height: "2.75rem", onClick: () => setOpen(true), children: activeStep + 1 })), children: _jsx(Flex, { column: true, width: "23.5rem", children: _jsx(FlexSpan, { width: "100%", padding: "0.75rem 1rem 0.75rem 0.75rem", children: steps.map((step, stepIndex) => {
                                const isStepActive = (stepIndex <= activeStep || ((stepIndex === activeStep + 1) && !!steps[activeStep + 1]?.onPick));
                                return (_jsxs(StepperPopoverItem, { children: [_jsx(FlexSpan, { mr: "0.75rem", children: isStepActive ? (_jsx(PrimaryButton, { buttonSize: "s", pl: 0, pr: 0, width: "2.75rem", height: "2.75rem", onClick: () => {
                                                    if (activeStep === stepIndex) {
                                                        return;
                                                    }
                                                    step.onPick && step.onPick();
                                                }, children: stepIndex < activeStep
                                                    ? (_jsx(FlexSpan, { display: "flex", children: _jsx(Icon, { icon: "check" }) })) : stepIndex + 1 })) : (_jsx(GrayButton, { buttonSize: "s", pl: 0, pr: 0, width: "2.75rem", disabled: true, style: { color: "#ffffff" }, children: stepIndex + 1 })) }), _jsx(StepperPopoverItemBody, { active: isStepActive, children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { children: _jsx(H4, { children: steps[stepIndex]?.title }) }), _jsx(FlexSpan, { children: _jsxs(StepDescription, { children: ["\u0428\u0430\u0433 ", stepIndex + 1, " \u0438\u0437 ", steps.length] }) })] }) })] }, `popover-step-${stepIndex}`));
                            }) }) }) }) }), _jsx(FlexSpan, { flexGrow: 1, children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { alignItems: "baseline", children: [_jsx(FlexSpan, { children: _jsx(H4, { children: steps[activeStep]?.title }) }), _jsx(FlexSpan, { display: "flex", ml: "0.25rem", position: "relative", top: "0.125rem", children: _jsx(IconButton, { icon: "chevron-large-bottom", onClick: () => setOpen(true) }) })] }) }), _jsx(FlexSpan, { children: _jsxs(StepDescription, { children: ["\u0428\u0430\u0433 ", activeStep + 1, " \u0438\u0437 ", steps.length] }) })] }) }), !hideButtons && hovered && (_jsx(FlexSpan, { children: _jsxs(Flex, { children: [activeStep > 0 && (_jsx(FlexSpan, { children: _jsx(StepButton, { onClick: steps[activeStep - 1]?.onPick, children: _jsx(Icon, { icon: "chevron-medium-left" }) }) })), activeStep < steps.length - 1 && (_jsx(FlexSpan, { ml: "1rem", children: _jsx(StepButton, { onClick: steps[activeStep + 1]?.onPick, disabled: !steps[activeStep + 1]?.onPick, children: _jsx(Icon, { icon: "chevron-medium-right" }) }) }))] }) }))] }));
};
